import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  Tooltip,
  Label,
  BarChart,
  Bar,
  AreaChart,
  Area,
} from "recharts";

const pdata = [
  {
    year: "2011",
    completed: 13,
    onGoing: 10,
    month: "January",
  },
  {
    year: "2012",
    completed: 15,
    onGoing: 12,
    month: "February",
  },
  {
    year: "2013",
    completed: 5,
    onGoing: 10,
    month: "March",
  },
  {
    year: "2014",
    completed: 10,
    onGoing: 5,
    month: "April",
  },
  {
    year: "2015",
    completed: 9,
    onGoing: 4,
    month: "May",
  },
  {
    year: "2016",
    completed: 10,
    onGoing: 8,
    month: "June",
  },
  {
    year: "2017",
    completed: 9,
    onGoing: 4,
    month: "July",
  },
  {
    year: "2018",
    completed: 10,
    onGoing: 8,
    month: "August",
  },
  {
    year: "2019",
    completed: 9,
    onGoing: 4,
    month: "September",
  },
  {
    year: "2020",
    completed: 10,
    onGoing: 8,
    month: "October",
  },
  {
    year: "2021",
    completed: 9,
    onGoing: 4,
    month: "November",
  },
  {
    year: "2022",
    completed: 10,
    onGoing: 8,
    month: "December",
  },
];

const BarChartComp = () => {
  return (
    <>
      <ResponsiveContainer aspect={4}>
        <BarChart data={pdata}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="month" />
          <YAxis />
          {/* <Tooltip /> */}
          {/* <Legend /> */}
          <Bar dataKey="completed" fill="#3366ff" />
          <Bar dataKey="onGoing" fill="#d6e0ff" />
        </BarChart>
      </ResponsiveContainer>
    </>
  );
};

export { BarChartComp };
