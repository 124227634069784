import React from 'react';
import { PieChart, Pie, Cell, Legend } from "recharts";




const TargetVsActual = ({ data }) => {
    const COLORS = ["#0088FE", "#FF8042", "#FFBB28", "#FF8042"];

    return (
        <PieChart width={280} height={170}>
           
            <Pie
                data={data}
                cx={135}
                cy={150}
                startAngle={180}
                endAngle={0}
                innerRadius={90}
                outerRadius={120}
                fill="#8884d8"
                paddingAngle={2}
                dataKey="value"
                label
            >
                {data.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
            </Pie>
            <Legend/>
        </PieChart>
    )
}
export default TargetVsActual;  