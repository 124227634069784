import React, { useState } from 'react';
import { FaEdit, FaTimes } from "react-icons/fa";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import currencycode from "../../helpers/currencyCodes.json";
import CustomModal from "../../Components/CustomModal";
import {
     createGatewayRoute,
     getStripeAccountsRoute,
     getPaypalAccountsRoute,
     updateGatewayByIdRoute,
     getAllWireAccountsRoute,
     getGatewayAccountByIdRoute,
     createWireBankGatewayRoute,
     updateWireGatewayByIdRoute,
     changeActiveStateByGatewayIdRoute,
     getWireGatewayAccountByIdRoute,
     getAllAuthorizenetGatewayAccountsRoute
} from '../../utils/APIRoutes';
import { toast } from "react-toastify";
import axios from "axios";
import { useEffect } from 'react';

const PaymentSettings = () => {
     const allcurrencyCode = currencycode.codes;
     const [merchantModal, setMerchantModal] = useState(false);
     const [stripeMerchants, setStripeMerchant] = useState([]);
     const [paypalMerchants, setPaypalMerchant] = useState([]);
     const [authorizenetMerchants, setAuthorizenetMerchant] = useState([]);
     const [bankMerchant, setBankMerchant] = useState([]);
     const handleMerchantStatusChange = async (mechId, merchStatus) => {
          const { data } = await axios.put(`${changeActiveStateByGatewayIdRoute}?gatewayId=${mechId}`, { active: merchStatus });
          if (data.success === true) {
               setIsLoading(false);
               getStripeMerchantDetails();
               getPaypalMerchantDetails();
               getAuthorizeMerchantDetails();
               getBankAccountLists();
               toast.success(data.msg, toastOptions, toastOptions['position'] = "top-right");
          } else {
               toast.error(data.msg, toastOptions, toastOptions['position'] = "top-left");
               setIsLoading(false);
          }
     };
     let toastOptions = {
          position: "top-left",
          autoClose: 5000,
          pauseOnHover: false,
          draggable: false,
          theme: "dark",
     };
     const [errors, setErrors] = useState({});
     const [isLoading, setIsLoading] = useState(false);
     const [merchantName, setMerchantName] = useState("");
     const [servError, setServError] = useState(false);
     // Get Merchants Details
     const getStripeMerchantDetails = async () => {
          try {
               const resp = await fetch(getStripeAccountsRoute);
               const resData = await resp.json();
               if (resData.success === true) {
                    setStripeMerchant(resData.stripe);
                    setServError(false);
               } else {
                    throw new Error('Something went wrong.');
               }
          } catch (error) {
               setServError(true);
          }
     }
     const getPaypalMerchantDetails = async () => {
          try {
               const resp = await fetch(getPaypalAccountsRoute);
               const resData = await resp.json();
               if (resData.success === true) {
                    setPaypalMerchant(resData.paypal);
                    setServError(false);
               } else {
                    throw new Error('Something went wrong.');
               }
          } catch (error) {
               setServError(true);
          }
     }
     // Get Authorize Merchants Details
     const getAuthorizeMerchantDetails = async () => {
          try {
               const resp = await fetch(getAllAuthorizenetGatewayAccountsRoute);
               const resData = await resp.json();
               if (resData.success === true) {
                    setAuthorizenetMerchant(resData.authorizenet);
                    setServError(false);
               } else {
                    throw new Error('Something went wrong.');
               }
          } catch (error) {
               setServError(true);
          }
     }
     useEffect(() => {
          getStripeMerchantDetails();
          getPaypalMerchantDetails();
          getAuthorizeMerchantDetails();
     }, [merchantModal])
     //  ---- States of inputs in the form of key value pair name:value ------ // 
     let [values, setValues] = useState({ accountName: "", gatewayName: "", clientId: "", publishableKey: "", secretKey: "", monthlyLimit: "", currencyCode: "", statusDescriptor: "" });
     const { accountName, clientId, publishableKey, secretKey, monthlyLimit, currencyCode, statusDescriptor } = values;
     const handleStripeForm = (merchant) => {
          setMerchantName(merchant);
          setMerchantModal(true);
     }
     const handlePaypalForm = (merchant) => {
          setMerchantName(merchant);
          setMerchantModal(true);
     }
     const handleAuthorizeNetForm = (merchant) => {
          setMerchantName(merchant);
          setMerchantModal(true);
     }
     // ---- Getting values of inputs ------ //  
     const handleInpChange = (event) => {
          setValues({ ...values, [event.target.name]: event.target.value });
     };
     let hasError = false;
     let hasbankError = false;
     // ---- Validate Form ------ //
     const newErrors = {};
     const validateForm = () => {
          if (accountName === "") {
               hasError = true;
               setIsLoading(false);
               newErrors.accountName = 'Account Name is required !';
          }
          if (secretKey === "") {
               hasError = true;
               setIsLoading(false);
               if (merchantName === "authorizenet") {
                    newErrors.secretKey = 'Transaction Key is required !';
               } else {
                    newErrors.secretKey = 'Secret Key is required !';
               }
          }
          if (monthlyLimit === "") {
               hasError = true;
               setIsLoading(false);
               newErrors.monthlyLimit = 'Monthly Limit is required !';
          }
          if (currencyCode === "") {
               hasError = true;
               setIsLoading(false);
               newErrors.currencyCode = 'Currency Code is required !';
          }
          setErrors(newErrors);
     };
     // ---- Form Submittion Function ------ // 
     const handleMerchant = async (event) => {
          event.preventDefault();
          setIsLoading(true);
          validateForm();
          if (hasError === false) {
               if (merchantName === "stripe") {
                    if (publishableKey === "") {
                         hasError = true;
                         setIsLoading(false);
                         newErrors.publishableKey = 'Publishable Key is required !';
                         setErrors(newErrors);
                    } else {
                         const { data } = await axios.post(`${createGatewayRoute}`, {
                              accountName: accountName.trim(),
                              gatewayName: merchantName.trim(),
                              publishableKey: publishableKey.trim(),
                              secretKey: secretKey.trim(),
                              monthlyLimit: monthlyLimit.trim(),
                              clientId: "",
                              currencyCode,
                              statusDescriptor
                         });
                         if (data.success === true) {
                              setValues({ accountName: "", gatewayName: "", clientId: "", publishableKey: "", secretKey: "", monthlyLimit: "", currencyCode: "", statusDescriptor: "" })
                              setMerchantModal(false)
                              setIsLoading(false);
                              toast.success(data.msg, toastOptions, toastOptions['position'] = "top-right");
                         } else {
                              toast.error(data.msg, toastOptions, toastOptions['position'] = "top-left");
                              setMerchantModal(false)
                              setIsLoading(false);
                         }
                    }
               } else if (merchantName === "paypal") {
                    if (clientId === "") {
                         hasError = true;
                         setIsLoading(false);
                         newErrors.clientId = 'Client Id is required !';
                         setErrors(newErrors);
                    } else {
                         const { data } = await axios.post(`${createGatewayRoute}`, {
                              accountName: accountName.trim(),
                              gatewayName: merchantName.trim(),
                              clientId: clientId.trim(),
                              publishableKey: "",
                              secretKey: secretKey.trim(),
                              monthlyLimit: monthlyLimit.trim(),
                              currencyCode,
                              statusDescriptor
                         });
                         if (data.success === true) {
                              setValues({ accountName: "", gatewayName: "", clientId: "", publishableKey: "", secretKey: "", monthlyLimit: "", currencyCode: "", statusDescriptor: "" })
                              setMerchantModal(false)
                              setIsLoading(false);
                              toast.success(data.msg, toastOptions, toastOptions['position'] = "top-right");
                         } else {
                              toast.error(data.msg, toastOptions, toastOptions['position'] = "top-left");
                              setMerchantModal(false)
                              setIsLoading(false);
                         }
                    }
               } else if (merchantName === "authorizenet") {
                    if (clientId === "") {
                         hasError = true;
                         setIsLoading(false);
                         newErrors.clientId = 'Api Login Id is required !';
                         setErrors(newErrors);
                    } else {
                         const { data } = await axios.post(`${createGatewayRoute}`, {
                              accountName: accountName.trim(),
                              gatewayName: merchantName.trim(),
                              clientId: clientId.trim(),
                              publishableKey: "",
                              secretKey: secretKey.trim(),
                              monthlyLimit: monthlyLimit.trim(),
                              currencyCode,
                              statusDescriptor
                         });
                         if (data.success === true) {
                              setValues({ accountName: "", gatewayName: "", clientId: "", publishableKey: "", secretKey: "", monthlyLimit: "", currencyCode: "", statusDescriptor: "" })
                              setMerchantModal(false)
                              setIsLoading(false);
                              toast.success(data.msg, toastOptions, toastOptions['position'] = "top-right");
                         } else {
                              toast.error(data.msg, toastOptions, toastOptions['position'] = "top-left");
                              setMerchantModal(false)
                              setIsLoading(false);
                         }
                    }
               }
          } else {
               toast.error("Kindly Fill the require fields !", toastOptions, toastOptions['position'] = "top-right");
          }
     };
     // Edit Merchant Details Modal
     const [editMerchantModal, setEditMerchantModal] = useState(false);
     const [editMerchantData, setEditMerchantData] = useState([]);

     const handleEditMerchant = async (_id) => {
          try {
               const resp = await fetch(`${getGatewayAccountByIdRoute}?gatewayId=${_id}`);
               const resData = await resp.json();
               if (resData.success === true) {
                    setEditMerchantModal(true);
                    setEditMerchantData(resData.gateway)
                    setServError(false);
               } else {
                    throw new Error('Something went wrong.');
               }
          } catch (error) {
               setServError(true);
          }
     }
     const handleEditForm = async (e) => {
          e.preventDefault();
          const { _id, clientId, gatewayName, publishableKey, secretKey, currencyCode, monthlyLimit, statusDescriptor } = editMerchantData;
          if (hasError === false) {
               if (gatewayName === "stripe") {
                    if (publishableKey === "") {
                         hasError = true;
                         setIsLoading(false);
                         newErrors.publishableKey = 'Publishable Key is required !';
                         setErrors(newErrors);
                    } else {
                         setIsLoading(true);
                         const { data } = await axios.put(`${updateGatewayByIdRoute}?gatewayId=${_id}`, {
                              clientId: "",
                              publishableKey: publishableKey.trim(),
                              secretKey: secretKey.trim(),
                              monthlyLimit: monthlyLimit.trim(),
                              currencyCode,
                              statusDescriptor,
                         });
                         if (data.success === true) {
                              setEditMerchantModal(false)
                              setIsLoading(false);
                              toast.success(data.msg, toastOptions, toastOptions['position'] = "top-right");
                         } else {
                              toast.error(data.msg, toastOptions, toastOptions['position'] = "top-left");
                              setEditMerchantModal(false)
                              setIsLoading(false);
                         }
                    }
               } else if (gatewayName === "paypal") {
                    if (clientId === "") {
                         hasError = true;
                         setIsLoading(false);
                         newErrors.clientId = 'Client Id is required !';
                         setErrors(newErrors);
                    } else {
                         setIsLoading(true);
                         const { data } = await axios.put(`${updateGatewayByIdRoute}?gatewayId=${_id}`, {
                              clientId: clientId.trim(),
                              publishableKey: "",
                              secretKey: secretKey.trim(),
                              monthlyLimit: monthlyLimit.trim(),
                              currencyCode,
                              statusDescriptor
                         });
                         if (data.success === true) {
                              setEditMerchantModal(false);
                              setIsLoading(false);
                              toast.success(data.msg, toastOptions, toastOptions['position'] = "top-right");
                         } else {
                              toast.error(data.msg, toastOptions, toastOptions['position'] = "top-left");
                              setEditMerchantModal(false);
                              setIsLoading(false);
                         }
                    }
               } else if (gatewayName === "authorizenet") {
                    if (clientId === "") {
                         hasError = true;
                         setIsLoading(false);
                         newErrors.clientId = 'Api Login Id is required !';
                         setErrors(newErrors);
                    } else {
                         setIsLoading(true);
                         const { data } = await axios.put(`${updateGatewayByIdRoute}?gatewayId=${_id}`, {
                              clientId: clientId.trim(),
                              publishableKey: "",
                              secretKey: secretKey.trim(),
                              monthlyLimit: monthlyLimit.trim(),
                              currencyCode,
                              statusDescriptor
                         });
                         if (data.success === true) {
                              setEditMerchantModal(false);
                              setIsLoading(false);
                              toast.success(data.msg, toastOptions, toastOptions['position'] = "top-right");
                         } else {
                              toast.error(data.msg, toastOptions, toastOptions['position'] = "top-left");
                              setEditMerchantModal(false);
                              setIsLoading(false);
                         }
                    }
               }
          } else {
               toast.error("Kindly Fill the require fields !", toastOptions, toastOptions['position'] = "top-right");
          }
     }
     // ---- Getting values of inputs ------ //  
     const handleEditInpChange = (event) => {
          setEditMerchantData({ ...editMerchantData, [event.target.name]: event.target.value });
     };
     // ================ Code For Bank Account ================= //
     const [bankModal, setBankModal] = useState(false);
     const [rerenderBankData, setRerenderBankData] = useState(false);
     const [bankErrors, newBankErrors] = useState({});
     const [editableBankModal, setEditableBankModal] = useState(false);
     const [bankGateWayId, setBankGateWayId] = useState("");
     // Get Bank Account Lists
     const getBankAccountLists = async () => {
          try {
               const resp = await fetch(getAllWireAccountsRoute);
               const resData = await resp.json();
               if (resData.success === true) {
                    setBankMerchant(resData.wire)
                    setServError(false);
               } else {
                    throw new Error('Something went wrong.');
               }
          } catch (error) {
               setServError(true);
          }
     }
     useEffect(() => {
          getBankAccountLists();
     }, [rerenderBankData])
     const [bankData, setBanktData] = useState({
          bankName: "",
          accountName: "",
          currencyCode: "",
          statusDescriptor: "",
          monthlyLimit: "",
          description: "",
          encryptionCode: "",
     });
     const handleBankInpChange = (event) => {
          setBanktData({ ...bankData, [event.target.name]: event.target.value });
     };
     const wireErrors = {};
     const validateBankForm = () => {
          const { bankName, accountName, currencyCode, monthlyLimit, description, encryptionCode } = bankData
          if (bankName === "") {
               setIsLoading(false);
               hasbankError = true;
               wireErrors.bankName = 'Bank Name is required !';
          }
          if (currencyCode === "") {
               setIsLoading(false);
               hasbankError = true;
               wireErrors.currencyCode = 'Currency Code is required !';
          }
          if (accountName === "") {
               setIsLoading(false);
               hasbankError = true;
               wireErrors.accountName = 'Account Title is required !';
          }
          if (monthlyLimit === "") {
               setIsLoading(false);
               hasbankError = true;
               wireErrors.monthLimit = 'Monthly Limit is required !';
          }
          if (description === "") {
               setIsLoading(false);
               hasbankError = true;
               wireErrors.desc = 'Description is required !';
          }
          if (encryptionCode === "") {
               setIsLoading(false);
               hasbankError = true;
               wireErrors.encryptionCode = 'Encryption Code is required !';
          }
          newBankErrors(wireErrors);
     }
     const handleBankform = async (event) => {
          event.preventDefault();
          setIsLoading(true);
          validateBankForm();
          if (hasbankError === false) {
               const { bankName, accountName, currencyCode, monthlyLimit, description, encryptionCode, statusDescriptor } = bankData
               if (editableBankModal === true) {
                    const { data } = await axios.put(`${updateWireGatewayByIdRoute}?gatewayId=${bankGateWayId}`, {
                         bankName,
                         accountName,
                         gatewayName: "wire",
                         currencyCode,
                         monthlyLimit,
                         statusDescriptor,
                         description,
                         encryptionCode,
                    });
                    if (data.success === true) {
                         setBankModal(false)
                         setIsLoading(false);
                         toast.success(data.msg, toastOptions, toastOptions['position'] = "top-right");
                         setBanktData({
                              bankName: "",
                              accountName: "",
                              currencyCode: "",
                              statusDescriptor: "",
                              monthlyLimit: "",
                              description: "",
                              encryptionCode: "",
                         })
                         setRerenderBankData(!rerenderBankData)
                    } else {
                         toast.error(data.msg, toastOptions, toastOptions['position'] = "top-left");
                         setMerchantModal(false)
                         setIsLoading(false);
                    }
               } else {
                    const { data } = await axios.post(`${createWireBankGatewayRoute}`, {
                         bankName,
                         accountName,
                         gatewayName: "wire",
                         currencyCode,
                         monthlyLimit,
                         statusDescriptor,
                         description,
                         encryptionCode,
                    });
                    if (data.success === true) {
                         setBanktData({ bankName: "", accountName: "", currencyCode: "", monthlyLimit: "", statusDescriptor: "", description: "", encryptionCode: "" })
                         setBankModal(false)
                         setIsLoading(false);
                         toast.success(data.msg, toastOptions, toastOptions['position'] = "top-right");
                         setRerenderBankData(!rerenderBankData)
                    } else {
                         toast.error(data.msg, toastOptions, toastOptions['position'] = "top-left");
                         setMerchantModal(false)
                         setIsLoading(false);
                    }
               }
          } else {
               toast.error("Kindly Fill the require fields !", toastOptions, toastOptions['position'] = "top-right");
          }
     }
     const handleEditBank = async (_id) => {
          try {
               const resp = await fetch(`${getWireGatewayAccountByIdRoute}?gatewayId=${_id}`);
               const resData = await resp.json();
               if (resData.success === true) {
                    setBanktData(resData.gateway);
                    setBankModal(true);
                    setServError(false);
                    setBankGateWayId(_id);
                    setEditableBankModal(true)
               } else {
                    setEditableBankModal(false)
                    throw new Error('Something went wrong.');
               }
          } catch (error) {
               setServError(true);
          }
     }
     const closeEditableModal = () => {
          setBanktData({
               bankName: "",
               accountName: "",
               currencyCode: "",
               statusDescriptor: "",
               monthlyLimit: "",
               description: "",
               encryptionCode: "",
          })
          setBankModal(false)
     }

     return (
          <>
               <h6 className='sectionHeading my-3 mb-4'>Payment Settings</h6>
               <div className="paymentSettingsTabs">
                    {servError === true ? <p className='text-center fw-600 text-danger text-capitalize'>Something went wrong please try again later ! (server Error 500)</p> : (
                         <Tabs defaultActiveKey="stripe" id="fill-tab-example" className="mb-3" fill>
                              <Tab eventKey="stripe" title="Stripe">
                                   {
                                        stripeMerchants.length > 0 ? (
                                             stripeMerchants?.map((stripeData) => {
                                                  const { _id, accountName, gatewayName, currencyCode, active } = stripeData;
                                                  return (
                                                       <div className='my-4' key={_id}>
                                                            <div className={active === true ? "merchatBox bgLtGreen" : "merchatBox bgLtRed"}>
                                                                 <div className="stripeMark">
                                                                      <span className='fw-700 fs-11 text-capitalize'>{gatewayName || "Stripe"}</span>
                                                                 </div>
                                                                 <div className="merchatDetails">
                                                                      <p className='mb-0  fs-13 fw-600 text-capitalize'>{accountName || "--"}</p>
                                                                      <p className='mb-0 fs-13 fw-400'>{currencyCode || "--"}</p>
                                                                 </div>
                                                                 <input checked={active} onChange={() => handleMerchantStatusChange(_id, !active)} type="checkbox" className='customToggleBtn' />
                                                                 <button onClick={() => handleEditMerchant(_id)} className='smEditBtn'><FaEdit /></button>
                                                            </div>
                                                       </div>
                                                  )
                                             })
                                        ) : ""
                                   }
                                   <div className="text-end">
                                        <button className='Btn main-btn' disabled={isLoading} onClick={() => handleStripeForm("stripe")} >Add Stripe Account</button>
                                   </div>
                              </Tab>
                              <Tab eventKey="paypal" title="Paypal">
                                   {
                                        paypalMerchants.length > 0 ? (
                                             paypalMerchants?.map((paypalData) => {
                                                  const { _id, accountName, gatewayName, active } = paypalData;
                                                  return (
                                                       <div className='my-4' key={_id}>
                                                            <div className={active === true ? "merchatBox bgLtGreen" : "merchatBox bgLtRed"}>
                                                                 <div className="stripeMark">
                                                                      <span className='fw-700 fs-11 text-capitalize'>{gatewayName || "Stripe"}</span>
                                                                 </div>
                                                                 <div className="merchatDetails">
                                                                      <p className='mb-0 fs-13 fw-600'>{accountName || "--"}</p>
                                                                 </div>
                                                                 <input checked={active} onChange={() => handleMerchantStatusChange(_id, !active)} type="checkbox" className='customToggleBtn' />
                                                                 <button onClick={() => handleEditMerchant(_id, gatewayName)} className='smEditBtn'><FaEdit /></button>
                                                            </div>
                                                       </div>
                                                  )
                                             })
                                        ) : ""
                                   }
                                   <div className="text-end">
                                        <button className='Btn main-btn' onClick={() => handlePaypalForm("paypal")}>Add Paypal Account</button>
                                   </div>
                              </Tab>
                              <Tab eventKey="authorizenet" title="Authorize.Net">
                                   {
                                        authorizenetMerchants.length > 0 ? (
                                             authorizenetMerchants?.map((authorizeData) => {
                                                  const { _id, accountName, gatewayName, currencyCode, active } = authorizeData;
                                                  return (
                                                       <div className='my-4' key={_id}>
                                                            <div className={active === true ? "merchatBox bgLtGreen" : "merchatBox bgLtRed"}>
                                                                 <div className="stripeMark">
                                                                      <span className='fw-700 fs-11 text-capitalize'>{gatewayName || "Stripe"}</span>
                                                                 </div>
                                                                 <div className="merchatDetails">
                                                                      <p className='mb-0  fs-13 fw-600 text-capitalize'>{accountName || "--"}</p>
                                                                      <p className='mb-0 fs-13 fw-400'>{currencyCode || "--"}</p>
                                                                 </div>
                                                                 <input checked={active} onChange={() => handleMerchantStatusChange(_id, !active)} type="checkbox" className='customToggleBtn' />
                                                                 <button onClick={() => handleEditMerchant(_id)} className='smEditBtn'><FaEdit /></button>
                                                            </div>
                                                       </div>
                                                  )
                                             })
                                        ) : ""
                                   }
                                   <div className="text-end">
                                        <button className='Btn main-btn' onClick={() => handleAuthorizeNetForm("authorizenet")}>Add Authorize.Net Account</button>
                                   </div>
                              </Tab>
                              <Tab eventKey="wire" title="Wire / Bank">
                                   {
                                        bankMerchant.length > 0 ? (
                                             bankMerchant?.map((bankList) => {
                                                  const { _id, accountName, gatewayName, active, bankName, encryptionCode } = bankList;
                                                  return (
                                                       <div className='my-4' key={_id}>
                                                            <div className={active === true ? "merchatBox bgLtGreen" : "merchatBox bgLtRed"}>
                                                                 <div className="stripeMark">
                                                                      <span className='fw-700 fs-11 text-capitalize'>{gatewayName || "Wire/Bank"}</span>
                                                                 </div>
                                                                 <div className="merchatDetails">
                                                                      <p className='mb-0 fs-13 fw-600'>{accountName || "--"}</p>
                                                                      <p className='mb-0 fs-13 fw-600'><small>{bankName || "--"}</small></p>
                                                                      <small><span className='fw-600'>Decryption Code:</span> {encryptionCode || "--"}</small>
                                                                 </div>
                                                                 <input checked={active} onChange={() => handleMerchantStatusChange(_id, !active)} type="checkbox" className='customToggleBtn' />
                                                                 <button onClick={() => handleEditBank(_id, gatewayName)} className='smEditBtn'><FaEdit /></button>
                                                            </div>
                                                       </div>
                                                  )
                                             })
                                        ) : ""
                                   }
                                   <div className="text-end">
                                        <button className='Btn main-btn' onClick={() => setBankModal(true)}>Add Bank</button>
                                   </div>
                              </Tab>
                         </Tabs>
                    )}
               </div>
               {/*================================ Merchant Modal ========================= */}
               <CustomModal show={merchantModal} onHide={() => setMerchantModal(false)} backdrop='static' keyboard="False">
                    <button onClick={() => setMerchantModal(false)} className="modal-cancel"><FaTimes /></button>
                    <div className="modalContent">
                         <h3 className="modalPrimHeading my-2"> </h3>
                         {/* modal buttons */}
                         <form onSubmit={(event) => handleMerchant(event)}>
                              <div className="text-start">
                                   <div className="modalText">
                                        <h4 className='fw-600 text-capitalize'>Add New {merchantName || "Merchant"} Account</h4>
                                        <p className='fw-400'>Please fill up necessary information in the form.</p>
                                   </div>
                                   <div className="my-3">
                                        <label className="text-bold mb-1" htmlFor="accountName">Account Name:<span className="text-danger">*</span></label>
                                        <input
                                             value={accountName}
                                             onChange={(e) => handleInpChange(e)}
                                             name="accountName"
                                             id="accountName"
                                             type="text"
                                             className="filterSelect ps-3"
                                             placeholder='Enter Account Name' />
                                        {errors.accountName && <p className="text-danger fw-500 fs-13 my-2 text-capitalize">{errors.accountName}</p>}
                                   </div>
                                   {
                                        merchantName === "stripe" ? (
                                             <div className="my-3">
                                                  <label className="text-bold mb-1" htmlFor="publishableKey">Publishable Key<span className="text-danger">*</span></label>
                                                  <input
                                                       value={publishableKey}
                                                       onChange={(e) => handleInpChange(e)}
                                                       name="publishableKey"
                                                       id="publishableKey"
                                                       type="text"
                                                       className="filterSelect ps-3"
                                                       placeholder='Enter Publishable Key' />
                                                  {errors.publishableKey && <p className="text-danger fw-500 fs-13 my-2 text-capitalize">{errors.publishableKey}</p>}
                                             </div>
                                        ) : merchantName === "paypal" || merchantName === "authorizenet" ? (
                                             <div div className="my-3">
                                                  <label className="text-bold mb-1" htmlFor="clientId">{merchantName === "authorizenet" ? "Api Login Id:" : "Client Id"} <span className="text-danger">*</span></label>
                                                  <input
                                                       value={clientId}
                                                       onChange={(e) => handleInpChange(e)}
                                                       name="clientId"
                                                       id="clientId"
                                                       type="text"
                                                       className="filterSelect ps-3"
                                                       placeholder={merchantName === "authorizenet" ? "Enter Api Login Id" : "Enter Client Id"}
                                                  />
                                                  {errors.clientId && <p className="text-danger fw-500 fs-13 my-2 text-capitalize">{errors.clientId}</p>}
                                             </div>
                                        ) : ""
                                   }
                                   <div className="my-3">
                                        <label className="text-bold mb-1" htmlFor="secretKey">{merchantName === "authorizenet" ? "Transaction Key" : "Secret Key"} <span className="text-danger">*</span></label>
                                        <input
                                             value={secretKey}
                                             onChange={(e) => handleInpChange(e)}
                                             name="secretKey"
                                             id="secretKey"
                                             type="text"
                                             className="filterSelect ps-3"
                                             placeholder={merchantName === "authorizenet" ? "Enter Transaction Key" : "Enter Secret Id"}
                                        />
                                        {errors.secretKey && <p className="text-danger fw-500 fs-13 my-2 text-capitalize">{errors.secretKey}</p>}
                                   </div>
                                   <div className="my-3">
                                        <label className="text-bold mb-1" htmlFor="monthlyLimit">Monthly Limit<span className="text-danger">*</span></label>
                                        <input
                                             value={monthlyLimit}
                                             onChange={(e) => handleInpChange(e)}
                                             name="monthlyLimit"
                                             id="monthlyLimit"
                                             type="number"
                                             className="filterSelect ps-3"
                                             placeholder='Enter Monthly Limit' />
                                        {errors.monthlyLimit && <p className="text-danger fw-500 fs-13 my-2 text-capitalize">{errors.monthlyLimit}</p>}
                                   </div>
                                   <div className="my-3">
                                        <label className="text-bold mb-1" htmlFor="statusDescriptor">Status Descriptor</label>
                                        <input
                                             value={statusDescriptor}
                                             onChange={(e) => handleInpChange(e)}
                                             name="statusDescriptor"
                                             id="statusDescriptor"
                                             type="text"
                                             className="filterSelect ps-3"
                                             placeholder='Enter Status Descriptor' />
                                   </div>
                                   <div className="my-3">
                                        <label className="text-bold mb-1" htmlFor="currencyCode">Currency:</label>
                                        <select
                                             className="filterSelect ps-3"
                                             id="currencyCode"
                                             name='currencyCode'
                                             value={currencyCode}
                                             onChange={(e) => handleInpChange(e)}>
                                             <option value="">Select Option</option>
                                             {Object.keys(allcurrencyCode).map((objkey, index) => {
                                                  return (
                                                       <option key={index} value={objkey}>{`${allcurrencyCode[objkey]} (${objkey})`}</option>
                                                  );
                                             })}
                                        </select>
                                        {errors.currencyCode && <p className="text-danger fw-500 fs-13 my-2 text-capitalize">{errors.currencyCode}</p>}
                                   </div>
                              </div>
                              <button className="main-btn m-2" disabled={isLoading} type="submit">{isLoading === true ? "Submiting..." : "Submit"}</button>
                              <button className="sec-btn m-2" type="button" onClick={() => setMerchantModal(false)}>Cancel </button>
                         </form>
                    </div>
               </CustomModal>
               {/*================================ Edit Merchant Modal ========================= */}
               <CustomModal show={editMerchantModal} onHide={() => setEditMerchantModal(false)} backdrop='static' keyboard="False" >
                    <button onClick={() => setEditMerchantModal(false)} className="modal-cancel"><FaTimes /></button>
                    <div className="modalContent">
                         <h3 className="modalPrimHeading my-2"> </h3>
                         {/* modal buttons */}
                         <form onSubmit={(event) => handleEditForm(event)}>
                              <div className="text-start">
                                   <div className="modalText">
                                        <h4 className='fw-600 text-capitalize'>Edit {editMerchantData.gatewayName || "Merchant"} Account</h4>
                                        <p className='fw-400'>Please Edit  up necessary informaiton in the form.</p>
                                   </div>
                                   <div className="my-3">
                                        <label className="text-bold mb-1" htmlFor="accountName">Account Name:<span className="text-danger">*</span></label>
                                        <input
                                             value={editMerchantData.accountName}
                                             onChange={(e) => handleEditInpChange(e)}
                                             name="accountName"
                                             id="accountName"
                                             type="text"
                                             disabled={true}
                                             className="filterSelect ps-3"
                                             placeholder='Enter Account Name' />
                                        {errors.accountName && <p className="text-danger fw-500 fs-13 my-2 text-capitalize">{errors.accountName}</p>}
                                   </div>
                                   {
                                        editMerchantData.gatewayName === "stripe" ? (
                                             <div className="my-3">
                                                  <label className="text-bold mb-1" htmlFor="publishableKey">Publishable Key<span className="text-danger">*</span></label>
                                                  <input
                                                       value={editMerchantData.publishableKey}
                                                       onChange={(e) => handleEditInpChange(e)}
                                                       name="publishableKey"
                                                       id="publishableKey"
                                                       type="text"
                                                       className="filterSelect ps-3"
                                                       placeholder='Enter Publishable Key' />
                                                  {errors.publishableKey && <p className="text-danger fw-500 fs-13 my-2 text-capitalize">{errors.publishableKey}</p>}
                                             </div>
                                        ) : editMerchantData.gatewayName === "paypal" || editMerchantData.gatewayName === "authorizenet" ? (
                                             <div className="my-3">
                                                  <label className="text-bold mb-1" htmlFor="clientId">{editMerchantData.gatewayName === "authorizenet" ? "Api Login Id:" : "Client Id"}<span className="text-danger">*</span></label>
                                                  <input
                                                       value={editMerchantData.clientId}
                                                       onChange={(e) => handleEditInpChange(e)}
                                                       name="clientId"
                                                       id="clientId"
                                                       type="text"
                                                       className="filterSelect ps-3"
                                                       placeholder={editMerchantData.gatewayName === "authorizenet" ? "Enter Api Login Id" : "Enter Client Id"} />
                                                  {errors.clientId && <p className="text-danger fw-500 fs-13 my-2 text-capitalize">{errors.clientId}</p>}
                                             </div>
                                        ) : ""
                                   }
                                   <div className="my-3">
                                        <label className="text-bold mb-1" htmlFor="secretKey">{editMerchantData.gatewayName === "authorizenet" ? "Transaction Key" : "Secret Key"}<span className="text-danger">*</span></label>
                                        <input
                                             value={editMerchantData.secretKey}
                                             onChange={(e) => handleEditInpChange(e)}
                                             name="secretKey"
                                             id="secretKey"
                                             type="text"
                                             className="filterSelect ps-3"
                                             placeholder={editMerchantData.gatewayName === "authorizenet" ? "Enter Transaction Key" : "Enter Secret Id"}
                                        />
                                        {errors.secretKey && <p className="text-danger fw-500 fs-13 my-2 text-capitalize">{errors.secretKey}</p>}
                                   </div>
                                   <div className="my-3">
                                        <label className="text-bold mb-1" htmlFor="monthlyLimit">Monthly Limit<span className="text-danger">*</span></label>
                                        <input
                                             value={editMerchantData.monthlyLimit}
                                             onChange={(e) => handleEditInpChange(e)}
                                             name="monthlyLimit"
                                             id="monthlyLimit"
                                             type="number"
                                             className="filterSelect ps-3"
                                             placeholder='Enter Monthly Limit' />
                                        {errors.monthlyLimit && <p className="text-danger fw-500 fs-13 my-2 text-capitalize">{errors.monthlyLimit}</p>}
                                   </div>
                                   <div className="my-3">
                                        <label className="text-bold mb-1" htmlFor="currencyCode">Currency:</label>
                                        <select
                                             className="filterSelect ps-3"
                                             id="currencyCode"
                                             name='currencyCode'
                                             value={editMerchantData.currencyCode}
                                             onChange={(e) => handleEditInpChange(e)}>
                                             <option value="">Select Option</option>
                                             {Object.keys(allcurrencyCode).map((objkey, index) => {
                                                  return (
                                                       <option key={index} value={objkey}>{`${allcurrencyCode[objkey]} (${objkey})`}</option>
                                                  );
                                             })}
                                        </select>
                                        {errors.currencyCode && <p className="text-danger fw-500 fs-13 my-2 text-capitalize">{errors.currencyCode}</p>}
                                   </div>
                                   <div className="my-3">
                                        <label className="text-bold mb-1" htmlFor="statusDescriptor">Status Descriptor</label>
                                        <input
                                             value={editMerchantData.statusDescriptor}
                                             onChange={(e) => handleEditInpChange(e)}
                                             name="statusDescriptor"
                                             id="statusDescriptor"
                                             type="text"
                                             className="filterSelect ps-3"
                                             placeholder='Enter Status Descriptor' />
                                   </div>
                              </div>
                              <button className="main-btn m-2" disabled={isLoading} type="submit">{isLoading === true ? "Submiting..." : "Submit"}</button>
                              <button className="sec-btn m-2" type="button" onClick={() => setEditMerchantModal(false)}>Cancel </button>
                         </form>
                    </div>
               </CustomModal>
               {/*================================ Wire --/-- Bank Modal ========================= */}
               <CustomModal show={bankModal} onHide={closeEditableModal} backdrop='static' keyboard="False" >
                    <button onClick={closeEditableModal} className="modal-cancel"><FaTimes /></button>
                    <div className="modalContent">
                         <form onSubmit={(event) => handleBankform(event)}>
                              <div className="text-start">
                                   <div className="modalText">
                                        <h4 className='fw-600 text-capitalize'>Add Bank Account</h4>
                                   </div>
                                   <div className="my-3">
                                        <label className="text-bold mb-1" htmlFor="bankName">Bank Name:<span className="text-danger">*</span></label>
                                        <input
                                             value={bankData.bankName}
                                             onChange={(e) => handleBankInpChange(e)}
                                             name="bankName"
                                             id="bankName"
                                             type="text"
                                             className="filterSelect ps-3"
                                             placeholder='Enter Account Name' />
                                        {bankErrors.bankName && <p className="text-danger fw-500 fs-13 my-2 text-capitalize">{bankErrors.bankName}</p>}
                                   </div>
                                   <div className="my-3">
                                        <label className="text-bold mb-1" htmlFor="accountName">Account Title:<span className="text-danger">*</span></label>
                                        <input
                                             value={bankData.accountName}
                                             onChange={(e) => handleBankInpChange(e)}
                                             name="accountName"
                                             id="accountName"
                                             type="text"
                                             className="filterSelect ps-3"
                                             placeholder='Enter Account Name' />
                                        {bankErrors.accountName && <p className="text-danger fw-500 fs-13 my-2 text-capitalize">{bankErrors.accountName}</p>}
                                   </div>
                                   <div className="my-3">
                                        <label className="text-bold mb-1" htmlFor="monthlyLimit">Monthly Limit<span className="text-danger">*</span></label>
                                        <input
                                             value={bankData.monthlyLimit}
                                             onChange={(e) => handleBankInpChange(e)}
                                             name="monthlyLimit"
                                             id="monthlyLimit"
                                             type="number"
                                             className="filterSelect ps-3"
                                             placeholder='Enter Monthly Limit' />
                                        {bankErrors.monthlyLimit && <p className="text-danger fw-500 fs-13 my-2 text-capitalize">{bankErrors.monthlyLimit}</p>}
                                   </div>
                                   <div className="my-3">
                                        <label className="text-bold mb-1" htmlFor="currencyCode">Currency:<span className="text-danger">*</span></label>
                                        <select
                                             className="filterSelect ps-3"
                                             id="currencyCode"
                                             name='currencyCode'
                                             value={bankData.currencyCode}
                                             onChange={(e) => handleBankInpChange(e)}>
                                             <option value="">Select Option</option>
                                             {Object.keys(allcurrencyCode).map((objkey, index) => {
                                                  return (
                                                       <option key={index} value={objkey}>{`${allcurrencyCode[objkey]} (${objkey})`}</option>
                                                  );
                                             })}
                                        </select>
                                        {bankErrors.currencyCode && <p className="text-danger fw-500 fs-13 my-2 text-capitalize">{bankErrors.currencyCode}</p>}
                                   </div>
                                   <div className="my-3">
                                        <label className="text-bold mb-1" htmlFor="encryptionCode">Decryption Code:<span className="text-danger">*</span></label>
                                        <input
                                             value={bankData.encryptionCode}
                                             onChange={(e) => handleBankInpChange(e)}
                                             name="encryptionCode"
                                             id="encryptionCode"
                                             type="text"
                                             className="filterSelect ps-3"
                                             placeholder='Enter Decryption Code' />
                                        {bankErrors.encryptionCode && <p className="text-danger fw-500 fs-13 my-2 text-capitalize">{bankErrors.encryptionCode}</p>}
                                   </div>
                                   <div className="my-3">
                                        <label className="text-bold mb-1" htmlFor="statusDescriptor">Status Descriptor</label>
                                        <input
                                             value={bankData.statusDescriptor}
                                             onChange={(e) => handleBankInpChange(e)}
                                             name="statusDescriptor"
                                             id="statusDescriptor"
                                             type="text"
                                             className="filterSelect ps-3"
                                             placeholder='Enter Status Descriptor'
                                        />
                                   </div>
                                   <div className="my-3">
                                        <label className="text-bold mb-1" htmlFor="description">Account Details:<span className="text-danger">*</span></label>
                                        <textarea
                                             value={bankData.description}
                                             onChange={(e) => handleBankInpChange(e)}
                                             name="description"
                                             id="description"
                                             type="text"
                                             className="filterSelect ps-3"
                                             placeholder='Enter Account Details'></textarea>
                                        {bankErrors.description && <p className="text-danger fw-500 fs-13 my-2 text-capitalize">{bankErrors.description}</p>}
                                   </div>
                              </div>
                              <button className="main-btn m-2" disabled={isLoading} type="submit">{isLoading === true ? "Submiting..." : "Submit"}</button>
                              <button className="sec-btn m-2" type="button" onClick={closeEditableModal}>Cancel </button>
                         </form>
                    </div>
               </CustomModal>
          </>
     );
}

export default PaymentSettings;