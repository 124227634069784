import React from 'react';
import { Player } from '@lottiefiles/react-lottie-player';

const Congratulate = () => {
     return (
          <>
               <h3 className='primHeading'><span className='colGreen'>Congratulations</span> , your payment was successful🎉</h3>
               <Player
                    src='https://assets9.lottiefiles.com/packages/lf20_ufoeuntu.json'
                    className="lottie-success" loop autoplay />
               <p>We appreciate your business and hope you enjoy your purchase!</p>
          </>
     );
}

export default Congratulate;
