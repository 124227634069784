import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { Link } from 'react-router-dom';
import Title from "../../hook/Title";
import { getSaleKpiDashboardDataRoute } from "../../utils/APIRoutes";
import axios from "axios";
import Loader from '../../utils/Loader';
import { FaCoins } from "react-icons/fa";
import { BsCashCoin, BsCart4, BsFillBoxSeamFill, BsPeople, BsGraphUpArrow } from "react-icons/bs";
import { SlCalender } from "react-icons/sl";
import { CgSearchLoading } from "react-icons/cg";
import { BiPurchaseTag } from "react-icons/bi";
import { RiUserSearchLine, RiFilter2Fill } from "react-icons/ri";
import { PiGraphDuotone } from "react-icons/pi";
import { MdLeaderboard } from "react-icons/md";



import { useSelector } from "react-redux";

const SalesKPIDashboard = () => {
    Title("Sales KPI Dashboard");

    const [filterLoader, setFilterLoader] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [month, setMonth] = useState(new Date().getMonth().toString());
    const [year, setYear] = useState(new Date().getFullYear().toString());
    const [incomingBarkLeadsCount, setIncomingBarkLeadsCount] = useState(0)
    const [convertedBarkLeadsCount, setConvertedBarkLeadsCount] = useState(0)
    const [barkLeadsSales, setBarkLeadsSales] = useState([])
    const [incomingSeoLeadsCount, setIncomingSeoLeadsCount] = useState(0)
    const [convertedSeoLeadsCount, setConvertedSeoLeadsCount] = useState(0)
    const [seoLeadsSales, setSeoLeadsSales] = useState([])
    const [incomingPpcLeadsCount, setIncomingPpcLeadsCount] = useState(0)
    const [convertedPpcLeadsCount, setConvertedPpcLeadsCount] = useState(0)
    const [ppcLeadsSales, setPpcLeadsSales] = useState([])

    // getting value of authenticated user using redux persistor
    //   const AuthReducer = useSelector((state) => state.AuthReducer);
    //   const { empId, designation } = AuthReducer.data;

    const clearFilters = () => {
        setMonth(new Date().getMonth().toString())
        setYear(new Date().getFullYear().toString())
    }

    const cyear = new Date().getFullYear();
    const previousYears = [];
    for (let i = 0; i <= 9; i++) {
        previousYears.push((cyear - i).toString());
    }

    const getSalesKpiDashboardData = async () => {
        try {
            setFilterLoader(true)
            const data = await axios.get(`${getSaleKpiDashboardDataRoute}?monthIndex=${month}&year=${year}`)
            if (data.data.success === true) {
                // console.log(data.data)
                setIncomingBarkLeadsCount(data?.data?.data?.incomingBarkLeadsCount);
                setConvertedBarkLeadsCount(data?.data?.data?.convertedBarkLeadsCount);
                setBarkLeadsSales(data?.data?.data?.barkLeadsSales);
                setIncomingSeoLeadsCount(data?.data?.data?.incomingSeoLeadsCount);
                setConvertedSeoLeadsCount(data?.data?.data?.convertedSeoLeadsCount);
                setSeoLeadsSales(data?.data?.data?.seoLeadsSales);
                setIncomingPpcLeadsCount(data?.data?.data?.incomingPpcLeadsCount);
                setConvertedPpcLeadsCount(data?.data?.data?.convertedPpcLeadsCount);
                setPpcLeadsSales(data?.data?.data?.ppcLeadsSales);
                // console.log(data?.data?.data)

                // setIsLoading(false)
                setFilterLoader(false)
            }
        } catch (err) {
            console.log(err);
            setFilterLoader(false)
             // setIsLoading(false);
        }
    }

    useEffect(() => {
        getSalesKpiDashboardData();
        setIsLoading(false)
    }, [month, year]);



    return (
        <>
            <h4 className="primHeading my-3">
                Sales KPI Dashboard
            </h4>
            {
                isLoading === true ? (
                    <Loader />
                ) : (
                    <>
                        <Row className="customFilters mb-3">
                            {/* <Col xl={4} lg={4} md={12} sm={12} className="my-2">
                                <label className="text-bold" htmlFor="SalePersonSelect">Sales Person:</label>
                                <select className='primSelect prim-date white' id='YearSelect' onChange={handleYearChange}>
                                    <option value=" ">Select SalesPerson</option>
                                    {previousYears.map((year, index) => {
                                        return (<option key={index} value={year}>{year}</option>)
                                    })}
                                </select>
                            </Col> */}
                            <Col xxl={2} xl={3} lg={3} md={4} sm={4} className="my-2">
                                <label className="text-bold" htmlFor="MonthSelect">Month:</label>
                                <select className='primSelect prim-date white' id='MonthSelect' value={month} onChange={(e) => { setMonth(e.target.value) }}>
                                    <option value="-1">Select Month</option>
                                    <option value="0">January</option>
                                    <option value="1">February</option>
                                    <option value="2">March</option>
                                    <option value="3">April</option>
                                    <option value="4">May</option>
                                    <option value="5">June</option>
                                    <option value="6">July</option>
                                    <option value="7">August</option>
                                    <option value="8">September</option>
                                    <option value="9">October</option>
                                    <option value="10">November</option>
                                    <option value="11">December</option>
                                </select>
                            </Col>
                            <Col xxl={2} xl={3} lg={3} md={4} sm={4} className="my-2">
                                <label className="text-bold" htmlFor="YearSelect">By Year:</label>
                                <select className='primSelect prim-date white' id='YearSelect' value={year} onChange={(e) => { setYear(e.target.value) }}>
                                    <option value=" ">Select Year</option>
                                    {previousYears.map((year, index) => {
                                        return (<option key={index} value={year}>{year}</option>)
                                    })}
                                </select>
                            </Col>
                            {
                                filterLoader ? (<Col lg={2} md={6} sm={12} className="my-2 position-relative">
                                    <div className="preloaderWrapper">
                                        <div className="preloaderMainWrapper">
                                            <div className="smLoader">
                                                <div className="smdot"></div>
                                            </div>
                                            <div className="smLoader">
                                                <div className="smdot"></div>
                                            </div>
                                            <div className="loader">
                                                <div className="smdot"></div>
                                            </div>
                                            <div className="smLoader">
                                                <div className="smdot"></div>
                                            </div>
                                            <div className="smLoader">
                                                <div className="smdot"></div>
                                            </div>
                                            <div className="smLoader">
                                                <div className="smdot"></div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>) : (<Col xl={2} lg={4} md={2} sm={2} className="my-2">
                                    <button onClick={clearFilters} title="Clear Date Filters" className="main-btn clearFilter w-100 mt-4">Clear</button>
                                </Col>)
                            }
                        </Row>
                        <Row>
                            <Col xxl={2} xl={3} lg={3} sm={4} className="analyticsWrap1 mb-2 mx-auto">
                                <Link to="#">
                                    <div className="dashboard-anatlytics">
                                        <div className="d-flex gap-2">
                                            <span className='d-block mx-auto'><BsCashCoin className="kpiIcon text-warning" /></span>

                                        </div>
                                        <div className=''>
                                            <div className="text-center">
                                                <h4 className='fw-600 mb-0 font-xl'>2300</h4>
                                                <h3 className='fs-14 fw-400 colGray mb-0'>Sales Amount</h3>
                                                <hr className="sp-hr" />
                                                {/* {totalLeadPercent === null || totalLeadPercent === undefined ? <small>Not Computed</small> : <h4 className={`mb-0 ${totalLeadPercent < 0 ? "text-danger" : ""}`}> {totalLeadPercent <= 0 ? <BsArrowDownLeft /> : <BsArrowUpRight />} {totalLeadPercent} / <small className="fs-13 text-capitalize colGray">month</small> </h4>} */}
                                            </div>
                                        </div>

                                    </div>
                                </Link>
                            </Col>
                            <Col xxl={2} xl={3} lg={3} sm={4} className="analyticsWrap1 mb-2 mx-auto">
                                <Link to="#">
                                    <div className="dashboard-anatlytics">
                                        <div className="d-flex gap-2">
                                            <span className='d-block mx-auto'><FaCoins className="kpiIcon text-warning" /></span>

                                        </div>
                                        <div className=''>
                                            <div className="text-center">
                                                <h4 className='fw-600 mb-0 font-xl'>2300</h4>
                                                <h3 className='fs-14 fw-400 colGray mb-0'>Sales + Marketing Cost</h3>
                                                <hr className="sp-hr" />
                                                {/* {totalLeadPercent === null || totalLeadPercent === undefined ? <small>Not Computed</small> : <h4 className={`mb-0 ${totalLeadPercent < 0 ? "text-danger" : ""}`}> {totalLeadPercent <= 0 ? <BsArrowDownLeft /> : <BsArrowUpRight />} {totalLeadPercent} / <small className="fs-13 text-capitalize colGray">month</small> </h4>} */}
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </Col>
                            <Col xxl={2} xl={3} lg={3} sm={4} className="analyticsWrap1 mb-2 mx-auto">
                                <Link to="#">
                                    <div className="dashboard-anatlytics">
                                        <div className="d-flex gap-2">
                                            <span className='d-block mx-auto'><BsGraphUpArrow className="kpiIcon text-secondary" /></span>

                                        </div>
                                        <div className=''>
                                            <div className="text-center">
                                                <h4 className='fw-600 mb-0 font-xl'>86.0%</h4>
                                                <h3 className='fs-14 fw-400 colGray mb-0'>Gross Profit Margin</h3>
                                                <hr className="sp-hr" />
                                                {/* {totalLeadPercent === null || totalLeadPercent === undefined ? <small>Not Computed</small> : <h4 className={`mb-0 ${totalLeadPercent < 0 ? "text-danger" : ""}`}> {totalLeadPercent <= 0 ? <BsArrowDownLeft /> : <BsArrowUpRight />} {totalLeadPercent} / <small className="fs-13 text-capitalize colGray">month</small> </h4>} */}
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </Col>
                            <Col xxl={2} xl={3} lg={3} sm={4} className="analyticsWrap1 mb-2 mx-auto">
                                <Link to="#">
                                    <div className="dashboard-anatlytics">
                                        <div className="d-flex gap-2">
                                            <span className='d-block mx-auto'><SlCalender className="kpiIcon text-secondary" /></span>
                                        </div>
                                        <div className=''>
                                            <div className="text-center">
                                                <h4 className='fw-600 mb-0 font-xl'>2300</h4>
                                                <h3 className='fs-14 fw-400 colGray mb-0'>Avg. Daily Sales</h3>
                                                <hr className="sp-hr" />
                                                {/* {totalLeadPercent === null || totalLeadPercent === undefined ? <small>Not Computed</small> : <h4 className={`mb-0 ${totalLeadPercent < 0 ? "text-danger" : ""}`}> {totalLeadPercent <= 0 ? <BsArrowDownLeft /> : <BsArrowUpRight />} {totalLeadPercent} / <small className="fs-13 text-capitalize colGray">month</small> </h4>} */}
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </Col>
                            <Col xxl={2} xl={3} lg={3} sm={4} className="analyticsWrap1 mb-2 mx-auto">
                                <Link to="#">
                                    <div className="dashboard-anatlytics">
                                        <div className="d-flex gap-2">
                                            <span className='d-block mx-auto'><BsCart4 className="kpiIcon text-secondary" /></span>
                                        </div>
                                        <div className=''>
                                            <div className="text-center">
                                                <h4 className='fw-600 mb-0 font-xl'>2300</h4>
                                                <h3 className='fs-14 fw-400 colGray mb-0'>Orders Placed</h3>
                                                <hr className="sp-hr" />
                                                {/* {totalLeadPercent === null || totalLeadPercent === undefined ? <small>Not Computed</small> : <h4 className={`mb-0 ${totalLeadPercent < 0 ? "text-danger" : ""}`}> {totalLeadPercent <= 0 ? <BsArrowDownLeft /> : <BsArrowUpRight />} {totalLeadPercent} / <small className="fs-13 text-capitalize colGray">month</small> </h4>} */}
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </Col>
                            <Col xxl={2} xl={3} lg={3} sm={4} className="analyticsWrap1 mb-2 mx-auto">
                                <Link to="#">
                                    <div className="dashboard-anatlytics">
                                        <div className="d-flex gap-2">
                                            <span className='d-block mx-auto'><CgSearchLoading className="kpiIcon text-secondary" /></span>
                                        </div>
                                        <div className=''>
                                            <div className="text-center">
                                                <h4 className='fw-600 mb-0 font-xl'>230</h4>
                                                <h3 className='fs-14 fw-400 colGray mb-0'>Avg. Sales Ticket</h3>
                                                <hr className="sp-hr" />
                                                {/* {totalLeadPercent === null || totalLeadPercent === undefined ? <small>Not Computed</small> : <h4 className={`mb-0 ${totalLeadPercent < 0 ? "text-danger" : ""}`}> {totalLeadPercent <= 0 ? <BsArrowDownLeft /> : <BsArrowUpRight />} {totalLeadPercent} / <small className="fs-13 text-capitalize colGray">month</small> </h4>} */}
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </Col>
                            <Col xxl={2} xl={3} lg={3} sm={4} className="analyticsWrap1 mb-2 mx-auto">
                                <Link to="#">
                                    <div className="dashboard-anatlytics">
                                        <div className="d-flex gap-2">
                                            <span className='d-block mx-auto'><BiPurchaseTag className="kpiIcon text-danger" /></span>
                                        </div>
                                        <div className=''>
                                            <div className="text-center">
                                                <h4 className='fw-600 mb-0 font-xl'>2300</h4>
                                                <h3 className='fs-14 fw-400 colGray mb-0'>Items Purchased</h3>
                                                <hr className="sp-hr" />
                                                {/* {totalLeadPercent === null || totalLeadPercent === undefined ? <small>Not Computed</small> : <h4 className={`mb-0 ${totalLeadPercent < 0 ? "text-danger" : ""}`}> {totalLeadPercent <= 0 ? <BsArrowDownLeft /> : <BsArrowUpRight />} {totalLeadPercent} / <small className="fs-13 text-capitalize colGray">month</small> </h4>} */}
                                            </div>
                                        </div>

                                    </div>
                                </Link>
                            </Col>
                            <Col xxl={2} xl={3} lg={3} sm={4} className="analyticsWrap1 mb-2 mx-auto">
                                <Link to="#">
                                    <div className="dashboard-anatlytics">
                                        <div className="d-flex gap-2">
                                            <span className='d-block mx-auto'><BsFillBoxSeamFill className="kpiIcon text-secondary" /></span>
                                        </div>
                                        <div className=''>
                                            <div className="text-center">
                                                <h4 className='fw-600 mb-0 font-xl'>2300</h4>
                                                <h3 className='fs-14 fw-400 colGray mb-0'>Items per Order</h3>
                                                <hr className="sp-hr" />
                                                {/* {totalLeadPercent === null || totalLeadPercent === undefined ? <small>Not Computed</small> : <h4 className={`mb-0 ${totalLeadPercent < 0 ? "text-danger" : ""}`}> {totalLeadPercent <= 0 ? <BsArrowDownLeft /> : <BsArrowUpRight />} {totalLeadPercent} / <small className="fs-13 text-capitalize colGray">month</small> </h4>} */}
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </Col>
                            <Col xxl={2} xl={3} lg={3} sm={4} className="analyticsWrap1 mb-2 mx-auto">
                                <Link to="#">
                                    <div className="dashboard-anatlytics">
                                        <div className="d-flex gap-2">
                                            <span className='d-block mx-auto'><BsPeople className="kpiIcon text-secondary" /></span>
                                        </div>
                                        <div className=''>
                                            <div className="text-center">
                                                <h4 className='fw-600 mb-0 font-xl'>2300</h4>
                                                <h3 className='fs-14 fw-400 colGray mb-0'># Customers</h3>
                                                <hr className="sp-hr" />
                                                {/* {totalLeadPercent === null || totalLeadPercent === undefined ? <small>Not Computed</small> : <h4 className={`mb-0 ${totalLeadPercent < 0 ? "text-danger" : ""}`}> {totalLeadPercent <= 0 ? <BsArrowDownLeft /> : <BsArrowUpRight />} {totalLeadPercent} / <small className="fs-13 text-capitalize colGray">month</small> </h4>} */}
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </Col>
                            <Col xxl={2} xl={3} lg={3} sm={4} className="analyticsWrap1 mb-2 mx-auto">
                                <Link to="#">
                                    <div className="dashboard-anatlytics">
                                        <div className="d-flex gap-2">
                                            <span className='d-block mx-auto'><RiUserSearchLine className="kpiIcon text-secondary" /></span>
                                        </div>
                                        <div className=''>
                                            <div className="text-center">
                                                <h4 className='fw-600 mb-0 font-xl'>2300</h4>
                                                <h3 className='fs-14 fw-400 colGray mb-0'>Avg. Sales per Customer</h3>
                                                <hr className="sp-hr" />
                                                {/* {totalLeadPercent === null || totalLeadPercent === undefined ? <small>Not Computed</small> : <h4 className={`mb-0 ${totalLeadPercent < 0 ? "text-danger" : ""}`}> {totalLeadPercent <= 0 ? <BsArrowDownLeft /> : <BsArrowUpRight />} {totalLeadPercent} / <small className="fs-13 text-capitalize colGray">month</small> </h4>} */}
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </Col>
                            <Col xxl={2} xl={3} lg={3} sm={4} className="analyticsWrap1 mb-2 mx-auto">
                                <Link to="#">
                                    <div className="dashboard-anatlytics">
                                        <div className="d-flex gap-2">
                                            <span className='d-block mx-auto'><PiGraphDuotone className="kpiIcon text-secondary" /></span>
                                        </div>
                                        <div className=''>
                                            <div className="text-center">
                                                <h4 className='fw-600 mb-0 font-xl'>2300</h4>
                                                <h3 className='fs-14 fw-400 colGray mb-0'>Visitors / Opportunities</h3>
                                                <hr className="sp-hr" />
                                                {/* {totalLeadPercent === null || totalLeadPercent === undefined ? <small>Not Computed</small> : <h4 className={`mb-0 ${totalLeadPercent < 0 ? "text-danger" : ""}`}> {totalLeadPercent <= 0 ? <BsArrowDownLeft /> : <BsArrowUpRight />} {totalLeadPercent} / <small className="fs-13 text-capitalize colGray">month</small> </h4>} */}
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </Col>
                            <Col xxl={2} xl={3} lg={3} sm={4} className="analyticsWrap1 mb-2 mx-auto">
                                <Link to="#">
                                    <div className="dashboard-anatlytics">
                                        <div className="d-flex gap-2">
                                            <span className='d-block mx-auto'><RiFilter2Fill className="kpiIcon text-secondary" /></span>
                                        </div>
                                        <div className=''>
                                            <div className="text-center">
                                                <h4 className='fw-600 mb-0 font-xl'>2.0%</h4>
                                                <h3 className='fs-14 fw-400 colGray mb-0'>Conversion Rate</h3>
                                                <hr className="sp-hr" />
                                                {/* {totalLeadPercent === null || totalLeadPercent === undefined ? <small>Not Computed</small> : <h4 className={`mb-0 ${totalLeadPercent < 0 ? "text-danger" : ""}`}> {totalLeadPercent <= 0 ? <BsArrowDownLeft /> : <BsArrowUpRight />} {totalLeadPercent} / <small className="fs-13 text-capitalize colGray">month</small> </h4>} */}
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </Col>
                            <Col xxl={2} xl={3} lg={3} sm={4} className="analyticsWrap1 mb-2 mx-auto">
                                <Link to="#">
                                    <div className="dashboard-anatlytics">
                                        <div className="d-flex gap-2">
                                            <span className='d-block mx-auto'><MdLeaderboard className="kpiIcon text-secondary" /></span>
                                        </div>
                                        <div className=''>
                                            <div className="text-center">
                                                <h3 className='fs-18 fw-400 colBlue mb-0'>PPC Leads</h3>
                                                <h4 className='fw-600 mb-0 font-xl'>{incomingPpcLeadsCount}</h4>
                                                <h3 className='fs-14 fw-400 colGray mb-0'># Incoming</h3>
                                                <hr className="sp-hr" />
                                                <h4 className='fw-600 mb-0 font-xl'>{convertedPpcLeadsCount}</h4>
                                                <h3 className='fs-14 fw-400 colGray mb-0'># Converted</h3>
                                                <hr className="sp-hr" />
                                                <h4 className='fw-600 mb-0 font-xl'>$ {ppcLeadsSales.length === 0 ? 0 : ppcLeadsSales[0].totalAmount}</h4>
                                                <h3 className='fs-14 fw-400 colGray mb-0'>Converted Sales</h3>
                                                {/* <hr className="sp-hr" /> */}

                                                {/* {totalLeadPercent === null || totalLeadPercent === undefined ? <small>Not Computed</small> : <h4 className={`mb-0 ${totalLeadPercent < 0 ? "text-danger" : ""}`}> {totalLeadPercent <= 0 ? <BsArrowDownLeft /> : <BsArrowUpRight />} {totalLeadPercent} / <small className="fs-13 text-capitalize colGray">month</small> </h4>} */}
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </Col>
                            <Col xxl={2} xl={3} lg={3} sm={4} className="analyticsWrap1 mb-2 mx-auto">
                                <Link to="#">
                                    <div className="dashboard-anatlytics">
                                        <div className="d-flex gap-2">
                                            <span className='d-block mx-auto'><MdLeaderboard className="kpiIcon text-secondary" /></span>
                                        </div>
                                        <div className=''>
                                            <div className="text-center">
                                            <h3 className='fs-18 fw-400 colBlue mb-0'>Seo Leads</h3>
                                                <h4 className='fw-600 mb-0 font-xl'>{incomingSeoLeadsCount}</h4>
                                                <h3 className='fs-14 fw-400 colGray mb-0'># Incoming</h3>
                                                <hr className="sp-hr" />
                                                <h4 className='fw-600 mb-0 font-xl'>{convertedSeoLeadsCount}</h4>
                                                <h3 className='fs-14 fw-400 colGray mb-0'># Converted</h3>
                                                <hr className="sp-hr" />
                                                <h4 className='fw-600 mb-0 font-xl'>$ {seoLeadsSales.length === 0 ? 0 : seoLeadsSales[0].totalAmount}</h4>
                                                <h3 className='fs-14 fw-400 colGray mb-0'>Converted Sales</h3>
                                                {/* {totalLeadPercent === null || totalLeadPercent === undefined ? <small>Not Computed</small> : <h4 className={`mb-0 ${totalLeadPercent < 0 ? "text-danger" : ""}`}> {totalLeadPercent <= 0 ? <BsArrowDownLeft /> : <BsArrowUpRight />} {totalLeadPercent} / <small className="fs-13 text-capitalize colGray">month</small> </h4>} */}
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </Col>
                            <Col xxl={2} xl={3} lg={3} sm={4} className="analyticsWrap1 mb-2 mx-auto">
                                <Link to="#">
                                    <div className="dashboard-anatlytics">
                                        <div className="d-flex gap-2">
                                            <span className='d-block mx-auto'><MdLeaderboard className="kpiIcon text-secondary" /></span>
                                        </div>
                                        <div className=''>
                                            <div className="text-center">
                                            <h3 className='fs-18 fw-400 colBlue mb-0'>Bark Leads</h3>
                                                <h4 className='fw-600 mb-0 font-xl'>{incomingBarkLeadsCount}</h4>
                                                <h3 className='fs-14 fw-400 colGray mb-0'># Incoming</h3>
                                                <hr className="sp-hr" />
                                                <h4 className='fw-600 mb-0 font-xl'>{convertedBarkLeadsCount}</h4>
                                                <h3 className='fs-14 fw-400 colGray mb-0'># Converted</h3>
                                                <hr className="sp-hr" />
                                                <h4 className='fw-600 mb-0 font-xl'>$ {barkLeadsSales.length === 0 ? 0 : barkLeadsSales[0].totalAmount}</h4>
                                                <h3 className='fs-14 fw-400 colGray mb-0'>Converted Sales</h3>
                                                {/* {totalLeadPercent === null || totalLeadPercent === undefined ? <small>Not Computed</small> : <h4 className={`mb-0 ${totalLeadPercent < 0 ? "text-danger" : ""}`}> {totalLeadPercent <= 0 ? <BsArrowDownLeft /> : <BsArrowUpRight />} {totalLeadPercent} / <small className="fs-13 text-capitalize colGray">month</small> </h4>} */}
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </Col>

                        </Row>
                    </>
                )
            }
        </>
    );
};

export default SalesKPIDashboard;
