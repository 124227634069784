export const openDealModal = (modalState) => { 
     return {
          type: "openDealmodal",
          payload: modalState
     }
}
export const closeDealModal = (modalState) => {
     return {
          type: "closeDealmodal",
          payload: modalState
     }
}
