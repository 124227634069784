import React from 'react';

const Brandbox = ({ brandLogoUrl, brandName, brandWebsite }) => {
     return (
          <a href={brandWebsite || "#"} target="_blank">
               <div className='brandBox'>
                    <img disabled={true} src={brandLogoUrl} alt="brandLogo" />
                    <h4 className="brandName">{brandName}</h4>
               </div>
          </a>
     );
}

export default Brandbox;
