import React, { useState, useEffect, useCallback } from "react";
import Title from "../../../hook/Title";
import { TableCom } from "../../../Components/TableCom";
import { Link } from "react-router-dom";
import { getAllClientsInvoicesLinkTrackingRoute } from "../../../utils/APIRoutes";
import countryCodes from "../../../helpers/currencyCodes.json";
import { debounce } from "lodash";

const InvoiceTracking = () => {
  Title("Invoice Tracking");
  const allbrandCountryCodes = countryCodes.codes;
  // Table Head Data
  const [thLength, setThlength] = useState(1);
  const [items, setItems] = useState([]);
  const [numPerPage, setNumPerPage] = useState(10);
  const [totalData, setTotalData] = useState(0);
  const [pageCount, setpageCount] = useState(0);
  const [currPage, setCurrPage] = useState(0);
  const [searchVal, setSearchVal] = useState("");
  const [renderData, setRerenderData] = useState(false);
  const Thead = [
    "SNo",
    "Invoice Id",
    "Country Name",
    "Country Code",
    "Ip Address",
    "state",
    "city",
    "Created At",
    "postal",
    "latitude",
    "longitude",
  ];
  useEffect(
    (currPage) => {
      currPage = currPage ?? 0;
      const getData = async () => {
        const res = await fetch(
          `${getAllClientsInvoicesLinkTrackingRoute}?numPerPage=${numPerPage}&page=${currPage}&search=${searchVal}`
        );
        const data = await res.json();
        const total = data.pagiantion.dataCount;
        setTotalData(total);
        setpageCount(Math.ceil(total / numPerPage));
        setItems(data.result);
      };
      getData();
    },
    [numPerPage, searchVal, renderData]
  );
  useEffect(() => {
    setThlength(Thead.length);
  }, [thLength]);
  const fetchData = async (currPage) => {
    const res = await fetch(
      `${getAllClientsInvoicesLinkTrackingRoute}?numPerPage=${numPerPage}&page=${currPage}&search=${searchVal}`
    );
    const changedData = await res.json();
    const data = changedData.result;
    return data;
  };
  const handlePageClick = async (data) => {
    let currPage = data.selected;
    const myData = await fetchData(currPage);
    setItems(myData);
    setCurrPage(currPage);
  };

  const handleChange = (e) => {
    setNumPerPage(e.target.value);
  };

  // search data in api using search box
  const debouncedSearch = useCallback(
    debounce((value) => {
      setSearchVal(value);
    }, 500),
    []
  );

  // search data in api using search box
  const hangleSearchVal = (e) => {
    debouncedSearch(e.target.value);
  };

  return (
    <>
      <h4 className="primHeading my-2 mb-4">All Invoice Tracking</h4>
      <div className="box my-3">
        <h4 className="sectionHeading my-2 mb-4">Tracking Summary</h4>
        <TableCom
          numPerPage={numPerPage}
          totalData={totalData}
          handleChange={handleChange}
          hangleSearchVal={hangleSearchVal}
          pageCount={pageCount}
          handlePageClick={handlePageClick}
          searcPlaceHolder="Search..."
        >
          <div className="customTable2 w-100 table-responsive">
            <table className="w-100">
              <thead>
                <tr>
                  {Thead?.map((th, index) => {
                    return <th key={index}>{th}</th>;
                  })}
                </tr>
              </thead>
              <tbody>
                {items == "" || items == undefined || items == null ? (
                  <tr>
                    <td colSpan={thLength}>
                      <p className="my-2 smText text-decoration-underline text-danger">
                        No Record Found
                      </p>
                    </td>
                  </tr>
                ) : (
                  items?.map((item, index) => {
                    const {
                      countryCode,
                      countryName,
                      ipv4,
                      createdAt,
                      details,
                      latitude,
                      longitude,
                      postal,
                      _id,
                      state,
                      city,
                    } = item;
                    return (
                      <tr key={_id} className="text-cap">
                        <td>{++index}</td>
                        <td>{details?.invoiceId || "--"}</td>
                        <td>{countryName || "--"}</td>
                        <td>{countryCode || "--"}</td>
                        <td>{ipv4 || "--"}</td>
                        <td>{state || "--"}</td>
                        <td>{city || "--"}</td>
                        <td>
                          {new Date(createdAt).toLocaleString()}
                          {/* <small className="customBadge bgGreen px-2 mx-2 fs-13">{createdAt.slice(11, 16) || ""}</small> */}
                        </td>
                        <td>{postal || "--"}</td>
                        <td>{latitude || "--"}</td>
                        <td>{longitude || "--"}</td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
        </TableCom>
      </div>
    </>
  );
};

export default InvoiceTracking;
