import React, { useEffect, useState } from "react";
import Title from "../../../../hook/Title";
import { toast } from "react-toastify";
import currencycode from "../../../../helpers/currencyCodes.json";
import countryCodes from "../../../../helpers/countryCodes.json";
import { createLeadRoute, getBrandsByUnitIdRoute } from "../../../../utils/APIRoutes";
import { Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import GoBack from "../../../../Components/GoBack";
import axios from "axios";
import { useParams, useLocation } from "react-router-dom";
const Index = () => {

    Title("Add Lead");
    const { state } = useLocation();

    const { id } = useParams();
    const navigate = useNavigate()
    const allcurrencyCode = currencycode.codes;
    const allbrandCountryCodes = countryCodes.codes;
    const [servError, setServError] = useState(false);
    const [brandByUnit, setBrandByUnit] = useState([]);
    //    Get Brands Name
    const getBrandsName = async () => {
        try {
            const resp = await fetch(`${getBrandsByUnitIdRoute}?unitId=${id}`);
            const resData = await resp.json();
            if (resData.success === true) {
                setBrandByUnit(resData.data);
                setServError(false);
            } else {
                throw new Error('Something went wrong.');
            }
        } catch (error) {
            setServError(true);
        }
    }
    useEffect(() => {
        getBrandsName()
    }, [])
    //============================================ ! Api inegration of Create Leads code !  ===============================================//
    let toastOptions = {
        position: "top-left",
        autoClose: 5000,
        pauseOnHover: false,
        draggable: false,
        theme: "dark",
    };
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    // ---- States of inputs in the form of key value pair name:value ------ // 
    let [values, setValues] = useState({ name: "", email: "", organization: "", phone: "", country: "", currencyCode: "", budget: "", industry: "", source: "", note: "", message: "", leadSource: "" });
    const { name, email, organization, phone, country, currencyCode, budget, industry, source, note, message, leadSource } = values;
    // ---- Getting values of inputs ------ //  
    const handleInpChange = (event) => {
        setValues({ ...values, [event.target.name]: event.target.value });
    };
    let hasError = false;
    // ---- Validate Form ------ //
    const validateForm = () => {
        const newErrors = {};
        if (name === "") {
            hasError = true;
            setIsLoading(false);
            newErrors.name = 'Name is required !';
        }
        if (email === "") {
            hasError = true;
            setIsLoading(false);
            newErrors.email = 'Email is required !';
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            hasError = true;
            setIsLoading(false);
            newErrors.email = 'Email is not valid !';
        }
        if (phone === "") {
            hasError = true;
            setIsLoading(false);
            newErrors.phone = 'Phone Number is required !';
        }
        if (source === "") {
            hasError = true;
            setIsLoading(false);
            newErrors.source = 'Brand Source is required !';
        }
        setErrors(newErrors);
    };
    // ---- Form Submittion Function ------ // 
    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);
        validateForm();
        if (hasError === false) {
            const { data } = await axios.post(`${createLeadRoute}?unitId=${id}&empId=${state}`, {
                name, email, organization, phone, country, currencyCode, budget, industry, source, note, message, leadSource
            });
            if (data.success === true) {
                setIsLoading(false);
                toast.success(data.msg, toastOptions, toastOptions['position'] = "top-right");
                setValues({ name: "", email: "", organization: "", phone: "", country: "", currencyCode: "", leadSource: "", budget: "", industry: "", source: "", note: "", message: "" });
                if (state === null || state === "" || state === undefined) { navigate(`/unit-leads/${id}`) } else { navigate(`/my-leads`) }
            } else {
                toast.error(data.msg, toastOptions, toastOptions['position'] = "top-left");
                setIsLoading(false);
            }
        } else {
            toast.error("Kindly Fill the require fields !", toastOptions, toastOptions['position'] = "top-right");
        }
    };
    return (
        <>
            <div className="d-flex align-items-center mb-4 flex-wrap">
                <GoBack />
                <h4 className="primHeading mb-0">Create <span>Lead</span></h4>
            </div>
            <div className="box">
                {
                    servError === true ? <p className="text-center my-2 fw-600 text-danger">Something went wrong Please try again later (Server Error 500)</p> : (
                        <form onSubmit={(event) => handleSubmit(event)}>
                            <Row>
                                <Col lg={8}>
                                    <p className="fw-600 my-2 mb-4">Add New Lead From here</p>
                                    <Row>
                                        <Col lg={6} className="my-2">
                                            <label className="text-bold mb-1" htmlFor="name">Name:<span className="text-danger">*</span></label>
                                            <input
                                                value={name}
                                                onChange={(e) => handleInpChange(e)}
                                                name='name'
                                                id='name'
                                                type="text"
                                                className="filterSelect ps-3"
                                                placeholder='Enter Name' />
                                            {errors.name && <p className="text-danger fw-500 fs-13 my-2 text-capitalize">{errors.name}</p>}
                                        </Col>
                                        <Col lg={6} className="my-2">
                                            <label className="text-bold mb-1" htmlFor="email">Email:<span className="text-danger">*</span></label>
                                            <input
                                                value={email}
                                                onChange={(e) => handleInpChange(e)}
                                                name="email"
                                                id="email"
                                                type="text"
                                                className="filterSelect ps-3"
                                                placeholder='Enter Email' />
                                            {errors.email && <p className="text-danger fw-500 fs-13 my-2 text-capitalize">{errors.email}</p>}
                                        </Col>
                                        <Col lg={6} className="my-2">
                                            <label className="text-bold mb-1" htmlFor="phone">Phone:<span className="text-danger">*</span></label>
                                            <input
                                                value={phone}
                                                onChange={(e) => handleInpChange(e)}
                                                name="phone"
                                                id="phone"
                                                type="number"
                                                className="filterSelect ps-3"
                                                placeholder='Enter Phone Number' />
                                            {errors.phone && <p className="text-danger fw-500 fs-13 my-2 text-capitalize">{errors.phone}</p>}
                                        </Col>
                                        <Col lg={6} className="my-2">
                                            <label className="text-bold mb-1" htmlFor="source">Brand Source: <span className="text-danger">*</span> </label>
                                            <select
                                                className="filterSelect ps-3"
                                                id="source"
                                                name='source'
                                                onChange={(e) => handleInpChange(e)} >
                                                <option value="">Select Option</option>
                                                {
                                                    brandByUnit?.map((brandByUnitData) => {
                                                        const { brandName, brandWebsite, _id } = brandByUnitData
                                                        return (
                                                            <option key={_id} value={brandWebsite}>{brandName}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                            {errors.source && <p className="text-danger fw-500 fs-13 my-2 text-capitalize">{errors.source}</p>}
                                        </Col>
                                        <Col lg={6} className="my-2">
                                            <label className="text-bold mb-1" htmlFor="leadSource">Lead Source:</label>
                                            <select
                                                className="filterSelect ps-3"
                                                id="leadSource"
                                                name='leadSource'
                                                value={leadSource}
                                                onChange={(e) => handleInpChange(e)}>
                                                <option value="">Select Option</option>
                                                <option value="marketting">Marketing</option>
                                                <option value="ppc">PPC</option>
                                                <option value="website">Website</option>
                                                <option value="smm">SMM</option>
                                                <option value="bark">BARK</option>
                                                <option value="custom">Custom</option>
                                            </select>
                                        </Col>
                                        <Col lg={6} className="my-2">
                                            <label className="text-bold mb-1" htmlFor="organization">organization:</label>
                                            <input
                                                value={organization}
                                                onChange={(e) => handleInpChange(e)}
                                                name="organization"
                                                id="organization"
                                                type="text"
                                                className="filterSelect ps-3"
                                                placeholder='Enter Organization Name' />
                                        </Col>
                                        <Col lg={6} className="my-2">
                                            <label className="text-bold mb-1" htmlFor="currencyCode">Currency:</label>
                                            <select
                                                className="filterSelect ps-3"
                                                id="currencyCode"
                                                name='currencyCode'
                                                value={currencyCode}
                                                onChange={(e) => handleInpChange(e)}>
                                                <option value="">Select Option</option>
                                                {Object.keys(allcurrencyCode).map((objkey, index) => {
                                                    return (
                                                        <option key={index} value={objkey}>{allcurrencyCode[objkey]}</option>
                                                    );
                                                })}
                                            </select>
                                        </Col>
                                        <Col lg={6} className="my-2">
                                            <label className="text-bold mb-1" htmlFor="country">Country:</label>
                                            <select
                                                className="filterSelect ps-3"
                                                id="country"
                                                name='country'
                                                value={country}
                                                onChange={(e) => handleInpChange(e)}>
                                                <option value="">Select Option</option>
                                                {Object.keys(allbrandCountryCodes).map((objkey, index) => {
                                                    return (
                                                        <option key={index} value={allbrandCountryCodes[objkey]}>{allbrandCountryCodes[objkey]}</option>
                                                    );
                                                })}
                                            </select>
                                        </Col>
                                        <Col lg={6} className="my-2">
                                            <label className="text-bold mb-1" htmlFor="budget">Budget:</label>
                                            <input
                                                value={budget}
                                                onChange={(e) => handleInpChange(e)}
                                                name="budget"
                                                id="budget"
                                                type="number"
                                                className="filterSelect ps-3"
                                                placeholder='Enter Budget' />
                                        </Col>
                                        <Col lg={6} className="my-2">
                                            <label className="text-bold mb-1" htmlFor="industry">Product:</label>
                                            <select
                                                className="filterSelect ps-3"
                                                id="industry"
                                                name='industry'
                                                value={industry}
                                                onChange={(e) => handleInpChange(e)}>
                                                <option value="">Select Option</option>
                                                <option value="website">Website</option>
                                                <option value="mobile application">Mobile Application</option>
                                                <option value="social media marketing">Social Media Marketing</option>
                                                <option value="graphic designing">Graphic Designing</option>
                                                <option value="Search Engine Optimization">Search Engine Optimization (SEO)</option>
                                                <option value="logo design">Logo Design</option>
                                                <option value="content writting">Content Writting</option>
                                                <option value="animation">Animation</option>
                                            </select>
                                        </Col>
                                        <Col lg={6} className="my-2">
                                            <label className="text-bold mb-1" htmlFor="note">Note:</label>
                                            <input
                                                value={note}
                                                onChange={(e) => handleInpChange(e)}
                                                name="note"
                                                id="note"
                                                type="text"
                                                className="filterSelect ps-3"
                                                placeholder='Enter Note' />
                                        </Col>
                                        <Col lg={12} className="my-2">
                                            <label className="text-bold mb-1" htmlFor="message">message:</label>
                                            <textarea
                                                rows="5"
                                                value={message}
                                                onChange={(e) => handleInpChange(e)}
                                                name="message"
                                                id="message"
                                                className="filterSelect ps-3"
                                                placeholder='Enter Message...'></textarea>
                                        </Col>
                                    </Row>
                                    <button className="main-btn mt-3" disabled={isLoading} type="submit"> {isLoading ? 'submiting...' : "submit"} </button>
                                </Col>
                            </Row>
                        </form>
                    )
                }
            </div>
        </>
    );
};
export default Index; 