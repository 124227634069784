import { React, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import NotificationDropdown from "../Components/NotificationDropdown";
import CustomModal from "../Components/CustomModal";
import { FaBars, FaEllipsisV, FaTimes, FaCog, FaCheckCircle } from "react-icons/fa";
import { useSelector, useDispatch } from 'react-redux';
import LoadingSpinner from "../utils/LoadingSpinner";
import Offcanvas from 'react-bootstrap/Offcanvas';
import { setThemeColor } from '../actions/ThemeActions';
import { allThemesData } from "../helpers/colorTheme";
import Logo from "../assets/images/logo.png";
import { RemoveAuthAction } from '../actions/AuthAction';
import { toggleTopSellingBrands, toggleUnitWiseIncome, toggleCompleteGrowthByMonthChart, togglePaymentActivity, toggleInvReceivable } from '../actions/ThemeActions';

const Header = (props) => {
  const navigate = useNavigate();
  // getting value of authenticated user using redux persistor
  const AuthReducer = useSelector((state) => state.AuthReducer);
  const GraphReducer = useSelector((state) => state.GraphReducer);
  const { completeGrowthByMonthChart, paymentActivity, topSellBrands, unitWiseIncome, totInvReceivable } = GraphReducer

  const { loading } = AuthReducer;
  const { employeeName, email, token, gender, designation } = AuthReducer.data;

  const dispatch = useDispatch()
  const ThemeReducer = useSelector((state) => state.ThemeReducer);

  useEffect(() => {
    if (token === "" || token === null || token === undefined) {
      navigate("/");
    }
  }, []);

  const [modalShow, setModalShow] = useState(false);
  const [finalShow, setfinalShow] = useState(false);
  const [bottomMenu, setBottomMenu] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const finalShowModal = () => {
    dispatch(RemoveAuthAction())
    navigate("/")
  };

  function toggleBottom() {
    setBottomMenu(!bottomMenu);
  }


  const notifificationData = [
    {
      id: "01",
      content: "New user has been registered on the platform ",
      date: "Dec 19, 2020",
      time: "10:30 AM ",
    },
    {
      id: "02",
      content: "New user has been registered on the platform ",
      date: "Dec 19, 2020",
      time: "10:30 AM ",
    },
    {
      id: "03",
      content: "New user has been registered on the platform ",
      date: "Dec 19, 2020",
      time: "10:30 AM ",
    },
    {
      id: "04",
      content: "New user has been registered on the platform ",
      date: "Dec 19, 2020",
      time: "10:30 AM ",
    },
  ];

  return (
    <>
      <button className="d-none smBlock hamburger flex-shrink-0" onClick={toggleBottom}><FaEllipsisV className="hamIcon" /></button>
      <div className="LogoWrapper">
        <Link to="/dashboard"> <img src={Logo} alt="Logo" draggable={false} className="headerLogo" /> </Link>
      </div>
      <div>
        <ul className="sideMenu">
          {/* <li className="md_none">
            <NotificationDropdown notifificationData={notifificationData} />
          </li> */}
          <li className="md_none">
            <Dropdown>
              <Dropdown.Toggle className="customDropDown mainDropDown">
                <div className="userName">
                  {loading ? <LoadingSpinner /> :
                    (<>
                      <img src={require(`../assets/images/${gender === "female" ? "adminAvatarfemale.png" : "avatar.png"}`)} alt="avatar" className="avatar" draggable={false} />
                      <div className="text-start">
                        <span className="d-block"><b>{employeeName ?? ""}</b></span>
                        <span className="d-block">{email ?? ""}</span>
                      </div>
                    </>)
                  }
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item className="drop_icon" as={Link} to="/profile">My Profile </Dropdown.Item>
                <Dropdown.Item
                  className="drop_icon"
                  onClick={() => setModalShow(true)}
                >
                  Logout
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </li>
          <li className="hamburger">
            <button className="themeSettingsBtn" onClick={handleShow}><FaCog size={30} /></button>
          </li>
          <li>
            <button className="hamburger" onClick={props.toggleSideNav}>
              <FaBars className="hamIcon" />
            </button>
          </li>
        </ul>
      </div>
      {/* for mobile size screen show user icon and notification */}
      <ul className={`mobileMenu ${bottomMenu ? "d-flex" : "d-none"}`}>
        {/* <li>
          <NotificationDropdown notifificationData={notifificationData} />
        </li> */}
        <li>
          <Dropdown>
            <Dropdown.Toggle className="customDropDown mainDropDown">
              <div className="userName">
                {loading ? <LoadingSpinner /> :
                  (<>
                    <img src={require(`../assets/images/${gender === "female" ? "adminAvatarfemale.png" : "avatar.png"}`)} alt="avatar" className="avatar" draggable="false" />
                    <div className="text-start">
                      <span className="d-block"><b>{employeeName ?? ""}</b></span>
                      <span className="d-block">{email ?? ""}</span>
                    </div>
                  </>)
                }
              </div>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item className="drop_icon" as={Link} to="/profile">My Profile</Dropdown.Item>
              <Dropdown.Item className="drop_icon" onClick={() => setModalShow(true)}>Logout</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </li>
      </ul>
      {/*==================== Theme Settings for dashboard =================*/}
      <Offcanvas className="bgGray" show={show} onHide={handleClose} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>THEME CUSTOMIZER</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <h6><b>Color Scheme</b></h6>
          <div className="themeColors my-2">
            {allThemesData?.map((themes, index) => {
              const { themeColor, secColor } = themes
              return (
                <div key={index} className="position-relative">
                  {document.getElementById(themeColor)?.checked || ThemeReducer.myTheme.themeColor === themeColor ? <FaCheckCircle style={{ color: secColor }} size={22} className="checkedIcon" /> : ""}
                  <input type="radio" onChange={() => dispatch(setThemeColor(themes))} name="theme" id={themeColor} value={themeColor} />
                  <label htmlFor={themeColor}><span
                    style={{
                      backgroundColor: themeColor,
                      ...(document.getElementById(themeColor)?.checked && {
                        border: `2px solid ${secColor}`,
                      }),
                    }}>
                  </span>
                  </label>
                </div>
              )
            })}
          </div>
          {
            designation === "head_of_department" || designation === "president" ? (
              <div className="graphsCustomization">
                <h6><b>Graphs Customization</b></h6>
                <label className="checkBoxDiv ms-0 my-3">TOP SELLING BRANDS
                  <input type="checkbox" checked={topSellBrands} value={topSellBrands} onChange={() => dispatch(toggleTopSellingBrands())} />
                  <span className="primCheckmark"></span>
                </label>
                <label className="checkBoxDiv ms-0 my-3">UNIT WISE INCOME
                  <input type="checkbox" checked={unitWiseIncome} value={unitWiseIncome} onChange={() => dispatch(toggleUnitWiseIncome())} />
                  <span className="primCheckmark"></span>
                </label>
                <label className="checkBoxDiv ms-0 my-3">COMPLETE GROWTH BY MONTH CHART
                  <input type="checkbox" checked={completeGrowthByMonthChart} value={completeGrowthByMonthChart} onChange={() => dispatch(toggleCompleteGrowthByMonthChart())} />
                  <span className="primCheckmark"></span>
                </label>
                <label className="checkBoxDiv ms-0 my-3">PAYMENT ACTIVITY
                  <input type="checkbox" checked={paymentActivity} value={paymentActivity} onChange={() => dispatch(togglePaymentActivity())} />
                  <span className="primCheckmark"></span>
                </label>
                <label className="checkBoxDiv ms-0 my-3">Total Invoice Receivable
                  <input type="checkbox" checked={totInvReceivable} value={totInvReceivable} onChange={() => dispatch(toggleInvReceivable())} />
                  <span className="primCheckmark"></span>
                </label>
              </div>
            ) : ""
          }

        </Offcanvas.Body>
      </Offcanvas>

      {/*==================== Modal =================*/}
      <CustomModal show={modalShow} onHide={() => setModalShow(false)}>
        <button onClick={() => setModalShow(false)} className="modal-cancel"><FaTimes /></button>
        <div className="modalContent">
          <img src={require("../assets/images/sure.png")} draggable={false} alt="modal image" />
          {/* modal text */}
          <h3 className="modalPrimHeading my-2"> Are You Sure You Want <br /> to Logout?</h3>
          {/* modal buttons */}
          <button className="main-btn m-2" type="button" onClick={() => { finalShowModal() }}>Yes</button>
          <button className="sec-btn m-2" type="button" onClick={() => setModalShow(false)}>No</button>
        </div>
      </CustomModal>
    </>
  );
};

export default Header;
