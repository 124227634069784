import React, { useState, useEffect, useCallback } from "react";
import { TableCom } from "../../../../Components/TableCom";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getAllDisputesRoute } from "../../../../utils/APIRoutes";
import Dropdown from "react-bootstrap/Dropdown";
import Title from "../../../../hook/Title";
import { FaRegEye, FaEllipsisV, FaTimes, FaEye } from "react-icons/fa";
import CustomModal from "../../../../Components/CustomModal";
import { Row, Col } from "react-bootstrap";
import useClipboard from "../../../../hook/useClipboard";
import { toast } from "react-toastify";
import { debounce } from "lodash";
const Index = () => {
  Title("All Disputed Invoices");
  const { handleCopyClipboard, copiedText } = useClipboard();
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const location = useLocation();
  // Retrieve the 'fromDate' from localStorage, or set it to the first day of the current month
  const storedFromDate = localStorage.getItem("fromDate");

  // Set up the state with the retrieved or default value
  const [filterDates, setfilterDates] = useState({
    from: storedFromDate || "",
    to: "",
  });

  // const handleDateChange = (e) => {
  //   setfilterDates({ ...filterDates, [e.target.name]: e.target.value });
  // };

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    setfilterDates((prevDates) => {
      const newDates = { ...prevDates, [name]: value };
      localStorage.setItem("filterDatesThree", JSON.stringify(newDates)); // Save to localStorage
      return newDates;
    });
  };

  useEffect(() => {
    const storedDates = localStorage.getItem("filterDatesThree");
    if (storedDates) {
      setfilterDates(JSON.parse(storedDates));
    }
  }, []);

  // const clearFilters = () => {
  //   setfilterDates({ from: "", to: "" }); // Clear from localStorage
  // };

  const clearFilters = () => {
    setfilterDates({ from: "", to: "" });
    localStorage.removeItem("filterDatesThree"); // Clear from localStorage
    localStorage.removeItem("fromDate"); // Clear fromDate from localStorage
  };

  const { from, to } = filterDates;

  // Table Head Data
  const [thLength, setThlength] = useState(1);
  const [items, setItems] = useState([]);
  const [numPerPage, setNumPerPage] = useState(10);
  const [totalData, setTotalData] = useState(0);
  const [pageCount, setpageCount] = useState(0);
  const [currPage, setCurrPage] = useState(0);
  const [searchVal, setSearchVal] = useState("");
  let toastOptions = {
    position: "top-left",
    autoClose: 5000,
    pauseOnHover: false,
    draggable: false,
    theme: "dark",
  };
  const Thead = [
    "S.No",
    "invoice Id",
    "Case Status",
    "Status",
    "Gateway Name",
    "Account Name",
    "Payment Intent",
    "Client",
    "Sales Person",
    "Total Amount",
    "Refund Requested Amount",
    "reason",
    "Description",
    "Last Update",
    "created At",
    "Action",
  ];
  useEffect(
    (currPage) => {
      currPage = currPage ?? 0;
      const getData = async () => {
        const res = await fetch(
          `${getAllDisputesRoute}?numPerPage=${numPerPage}&page=${currPage}&search=${searchVal}&from=${from}&to=${to}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (res.status === 401) {
          navigate("/");
          toast.error(
            "Session has been Expired",
            toastOptions,
            (toastOptions["position"] = "top-right")
          );
          return;
        }
        const data = await res.json();
        const total = data.pagiantion.dataCount;
        setTotalData(total);
        setpageCount(Math.ceil(total / numPerPage));
        setItems(data.result);
      };
      getData();
    },
    [numPerPage, searchVal, from, to]
  );
  useEffect(() => {
    setThlength(Thead.length);
  }, [thLength]);
  const fetchData = async (currPage) => {
    const res = await fetch(
      `${getAllDisputesRoute}?&numPerPage=${numPerPage}&page=${currPage}&search=${searchVal}&from=${from}&to=${to}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (res.status === 401) {
      navigate("/");
      toast.error(
        "Session has been Expired",
        toastOptions,
        (toastOptions["position"] = "top-right")
      );
      return;
    }
    const changedData = await res.json();
    const data = changedData.result;
    return data;
  };
  const handlePageClick = async (data) => {
    let currPage = data.selected;
    const myData = await fetchData(currPage);
    setItems(myData);
    setCurrPage(currPage);
  };
  const handleChange = (e) => {
    setNumPerPage(e.target.value);
  };

  // stored search value in local storage
  useEffect(() => {
    const storedSearchValue = localStorage.getItem("searchValueThree");
    const searchValueFromState = location.state?.searchValue;

    if (searchValueFromState) {
      setSearchVal(searchValueFromState);
      localStorage.setItem("searchValueThree", searchValueFromState);
    } else if (storedSearchValue) {
      setSearchVal(storedSearchValue);
    }
  }, [location.state]);

  // Debounced search function
  const debouncedSearch = useCallback(
    debounce((value) => {
      setSearchVal(value);
    }, 500),
    []
  );

  // search data in api using search box
  const hangleSearchVal = (e) => {
    const value = e.target.value;
    debouncedSearch(value);
    localStorage.setItem("searchValueThree", value); // Store search value in localStorage
  };

  const handleRowDoubleClick = (invoice) => {
    navigate(`/sales-invoice/${invoice}`, {
      state: { searchValue: searchVal },
    });
  };

  //======================== Show Complete Error When Clicking on show Btn ========================//
  const [errorModal, setErrorModal] = useState(false);
  const [errorText, setErrorText] = useState(false);
  const handleShowError = (errorDetails) => {
    setErrorModal(true);
    setErrorText(errorDetails);
  };
  return (
    <>
      <h4 className="primHeading my-2 mb-4">All Disputed Invoices</h4>
      <div className="box my-3">
        <h4 className="sectionHeading my-2 mb-4">Disputed Invoices summary</h4>
        {/*==========================|||****** Filters Start Here ********|||==========================*/}
        <Row className="customFilters">
          <Col xl={2} lg={4} md={6} sm={5} className="my-2">
            <label className="text-bold" htmlFor="From">
              From:
            </label>
            <input
              value={from}
              onChange={handleDateChange}
              className="prim-date"
              type="date"
              name="from"
              id="From"
            />
          </Col>
          <Col xl={2} lg={4} md={6} sm={5} className="my-2">
            <label className="text-bold" htmlFor="To">
              To:
            </label>
            <input
              value={to}
              onChange={handleDateChange}
              className="prim-date"
              type="date"
              name="to"
              id="To"
            />
          </Col>
          <Col xl={2} lg={4} md={6} sm={2} className="my-2">
            <button
              onClick={clearFilters}
              title="Clear Date Filters"
              className="main-btn clearFilter w-100 mt-4"
            >
              Clear
            </button>
          </Col>
        </Row>
        <hr />
        <TableCom
          numPerPage={numPerPage}
          totalData={totalData}
          handleChange={handleChange}
          hangleSearchVal={hangleSearchVal}
          pageCount={pageCount}
          handlePageClick={handlePageClick}
          searcPlaceHolder="Search..."
          searchVal={searchVal}
        >
          <div className="customTable2 w-100 table-responsive">
            <table className="w-100">
              <thead>
                <tr>
                  {Thead?.map((th, index) => {
                    return <th key={index}>{th}</th>;
                  })}
                </tr>
              </thead>
              <tbody>
                {items == "" || items == undefined || items == null ? (
                  <tr>
                    <td colSpan={thLength}>
                      <p className="my-2 smText text-decoration-underline text-danger">
                        No Record Found
                      </p>
                    </td>
                  </tr>
                ) : (
                  items?.map((item, index) => {
                    const {
                      description,
                      client,
                      createdAt,
                      currency,
                      invoiceId,
                      reason,
                      totalAmount,
                      paymentIntent,
                      salesPerson,
                      status,
                      invoice,
                      caseStatus,
                      accountName,
                      gatewayName,
                      requestedAmount,
                      updatedAt,
                    } = item;
                    return (
                      <tr
                        key={index}
                        onDoubleClick={() => handleRowDoubleClick(invoice)}
                        title="Double Click To View Details"
                        style={{ cursor: "pointer" }}
                      >
                        <td>{++index}</td>
                        <td onClick={() => handleCopyClipboard(invoiceId)}>
                          <span className="cursor-pointer">
                            {copiedText === invoiceId
                              ? "Copied"
                              : invoiceId || "--"}
                          </span>
                        </td>
                        <td>
                          {(caseStatus === "open" ? (
                            <span className="bedge colWhite bgRed">
                              {caseStatus}
                            </span>
                          ) : (
                            <span className="bedge colWhite colbgGreen">
                              {caseStatus}
                            </span>
                          )) || "--"}
                        </td>
                        <td>{status || "--"}</td>
                        <td>{gatewayName || "--"}</td>
                        <td>{accountName || "--"}</td>
                        <td>{paymentIntent || "--"}</td>
                        <td>{client || "--"}</td>
                        <td>{salesPerson || "--"}</td>
                        <td className="text-lowercase">
                          {totalAmount
                            ? `${totalAmount + " " + currency}`
                            : "--"}{" "}
                        </td>
                        <td>{requestedAmount || "--"}</td>

                        <td>{reason || "--"}</td>
                        <td>
                          {description.length > 12 ? (
                            <>
                              {" "}
                              {`${description.slice(0, 12)}...`}{" "}
                              <button
                                onClick={() => handleShowError(description)}
                                className="smBtn"
                              >
                                <FaEye />
                              </button>
                            </>
                          ) : (
                            description
                          )}
                        </td>
                        <td>{new Date(updatedAt).toLocaleString()}</td>
                        <td>{new Date(createdAt).toLocaleString()}</td>
                        <td>
                          <Dropdown className="actionDropDown">
                            <Dropdown.Toggle className="customDropDown">
                              <FaEllipsisV className="colBlack" />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item
                                className="drop_item"
                                as={Link}
                                to={`/sales-invoice/${invoice}`}
                              >
                                <FaRegEye className="me-1" />
                                <span>View</span>
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
        </TableCom>
      </div>
      {/*========================================== ! View --Or-- Errors here ! =======================================*/}
      <CustomModal
        show={errorModal}
        backdrop="static"
        keyboard="False"
        size="md"
      >
        <button onClick={() => setErrorModal(false)} className="modal-cancel">
          <FaTimes />
        </button>
        <div className="modalContent">
          <p className="mb-0 fw-600 my-2">{errorText || "--"}</p>
        </div>
      </CustomModal>
    </>
  );
};

export default Index;
