import React, { useState, useEffect } from 'react';
import GoBack from '../../../Components/GoBack';
import { Col, Row } from 'react-bootstrap';
import Tittle from "../../../hook/Title";
import { Link, useParams, useLocation } from 'react-router-dom';
import {
    getStripeCustomerbyIdRoute,
    cardsStripeByCustomerRoute
} from "../../../utils/APIRoutes";

const ViewClient = () => {
    Tittle("Client Details");
    const { custId } = useParams();
    const location = useLocation();
    const unitname = location.state;
    // Stripe Customers
    const [stripeCustomer, setStripeCustomer] = useState([])
    const [stripeCustomerCard, setStripeCustomerCard] = useState([])
    const { name, email, phone, address } = stripeCustomer;
    useEffect(() => {
        getStripeCustomer()
        listOfCardsStripeCustomer()
    }, []);
    // get a Stripe User by id 
    const getStripeCustomer = async () => {
        const response = await fetch(
            `${getStripeCustomerbyIdRoute}?id=${custId}`,
            {
                method: 'GET',
                headers: {
                    // 'x-rapidapi-host': 'carbonfootprint1.p.rapidapi.com', 
                }
            }
        );
        const data = await response.json();
        setStripeCustomer(data.customer);
    }
    // Getting card information of stripe customers !
    const listOfCardsStripeCustomer = async () => {
        const response = await fetch(
            `${cardsStripeByCustomerRoute}?id=${custId}`,
            {
                method: 'GET',
                headers: {
                    // 'x-rapidapi-host': 'carbonfootprint1.p.rapidapi.com', 
                }
            }
        );
        const customerCarddata = await response.json();
        setStripeCustomerCard(customerCarddata.cards.data)
    }

    return (
        <div className="box my-3">
            <div className="d-flex align-items-center mb-2 flex-wrap my-3">
                <GoBack disabled={true} /><h4 className="primHeading mb-0">{unitname === ("" || undefined || null) ? "Unit" : unitname} Client <span>Details</span></h4>
            </div>
            <Row>
                <Col xl={8} lg={10}>
                    <Row>
                        <Col md={6}>
                            <div className="my-3">
                                <label className="text-bold" htmlFor="cname">Name:</label>
                                <input id='cname' value={name || "-"} type="text" className="filterSelect disableInputs" placeholder='Enter Client Name' disabled={true} />
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="my-3">
                                <label className="text-bold" htmlFor="email">Email Address:</label>
                                <input id='email' value={email || "-"} type="email" className="filterSelect disableInputs" placeholder='Enter Email Address' disabled={true} />
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="my-3">
                                <label className="text-bold" htmlFor="num">Phone:</label>
                                <input value={phone || 0} type="number" id='num' className="filterSelect disableInputs" placeholder='Enter Phone Number' disabled={true} />
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="my-3">
                                <label className="text-bold" htmlFor="country">Country Code:</label>
                                <input id='country' value={address?.country || "-"} type="text" className="filterSelect disableInputs" placeholder='Enter Country' disabled={true} />
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <h4 className="primHeading mb-0">Cards <span>Detail</span></h4>
            {/* client Cards start here */}
            <Row>
                {
                    stripeCustomerCard === "" || stripeCustomerCard.length === 0 || stripeCustomerCard === null ? (
                        <div className="text-center my-5">
                            <p className='text-danger text-uppercase'><b>No cards to show !</b></p>
                            <Link to={`/add-card/${custId}`} className='Btn main-btn'>Add Card</Link>
                        </div>
                    ) : (
                        stripeCustomerCard?.map((card, index) => {
                            const { brand, id, exp_year, country, exp_month, last4, cvc_check } = card
                            const cardId = id;
                            return (
                                <Col key={index} xxl={4} xl={6} lg={6}>
                                    <div className="clientCard my-4">
                                        <div className="text-end"><h5>{brand}</h5></div>
                                        <h5 className='mb-3 fw-600'>{`**** **** **** ${last4}`}</h5>
                                        <div className="d-flex flex-wrap w-100 justify-content-between align-items-center">
                                            <h6 className='mb-3'>Exp <span className='cardsmtxt ps-2'>{exp_month}/{exp_year}</span></h6>
                                            <h6 className='mb-3'>CVC <span className='cardsmtxt ps-2 text-green'>{cvc_check}</span></h6>
                                        </div>
                                        <div className="d-flex flex-wrap w-100 justify-content-between align-items-center">
                                            <h4 className='text-uppercase mt-3'>{name}</h4>
                                            <Link className='text-white' to={`/view-stripe-card/${custId}/${cardId}`}><u>View Details</u></Link>
                                        </div>
                                    </div>
                                </Col>
                            )
                        })
                    )
                }
            </Row>
        </div>
    );
}
export default ViewClient;  