export const setThemeColor = (color) => ({
    type: 'SET_THEME_COLOR',
    theme: color,
});
export const toggleTopSellingBrands = () => ({
    type: 'TOGGLE_TOP_SELLING_BRANDS',
});
export const toggleUnitWiseIncome = () => ({
    type: 'TOGGLE_UNIT_WISE_INCOME',
});
export const toggleCompleteGrowthByMonthChart = () => ({
    type: 'TOGGLE_COMPLETE_GROWTH_BY_MONTH_CHART',
});
export const togglePaymentActivity = () => ({
    type: 'TOGGLE_PAYMENT_ACTIVITY',
});
export const toggleInvReceivable = () => ({
    type: 'Tot_Inv_Receivable',
});