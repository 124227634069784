import React from 'react';

const DeallInfo = ({ phone, source, state, country, city, organization }) => {
     return (
          <div className='box rounded-0'>
               <h4 className='subHeading fw-800'>Additional Info</h4>
               <div className="mb-3">
                    <label className='colBlack fw-600 exsmText' htmlFor="mobile">Mobile:</label>
                    <p className='exsmText colGray'>{phone || "-"}</p>
               </div>
               <div className="mb-3">
                    <label className='colBlack fw-600 exsmText' htmlFor="brand">Brand:</label>
                    <p className='exsmText colGray'>{source || "-"}</p>
               </div>
               <div className="mb-3">
                    <label className='colBlack fw-600 exsmText' htmlFor="organization">Organization:</label>
                    <p className='exsmText colGray'>{organization || "-"}</p>
               </div>
               <div className="mb-3">
                    <label className='colBlack fw-600 exsmText' htmlFor="city">City:</label>
                    <p className='exsmText colGray'>{city || "-"}</p>
               </div>
               <div className="mb-3">
                    <label className='colBlack fw-600 exsmText' htmlFor="state">State:</label>
                    <p className='exsmText colGray'>{state || "-"}</p>
               </div>
               <div className="mb-3">
                    <label className='colBlack fw-600 exsmText' htmlFor="country">Country:</label>
                    <p className='exsmText colGray'>{country || "-"}</p>
               </div>
          </div>
     );
}

export default DeallInfo;
