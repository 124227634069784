const initialState = {
    data: [],
    loading: true,
    success: false
}

const AuthReducer = (state = initialState, action) => {
    switch (action.type) {
        case "get_auth_data": return {
            ...state,
            data: action.data,
            loading: action.loading,
            success: action.success,
        }
        case "Reset_Auth_State":
            return initialState;

        default: return state;
    }
}

export default AuthReducer;
