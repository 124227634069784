import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { FaPlus } from "react-icons/fa";
import { Navigate, useParams } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import EditDealModal from "../../../../Components/ScreenComponents/EditDealModal";
import DeallInfo from '../../../../Components/ScreenComponents/DeallInfo';
import DealsNote from '../../../../Components/ScreenComponents/DealsNote';
import { getDealbyIdRoute, updateDealStageByIdRoute } from "../../../../utils/APIRoutes";
import { openDealModal } from "../../../../actions/EditDealModalButton"
import { useDispatch } from 'react-redux';
import LoadingSpinner from "../../../../utils/LoadingSpinner";
import axios from 'axios';
import { toast } from "react-toastify";
 
const ViewDeal = () => { 
     let toastOptions = {
          position: "top-left",
          autoClose: 5000,
          pauseOnHover: false,
          draggable: false,
          theme: "dark",
     };
     const disPatch = useDispatch();
     const { dealId } = useParams();
     const [dealData, setDealData] = useState([]);
     const { _id, budget, city, country, currencyCode, email, industry, message, name, note, organization, phone, source, stage, state, title } = dealData;

     const [error, setError] = useState(false);
     const getDealsData = async () => {
          const response = await fetch(`${getDealbyIdRoute}?dealId=${dealId}`);
          const dealsData = await response.json();
          if (dealsData.status === 500) {
               setError(true)
          } else {
               setDealData(dealsData.data);
          }
     }
     useEffect(() => {
          getDealsData();
     }, []);

     const changeStage = async (stageValue) => {
          const { data } = await axios.put(`${updateDealStageByIdRoute}?dealId=${dealId}`, {
               stage: stageValue
          });
          if (data.success === true) {
               toast.success(data.msg, toastOptions, toastOptions['position'] = "top-right");
               getDealsData();
          } else {
               toast.error(data.msg, toastOptions, toastOptions['position'] = "bottom-right");
          }
     }
     return (
          <>
               <Row>
                    {
                         error === true ? (
                              <>
                                   <Col lg={12}>
                                        <Skeleton width={100} count={1} height={35} />
                                        <Skeleton width={100} count={1} />
                                   </Col>
                              </>
                         ) : (
                              <>
                                   <Col lg={12}>
                                        <div className='box rounded-0 mb-4'>
                                             <div className="d-flex align-items-center justify-content-between flex-wrap">
                                                  <div className="d-flex align-items-center my-3">
                                                       <h4 className='sectionHeading mb-0 me-3'>{title || "-"}</h4>
                                                       <button className='smBtn' onClick={() => disPatch(openDealModal("openDealModal"))}>Edit</button>
                                                  </div>
                                                  <div className='my-3'>
                                                       <label htmlFor="stage" className='me-2'>Deal Stage: </label>
                                                       <select name="stage"
                                                            value={stage}
                                                            className={`dashboard-inputs text-capitalize ${stage}`}
                                                            disabled id="stage">
                                                            <option value={stage}>{stage}</option>
                                                       </select>
                                                  </div>
                                             </div>
                                             <div className="d-flex align-items-center justify-content-between flex-wrap mb-4">
                                                  <div className="d-flex align-items-center mb-3">
                                                       <img src={require("../../../../assets/images/avatar.png")} className="smImg flex-shrink-0 me-2" alt="avatar user" draggable={false} />
                                                       <div>
                                                            <span className="d-block exsmText">{name}</span>
                                                            <span className="d-block exsmText"><b>{email}</b></span>
                                                       </div>
                                                  </div>
                                                  <div className="d-flex align-items-center justify-content-end flex-wrap">
                                                       <button title='mark as Contacting' className={stage === "contacting" ? "d-none" : "py-2 smBtn rounded-0 mx-2 mb-1"} onClick={() => changeStage("contacting")}> 🤔 Contacting </button>
                                                       <button title='mark as won' className={stage === "won" ? "d-none" : "py-2 smBtn rounded-0 mx-2 mb-1"} onClick={() => changeStage("won")} disabled={stage === "won" ? true : false} > 😊 Won </button>
                                                       <button title='mark as lost' className={stage === "lost" ? "d-none" : "py-2 smBtn rounded-0 mx-2 mb-1"} onClick={() => changeStage("lost")} disabled={stage === "lost" ? true : false} > 😔 Lost </button>
                                                  </div>
                                             </div>
                                             <div className="mb-3">
                                                  <label className='colBlack fw-600 exsmText' htmlFor="address">Dscription:</label>
                                                  <p className='exsmText colGray'>{message || "-"}</p>
                                             </div>
                                             <hr />
                                             <Row>
                                                  <Col xl={2} lg={3} md={4}>
                                                       <div className="mb-3 d-flex align-items-center gap-1">
                                                            <label className='colBlack fw-600 exsmText' htmlFor="budget">Budget:</label>
                                                            <p className='exsmText colGray mb-0'>$ {budget || "-"}</p>
                                                       </div>
                                                  </Col>
                                                  <Col xl={2} lg={3} md={4}>
                                                       <div className="mb-3 d-flex align-items-center gap-1">
                                                            <label className='colBlack fw-600 exsmText' htmlFor="tag">Tag:</label>
                                                            <p className='exsmText colGray mb-0'>{industry || "-"}</p>
                                                       </div>
                                                  </Col>
                                                  <Col xl={2} lg={3} md={4}>
                                                       <div className="mb-3 d-flex align-items-center gap-1">
                                                            <label className='colBlack fw-600 exsmText' htmlFor="source">Source:</label>
                                                            <p className='exsmText colGray mb-0'>{source || "-"}</p>
                                                       </div>
                                                  </Col>
                                                  <Col xl={2} lg={3} md={4}>
                                                       <div className="mb-3 d-flex align-items-center gap-1">
                                                            <label className='colBlack fw-600 exsmText' htmlFor="currencyCode">Currency:</label>
                                                            <p className='exsmText colGray mb-0'>{currencyCode || "-"}</p>
                                                       </div>
                                                  </Col>
                                                  <Col lg={12} className="text-end">
                                                       <button className='main-btn py-2 rounded-0 mx-2'><FaPlus /> Move To Project</button>
                                                  </Col>
                                             </Row>
                                        </div>
                                   </Col>
                                   <Col lg={8}>
                                        {note !== undefined ? <DealsNote dealNote={note} id={_id} /> : <LoadingSpinner />}
                                   </Col>
                                   <Col lg={4}>
                                        <DeallInfo phone={phone} source={source} state={state} organization={organization} country={country} city={city} />
                                   </Col>
                              </>
                         )
                    }
               </Row>
               <EditDealModal id={_id} />
          </>
     );
}

export default ViewDeal;
