import React, { useEffect, useState } from "react";
import {
  createClientRoute,
  getEmployeeUnitsRoute,
  getAllLeadsOnCreateClientRoute,
} from "../../../utils/APIRoutes";
import countryCodes from "../../../helpers/countryCodes.json";
import { toast } from "react-toastify";
import { Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import GoBack from "../../../Components/GoBack";
import { useSelector } from "react-redux";
import Title from "../../../hook/Title";
import axios from "axios";
import { FaTimes } from "react-icons/fa";
import CustomModal from "../../../Components/CustomModal";
import { Modal, Button, Table } from "react-bootstrap";

const CreateClient = () => {
  Title("Create Client");
  const AuthReducer = useSelector((state) => state.AuthReducer);
  const token = localStorage.getItem("token");
  const { empId, designation } = AuthReducer.data;
  const navigate = useNavigate();
  const allbrandCountryCodes = countryCodes.codes;
  const [servError, setServError] = useState(false);
  const [empUnit, setEmpUnit] = useState([]);
  const [leadSource, setLeadSource] = useState([]);
  const [leadsModal, setleadsModal] = useState(false);
  const [searching, setSearching] = useState("");
  const [tableData, settableData] = useState([]);
  const [count, setCount] = useState(0);
  const [filterLoader, setFilterLoader] = useState(false);
  const [leadId, setLeadId] = useState("");
  const [leadname, setLeadName] = useState("");
  let toastOptions = {
    position: "top-left",
    autoClose: 5000,
    pauseOnHover: false,
    draggable: false,
    theme: "dark",
  };
  //  Get Brands Name
  const getEmpUnit = async () => {
    try {
      const resp = await fetch(`${getEmployeeUnitsRoute}?empId=${empId}`);
      const resData = await resp.json();
      if (resData.success === true) {
        setEmpUnit(resData.unit);
        setServError(false);
      } else {
        throw new Error("Something went wrong.");
      }
    } catch (error) {
      setServError(true);
    }
  };
  useEffect(() => {
    getEmpUnit();
  }, []);
  //============================================ ! Api inegration of Create Leads code !  ===============================================//

  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  // ---- States of inputs in the form of key value pair name:value ------ //
  let [values, setValues] = useState({
    name: "",
    email: "",
    organization: "",
    phone: "",
    country: "",
    unitId: "",
  });
  const { name, email, organization, phone, country, unitId } = values;
  // ---- Getting values of inputs ------ //
  const handleInpChange = (event) => {
    setValues({ ...values, [event.target.name]: event.target.value });
  };
  let hasError = false;
  // ---- Validate Form ------ //
  const validateForm = () => {
    const newErrors = {};
    if (name === "") {
      hasError = true;
      setIsLoading(false);
      newErrors.name = "Name is required !";
    }
    if (email === "") {
      hasError = true;
      setIsLoading(false);
      newErrors.email = "Email is required !";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      hasError = true;
      setIsLoading(false);
      newErrors.email = "Email is not valid !";
    }
    if (phone === "") {
      hasError = true;
      setIsLoading(false);
      newErrors.phone = "Phone Number is required !";
    }
    if (unitId === "") {
      hasError = true;
      setIsLoading(false);
      newErrors.unitId = "Unit Source is required !";
    }
    if (leadId === "") {
      hasError = true;
      setIsLoading(false);
      newErrors.leadId = "Lead Source is required !";
    }
    setErrors(newErrors);
  };
  // ---- Form Submittion Function ------ //
  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    validateForm();
    if (hasError === false) {
      const { data } = await axios.post(`${createClientRoute}?empId=${empId}`, {
        name,
        email,
        organization,
        phone,
        country,
        unitId,
        leadId,
      });
      if (data.success === true) {
        setIsLoading(false);
        toast.success(
          data.msg,
          toastOptions,
          (toastOptions["position"] = "top-right")
        );
        setValues({
          name: "",
          email: "",
          organization: "",
          phone: "",
          country: "",
          unitId: "",
        });
        setLeadId("");
        setLeadName("");
        if (designation === "unit_head") {
          navigate("/my-unit-clients");
        } else {
          navigate("/my-clients");
        }
      } else {
        toast.error(
          data.msg,
          toastOptions,
          (toastOptions["position"] = "bottom-right")
        );
        setIsLoading(false);
      }
    } else {
      toast.error(
        "Kindly Fill the require fields !",
        toastOptions,
        (toastOptions["position"] = "bottom-right")
      );
    }
  };

  const openLeadPanel = () => {
    setLeadId("");
    setLeadName("");
    setSearching("");
    setleadsModal(true);
    settableData([]);
    setCount(0);
  };

  const closeLeadPanel = () => {
    setleadsModal(false);
  };

  const setLeadValues = (_id, name, phone, email, leadSource) => {
    setLeadId(_id);
    setLeadName(
      "Name: " +
        name +
        ", Email: " +
        email +
        ", Phone: " +
        phone +
        ", Lead Type: " +
        leadSource
    );
    setleadsModal(false);
  };

  useEffect(() => {
    const getSearchedLeads = async () => {
      try {
        setFilterLoader(true);
        const { data } = await axios.get(
          `${getAllLeadsOnCreateClientRoute}?search=${searching}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        // console.log(data)
        if (data.success === true) {
          // setWireList(data.wire)
          settableData(data.result);
          setCount(data.count);
          setFilterLoader(false);
        } else {
          setFilterLoader(false);
        }
      } catch (error) {
        setFilterLoader(false);
        if (error?.response?.status === 401) {
          navigate("/");
          toast.error(
            "Session has been Expired",
            toastOptions,
            (toastOptions["position"] = "top-right")
          );
        }
      }
    };
    getSearchedLeads();
  }, [searching]);

  return (
    <>
      <div className="d-flex align-items-center mb-4 flex-wrap">
        <GoBack />
        <h4 className="section-heading mb-0">
          Create <span>Client</span>
        </h4>
      </div>
      <div className="box">
        {servError === true ? (
          <p className="text-center my-2 fw-600 text-danger">
            Something went wrong Please try again later (Server Error 500)
          </p>
        ) : (
          <form onSubmit={(event) => handleSubmit(event)}>
            <Row>
              <Col lg={8}>
                <p className="fw-600 my-2 mb-4">Add New Client From here</p>
                <Row>
                  <Col lg={12} className="my-2">
                    <label className="text-bold mb-1" htmlFor="name">
                      Select Lead Source:<span className="text-danger">*</span>{" "}
                      <span className="text-secondary">
                        'double click to select'
                      </span>
                    </label>
                    <input
                      value={leadname}
                      name="leadSource"
                      id="leadSource"
                      type="text"
                      className="filterSelect ps-3"
                      placeholder="Click to Select Lead Source"
                      readOnly
                      onDoubleClick={() => openLeadPanel()}
                      title="Double Click to Select ..."
                    />

                    {errors.leadId && (
                      <p className="text-danger fw-500 fs-13 my-2 text-capitalize">
                        {errors.leadId}
                      </p>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col lg={6} className="my-2">
                    <label className="text-bold mb-1" htmlFor="name">
                      Name:<span className="text-danger">*</span>
                    </label>
                    <input
                      value={name}
                      onChange={(e) => handleInpChange(e)}
                      name="name"
                      id="name"
                      type="text"
                      className="filterSelect ps-3"
                      placeholder="Enter Name"
                    />
                    {errors.name && (
                      <p className="text-danger fw-500 fs-13 my-2 text-capitalize">
                        {errors.name}
                      </p>
                    )}
                  </Col>
                  <Col lg={6} className="my-2">
                    <label className="text-bold mb-1" htmlFor="email">
                      Email:<span className="text-danger">*</span>
                    </label>
                    <input
                      value={email}
                      onChange={(e) => handleInpChange(e)}
                      name="email"
                      id="email"
                      type="text"
                      className="filterSelect ps-3"
                      placeholder="Enter Email"
                    />
                    {errors.email && (
                      <p className="text-danger fw-500 fs-13 my-2 text-capitalize">
                        {errors.email}
                      </p>
                    )}
                  </Col>
                  <Col lg={6} className="my-2">
                    <label className="text-bold mb-1" htmlFor="phone">
                      Phone:<span className="text-danger">*</span>
                    </label>
                    <input
                      value={phone}
                      onChange={(e) => handleInpChange(e)}
                      name="phone"
                      id="phone"
                      type="number"
                      className="filterSelect ps-3"
                      placeholder="Enter Phone Number"
                    />
                    {errors.phone && (
                      <p className="text-danger fw-500 fs-13 my-2 text-capitalize">
                        {errors.phone}
                      </p>
                    )}
                  </Col>
                  <Col lg={6} className="my-2">
                    <label className="text-bold mb-1" htmlFor="organization">
                      Organization:
                    </label>
                    <input
                      value={organization}
                      onChange={(e) => handleInpChange(e)}
                      name="organization"
                      id="organization"
                      type="text"
                      className="filterSelect ps-3"
                      placeholder="Enter Organization Name"
                    />
                  </Col>
                  <Col lg={6} className="my-2">
                    <label className="text-bold mb-1" htmlFor="country">
                      Country:
                    </label>
                    <select
                      className="filterSelect ps-3"
                      id="country"
                      name="country"
                      value={country}
                      onChange={(e) => handleInpChange(e)}
                    >
                      <option value="">Select Option</option>
                      {Object.keys(allbrandCountryCodes).map(
                        (objkey, index) => {
                          return (
                            <option
                              key={index}
                              value={allbrandCountryCodes[objkey]}
                            >
                              {allbrandCountryCodes[objkey]}
                            </option>
                          );
                        }
                      )}
                    </select>
                  </Col>
                  <Col lg={6} className="my-2">
                    <label className="text-bold mb-1" htmlFor="unitId">
                      Unit Source: <span className="text-danger">*</span>{" "}
                    </label>
                    <select
                      className="filterSelect ps-3"
                      id="unitId"
                      name="unitId"
                      onChange={(e) => handleInpChange(e)}
                      value={unitId}
                    >
                      <option value="">Select Option</option>
                      {empUnit?.map((empUnit) => {
                        const { name, id } = empUnit;
                        return (
                          <option key={id} value={id}>
                            {name}
                          </option>
                        );
                      })}
                    </select>
                    {errors.unitId && (
                      <p className="text-danger fw-500 fs-13 my-2 text-capitalize">
                        {errors.unitId}
                      </p>
                    )}
                  </Col>
                </Row>
                <button
                  className="main-btn mt-3"
                  disabled={isLoading}
                  type="submit"
                >
                  {" "}
                  {isLoading ? "submiting..." : "submit"}{" "}
                </button>
              </Col>
            </Row>
          </form>
        )}
      </div>

      <Modal
        className="searchingLeadsModal"
        size="xl"
        show={leadsModal}
        onHide={() => closeLeadPanel()}
        backdrop="static"
        keyboard={false}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header>
          <Modal.Title id="example-modal-sizes-title-lg">
            Search Leads By Any Name, Email Or Phone Number
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xxl={8} xl={8} lg={8} md={8} sm={8}>
              <input
                value={searching}
                onChange={(e) => setSearching(e.target.value)}
                name="organization"
                id="organization"
                type="text"
                className="filterSelect ps-3"
                placeholder="Type minimum 3 character to search ..."
              />
            </Col>
            <Col
              xxl={2}
              xl={2}
              lg={2}
              md={2}
              sm={2}
              className="my-2 text-secondary"
            >
              <label>Count: {count}</label>
            </Col>
            <Col
              xxl={2}
              xl={2}
              lg={2}
              md={2}
              sm={2}
              className="my-2 text-danger"
            >
              {filterLoader ? <label>Loading...</label> : <></>}
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="leadSearchTable ps-3 overflow-auto">
                <Table striped bordered hover>
                  <thead>
                    {tableData == "" ||
                    tableData == undefined ||
                    tableData == null ? (
                      <tr></tr>
                    ) : (
                      <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Phone</th>
                        <th>Lead Type</th>
                        <th>CreatedAt</th>
                        <th>Source</th>
                        <th>Assigned To</th>
                      </tr>
                    )}
                  </thead>
                  <tbody>
                    {tableData == "" ||
                    tableData == undefined ||
                    tableData == null ? (
                      <tr>
                        <td className="text-center">
                          <p className="my-2 smText text-decoration-underline text-danger">
                            No Record Found
                          </p>
                        </td>
                      </tr>
                    ) : (
                      tableData?.map((item, index) => {
                        const {
                          _id,
                          name,
                          email,
                          phone,
                          salesPerson,
                          leadSource,
                          source,
                          createdAt,
                        } = item;
                        return (
                          <tr
                            key={index}
                            onDoubleClick={() =>
                              setLeadValues(_id, name, phone, email, leadSource)
                            }
                            title="Double Click to Select"
                          >
                            <td>{++index}</td>
                            <td>{name === "" ? "--" : name}</td>
                            <td>{email === "" ? "--" : email}</td>
                            <td>{phone === "" ? "--" : phone}</td>
                            <td>{leadSource === "" ? "--" : leadSource}</td>
                            <td>
                              {createdAt
                                .slice(0, 10)
                                .split("-")
                                .reverse()
                                .join("-") || "-"}
                            </td>
                            <td>{source === "" ? "--" : source}</td>
                            <td>
                              {salesPerson.length > 0
                                ? salesPerson[0].name
                                : "--"}
                            </td>
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </Table>
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setleadsModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default CreateClient;
