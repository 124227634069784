import { React, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import LoadingSpinner from "../../utils/LoadingSpinner";
import Title from "../../hook/Title";
import { forgetRoute } from "../../utils/APIRoutes";
import axios from "axios";
import {
  FaInstagram,
  FaFacebookF,
  FaTwitter,
  FaRegEnvelope,
} from "react-icons/fa";


const Forget = () => {
  Title("Forget Password");
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  let toastOptions = {
    position: "top-left",
    autoClose: 5000,
    pauseOnHover: false,
    draggable: false,
    theme: "dark",
  };
  // ---- States of inputs in the form of key value pair name:value ------ // 
  const [values, setValues] = useState({ email: "" });
  // ---- Getting values of inputs ------ //  
  const handleChange = (event) => {
    setValues({ ...values, [event.target.name]: event.target.value });
  };
  // ---- Validate Form ------ //
  const validateForm = () => {
    const { email } = values;
    if (email === "") {
      toast.error("Email is required ! ", toastOptions, toastOptions['position'] = "bottom-right");
      setIsLoading(false);
      return false;
    } else {
      return true;
    }
  };
  // ---- Form Submittion Function ------ // 
  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    if (validateForm()) {
      const { email } = values;
      const { data } = await axios.post(forgetRoute, {
        email
      });
      if (data.statusText === "Success") {
        toast.success("Verification code sent to your email kindly check your email addresss !", toastOptions, toastOptions['position'] = "top-right");
        navigate(`/verification/${email}`);
        setIsLoading(false);
      } else {
        toast.error(data.message, toastOptions, toastOptions['position'] = "top-left");
        setIsLoading(false);
      }
    }
  };
  return (
    <section className="authWrapper">
      <div className="authBox">
        <div className="text-center">
          <h2 className="primHeading ">FORGET PASSWORD</h2>
          <h6 className="smText">
            Enter your email address to receive
            <br /> a verification code
          </h6>
        </div>
        <form onSubmit={(event) => handleSubmit(event)}>
          <div className="registration-inp-box my-3">
            <label className="d-block" htmlFor="email">
              Email<span className="text-danger">*</span>
            </label>
            <div className="position-relative">
              <button
                className="position-absolute show-password"
                type="button">
                <FaRegEnvelope />
              </button>
              <input
                id="email"
                className="primary-inputs"
                type="email"
                placeholder="Email"
                name="email"
                onChange={(e) => handleChange(e)}
              />
            </div>
          </div>
          <div className="text-center mt-4">
            <button
              className="main-btn w-100"
              type="submit"
              disabled={isLoading}>
              {isLoading ? <LoadingSpinner /> : "Continue"}
            </button>
            <Link className="d-block my-3 smText colBlack primLink" to="/">BACK TO LOGIN</Link>
            <div className="socialWrap">
              <a href="https://zibbletech.com/" target="_blank">
                <FaFacebookF />
              </a>
              <a href="https://zibbletech.com/" target="_blank">
                <FaInstagram />
              </a>
              <a href="https://zibbletech.com/" target="_blank">
                <FaTwitter />
              </a>
            </div>
          </div>
        </form>
      </div>
    </section>
  );
};
export default Forget;
