import Title from "../../hook/Title";
import React, { useState, useEffect } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import { TableCom } from "../../Components/TableCom";
import {
  FaExclamationCircle,
  FaBoxes,
  FaRegEye,
  FaFileAlt,
  FaEllipsisV,
  FaCheck,
  FaPlus,
} from "react-icons/fa";
const Index = () => {
  // Table Head Data
  const Thead = [
    "S.No",
    "Task",
    "Assigned To",
    "Priority",
    "Start Date",
    "Deadline",
    "Work Status",
    "Action",
  ];
  // =======================================
  const taskData = [
    {
      id: 1,
      quantity: 1024,
      title: "Total Tasks",
    },
    {
      id: 2,
      quantity: 42,
      title: "Running Tasks",
    },
    {
      id: 3,
      quantity: 11,
      title: "On hold Tasks",
    },
    {
      id: 4,
      quantity: 38,
      title: "Completed Tasks",
    },
  ];
  const [thLength, setThlength] = useState(1);
  const [items, setItems] = useState([]);
  const [limit, setLimit] = useState(5);
  const [totalData, setTotalData] = useState(0);
  const [pageCount, setpageCount] = useState(0);
  const [searchVal, setSearchVal] = useState("");
  const BASEURL = "http://jsonplaceholder.typicode.com/posts";
  useEffect(
    (currentPage) => {
      currentPage = currentPage && 0;
      const getData = async () => {
        const res = await fetch(
          `${BASEURL}/?q=${searchVal}&?_page=${currentPage}&_limit=${limit}`
        );
        const data = await res.json();
        const total = res.headers.get("x-total-count");
        setTotalData(total);
        setpageCount(Math.ceil(total / limit));
        setItems(data);
      };
      getData();
    },
    [limit, searchVal]
  );
  useEffect(() => {
    setThlength(Thead.length);
  }, [thLength]);
  const fetchData = async (currentPage) => {
    const res = await fetch(`${BASEURL}?_page=${currentPage}&_limit=${limit}`);
    const data = await res.json();
    return data;
  };
  const handlePageClick = async (data) => {
    let currentPage = data.selected + 1;
    const myData = await fetchData(currentPage);
    setItems(myData);
  };
  const handleChange = (e) => {
    setLimit(e.target.value);
  };
  // search data in api using search box
  const hangleSearchVal = (e) => {
    setSearchVal(e.target.value);
  };

  Title("Task List");
  const role = "employee";
  return (
    <>
      <div className="d-flex align-items-center justify-content-between">
        <h4 className="primHeading my-2 mb-4">Task List</h4>
        {role == "sales" ? (
          <Link className="main-btn" to="/create-task">
            Create Task
          </Link>
        ) : (
          ""
        )}
      </div>
      <Row className="my-4">
        {taskData.map(({ quantity, title, id }, index) => {
          return (
            <Col
              key={index}
              xxl={3}
              xl={6}
              sm={6}
              className={`analyticsWrap${id} mb-2`}
            >
              <div className="dashboard-anatlytics d-flex align-items-center justify-content-between flex-wrap">
                <div>
                  <h4 className="mb-2 mt-0">{title}</h4>
                  <h3>{quantity}</h3>
                </div>
                <div className={`analyticsIcon${id} analyticsIcon`}>
                  {id == 1 ? (
                    <FaFileAlt className="icons" />
                  ) : id == 2 ? (
                    <FaBoxes className="icons" />
                  ) : id == 3 ? (
                    <FaExclamationCircle className="icons" />
                  ) : id == 4 ? (
                    <FaCheck className="icons" />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </Col>
          );
        })}
      </Row>
      <div className="box my-3">
        <h4 className="sectionHeading my-2 mb-4">Recent Task Summary</h4>
        {/* Filters start here */}
        <Row className="customFilters">
          <Col xl={2} lg={4} md={6} sm={6} className="my-2">
            <label className="text-bold" htmlFor="From">
              From:
            </label>
            <input className="prim-date" type="date" name="" id="From" />
          </Col>
          <Col xl={2} lg={4} md={6} sm={6} className="my-2">
            <label className="text-bold" htmlFor="To">
              To:
            </label>
            <input className="prim-date" type="date" name="" id="To" />
          </Col>
          <Col xl={3} lg={4} md={6} sm={6} className="my-2">
            <label className="text-bold" htmlFor="Month">
              Status:
            </label>
            <select className="filterSelect">
              <option disabled>Select Employee</option>
              <option value="abc">Abc</option>
              <option value="abc">Def</option>
              <option value="abc">xyz</option>
            </select>
          </Col>
          <Col xl={3} lg={4} md={6} sm={6} className="my-2">
            <label className="text-bold" htmlFor="Year">
              Select Priority:
            </label>
            <select className="filterSelect">
              <option disabled> Select Priority </option>
              <option value="Low">Low</option>
              <option value="Medium">Medium</option>
              <option value="High">High</option>
            </select>
          </Col>
          <Col xl={2} lg={4} md={6} sm={6} className="my-2">
            <button className="main-btn w-100 mt-4">Search</button>
          </Col>
        </Row>
        <hr className="my-4" />
        {/* Table start here */}
        <TableCom
          limit={limit}
          totalData={totalData}
          handleChange={handleChange}
          hangleSearchVal={hangleSearchVal}
          pageCount={pageCount}
          handlePageClick={handlePageClick}
        >
          <div className="customTable2 w-100 table-responsive">
            <table className="w-100">
              <thead>
                <tr>
                  {Thead?.map((th, index) => {
                    return <th key={index}>{th}</th>;
                  })}
                </tr>
              </thead>
              <tbody>
                {items === "" || items === undefined || items === null ? (
                  <tr>
                    <td colSpan={thLength}>
                      <p className="my-2 smText text-decoration-underline text-danger">
                        No Record Found
                      </p>
                    </td>
                  </tr>
                ) : (
                  items.map((item) => {
                    return (
                      <tr key={item.id}>
                        <td>{item.id}</td>
                        <td>{item.title.slice(0, 5)}</td>
                        <td>
                          {/* asssigned user avatar */}
                          <img
                            className="tdAvatar"
                            src={require("../../assets/images/avatar.png")}
                            alt="assigned user"
                          />
                          {item.title.slice(0, 10)}
                        </td>
                        <td>
                          <span
                            className={
                              item.id == 1
                                ? "customBadge bgBlue"
                                : item.id == 2
                                ? "customBadge bgRed"
                                : item.id == 3
                                ? "customBadge bgGreen"
                                : "customBadge bgOrange"
                            }
                          >
                            {item.title.slice(0, 3)}
                          </span>
                        </td>
                        <td>{item.title.slice(0, 5)}</td>
                        <td>{item.title.slice(0, 5)}</td>
                        <td>
                          <span
                            className={
                              item.id == 1
                                ? "customBadge bgBlue"
                                : item.id == 2
                                ? "customBadge bgRed"
                                : item.id == 3
                                ? "customBadge bgGreen"
                                : "customBadge bgOrange"
                            }
                          >
                            {item.title.slice(0, 3)}
                          </span>
                        </td>
                        <td>
                          <Dropdown className="actionDropDown">
                            <Dropdown.Toggle className="customDropDown">
                              <FaEllipsisV className="colBlack" />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item
                                className="drop_item"
                                as={Link}
                                to="/view-tasks"
                                type="button"
                              >
                                <FaRegEye className="me-1" />
                                <span>View</span>
                              </Dropdown.Item>
                              <Dropdown.Item
                                className="drop_item"
                                as={Link}
                                to="/assign-task"
                                type="button"
                              >
                                <FaPlus className="me-1" />
                                <span>Assign</span>
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
        </TableCom>
      </div>
    </>
  );
};

export default Index;
