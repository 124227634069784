import React from 'react';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { updateDealNoteByIdRoute } from '../../utils/APIRoutes';
import { toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';

const DealsNote = ({ dealNote, id }) => {
     const navigate = useNavigate();
     const [loading, setIsLoading] = useState(false);
     let toastOptions = {
          position: "top-left",
          autoClose: 5000,
          pauseOnHover: false,
          draggable: false,
          theme: "dark",
     };
     const [note, setNote] = useState("");
     useEffect(() => {
          setNote(dealNote)
     }, []);
     // ---- Form Submittion Function ------ // 
     const handleSubmit = async (event) => {
          setIsLoading(true)
          event.preventDefault();
          const { data } = await axios.put(`${updateDealNoteByIdRoute}?dealId=${id}`, {
               note,
          });
          if (data.success === true) {
               setIsLoading(false)
               toast.success(data.msg, toastOptions, toastOptions['position'] = "top-right");
               navigate(`/view-deal/${id}`);
          } else {
               toast.error(data.msg, toastOptions, toastOptions['position'] = "top-left");
               setIsLoading(false)
          }
     }
     return (
          <div className='box rounded-0 mb-4'>
               <h4 className="sectionHeading fw-700 border-bottom">Note</h4>
               <form className='my-5 dealNoteForm' onSubmit={handleSubmit}>
                    <textarea onChange={(e) => { setNote(e.target.value) }} className='filterSelect' value={note} placeholder='Enter Note' rows="5"></textarea>
                    <button type='submit' className='main-btn mx-2' disabled={loading}> {loading === true ? "Submiting..." : "Submit"} </button>
               </form>
          </div>
     );
}

export default DealsNote;
