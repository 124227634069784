import React, { useState, useEffect, useCallback } from "react";
import { TableCom } from "../../Components/TableCom";
import { getAllSaleEmployeesOfThisUnitRoute } from "../../utils/APIRoutes";
import { debounce } from "lodash";

const UnitEmployeeTable = ({ unitId, unitName, rerender }) => {
  // Table Head Data
  const [thLength, setThlength] = useState(1);
  const [items, setItems] = useState([]);
  const [numPerPage, setNumPerPage] = useState(10);
  const [totalData, setTotalData] = useState(0);
  const [pageCount, setpageCount] = useState(0);
  const [currPage, setCurrPage] = useState(0);
  const [searchVal, setSearchVal] = useState("");
  const Thead = [
    "S.No",
    "employee Name",
    "department",
    "designation",
    "phone",
    "gender",
  ];
  useEffect(
    (currPage) => {
      currPage = currPage ?? 0;
      const getData = async () => {
        const res = await fetch(
          `${getAllSaleEmployeesOfThisUnitRoute}?unitId=${unitId}&name=${unitName}&numPerPage=${numPerPage}&page=${currPage}&search=${searchVal}`
        );
        const data = await res.json();
        const total = data.pagiantion.dataCount;
        setTotalData(total);
        setpageCount(Math.ceil(total / numPerPage));
        setItems(data.result);
      };
      getData();
    },
    [numPerPage, searchVal, rerender]
  );
  useEffect(() => {
    setThlength(Thead.length);
  }, [thLength]);
  const fetchData = async (currPage) => {
    const res = await fetch(
      `${getAllSaleEmployeesOfThisUnitRoute}?unitId=${unitId}&name=${unitName}&numPerPage=${numPerPage}&page=${currPage}&search=${searchVal}`
    );
    const changedData = await res.json();
    const data = changedData.result;
    return data;
  };
  const handlePageClick = async (data) => {
    let currPage = data.selected;
    const myData = await fetchData(currPage);
    setItems(myData);
    setCurrPage(currPage);
  };
  const handleChange = (e) => {
    setNumPerPage(e.target.value);
  };

  // Debounced search function
  const debouncedSearch = useCallback(
    debounce((value) => {
      setSearchVal(value);
    }, 500), // 500ms debounce delay
    []
  );

  // Search input handler
  const hangleSearchVal = (e) => {
    debouncedSearch(e.target.value);
  };

  return (
    <>
      <div className="box my-3 rounded-0">
        <h4 className="sectionHeading my-2 mb-4">Employee Summary</h4>
        <TableCom
          numPerPage={numPerPage}
          totalData={totalData}
          handleChange={handleChange}
          hangleSearchVal={hangleSearchVal}
          pageCount={pageCount}
          handlePageClick={handlePageClick}
          searcPlaceHolder="Search By Name..."
        >
          <div className="customTable2 w-100 table-responsive">
            <table className="w-100">
              <thead>
                <tr>
                  {Thead?.map((th, index) => {
                    return <th key={index}>{th}</th>;
                  })}
                </tr>
              </thead>
              <tbody>
                {items == "" || items == undefined || items == null ? (
                  <tr>
                    <td colSpan={thLength}>
                      {" "}
                      <p className="my-2 smText text-decoration-underline text-danger">
                        No Record Found
                      </p>{" "}
                    </td>
                  </tr>
                ) : (
                  items.map((item, index) => {
                    const {
                      employeeName,
                      department,
                      designation,
                      phone,
                      gender,
                      _id,
                    } = item;
                    return (
                      <tr key={_id}>
                        <td>{++index}</td>
                        <td>{employeeName || "-"}</td>
                        <td>{department || "-"}</td>
                        <td>
                          {designation === "unit_head"
                            ? "Unit Head"
                            : designation === "associate_manager"
                            ? "Associate Manager"
                            : designation === "head_of_department"
                            ? "Head Of Department"
                            : designation}
                        </td>
                        <td>{phone || "-"}</td>
                        <td>{gender || "-"}</td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
        </TableCom>
      </div>
    </>
  );
};

export default UnitEmployeeTable;
