import React, { useState, useEffect, useCallback } from "react";
import Title from "../../../hook/Title";
import { TableCom } from "../../../Components/TableCom";
import { getAllClientsRoute } from "../../../utils/APIRoutes";
import useClipboard from "../../../hook/useClipboard";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { debounce } from "lodash";

const AllClients = () => {
  Title("All Clients");
  const { handleCopyClipboard, copiedText } = useClipboard();

  // Table Head Data
  const [thLength, setThlength] = useState(1);
  const [items, setItems] = useState([]);
  const [numPerPage, setNumPerPage] = useState(10);
  const [totalData, setTotalData] = useState(0);
  const [pageCount, setpageCount] = useState(0);
  const [currPage, setCurrPage] = useState(0);
  const [searchVal, setSearchVal] = useState("");
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  let toastOptions = {
    position: "top-left",
    autoClose: 5000,
    pauseOnHover: false,
    draggable: false,
    theme: "dark",
  };
  const Thead = [
    "SNo",
    "Client Name",
    "Email",
    "Phone",
    "Sales person",
    "Unit",
    "Country",
    "Created At",
  ];
  useEffect(
    (currPage) => {
      currPage = currPage ?? 0;
      const getData = async () => {
        const res = await fetch(
          `${getAllClientsRoute}?numPerPage=${numPerPage}&page=${currPage}&search=${searchVal}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (res.status === 401) {
          navigate("/");
          toast.error(
            "Session has been Expired",
            toastOptions,
            (toastOptions["position"] = "top-right")
          );
          return;
        }
        const data = await res.json();
        const total = data.pagiantion.dataCount;
        setTotalData(total);
        setpageCount(Math.ceil(total / numPerPage));
        setItems(data.result);
      };
      getData();
    },
    [numPerPage, searchVal]
  );
  useEffect(() => {
    setThlength(Thead.length);
  }, [thLength]);
  const fetchData = async (currPage) => {
    const res = await fetch(
      `${getAllClientsRoute}?numPerPage=${numPerPage}&page=${currPage}&search=${searchVal}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (res.status === 401) {
      navigate("/");
      toast.error(
        "Session has been Expired",
        toastOptions,
        (toastOptions["position"] = "top-right")
      );
      return;
    }
    const changedData = await res.json();
    const data = changedData.result;
    return data;
  };
  const handlePageClick = async (data) => {
    let currPage = data.selected;
    const myData = await fetchData(currPage);
    setItems(myData);
    setCurrPage(currPage);
  };
  const handleChange = (e) => {
    setNumPerPage(e.target.value);
  };

  // Debounced search function
  const debouncedSearch = useCallback(
    debounce((value) => {
      setSearchVal(value);
    }, 500),
    []
  );

  // search data in api using search box
  const hangleSearchVal = (e) => {
    debouncedSearch(e.target.value);
  };

  return (
    <>
      <h4 className="primHeading my-2">All Clients</h4>
      <div className="box my-3">
        <h4 className="sectionHeading my-2 mb-4">All Clients Summary</h4>
        <TableCom
          numPerPage={numPerPage}
          totalData={totalData}
          handleChange={handleChange}
          hangleSearchVal={hangleSearchVal}
          pageCount={pageCount}
          handlePageClick={handlePageClick}
          searcPlaceHolder="Search By Name..."
        >
          <div className="customTable2 w-100 table-responsive">
            <table className="w-100">
              <thead>
                <tr>
                  {Thead?.map((th, index) => {
                    return <th key={index}>{th}</th>;
                  })}
                </tr>
              </thead>
              <tbody>
                {items == "" || items == undefined || items == null ? (
                  <tr>
                    <td colSpan={thLength}>
                      <p className="my-2 smText text-decoration-underline text-danger">
                        No Record Found
                      </p>
                    </td>
                  </tr>
                ) : (
                  items?.map((item, index) => {
                    const {
                      _id,
                      name,
                      email,
                      phone,
                      country,
                      salesPerson,
                      unit,
                      createdAt,
                    } = item;
                    return (
                      <tr key={_id}>
                        <td>{++index}</td>
                        <td>{name || "--"}</td>
                        <td>{email || "--"}</td>
                        <td onClick={() => handleCopyClipboard(phone)}>
                          <span className="cursor-pointer">
                            {copiedText === phone ? "Copied" : phone || "--"}
                          </span>
                        </td>
                        <td>
                          {salesPerson.length > 0 ? salesPerson[0].name : "--"}
                        </td>
                        <td>{unit.length > 0 ? unit[0].name : "--"}</td>
                        <td>{country || "--"}</td>
                        <td>{new Date(createdAt).toLocaleString()}</td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
        </TableCom>
      </div>
    </>
  );
};

export default AllClients;
