export const AuthAction = (data) => {
    return {
        type: "get_auth_data",
        data: data,
        loading: false,
        success: true,
    }
}
export const RemoveAuthAction = () => {
    return {
        type: "Reset_Auth_State",
        data: [],
        loading: true,
        success: false,
    }
} 