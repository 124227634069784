import { React, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { loginRoute } from "../../utils/APIRoutes";
import { useDispatch } from "react-redux";
import { AuthAction, RemoveAuthAction } from "../../actions/AuthAction";
import {
  FaEyeSlash,
  FaEye,
  FaInstagram,
  FaFacebookF,
  FaTwitter,
  FaRegEnvelope,
} from "react-icons/fa";
import "react-toastify/dist/ReactToastify.css";
import Title from "../../hook/Title";
import LoadingSpinner from "../../utils/LoadingSpinner";
import axios from "axios";

const Login = () => {
  Title("Login");
  const disPatch = useDispatch();
  let toastOptions = {
    position: "top-left",
    autoClose: 5000,
    pauseOnHover: false,
    draggable: false,
    theme: "dark",
  };
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };
  // ---- States of inputs in the form of key value pair name:value ------ //
  const [values, setValues] = useState({ email: "", password: "" });
  // ---- Getting values of inputs ------ //
  const handleChange = (event) => {
    setValues({ ...values, [event.target.name]: event.target.value });
  };
  // ---- Validate Form ------ //
  const validateForm = () => {
    const { email, password } = values;
    if (email === "") {
      toast.error(
        "Email and Password is required ! ",
        toastOptions,
        (toastOptions["position"] = "bottom-right")
      );
      setIsLoading(false);
      return false;
    } else if (password === "") {
      toast.error(
        "Email and Password is required ! ",
        toastOptions,
        (toastOptions["position"] = "bottom-right")
      );
      setIsLoading(false);
      return false;
    } else {
      return true;
    }
  };
  // ---- Form Submittion Function ------ //
  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    if (validateForm()) {
      const { email, password } = values;
      const { data } = await axios.post(loginRoute, {
        email: email.trim(),
        password: password.trim(),
      });
      if (data.success === true) {
        if (
          data.role === "sales" ||
          data.role === "development" ||
          data.role === "finance" ||
          data.role === "accounts" ||
          data.role === "ppc"
        ) {
          localStorage.setItem("token", data.token);
          localStorage.setItem("name", data.employeeName);
          localStorage.setItem("role", data.role);
          localStorage.setItem("emp_id", data.empId);
          toast.success(
            "Login Successfully !",
            toastOptions,
            (toastOptions["position"] = "top-right")
          );
          setIsLoading(false);
          disPatch(AuthAction(data));
          navigate("/dashboard");
        } else {
          setIsLoading(false);
          toast.error(
            "You dont have a right to access this app !",
            toastOptions,
            (toastOptions["position"] = "top-left")
          );
        }
      } else {
        toast.error(
          data.message,
          toastOptions,
          (toastOptions["position"] = "top-left")
        );
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    localStorage.removeItem("token");
    localStorage.removeItem("name");
    localStorage.removeItem("role");
    disPatch(RemoveAuthAction());
    navigate("/");
  }, []);

  return (
    <section className="authWrapper">
      <div className="authBox">
        <div className="text-center">
          <h2 className="primHeading ">LOGIN</h2>
          <h6 className="smText">Sign In to your account</h6>
        </div>
        <form onSubmit={(event) => handleSubmit(event)}>
          <div className="registration-inp-box my-3">
            <label className="d-block" htmlFor="email">
              Email<span className="text-danger">*</span>
            </label>
            <div className="position-relative">
              <button className="position-absolute show-password" type="button">
                <FaRegEnvelope />
              </button>
              <input
                id="email"
                className="primary-inputs"
                type="text"
                placeholder="Email"
                name="email"
                onChange={(e) => handleChange(e)}
              />
            </div>
          </div>
          <div className="registration-inp-box my-3">
            <label className="d-block" htmlFor="password">
              Password<span className="text-danger">*</span>
            </label>
            <div className="position-relative">
              <input
                id="password"
                className="primary-inputs"
                type={passwordShown ? "text" : "password"}
                placeholder="Password"
                name="password"
                onChange={(e) => handleChange(e)}
              />
              <button
                className="position-absolute show-password"
                type="button"
                onClick={togglePassword}
              >
                {passwordShown ? <FaEye /> : <FaEyeSlash />}
              </button>
            </div>
          </div>
          <button
            className="main-btn w-100 my-3"
            type="submit"
            disabled={isLoading}
          >
            {isLoading ? <LoadingSpinner /> : "Login"}
          </button>
          <div className="text-center my-3">
            <Link
              className="d-block smText colBlack my-3"
              to="/forgot-Password"
            >
              {" "}
              FORGET PASSWORD?{" "}
            </Link>
            <div className="socialWrap">
              <a href="https://zibbletech.com/" target="_blank">
                <FaFacebookF />
              </a>
              <a href="https://zibbletech.com/" target="_blank">
                <FaInstagram />
              </a>
              <a href="https://zibbletech.com/" target="_blank">
                <FaTwitter />
              </a>
            </div>
          </div>
        </form>
      </div>
    </section>
  );
};

export default Login;
