import React, { useState, useEffect, useCallback, useRef } from "react";
import Title from "../../../../hook/Title";
import { TableCom } from "../../../../Components/TableCom";
import currencycode from "../../../../helpers/currencyCodes.json";
import countrycodes from "../../../../helpers/countryCodes.json";
import Dropdown from "react-bootstrap/Dropdown";
import { useNavigate } from "react-router-dom";
import {
  getMyUnitsByEmpIdRoute,
  updateLeadRoute,
  getLeadbyIdRoute,
  createDealByLeadRoute,
  getMyLeadsBySalesPersonRoute,
  createInvoiceByLeadToClientRoute,
  updateManyLeadsStatusByLeadsRoute,
  getCommentUnitLeadsRoute,
  addCommentUnitLeadsRoute,
} from "../../../../utils/APIRoutes";
import { toast } from "react-toastify";
import axios from "axios";
import { FaRegEye, FaEllipsisV, FaEdit, FaTimes } from "react-icons/fa";
import { FaRegCommentDots } from "react-icons/fa6";
import CustomModal from "../../../../Components/CustomModal";
import { Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import useClipboard from "../../../../hook/useClipboard";
import { debounce } from "lodash";

const MyLeads = () => {
  Title("My Leads");
  const navigate = useNavigate();
  const { handleCopyClipboard, copiedText } = useClipboard();
  let toastOptions = {
    position: "top-left",
    autoClose: 5000,
    pauseOnHover: false,
    draggable: false,
    theme: "dark",
  };
  // ! Filter By Date Code for table ! :
  const [filterDates, setfilterDates] = useState({
    from: new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      1
    ).toLocaleDateString("en-CA"), // Set from to the first day of the current month
    to: "",
  });
  const handleDateChange = (e) => {
    setfilterDates({ ...filterDates, [e.target.name]: e.target.value });
  };
  const clearFilters = () => {
    setfilterDates({ from: "", to: "" });
  };
  const { from, to } = filterDates;

  const allcurrencyCode = currencycode.codes;
  const allCountryCodes = countrycodes.codes;
  const AuthReducer = useSelector((state) => state.AuthReducer);
  const { empId } = AuthReducer.data;
  const token = localStorage.getItem("token");
  const userId = localStorage.getItem("emp_id");
  const userName = localStorage.getItem("name");
  // Table Head Data
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [organization, setOrganization] = useState("");
  const [phone, setPhone] = useState("");
  const [country, setCountry] = useState("");
  const [currencyCode, setCurrencyCode] = useState("");
  const [budget, setBudget] = useState("");
  const [industry, setIndustry] = useState("");
  const [source, setSource] = useState("");
  const [note, setNote] = useState("");
  const [message, setMessage] = useState("");
  const [city, setCity] = useState("--");
  const [state, setState] = useState("--");
  const [leadSource, setLeadSource] = useState("--");
  // ...........! Table Code !......... //
  const [thLength, setThlength] = useState(1);
  const [items, setItems] = useState([]);
  const [numPerPage, setNumPerPage] = useState(10);
  const [totalData, setTotalData] = useState(0);
  const [pageCount, setpageCount] = useState(0);
  const [currPage, setCurrPage] = useState(0);
  const [searchVal, setSearchVal] = useState("");
  const [leadId, setLeadId] = useState("");
  const [rerendertable, setRerendertableData] = useState(false);
  const [editableState, setEditableState] = useState(false);
  const [title, setTitle] = useState("");
  const [comments, setComments] = useState([]); // State to store comments
  const [newComment, setNewComment] = useState(""); // State for new comment input
  const [commentsLoader, setCommentsLoader] = useState(false);
  const [commentModalShow, setCommentModalShow] = useState(false);
  const commentsEndRef = useRef(null);
  const [commentsHeight, setCommentsHeight] = useState(0);
  const commentHeightRef = useRef(null);

  console.log(commentsHeight, "comments height check");

  const Thead = [
    "S.No",
    "Mark",
    "name",
    "Email",
    "phone",
    "Note",
    "Website",
    "Lead Source",
    "country",
    "created At",
    "Assigned On",
    "budget",
    "industry",
    "Unit",
    "Assigned By",
    "Lead Status",
    "Action",
  ];

  useEffect(() => {
    // currPage = currPage ?? 0
    const getData = async () => {
      const res = await fetch(
        `${getMyLeadsBySalesPersonRoute}?empId=${empId}&numPerPage=${numPerPage}&page=${currPage}&search=${searchVal}&from=${from}&to=${to}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res.status === 401) {
        navigate("/");
        toast.error(
          "Session has been Expired",
          toastOptions,
          (toastOptions["position"] = "top-right")
        );
        return;
      }
      const data = await res.json();
      const total = data.pagiantion.dataCount;
      setTotalData(total);
      setpageCount(Math.ceil(total / numPerPage));
      setItems(data.result);
    };
    getData();
  }, [currPage, numPerPage, searchVal, from, to]);

  useEffect(() => {
    setThlength(Thead.length);
  }, [thLength]);

  const fetchData = async (currPage) => {
    const res = await fetch(
      `${getMyLeadsBySalesPersonRoute}?empId=${empId}&numPerPage=${numPerPage}&page=${currPage}&search=${searchVal}&from=${from}&to=${to}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (res.status === 401) {
      navigate("/");
      toast.error(
        "Session has been Expired",
        toastOptions,
        (toastOptions["position"] = "top-right")
      );
      return;
    }
    const changedData = await res.json();
    const data = changedData.result;
    return data;
  };

  const handlePageClick = async (data) => {
    let currPage = data.selected;
    setCurrPage(currPage);
    const myData = await fetchData(currPage);
    setItems(myData);
  };

  const handleChange = (e) => {
    setNumPerPage(e.target.value);
  };

  // Debounced search function
  const debouncedSearch = useCallback(
    debounce((value) => {
      setSearchVal(value);
    }, 500),
    []
  );

  // search data in api using search box
  const hangleSearchVal = (e) => {
    debouncedSearch(e.target.value);
  };

  const [editModalShow, setEditModalShow] = useState(false);
  const [conversionModal, setConversionModal] = useState(false);
  // Get Units By Employee Id .............
  const [unitList, setUnitList] = useState([]);
  const [selectedUnitId, setSelectedUnitId] = useState("");
  const [openUnitListModal, setOpenUnitListModal] = useState(false);

  const getUnitsById = async () => {
    try {
      const resp = await fetch(`${getMyUnitsByEmpIdRoute}?empId=${empId}`);
      const resData = await resp.json();
      if (resData.success === true) {
        setUnitList(resData.data);
      }
    } catch (error) {
      console.log("error ==>", error);
    }
  };

  useEffect(() => {
    getUnitsById();
  }, []);

  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  // ---- States of inputs in the form of key value pair name:value ------ //
  let hasError = false;
  // ---- Validate Form ------ //
  const validateForm = () => {
    const newErrors = {};
    if (name === "") {
      hasError = true;
      setIsLoading(false);
      newErrors.name = "Name is required !";
    }
    setErrors(newErrors);
  };
  // ---- Form Submittion Function ------ //
  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    validateForm();
    if (hasError === false) {
      const { data } = await axios.put(`${updateLeadRoute}?leadId=${leadId}`, {
        name,
        email,
        organization,
        phone,
        country,
        currencyCode,
        budget,
        industry,
        source,
        note,
        message,
        city,
        state,
        leadSource,
      });
      if (data.success === true) {
        setIsLoading(false);
        setEditModalShow(false);
        toast.success(
          data.msg,
          toastOptions,
          (toastOptions["position"] = "top-right")
        );
        const myData = await fetchData(currPage);
        setItems(myData);
      } else {
        toast.error(
          data.msg,
          toastOptions,
          (toastOptions["position"] = "top-left")
        );
        setIsLoading(false);
      }
    } else {
      toast.error(
        "Kindly Fill the require fields !",
        toastOptions,
        (toastOptions["position"] = "top-left")
      );
    }
  };
  const openEditLeadModal = async (_id) => {
    setEditModalShow(true);
    setEditableState(false);
    setLeadId(_id);
    fetch(`${getLeadbyIdRoute}?leadId=${_id}`)
      .then((response) => response.json())
      .then(({ data: leadData }) => {
        setName(leadData.name);
        setEmail(leadData.email);
        setOrganization(leadData.organization);
        setPhone(leadData.phone);
        setCountry(leadData.country);
        setCurrencyCode(leadData.currencyCode);
        setBudget(leadData.budget);
        setIndustry(leadData.industry);
        setSource(leadData.source);
        setNote(leadData.note);
        setMessage(leadData.message);
        setLeadSource(leadData.leadSource);
      });
  };
  const openLeadModal = (_id) => {
    setRerendertableData(!rerendertable);
    setEditModalShow(true);
    setEditableState(true);
    setLeadId(_id);
    fetch(`${getLeadbyIdRoute}?leadId=${_id}`)
      .then((response) => response.json())
      .then(({ data: leadData }) => {
        setName(leadData.name);
        setEmail(leadData.email);
        setOrganization(leadData.organization);
        setPhone(leadData.phone);
        setCountry(leadData.country);
        setCurrencyCode(leadData.currencyCode);
        setBudget(leadData.budget);
        setIndustry(leadData.industry);
        setSource(leadData.source);
        setNote(leadData.note);
        setMessage(leadData.message);
        setLeadSource(leadData.leadSource);
      });
  };

  // Function to fetch comments from API
  const fetchComments = async () => {
    // setCommentsLoader(true);
    try {
      const response = await axios.get(
        `${getCommentUnitLeadsRoute}?leadId=${leadId}`
      );
      if (response?.data?.success === true) {
        // setCommentsLoader(false);
        setComments(response?.data?.lead_comments);
      }
    } catch (error) {
      // setCommentsLoader(false);
      console.error("Error fetching comments:", error);
      if (error?.response?.data?.status === 401) {
        navigate("/");
      } else {
        toast.error(
          error?.response?.data?.msg,
          toastOptions,
          (toastOptions["position"] = "top-right")
        );
      }
    }
  };

  useEffect(() => {
    if (commentModalShow && leadId) {
      fetchComments();
    }
  }, [commentModalShow, leadId]);

  console.log(commentsEndRef.current, "comments end check");
  console.log(commentHeightRef, "comments height check");

  useEffect(() => {
    if (commentModalShow) {
      // Use setTimeout to ensure DOM updates before scrolling
      setTimeout(() => {
        if (commentHeightRef.current) {
          setCommentsHeight(commentHeightRef.current.clientHeight);
          if (commentsEndRef.current) {
            commentsEndRef.current.scrollIntoView({ behavior: "smooth" });
          }
        }
      }, 100); // Adjust timeout if necessary
    }
  }, [comments, commentModalShow]);

  const openCommentModal = (_id) => {
    setLeadId(_id);
    setCommentModalShow(true);
  };

  useEffect(() => {
    let intervalId;

    if (commentModalShow) {
      // Call fetchComments immediately when modal opens
      fetchComments();

      // Set up interval to fetch comments every 5 seconds
      intervalId = setInterval(() => {
        fetchComments();
      }, 10000); // 5000ms = 5 seconds
    }

    // Clear the interval when the modal is closed or component is unmounted
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [commentModalShow, leadId]); // run when the modal is shown or leadId changes

  // Function to handle comment submission
  const handleCommentSubmit = async () => {
    if (newComment.trim()) {
      try {
        const payload = {
          userId: userId,
          userName: userName,
          new_comment: newComment,
        };
        const response = await axios.post(
          `${addCommentUnitLeadsRoute}?leadId=${leadId}`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.data.success) {
          // Refresh comments
          fetchComments();
          setNewComment("");
          toast.success(response?.data?.msg);
        }
      } catch (error) {
        console.error("Error submitting comment:", error);
        if (error?.response?.data?.status === 401) {
          navigate("/");
        } else {
          toast.error(
            error?.response?.data?.msg,
            toastOptions,
            (toastOptions["position"] = "top-right")
          );
        }
      }
    }
  };

  const convertToDealModal = () => {
    setConversionModal(true);
    setEditModalShow(false);
    setRerendertableData(!rerendertable);
  };

  const handleConversionData = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    if (title === "" || title === null || title === undefined) {
      toast.error(
        "Title is Required !",
        toastOptions,
        (toastOptions["position"] = "top-left")
      );
      setIsLoading(false);
    } else {
      const { data } = await axios.post(
        `${createDealByLeadRoute}?leadId=${leadId}`,
        {
          title,
        }
      );
      if (data.success === true) {
        setIsLoading(false);
        setConversionModal(false);
        setTitle("");
        toast.success(
          data.msg,
          toastOptions,
          (toastOptions["position"] = "top-right")
        );
        navigate("/deals-pipeline");
      } else {
        toast.error(
          data.msg,
          toastOptions,
          (toastOptions["position"] = "top-left")
        );
        setIsLoading(false);
        setConversionModal(false);
      }
    }
  };

  // ======= creating automatically client using lead details ============== //
  const createLeadInvoice = async () => {
    try {
      const response = await axios.post(
        `${createInvoiceByLeadToClientRoute}?leadId=${leadId}`
      );
      if (response.data.success === true) {
        navigate("/create-invoice");
        toast.success(
          response.data.msg,
          toastOptions,
          (toastOptions["position"] = "top-right")
        );
      } else {
        toast.error(
          response.data.msg,
          toastOptions,
          (toastOptions["position"] = "top-right")
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [followUpModal, setFollowUpModal] = useState(false);
  const [followDate, setFollowDate] = useState("");

  const handleFollowUpDate = (e) => {
    e.preventDefault();
    if (followDate) {
      setIsLoading(true);
      var date = new Date(followDate);
      var isoDateString = date.toISOString();
      setFollowDate(isoDateString);
      setCurrPage((prevPage) => prevPage);
      changeLeadStatus("followup", followDate, currPage);
    } else {
      alert("Please Select Follow Up Date !");
    }
  };

  const handleLeadStatus = (e) => {
    const leadstatus = e.target.value;
    if (leadstatus) {
      if (leadstatus === "followup") {
        setFollowUpModal(true);
        setChangeStatusModalShow(false);
      } else {
        alert(`Lead status change to ${leadstatus}`);
        setCurrPage((prevPage) => prevPage);
        changeLeadStatus(leadstatus, "", currPage);
      }
    }
  };

  //  Api Code for Lead Status Changes
  const changeLeadStatus = async (leadstatus, followDate, currPage) => {
    const { data } = await axios.put(`${updateManyLeadsStatusByLeadsRoute}`, {
      leads: selectedIds,
      leadStatus: leadstatus,
      followupDate: followDate,
    });
    if (data.success === true) {
      toast.success(
        data.msg,
        toastOptions,
        (toastOptions["position"] = "top-right")
      );
      const myData = await fetchData(currPage);
      setItems(myData);
      setFollowUpModal(false);
      setFollowDate("");
      setIsLoading(false);
      setChangeStatusModalShow(false);
      setSelectedIds([]);
    } else {
      toast.error(
        data.msg,
        toastOptions,
        (toastOptions["position"] = "top-right")
      );
      setIsLoading(false);
      setChangeStatusModalShow(false);
    }
  };

  //  Api Code for Multiple Lead Assigning
  const [changeStatusModalShow, setChangeStatusModalShow] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const handleLeadChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setSelectedIds((prevSelectedIds) => [...prevSelectedIds, value]);
    } else {
      setSelectedIds((prevSelectedIds) =>
        prevSelectedIds.filter((id) => id !== value)
      );
    }
  };

  return (
    <>
      <h4 className="primHeading my-2 mb-4">My Leads</h4>

      <div className="box my-3">
        <div className="d-flex align-items-center justify-content-between gap-2 flex-wrap">
          <h4 className="sectionHeading my-2 flex-shrink-0">
            My Leads Summary
          </h4>
          <button
            className="main-btn my-2"
            onClick={() => setOpenUnitListModal(true)}
          >
            Add Lead
          </button>
        </div>
        {/*==========================|||****** Filters Start Here ********|||==========================*/}
        <Row className="customFilters">
          <Col xl={2} lg={4} md={6} sm={5} className="my-2">
            <label className="text-bold" htmlFor="From">
              From:
            </label>
            <input
              value={from}
              onChange={handleDateChange}
              className="prim-date"
              type="date"
              name="from"
              id="From"
            />
          </Col>
          <Col xl={2} lg={4} md={6} sm={5} className="my-2">
            <label className="text-bold" htmlFor="To">
              To:
            </label>
            <input
              value={to}
              onChange={handleDateChange}
              className="prim-date"
              type="date"
              name="to"
              id="To"
            />
          </Col>
          <Col xl={2} lg={4} md={6} sm={2} className="my-2">
            <button
              onClick={clearFilters}
              title="Clear Date Filters"
              className="main-btn clearFilter w-100 mt-4"
            >
              Clear
            </button>
          </Col>
        </Row>
        <hr />
        <div className="text-end">
          <div className="d-flex align-items-center justify-content-end gap-3 my-3">
            {selectedIds.length > 0 ? (
              <>
                <button
                  className="main-btn my-2"
                  onClick={() => setChangeStatusModalShow(true)}
                >
                  Change Lead Status
                </button>
                <button
                  className="main-btn clearFilter my-2"
                  onClick={() => setSelectedIds([])}
                >
                  Remove All Select
                </button>
              </>
            ) : (
              ""
            )}
          </div>
        </div>
        <TableCom
          numPerPage={numPerPage}
          totalData={totalData}
          handleChange={handleChange}
          hangleSearchVal={hangleSearchVal}
          pageCount={pageCount}
          handlePageClick={handlePageClick}
          searcPlaceHolder="Search..."
        >
          <div className="customTable2 w-100 table-responsive">
            <table className="w-100">
              <thead>
                <tr>
                  {Thead?.map((th, index) => {
                    return <th key={index}>{th}</th>;
                  })}
                </tr>
              </thead>
              <tbody>
                {items == "" || items == undefined || items == null ? (
                  <tr>
                    <td colSpan={thLength}>
                      <p className="my-2 smText text-decoration-underline text-danger">
                        No Record Found
                      </p>
                    </td>
                  </tr>
                ) : (
                  items.map((item, index) => {
                    const {
                      budget,
                      phone,
                      country,
                      email,
                      industry,
                      name,
                      read,
                      _id,
                      createdAt,
                      source,
                      Unit,
                      leadSource,
                      leadStatus,
                      leadFollowUpDate,
                      assignedOn,
                      assignedBy,
                      note,
                    } = item;
                    return (
                      <tr
                        key={_id}
                        className={read === true ? "unreadtr" : ""}
                        onDoubleClick={() => {
                          openEditLeadModal(_id);
                        }}
                        title="Double Click To Edit Details"
                        style={{ cursor: "pointer" }}
                      >
                        <td>{++index}</td>
                        <td>
                          <label className="checkBoxDiv">
                            <input
                              checked={selectedIds.includes(_id)}
                              type="checkbox"
                              value={_id}
                              onChange={handleLeadChange}
                            />
                            <span className="primCheckmark"></span>
                          </label>
                        </td>
                        <td>
                          <span className="ellipsisContent">
                            {name || "--"}
                          </span>
                        </td>
                        <td>
                          <span className="ellipsisContent">
                            {email || "--"}
                          </span>
                        </td>
                        <td>
                          <a
                            className="sw-phone"
                            title="Call with Dialpad"
                            href={`dialpad://${phone}`}
                          >
                            {phone || "--"}
                          </a>
                        </td>
                        {/* <td onClick={() => handleCopyClipboard(phone)}><span className="cursor-pointer">{copiedText === phone ? 'Copied' : phone}</span></td> */}
                        <td>{note || "--"}</td>
                        <td>
                          {source === "" ? (
                            "--"
                          ) : (
                            <a href={source} target="_blank">
                              {source}
                            </a>
                          )}
                        </td>
                        <td>{leadSource || "--"}</td>
                        <td>{country || "--"}</td>
                        <td>{new Date(createdAt).toLocaleString()}</td>
                        <td>
                          {assignedOn
                            ? new Date(assignedOn).toLocaleString()
                            : "-"}
                        </td>
                        <td>{budget || "--"}</td>
                        <td>
                          <span className="ellipsisContent">
                            {industry || "--"}
                          </span>
                        </td>
                        <td>{Unit.length > 0 ? Unit[0].name : "--"}</td>
                        <td>
                          {assignedBy.length > 0 ? assignedBy[0].name : "--"}
                        </td>
                        <td className="text-capitalize">
                          {leadStatus === "followup" ? (
                            <span>
                              Follow Up <br />{" "}
                              {leadFollowUpDate
                                ?.slice(0, 10)
                                .split("-")
                                .reverse()
                                .join("-")}
                            </span>
                          ) : leadStatus === "" ? (
                            "--"
                          ) : (
                            leadStatus
                          )}
                        </td>
                        <td>
                          <Dropdown className="actionDropDown">
                            <Dropdown.Toggle className="customDropDown">
                              <FaEllipsisV className="colBlack" />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item
                                className="drop_item"
                                type="button"
                                onClick={() => {
                                  openLeadModal(_id);
                                }}
                              >
                                <FaRegEye className="me-1" />
                                <span>View</span>
                              </Dropdown.Item>
                              <Dropdown.Item
                                className="drop_item"
                                type="button"
                                onClick={() => {
                                  openEditLeadModal(_id);
                                }}
                              >
                                <FaEdit className="me-1" />
                                <span>Edit</span>
                              </Dropdown.Item>
                              <Dropdown.Item
                                className="drop_item"
                                type="button"
                                onClick={() => {
                                  openCommentModal(_id);
                                }}
                              >
                                <FaRegCommentDots className="me-1" />
                                <span>Comment</span>
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
        </TableCom>
      </div>

      {/*========================================== ! View --Or-- Edit Leads Modal code start here ! =======================================*/}
      <CustomModal
        show={editModalShow}
        onHide={() => setEditModalShow(false)}
        backdrop="static"
        keyboard="False"
        size="lg"
      >
        <button
          onClick={() => setEditModalShow(false)}
          className="modal-cancel"
        >
          <FaTimes />
        </button>
        <div className="modalContent">
          {/* modal text */}
          <form onSubmit={(event) => handleSubmit(event)}>
            <div className="text-start">
              <div className="d-flex align-items-center justify-content-between flex-wrap mt-4">
                <h3 className="fw-600 my-2">
                  {" "}
                  {editableState === false ? "Edit Lead" : `${name}`}{" "}
                </h3>
                <div>
                  {editableState === false ? (
                    ""
                  ) : (
                    <button
                      className="main-btn bgGreen mx-2"
                      type="button"
                      onClick={createLeadInvoice}
                    >
                      Create Invoice
                    </button>
                  )}
                  {/* {editableState === false ? "" : <button className="main-btn" onClick={convertToDealModal} type="button"><FaArrowRight className="pe-1" /> Move to deal </button>} */}
                </div>
              </div>
              <p>
                {editableState === false
                  ? "Edit Lead From here"
                  : `View ${name} Details here`}{" "}
              </p>
              <Row>
                <Col lg={6} className="my-2">
                  <label className="text-bold mb-1" htmlFor="name">
                    Name:<span className="text-danger">*</span>
                  </label>
                  <input
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    name="name"
                    id="name"
                    type="text"
                    className="filterSelect ps-3"
                    placeholder="Enter Name"
                  />
                  {errors.name && (
                    <p className="text-danger fw-500 fs-13 my-2 text-capitalize">
                      {errors.name}
                    </p>
                  )}
                </Col>
                <Col lg={6} className="my-2">
                  <label className="text-bold mb-1" htmlFor="email">
                    Email:<span className="text-danger">*</span>
                  </label>
                  <input
                    disabled={editableState}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    name="email"
                    id="email"
                    type="text"
                    className="filterSelect ps-3"
                    placeholder="Enter Email"
                  />
                  {errors.email && (
                    <p className="text-danger fw-500 fs-13 my-2 text-capitalize">
                      {errors.email}
                    </p>
                  )}
                </Col>
                <Col lg={6} className="my-2">
                  <label className="text-bold mb-1" htmlFor="phone">
                    Phone:<span className="text-danger">*</span>
                  </label>
                  <input
                    disabled={editableState}
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    name="phone"
                    id="phone"
                    type="number"
                    className="filterSelect ps-3"
                    placeholder="Enter Phone Number"
                  />
                  {errors.phone && (
                    <p className="text-danger fw-500 fs-13 my-2 text-capitalize">
                      {errors.phone}
                    </p>
                  )}
                </Col>
                <Col lg={6} className="my-2">
                  <label className="text-bold mb-1" htmlFor="leadSource">
                    Lead Source:
                  </label>
                  <select
                    className="filterSelect ps-3"
                    id="leadSource"
                    name="leadSource"
                    disabled={editableState}
                    value={leadSource}
                    onChange={(e) => setLeadSource(e.target.value)}
                  >
                    <option value="">Select Option</option>
                    <option value="marketting">Marketing</option>
                    <option value="ppc">PPC</option>
                    <option value="website">Website</option>
                    <option value="smm">SMM</option>
                    <option value="bark">BARK</option>
                    <option value="custom">Custom</option>
                  </select>
                </Col>
                <Col lg={6} className="my-2">
                  <label className="text-bold mb-1" htmlFor="organization">
                    organization:
                  </label>
                  <input
                    disabled={editableState}
                    value={organization}
                    onChange={(e) => setOrganization(e.target.value)}
                    name="organization"
                    id="organization"
                    type="text"
                    className="filterSelect ps-3"
                    placeholder="Enter Organization Name"
                  />
                </Col>
                <Col lg={6} className="my-2">
                  <label className="text-bold mb-1" htmlFor="currencyCode">
                    Currency:
                  </label>
                  <select
                    className="filterSelect ps-3"
                    id="currencyCode"
                    name="currencyCode"
                    disabled={editableState}
                    value={currencyCode}
                    onChange={(e) => setCurrencyCode(e.target.value)}
                  >
                    <option value="">Select Option</option>
                    {Object.keys(allcurrencyCode).map((objkey, index) => {
                      return (
                        <option key={index} value={objkey}>
                          {allcurrencyCode[objkey]}
                        </option>
                      );
                    })}
                  </select>
                </Col>
                <Col lg={6} className="my-2">
                  <label className="text-bold mb-1" htmlFor="country">
                    Country:
                  </label>
                  <select
                    className="filterSelect ps-3"
                    id="country"
                    name="country"
                    value={country}
                    disabled={editableState}
                    onChange={(e) => setCountry(e.target.value)}
                  >
                    <option value="">Select Option</option>
                    {Object.keys(allCountryCodes).map((objkey, index) => {
                      return (
                        <option key={index} value={allCountryCodes[objkey]}>
                          {allCountryCodes[objkey]}
                        </option>
                      );
                    })}
                  </select>
                </Col>
                <Col lg={6} className="my-2">
                  <label className="text-bold mb-1" htmlFor="city">
                    City:
                  </label>
                  <input
                    disabled={editableState}
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                    name="city"
                    id="city"
                    type="text"
                    className="filterSelect ps-3"
                    placeholder="Enter City"
                  />
                </Col>
                <Col lg={6} className="my-2">
                  <label className="text-bold mb-1" htmlFor="state">
                    State:
                  </label>
                  <input
                    disabled={editableState}
                    value={state}
                    onChange={(e) => setState(e.target.value)}
                    name="state"
                    id="state"
                    type="text"
                    className="filterSelect ps-3"
                    placeholder="Enter state"
                  />
                </Col>
                <Col lg={6} className="my-2">
                  <label className="text-bold mb-1" htmlFor="budget">
                    Budget:
                  </label>
                  <input
                    disabled={editableState}
                    value={budget}
                    onChange={(e) => setBudget(e.target.value)}
                    name="budget"
                    id="budget"
                    type="number"
                    className="filterSelect ps-3"
                    placeholder="Enter Budget"
                  />
                </Col>
                <Col lg={6} className="my-2">
                  <label className="text-bold mb-1" htmlFor="industry">
                    industry:
                  </label>
                  <input
                    disabled={editableState}
                    value={industry}
                    onChange={(e) => setIndustry(e.target.value)}
                    name="industry"
                    id="industry"
                    type="text"
                    className="filterSelect ps-3"
                    placeholder="Enter Industry Name"
                  />
                </Col>
                <Col lg={6} className="my-2">
                  <label className="text-bold mb-1" htmlFor="source">
                    source:
                  </label>
                  <input
                    disabled={editableState}
                    value={source}
                    onChange={(e) => setSource(e.target.value)}
                    name="source"
                    id="source"
                    type="text"
                    className="filterSelect ps-3"
                    placeholder="Enter Source"
                  />
                </Col>
                <Col lg={6} className="my-2">
                  <label className="text-bold mb-1" htmlFor="note">
                    note:
                  </label>
                  <input
                    disabled={editableState}
                    value={note}
                    onChange={(e) => setNote(e.target.value)}
                    name="note"
                    id="note"
                    type="text"
                    className="filterSelect ps-3"
                    placeholder="Enter Note"
                  />
                </Col>
                <Col lg={12} className="my-2">
                  <label className="text-bold mb-1" htmlFor="message">
                    message:
                  </label>
                  <textarea
                    rows="5"
                    disabled={editableState}
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    name="message"
                    id="message"
                    className="filterSelect ps-3"
                    placeholder="Enter Message..."
                  ></textarea>
                </Col>
              </Row>
            </div>
            <div className="mt-3">
              {/* modal buttons */}
              {editableState === false ? (
                <>
                  <button
                    className="main-btn m-2"
                    disabled={isLoading}
                    type="submit"
                  >
                    {" "}
                    {isLoading ? "saving..." : "save"}{" "}
                  </button>
                  <button
                    className="sec-btn m-2"
                    type="button"
                    onClick={() => setEditModalShow(false)}
                  >
                    Cancel
                  </button>
                </>
              ) : (
                ""
              )}
            </div>
          </form>
        </div>
      </CustomModal>
      {/*========================================== ! Move Leads to Deal Modal code start here ! =======================================*/}
      <CustomModal
        show={conversionModal}
        onHide={() => setConversionModal(false)}
        backdrop="static"
        keyboard="False"
        size="sm"
      >
        <button
          onClick={() => setConversionModal(false)}
          className="modal-cancel"
        >
          <FaTimes />
        </button>
        <div className="modalContent">
          {/* modal text */}
          <form onSubmit={handleConversionData}>
            <div className="text-start">
              <h5 className="fw-600 my-2 secHeading">Move to Deal !</h5>
              <Row>
                <Col lg={12} className="my-2">
                  <label className="text-bold mb-1" htmlFor="title">
                    Title:<span className="text-danger">*</span>
                  </label>
                  <input
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    name="title"
                    id="title"
                    type="text"
                    className="filterSelect ps-3"
                    placeholder="Enter Title"
                  />
                </Col>
              </Row>
            </div>
            <div className="mt-3">
              {/* modal buttons */}
              <button
                className="main-btn m-2"
                disabled={isLoading}
                type="submit"
              >
                {" "}
                {isLoading ? "Moving..." : "Move"}{" "}
              </button>
            </div>
          </form>
        </div>
      </CustomModal>
      {/*========================================== follow Up Modal =======================================*/}
      <CustomModal
        show={followUpModal}
        onHide={() => {
          setFollowUpModal(false);
          setFollowDate("");
        }}
        backdrop="static"
        keyboard="False"
        size="sm"
      >
        <button
          onClick={() => {
            setFollowUpModal(false);
            setFollowDate("");
          }}
          className="modal-cancel"
        >
          <FaTimes />
        </button>
        <div className="modalContent">
          {/* modal text */}
          <form onSubmit={handleFollowUpDate}>
            <div className="text-start">
              <h5 className="fw-600 my-2 secHeading">Select Date</h5>
              <input
                onChange={(e) => setFollowDate(e.target.value)}
                name="date"
                id="date"
                type="date"
                className="filterSelect ps-3"
              />
              <div className="text-center">
                <button
                  className="main-btn mt-3"
                  disabled={isLoading}
                  type="submit"
                >
                  {isLoading ? "Setting..." : "Set"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </CustomModal>
      {/*========================================== follow Up Modal =======================================*/}
      <CustomModal
        show={openUnitListModal}
        onHide={() => {
          setOpenUnitListModal(false);
          setSelectedUnitId("");
        }}
        backdrop="static"
        keyboard="False"
        size="sm"
      >
        <button
          onClick={() => {
            setOpenUnitListModal(false);
            setSelectedUnitId("");
          }}
          className="modal-cancel"
        >
          <FaTimes />
        </button>
        <div className="modalContent">
          <div className="text-start my-4">
            <label htmlFor="selectUnit">Select Unit</label>
            {unitList.length > 0 ? (
              <select
                onChange={(e) => setSelectedUnitId(e.target.value)}
                name="selectUnit"
                id="selectUnit"
                className="dashboard-inputs w-100"
              >
                <option value="">Select Unit</option>
                {unitList?.map((unitList) => {
                  const { id, name } = unitList;
                  return (
                    <option key={id} value={id}>
                      {name}
                    </option>
                  );
                })}
              </select>
            ) : (
              <p className="text-center text-danger">No Unit Found</p>
            )}
          </div>
          {selectedUnitId && (
            <Link
              className="main-btn"
              to={`/create-lead/${selectedUnitId}`}
              state={empId}
            >
              Create Lead
            </Link>
          )}
        </div>
      </CustomModal>
      {/*========================================== ! Change Leads Status start here ! =======================================*/}
      <CustomModal
        show={changeStatusModalShow}
        onHide={() => setChangeStatusModalShow(false)}
        backdrop="static"
        keyboard="False"
        size="md"
      >
        <button
          onClick={() => setChangeStatusModalShow(false)}
          className="modal-cancel"
        >
          <FaTimes />
        </button>
        <div className="modalContent">
          <div className="my-4 text-start">
            <label htmlFor="leadStatusChange">Change Leads Status</label>
            <select
              id="leadStatusChange"
              onChange={handleLeadStatus}
              className="dashboard-inputs w-100"
            >
              <option value="">Change Status</option>
              <option value="dnc">Do Not Contact</option>
              <option value="not-interested">Not Interested</option>
              <option value="voicemail/unanswered">
                Voicemail / Unanswered
              </option>
              <option value="duplicate">Duplicate</option>
              <option value="archived">Archived</option>
              <option value="language-barrier">Language Barrier</option>
              <option value="followup">Follow Up</option>
            </select>
          </div>
        </div>
      </CustomModal>

      <CustomModal
        show={commentModalShow}
        onHide={() => {
          setCommentModalShow(false);
          setComments([]);
          setNewComment("");
        }}
        backdrop="static"
        keyboard="False"
        size="md"
      >
        <button
          onClick={() => {
            setCommentModalShow(false);
            setComments([]);
            setNewComment("");
            setCommentsHeight(0);
          }}
          className="modal-cancel"
        >
          <FaTimes />
        </button>
        <div className="modalContent">
          <div className="my-4 text-start">
            <h4 className="fw-600 my-2">Comments</h4>
            {commentsLoader === true ? (
              <div className="d-flex justify-content-center">
                <div className="loader">
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              </div>
            ) : (
              <div
                ref={commentHeightRef}
                className="comments-list"
                style={{
                  maxHeight: "20em",
                  // overflowY: commentsHeight > 300 ? "scroll" : "",
                  overflow: "scroll",
                  paddingRight: "3px",
                }}
              >
                {comments?.length > 0 ? (
                  comments?.map((comment, index) => (
                    <div
                      key={comment.id}
                      className="comment-item my-2 px-2 py-1 rounded-3"
                      style={{ background: "#C6D4FF" }}
                    >
                      <strong style={{ fontSize: "14px" }}>
                        {comment.userId === userId ? "You" : comment.userName}
                      </strong>
                      <p className="mb-0 " style={{ fontSize: "12px" }}>
                        {comment.comment}
                      </p>
                      <div className="d-flex justify-content-end">
                        <small
                          className="text-align-right"
                          style={{ fontSize: "11px" }}
                        >
                          {new Date(comment.created_at).toLocaleString()}
                        </small>
                      </div>
                    </div>
                  ))
                ) : (
                  <p style={{ fontSize: "14px" }}>No comments yet...</p>
                )}
                <div ref={commentsEndRef} />
              </div>
            )}
          </div>
          <div className="mt-4 text-align-left">
            <textarea
              rows="3"
              className="form-control filterSelect ps-3"
              placeholder="Add a comment..."
              value={newComment}
              onChange={(e) => setNewComment(e.target.value)}
              // onKeyDown={handleKeyPress}
              onKeyDown={(e) => e.key === "Enter" && handleCommentSubmit()}
            />
            <div className="d-flex justify-content-center">
              <button
                disabled={!newComment}
                className="main-btn mt-2 rounded-0 fs-6"
                onClick={handleCommentSubmit}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </CustomModal>
    </>
  );
};

export default MyLeads;
