import React from "react";
import { Col, Row } from "react-bootstrap";

const Footer = () => {
  const date = new Date();
  const currYear = date.getFullYear();
  return (
    <footer className="footer">
      <Row className="align-items-center">
        <Col className="text-center">
          <p className="my-3"> Copyright © {currYear || "2023"} <a className="" target="_blank" href="https://zibbletech.com/"> Zibbletect Pvt Ltd. </a> All Rights Reserved.</p>
        </Col>
      </Row>
    </footer>
  );
};

export default Footer;
