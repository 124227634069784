export const changeBrandPage = (pageLayout) => {
     return {
          type: "removeForm",
          payload: pageLayout
     }
}
export const removeAllBrands = (pageLayout) => {
     return {
          type: "removeBrands",
          payload: pageLayout
     }
}
