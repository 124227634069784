import React from "react";
import GoBack from "../../../Components/GoBack";
import { Link, useLocation } from "react-router-dom";
import { Col, Form, Row, Button } from "react-bootstrap";
import UnitClients from "./UnitClients";
import ClientSummaryTable from "../../../Components/ScreenComponents/ClientSummaryTable";
// import { Button } from "bootstrap";

const ClientInfo = () => {
  const location = useLocation();
  const unitname = location.state;

  console.log(unitname, "unit name check");

  return (
    <>
      <div className="d-flex align-items-center justify-content-between mb-4 flex-wrap">
        <div className="d-flex align-items-center my-2 mb-2">
          <GoBack />
          <h4 className="primHeading my-2">
            {unitname === ("" || undefined || null) ? "Client" : unitname}{" "}
            <span>Info</span>
          </h4>
        </div>
      </div>
      <div className="box my-3">
        <div className="d-flex align-items-center justify-content-between mb-4 flex-wrap">
          <h4 className="sectionHeading my-2">
            {unitname === ("" || undefined || null) ? "Client" : unitname}{" "}
            Details
          </h4>
        </div>
        <Row>
          <Col xl={12} lg={10}>
            <Row>
              <Col lg={4}>
                <label htmlFor="">Client Name</label>
                <p>John Doe</p>
              </Col>
              <Col lg={4}>
                <label htmlFor="">Email</label>
                <p>johndoe@example.com</p>
              </Col>
              <Col lg={4}>
                <label htmlFor="">Phone</label>
                <p>+1 234 567 8901</p>
              </Col>
              <Col lg={4}>
                <label htmlFor="">Country</label>
                <p>USA</p>
              </Col>
              <Col lg={4}>
                <label htmlFor="">Address</label>
                <p>1234 Elm Street, Springfield, IL</p>
              </Col>
              <Col lg={4}>
                <label htmlFor="">Industry</label>
                <p>Technology</p>
              </Col>
              <Col lg={4}>
                <label htmlFor="">Company Size</label>
                <p>50-200 employees</p>
              </Col>
              <Col lg={4}>
                <label htmlFor="">Status</label>
                <p className="colGreen">Active</p>
              </Col>
              <Col lg={4}>
                <label htmlFor="">Created At</label>
                <p>12/08/2024</p>
              </Col>
              <Col lg={4}>
                <label htmlFor="">Notes</label>
                <p>Important client, handles large projects.</p>
              </Col>
            </Row>
          </Col>
          {/* <Col xl={12} lg={10}> */}
          {/* <ClientSummaryTable /> */}
          {/* </Col> */}
        </Row>
      </div>
      {/* client summary table */}
      <div className="box my-3">
        <ClientSummaryTable />
      </div>
      {/* Contact Phone Log Form */}
      <div className="box my-3">
        <h4 className="sectionHeading my-2 mb-4">Log a Phone Call</h4>
        <Form>
          <Row>
            <Col lg={4}>
              <Form.Group className="mb-2" controlId="formCallDate">
                <Form.Label>Date</Form.Label>
                <Form.Control type="date" placeholder="Enter Date" />
              </Form.Group>
            </Col>
            <Col lg={4}>
              <Form.Group className="mb-2" controlId="formCallTime">
                <Form.Label>Time</Form.Label>
                <Form.Control type="time" placeholder="Enter Time" />
              </Form.Group>
            </Col>
            <Col lg={4}>
              <Form.Group className="mb-2" controlId="formCallDuration">
                <Form.Label>Duration</Form.Label>
                <Form.Control type="text" placeholder="Enter Duration" />
              </Form.Group>
            </Col>
            <Col lg={4}>
              <Form.Group className="mb-2" controlId="formSalesperson">
                <Form.Label>Salesperson</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Salesperson Name"
                />
              </Form.Group>
            </Col>
            <Col lg={4}>
              <Form.Group className="mb-2" controlId="formPurpose">
                <Form.Label>Purpose</Form.Label>
                <Form.Control type="text" placeholder="Enter Purpose of Call" />
              </Form.Group>
            </Col>
            <Col lg={4}>
              <Form.Group className="mb-2" controlId="formOutcome">
                <Form.Label>Outcome</Form.Label>
                <Form.Control type="text" placeholder="Enter Outcome" />
              </Form.Group>
            </Col>
            <Col lg={4}>
              <Form.Group className="mb-2" controlId="formNextSteps">
                <Form.Label>Next Steps</Form.Label>
                <Form.Control type="text" placeholder="Enter Next Steps" />
              </Form.Group>
            </Col>
          </Row>
          <Button variant="primary" type="submit" className="mt-3">
            Save Call Log
          </Button>
        </Form>
      </div>

      {/* Email Log Form */}
      <div className="box my-3">
        <h4 className="sectionHeading my-2 mb-4">Log an Email</h4>
        <Form>
          <Row>
            <Col lg={4}>
              <Form.Group className="mb-2" controlId="formEmailDate">
                <Form.Label>Date</Form.Label>
                <Form.Control type="date" placeholder="Enter Date" />
              </Form.Group>
            </Col>
            <Col lg={4}>
              <Form.Group className="mb-2" controlId="formEmailTime">
                <Form.Label>Time</Form.Label>
                <Form.Control type="time" placeholder="Enter Time" />
              </Form.Group>
            </Col>
            <Col lg={4}>
              <Form.Group className="mb-2" controlId="formEmailSalesperson">
                <Form.Label>Salesperson</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Salesperson Name"
                />
              </Form.Group>
            </Col>
            <Col lg={4}>
              <Form.Group className="mb-2" controlId="formEmailSubject">
                <Form.Label>Subject</Form.Label>
                <Form.Control type="text" placeholder="Enter Email Subject" />
              </Form.Group>
            </Col>
            <Col lg={4}>
              <Form.Group className="mb-2" controlId="formEmailContent">
                <Form.Label>Content</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Enter Email Content"
                />
              </Form.Group>
            </Col>
            <Col lg={4}>
              <Form.Group className="mb-2" controlId="formEmailAttachments">
                <Form.Label>Attachments</Form.Label>
                <Form.Control type="text" placeholder="Enter Attachments" />
              </Form.Group>
            </Col>
            <Col lg={4}>
              <Form.Group className="mb-2" controlId="formEmailOutcome">
                <Form.Label>Outcome</Form.Label>
                <Form.Control type="text" placeholder="Enter Outcome" />
              </Form.Group>
            </Col>
            <Col lg={4}>
              <Form.Group className="mb-2" controlId="formEmailNextSteps">
                <Form.Label>Next Steps</Form.Label>
                <Form.Control type="text" placeholder="Enter Next Steps" />
              </Form.Group>
            </Col>
          </Row>
          <Button variant="primary" type="submit" className="mt-3">
            Save Email Log
          </Button>
        </Form>
      </div>
    </>
  );
};

export default ClientInfo;
