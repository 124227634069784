import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { Link } from 'react-router-dom';
import Title from "../../hook/Title";
import CommissionPayablePerSaleperson from '../../Components/Charts/CommissionPayablePerSaleperson';
import CommissionPayable from '../../Components/Charts/CommissionPayable';

import Loader from '../../utils/Loader';
import { BsCashCoin, BsDatabaseDown, BsDashLg } from "react-icons/bs";
import { MdAutoGraph, MdOutlineCurrencyExchange, MdOutlinePayments } from "react-icons/md";



import { useSelector } from "react-redux";

const CommissionDashboard = () => {
    Title("Commision Dashboard");

    const [filterLoader, setFilterLoader] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    // getting value of authenticated user using redux persistor
    //   const AuthReducer = useSelector((state) => state.AuthReducer);
    //   const { empId, designation } = AuthReducer.data;

    const clearFilters = () => {

    }

    const handleMonthChange = (e) => {

    };

    const previousYears = [];

    const handleYearChange = (e) => {

    }

    useEffect(() => {

        setIsLoading(false)
    }, []);



    return (
        <>
            <h4 className="primHeading my-3">
                Commissions Dashboard
            </h4>
            {
                isLoading === true ? (
                    <Loader />
                ) : (
                    <>
                        <Row className="customFilters mb-3">
                            <Col xxl={4} xl={4} lg={3} md={4} sm={4} className="my-2">
                                <label className="text-bold" htmlFor="SalePersonSelect">Sales Person:</label>
                                <select className='primSelect prim-date white' id='YearSelect' onChange={handleYearChange}>
                                    <option value=" ">Select SalesPerson</option>
                                    {previousYears.map((year, index) => {
                                        return (<option key={index} value={year}>{year}</option>)
                                    })}
                                </select>
                            </Col>
                            <Col xxl={2} xl={2} lg={3} md={3} sm={3} className="my-2">
                                <label className="text-bold" htmlFor="MonthSelect">Month:</label>
                                <select className='primSelect prim-date white' id='MonthSelect' onChange={handleMonthChange}>
                                    <option value=" ">Select Month</option>
                                    <option value="0">January</option>
                                    <option value="1">February</option>
                                    <option value="2">March</option>
                                    <option value="3">April</option>
                                    <option value="4">May</option>
                                    <option value="5">June</option>
                                    <option value="6">July</option>
                                    <option value="7">August</option>
                                    <option value="8">September</option>
                                    <option value="9">October</option>
                                    <option value="10">November</option>
                                    <option value="11">December</option>
                                </select>
                            </Col>
                            <Col xxl={2} xl={2} lg={3} md={3} sm={3} className="my-2">
                                <label className="text-bold" htmlFor="YearSelect">By Year:</label>
                                <select className='primSelect prim-date white' id='YearSelect' onChange={handleYearChange}>
                                    <option value=" ">Select Year</option>
                                    {previousYears.map((year, index) => {
                                        return (<option key={index} value={year}>{year}</option>)
                                    })}
                                </select>
                            </Col>
                            {
                                filterLoader ? (<Col lg={2} md={6} sm={12} className="my-2 position-relative">
                                    <div className="preloaderWrapper">
                                        <div className="preloaderMainWrapper">
                                            <div className="smLoader">
                                                <div className="smdot"></div>
                                            </div>
                                            <div className="smLoader">
                                                <div className="smdot"></div>
                                            </div>
                                            <div className="loader">
                                                <div className="smdot"></div>
                                            </div>
                                            <div className="smLoader">
                                                <div className="smdot"></div>
                                            </div>
                                            <div className="smLoader">
                                                <div className="smdot"></div>
                                            </div>
                                            <div className="smLoader">
                                                <div className="smdot"></div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>) : (<Col xl={2} lg={3} md={2} sm={2} className="my-2">
                                    <button onClick={clearFilters} title="Clear Date Filters" className="main-btn clearFilter w-100 mt-4">Clear</button>
                                </Col>)
                            }
                        </Row>
                        <Row>
                            <Col xxl={2} xl={4} lg={4} sm={4} className="analyticsWrap1 mb-2 mx-auto">
                                <Link to="#">
                                    <div className="dashboard-anatlytics">
                                        <div className="d-flex gap-2">
                                            <span className='d-block mx-auto'><BsCashCoin className="kpiIcon text-warning" /></span>

                                        </div>
                                        <div className=''>
                                            <div className="text-center">
                                                <h4 className='fw-600 mb-0 font-xl'>2300</h4>
                                                <h3 className='fs-14 fw-400 colGray mb-0'>Sales Amount</h3>
                                                <hr className="sp-hr" />
                                                {/* {totalLeadPercent === null || totalLeadPercent === undefined ? <small>Not Computed</small> : <h4 className={`mb-0 ${totalLeadPercent < 0 ? "text-danger" : ""}`}> {totalLeadPercent <= 0 ? <BsArrowDownLeft /> : <BsArrowUpRight />} {totalLeadPercent} / <small className="fs-13 text-capitalize colGray">month</small> </h4>} */}
                                            </div>
                                        </div>

                                    </div>
                                </Link>
                            </Col>
                            <Col xxl={2} xl={4} lg={4} sm={4} className="analyticsWrap1 mb-2 mx-auto">
                                <Link to="#">
                                    <div className="dashboard-anatlytics">
                                        <div className="d-flex gap-2">
                                            <span className='d-block mx-auto'><MdAutoGraph className="kpiIcon text-secondary" /></span>

                                        </div>
                                        <div className=''>
                                            <div className="text-center">
                                                <h4 className='fw-600 mb-0 font-xl'>2.3%</h4>
                                                <h3 className='fs-14 fw-400 colGray mb-0'>Average Commision Rate</h3>
                                                <hr className="sp-hr" />
                                                {/* {totalLeadPercent === null || totalLeadPercent === undefined ? <small>Not Computed</small> : <h4 className={`mb-0 ${totalLeadPercent < 0 ? "text-danger" : ""}`}> {totalLeadPercent <= 0 ? <BsArrowDownLeft /> : <BsArrowUpRight />} {totalLeadPercent} / <small className="fs-13 text-capitalize colGray">month</small> </h4>} */}
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </Col>
                            <Col xxl={2} xl={4} lg={4} sm={4} className="analyticsWrap1 mb-2 mx-auto">
                                <Link to="#">
                                    <div className="dashboard-anatlytics">
                                        <div className="d-flex gap-2">
                                            <span className='d-block mx-auto'><MdOutlineCurrencyExchange className="kpiIcon text-secondary" /></span>

                                        </div>
                                        <div className=''>
                                            <div className="text-center">
                                                <h4 className='fw-600 mb-0 font-xl'>1000</h4>
                                                <h3 className='fs-14 fw-400 colGray mb-0'>Total Commission</h3>
                                                <hr className="sp-hr" />
                                                {/* {totalLeadPercent === null || totalLeadPercent === undefined ? <small>Not Computed</small> : <h4 className={`mb-0 ${totalLeadPercent < 0 ? "text-danger" : ""}`}> {totalLeadPercent <= 0 ? <BsArrowDownLeft /> : <BsArrowUpRight />} {totalLeadPercent} / <small className="fs-13 text-capitalize colGray">month</small> </h4>} */}
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </Col>
                            <Col xxl={2} xl={4} lg={4} sm={4} className="analyticsWrap1 mb-2 mx-auto">
                                <Link to="#">
                                    <div className="dashboard-anatlytics">
                                        <div className="d-flex gap-2">
                                            <span className='d-block mx-auto'><BsDatabaseDown className="kpiIcon text-danger" /></span>
                                        </div>
                                        <div className=''>
                                            <div className="text-center">
                                                <h4 className='fw-600 mb-0 font-xl'>2300</h4>
                                                <h3 className='fs-14 fw-400 colGray mb-0'>Total Deduction</h3>
                                                <hr className="sp-hr" />
                                                {/* {totalLeadPercent === null || totalLeadPercent === undefined ? <small>Not Computed</small> : <h4 className={`mb-0 ${totalLeadPercent < 0 ? "text-danger" : ""}`}> {totalLeadPercent <= 0 ? <BsArrowDownLeft /> : <BsArrowUpRight />} {totalLeadPercent} / <small className="fs-13 text-capitalize colGray">month</small> </h4>} */}
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </Col>
                            <Col xxl={2} xl={4} lg={4} sm={4} className="analyticsWrap1 mb-2 mx-auto">
                                <Link to="#">
                                    <div className="dashboard-anatlytics">
                                        <div className="d-flex gap-2">
                                            <span className='d-block mx-auto'><MdOutlinePayments className="kpiIcon text-secondary"/></span>
                                        </div>
                                        <div className=''>
                                            <div className="text-center">
                                                <h4 className='fw-600 mb-0 font-xl'>2300</h4>
                                                <h3 className='fs-14 fw-400 colGray mb-0'>Commission Payable</h3>
                                                <hr className="sp-hr" />
                                                {/* {totalLeadPercent === null || totalLeadPercent === undefined ? <small>Not Computed</small> : <h4 className={`mb-0 ${totalLeadPercent < 0 ? "text-danger" : ""}`}> {totalLeadPercent <= 0 ? <BsArrowDownLeft /> : <BsArrowUpRight />} {totalLeadPercent} / <small className="fs-13 text-capitalize colGray">month</small> </h4>} */}
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </Col>
                            <Col xxl={2} xl={4} lg={4} sm={4} className="analyticsWrap1 mb-2 mx-auto">
                                <Link to="#">
                                    <div className="dashboard-anatlytics">
                                        <div className="d-flex gap-2">
                                            <span className='d-block mx-auto'><BsDashLg className="kpiIcon text-secondary"/></span>
                                        </div>
                                        <div className=''>
                                            <div className="text-center">
                                                <h4 className='fw-600 mb-0 font-xl'>--</h4>
                                                <h3 className='fs-14 fw-400 colGray mb-0'>Not Defined</h3>
                                                <hr className="sp-hr" />
                                                {/* {totalLeadPercent === null || totalLeadPercent === undefined ? <small>Not Computed</small> : <h4 className={`mb-0 ${totalLeadPercent < 0 ? "text-danger" : ""}`}> {totalLeadPercent <= 0 ? <BsArrowDownLeft /> : <BsArrowUpRight />} {totalLeadPercent} / <small className="fs-13 text-capitalize colGray">month</small> </h4>} */}
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </Col>
                            
                        </Row>
                        <Row className='mt-2 justify-content-center'>
                            <Col xl={6} lg={6} className="targetCol">
                                <div className="salesDashboard-analytics">
                                    <h5 className="fs-14 fw-400 colGray mb-0">Commission Payable - Selected Saleperson</h5>
                                    <CommissionPayable />
                                </div>
                            </Col>
                            <Col xl={6} lg={6} className="targetCol">
                                <div className="salesDashboard-analytics">
                                    <h5 className="fs-14 fw-400 colGray mb-0">Selected Month - Commission Payable Per Salesperson - Top 10</h5>
                                    <CommissionPayablePerSaleperson />
                                </div>
                            </Col>
                        </Row>
                    </>
                )
            }
        </>
    );
};

export default CommissionDashboard;
