import React, { useState, useEffect, useCallback } from "react";
import Title from "../../../hook/Title";
import { TableCom } from "../../../Components/TableCom";
import { Link, useNavigate } from "react-router-dom";
import {
  getMyClientsBySalesPersonRoute,
  updateClientByIdRoute,
} from "../../../utils/APIRoutes";
import { useSelector } from "react-redux";
import { Dropdown } from "react-bootstrap";
import { FaRegEye, FaEllipsisV, FaTimes, FaEdit } from "react-icons/fa";
import CustomModal from "../../../Components/CustomModal";
import { Row, Col } from "react-bootstrap";
import countryCodes from "../../../helpers/currencyCodes.json";
import axios from "axios";
import { toast } from "react-toastify";
import useClipboard from "../../../hook/useClipboard";
import { debounce } from "lodash";

const MyClients = () => {
  Title("My Clients");

  const { handleCopyClipboard, copiedText } = useClipboard();
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  const AuthReducer = useSelector((state) => state.AuthReducer);
  const { empId } = AuthReducer.data;
  const allbrandCountryCodes = countryCodes.codes;
  // Table Head Data
  const [thLength, setThlength] = useState(1);
  const [items, setItems] = useState([]);
  const [numPerPage, setNumPerPage] = useState(10);
  const [totalData, setTotalData] = useState(0);
  const [pageCount, setpageCount] = useState(0);
  const [currPage, setCurrPage] = useState(0);
  const [searchVal, setSearchVal] = useState("");
  const Thead = [
    "SNo",
    "Name",
    "Email",
    "Phone",
    "Country",
    "Created At",
    "Unit",
    "Action",
  ];
  useEffect(
    (currPage) => {
      currPage = currPage ?? 0;
      const getData = async () => {
        const res = await fetch(
          `${getMyClientsBySalesPersonRoute}?empId=${empId}&numPerPage=${numPerPage}&page=${currPage}&search=${searchVal}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (res.status === 401) {
          navigate("/");
          toast.error(
            "Session has been Expired",
            toastOptions,
            (toastOptions["position"] = "top-right")
          );
          return;
        }
        const data = await res.json();
        const total = data?.pagiantion?.dataCount;
        setTotalData(total);
        setpageCount(Math.ceil(total / numPerPage));
        setItems(data.result);
      };
      getData();
    },
    [numPerPage, searchVal]
  );
  useEffect(() => {
    setThlength(Thead.length);
  }, [thLength]);
  const fetchData = async (currPage) => {
    const res = await fetch(
      `${getMyClientsBySalesPersonRoute}?empId=${empId}&numPerPage=${numPerPage}&page=${currPage}&search=${searchVal}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (res.status === 401) {
      navigate("/");
      toast.error(
        "Session has been Expired",
        toastOptions,
        (toastOptions["position"] = "top-right")
      );
      return;
    }
    const changedData = await res.json();
    const data = changedData.result;
    return data;
  };
  const handlePageClick = async (data) => {
    let currPage = data?.selected;
    const myData = await fetchData(currPage);
    setItems(myData);
    setCurrPage(currPage);
  };

  const handleChange = (e) => {
    setNumPerPage(e.target.value);
  };

  // Debounced search function
  const debouncedSearch = useCallback(
    debounce((value) => {
      setSearchVal(value);
    }, 500),
    []
  );

  // search data in api using search box
  const hangleSearchVal = (e) => {
    debouncedSearch(e.target.value);
  };

  // Edit Client Details Modal !
  let toastOptions = {
    position: "top-left",
    autoClose: 5000,
    pauseOnHover: false,
    draggable: false,
    theme: "dark",
  };
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [clientData, setClientData] = useState([]);
  const [editableState, setEditableState] = useState(true);
  const { _id, name, email, phone, country, organization, state, city } =
    clientData;
  const [editModalShow, setEditModalShow] = useState(false);
  const handleInpChange = (event) => {
    setClientData({ ...clientData, [event.target.name]: event.target.value });
  };
  const handleViewModal = (item, readOnly) => {
    setClientData(item);
    setEditableState(readOnly);
    setEditModalShow(true);
    // ========================== //
  };
  let hasError = false;
  // ---- Validate Form ------ //
  const validateForm = () => {
    const newErrors = {};
    if (name === "") {
      hasError = true;
      setIsLoading(false);
      newErrors.name = "Name is required !";
    }
    if (email === "") {
      hasError = true;
      setIsLoading(false);
      newErrors.email = "Email is required !";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      hasError = true;
      setIsLoading(false);
      newErrors.email = "Email is not valid !";
    }
    if (phone === "") {
      hasError = true;
      setIsLoading(false);
      newErrors.phone = "Phone Number is required !";
    }
    setErrors(newErrors);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    validateForm();
    if (hasError === false) {
      const { data } = await axios.put(
        `${updateClientByIdRoute}?clientId=${_id}`,
        {
          name: name.trim(),
          email,
          organization,
          phone,
          country,
          state,
          city,
        }
      );
      if (data.success === true) {
        setIsLoading(false);
        toast.success(
          data.msg,
          toastOptions,
          (toastOptions["position"] = "top-right")
        );
        setEditModalShow(false);
        const myData = await fetchData(currPage);
        setItems(myData);
      } else {
        toast.error(
          data.msg,
          toastOptions,
          (toastOptions["position"] = "bottom-right")
        );
        setIsLoading(false);
      }
    } else {
      toast.error(
        "Something Went Wrong Please try again later!",
        toastOptions,
        (toastOptions["position"] = "bottom-right")
      );
    }
  };
  return (
    <>
      <div className="d-flex align-items-center justify-content-between mb-4 flex-wrap">
        <h4 className="primHeading my-2">My Clients</h4>
        <Link className="main-btn my-2" to="/create-client">
          Create Client
        </Link>
      </div>
      <div className="box my-3">
        <div className="d-flex align-items-center justify-content-between mb-4 flex-wrap">
          <h4 className="sectionHeading my-2 mb-4">My Clients Summary</h4>
          <Link
            title="Create A New Invoice"
            className="main-btn my-2 rounded-0"
            to="/create-invoice"
          >
            create New invoice
          </Link>
        </div>
        <TableCom
          numPerPage={numPerPage}
          totalData={totalData}
          handleChange={handleChange}
          hangleSearchVal={hangleSearchVal}
          pageCount={pageCount}
          handlePageClick={handlePageClick}
          searcPlaceHolder="Search..."
        >
          <div className="customTable2 w-100 table-responsive">
            <table className="w-100">
              <thead>
                <tr>
                  {Thead?.map((th, index) => {
                    return <th key={index}>{th}</th>;
                  })}
                </tr>
              </thead>
              <tbody>
                {items == "" || items == undefined || items == null ? (
                  <tr>
                    <td colSpan={thLength}>
                      <p className="my-2 smText text-decoration-underline text-danger">
                        No Record Found
                      </p>
                    </td>
                  </tr>
                ) : (
                  items?.map((item, index) => {
                    const {
                      _id,
                      name,
                      email,
                      phone,
                      country,
                      unit,
                      createdAt,
                    } = item;
                    return (
                      <tr
                        key={_id}
                        onDoubleClick={() => handleViewModal(item, true)}
                        style={{ cursor: "pointer" }}
                      >
                        <td>{++index}</td>
                        <td>{name || "-"}</td>
                        <td>{email || "-"}</td>
                        <td onClick={() => handleCopyClipboard(phone)}>
                          <span className="cursor-pointer">
                            {copiedText === phone ? "Copied" : phone}
                          </span>
                        </td>
                        <td>{country || "-"}</td>
                        <td>{new Date(createdAt).toLocaleString() || "-"}</td>
                        <td>{unit.length > 0 ? unit[0].name : "--"}</td>
                        <td>
                          <Dropdown className="actionDropDown">
                            <Dropdown.Toggle className="customDropDown">
                              <FaEllipsisV className="colBlack" />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item
                                className="drop_item"
                                type="button"
                                onClick={() => handleViewModal(item, true)}
                              >
                                <FaRegEye className="me-1" />
                                <span>View</span>
                              </Dropdown.Item>
                              <Dropdown.Item
                                className="drop_item"
                                type="button"
                                onClick={() => handleViewModal(item, false)}
                              >
                                <FaEdit className="me-1" />
                                <span>Edit</span>
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
        </TableCom>
      </div>
      {/*========================================== ! View --Or-- Edit Client code start here ! =======================================*/}
      <CustomModal
        show={editModalShow}
        onHide={() => setEditModalShow(false)}
        backdrop="static"
        keyboard="False"
        size="lg"
      >
        <button
          onClick={() => setEditModalShow(false)}
          className="modal-cancel"
        >
          <FaTimes />
        </button>
        <div className="modalContent">
          {/* modal text */}
          <form
            onSubmit={(event) => handleSubmit(event)}
            className="text-start"
          >
            <p className="fw-600 my-2 mb-4">
              Edit <span className="colBlue">{name || ""}</span> Details From
              here
            </p>
            <Row>
              <Col lg={6} className="my-2">
                <label className="text-bold mb-1" htmlFor="name">
                  Name:<span className="text-danger">*</span>
                </label>
                <input
                  disabled={true}
                  value={name}
                  onChange={(e) => handleInpChange(e)}
                  name="name"
                  id="name"
                  type="text"
                  className="filterSelect ps-3"
                  placeholder="Enter Name"
                />
                {errors.name && (
                  <p className="text-danger fw-500 fs-13 my-2 text-capitalize">
                    {errors.name}
                  </p>
                )}
              </Col>
              <Col lg={6} className="my-2">
                <label className="text-bold mb-1" htmlFor="email">
                  Email:<span className="text-danger">*</span>
                </label>
                <input
                  disabled={editableState}
                  value={email}
                  onChange={(e) => handleInpChange(e)}
                  name="email"
                  id="email"
                  type="text"
                  className="filterSelect ps-3"
                  placeholder="Enter Email"
                />
                {errors.email && (
                  <p className="text-danger fw-500 fs-13 my-2 text-capitalize">
                    {errors.email}
                  </p>
                )}
              </Col>
              <Col lg={6} className="my-2">
                <label className="text-bold mb-1" htmlFor="phone">
                  Phone:<span className="text-danger">*</span>
                </label>
                <input
                  disabled={editableState}
                  value={phone}
                  onChange={(e) => handleInpChange(e)}
                  name="phone"
                  id="phone"
                  type="number"
                  className="filterSelect ps-3"
                  placeholder="Enter Phone Number"
                />
                {errors.phone && (
                  <p className="text-danger fw-500 fs-13 my-2 text-capitalize">
                    {errors.phone}
                  </p>
                )}
              </Col>
              <Col lg={6} className="my-2">
                <label className="text-bold mb-1" htmlFor="organization">
                  Organization:
                </label>
                <input
                  disabled={editableState}
                  value={organization}
                  onChange={(e) => handleInpChange(e)}
                  name="organization"
                  id="organization"
                  type="text"
                  className="filterSelect ps-3"
                  placeholder="Enter Organization Name"
                />
              </Col>
              <Col lg={6} className="my-2">
                <label className="text-bold mb-1" htmlFor="country">
                  Country:
                </label>
                <select
                  disabled={editableState}
                  className="filterSelect ps-3"
                  id="country"
                  name="country"
                  value={country}
                  onChange={(e) => handleInpChange(e)}
                >
                  <option value="">Select Option</option>
                  {Object.keys(allbrandCountryCodes).map((objkey, index) => {
                    return (
                      <option key={index} value={allbrandCountryCodes[objkey]}>
                        {allbrandCountryCodes[objkey]}
                      </option>
                    );
                  })}
                </select>
              </Col>
              <Col lg={12} className="my-2 text-center">
                {editableState === true ? (
                  ""
                ) : (
                  <button
                    className="main-btn mt-3"
                    disabled={isLoading}
                    type="submit"
                  >
                    {" "}
                    {isLoading ? "submiting..." : "submit"}{" "}
                  </button>
                )}
              </Col>
            </Row>
          </form>
        </div>
      </CustomModal>
    </>
  );
};

export default MyClients;
