import React from "react";
import { TableCom } from "../TableCom";

const ClientSummaryTable = () => {
  const Thead = [
    "S.No",
    "Project Name",
    "Start Date",
    "End Date",
    "Status",
    "Total Cost",
  ];

  return (
    <>
      <div className="box my-3 rounded-0">
        <h4 className="sectionHeading my-2 mb-4">Client Project Summary</h4>
        <TableCom searchPlaceHolder="Search By Project Name...">
          <div className="customTable2 w-100 table-responsive">
            <table className="w-100">
              <thead>
                <tr>
                  {Thead?.map((th, index) => (
                    <th key={index}>{th}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {[1, 2, 3, 4, 5].map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{`Project ${index + 1}`}</td>
                    <td>01/01/2023</td>
                    <td>12/31/2023</td>
                    <td>Ongoing</td>
                    <td>$100,000</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </TableCom>
      </div>
    </>
  );
};

export default ClientSummaryTable;
