import React from "react";
import { useSelector } from 'react-redux';
const Info = () => {
  const AuthReducer = useSelector((state) => state.AuthReducer);
  const { email, designation, department, cnic_passport, dateOfJoining, employeeName, active } = AuthReducer.data;
  return (
    <>
      <h6 className="sectionHeading my-2 mb-4">Basic Info</h6>
      <div className="d-md-flex algn-items-center justify-content-between gap-2 infoBox">
        <label className="flex-shrink-0" htmlFor="#">Employee Name :</label>
        <p><span>{employeeName || "--"}</span></p>
      </div>
      <div className="d-md-flex algn-items-center justify-content-between gap-2 infoBox">
        <label className="flex-shrink-0" htmlFor="#">Email :</label>
        <p><span>{email || "--"}</span></p>
      </div>
      <div className="d-md-flex algn-items-center justify-content-between gap-2 infoBox">
        <label className="flex-shrink-0" htmlFor="#">Designation :</label>
        <p><span>{designation === "unit_head" ? "Unit Head" : designation === "head_of_department" ? "Head Of Department" : designation === "lead_generator" ? "Lead Generator" : "--"}</span></p>
      </div>
      <div className="d-md-flex algn-items-center justify-content-between gap-2 infoBox">
        <label className="flex-shrink-0" htmlFor="#">Department :</label>
        <p><span>{department || "--"}</span></p>
      </div>
      <div className="d-md-flex algn-items-center justify-content-between gap-2 infoBox">
        <label className="flex-shrink-0" htmlFor="#">Date Of Joining :</label>
        <p><span>{dateOfJoining.slice(0, 10).split("-").reverse().join("-") || "--"}</span></p>
      </div>
      <div className="d-md-flex algn-items-center justify-content-between gap-2 infoBox">
        <label className="flex-shrink-0" htmlFor="#">Cnic/Passport :</label>
        <p><span>{cnic_passport || "#"}</span></p>
      </div>
      <div className="d-md-flex algn-items-center justify-content-between gap-2 infoBox">
        <label className="flex-shrink-0" htmlFor="#">Status :</label>
        <p>{active === true ? <span className="customBadge bgGreen">Active</span> : <span className="customBadge bgRed">InActive</span>}</p>
      </div>
    </>
  );
};

export default Info;
