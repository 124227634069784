import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend
} from "recharts";



const Top10SalePersons = ({ data }) => {
    // const data = [
    //     {
    //         name: "Ghuffran Ali",
    //         uv: 4000,
    //         SalePerson: 2400,
    //         amt: 2400
    //       },
    //       {
    //         name: "Muhammad Faraz",
    //         uv: 3000,
    //         SalePerson: 1398,
    //         amt: 2210
    //       },
    //       {
    //         name: "Zohaib",
    //         uv: 2000,
    //         SalePerson: 9800,
    //         amt: 2290
    //       },
    //       {
    //         name: "Noah",
    //         uv: 2780,
    //         SalePerson: 3908,
    //         amt: 2000
    //       },
    //       {
    //         name: "Mohsin Alam",
    //         uv: 1890,
    //         SalePerson: 4800,
    //         amt: 2181
    //       },
    //       {
    //         name: "Naveed Ramzan",
    //         uv: 2390,
    //         SalePerson: 3800,
    //         amt: 2500
    //       },
    //       {
    //         name: "Eyraj Sohail",
    //         uv: 3490,
    //         SalePerson: 4300,
    //         amt: 2100
    //       },
    //       {
    //         name: "sohaib",
    //         uv: 1890,
    //         SalePerson: 4800,
    //         amt: 2181
    //       },
    //       {
    //         name: "mehar",
    //         uv: 2390,
    //         SalePerson: 3800,
    //         amt: 2500
    //       },
    //       {
    //         name: "aa",
    //         uv: 3490,
    //         SalePerson: 4300,
    //         amt: 2100
    //       }
    // ];

    return (
        <BarChart
        width={670}
        height={300}
        data={data}
        margin={{
          top: 5,
          right: 5,
          left: 5,
          bottom: 5
        }}
        barSize={20}
      >
        <XAxis dataKey="name" scale="point" padding={{ left: 20, right: 20 }} />
        <YAxis />
        <Tooltip />
        <Legend />
        <CartesianGrid strokeDasharray="3 3" />
        <Bar dataKey="NetSales" fill="#8884d8" />
      </BarChart>
    )
}
export default Top10SalePersons;