import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import 'chartjs-plugin-zoom';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
    responsive: true,
    maintainAspectRatio: false,
    height: 300, // Set the height to 400 pixels 
    plugins: {
        legend: {
            position: 'top',
        },
        title: {
            display: false,
            text: '',
        },
    },
};



const BrandsIncome = ({ topSellingBrands }) => {
    const { amount, brand } = topSellingBrands
    // const labels = ['Academic Dissertations', 'Branding Studio Inc', 'Built A Website', 'Custom Paper Center', 'Design Elegancy', 'Dissertation Helper Center', 'Finest Coursework', 'Ghost Writing Arena', 'Help Paper Pro', 'Logo Design Platinum'];
    const data = {
        labels: brand,
        datasets: [
            {
                label: 'Sales',
                data: amount,
                backgroundColor: '#4F46BA',
                borderColor: '#4F46BA',
                borderWidth: 1,

            },
        ],
    };
    return (
        <div className="h-350">
            <Bar options={options} data={data} />
        </div>
    )
}
export default BrandsIncome;