import React, { useState } from "react";
import { toast } from "react-toastify";
import { createUnitRoute } from "../../utils/APIRoutes";
import axios from "axios";
import { Row, Col } from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";
const Index = () => {
     const navigate = useNavigate()
     //============================================ ! Api inegration of Create Employee code !  ===============================================//
     let toastOptions = {
          position: "top-left",
          autoClose: 5000,
          pauseOnHover: false,
          draggable: false,
          theme: "dark",
     };
     const [errors, setErrors] = useState({});
     const [isLoading, setIsLoading] = useState(false);
     // ---- States of inputs in the form of key value pair name:value ------ // 
     let [values, setValues] = useState({ name: "", description: "" });
     const { name, description } = values;
     // ---- Getting values of inputs ------ //  
     const handleInpChange = (event) => {
          setValues({ ...values, [event.target.name]: event.target.value });
     };
     let hasError = false;
     // ---- Validate Form ------ //
     const validateForm = () => {
          const newErrors = {};
          if (!name) {
               hasError = true;
               setIsLoading(false);
               newErrors.name = 'Unit Name is required !';
          }
          setErrors(newErrors);
     };
     // ---- Form Submittion Function ------ // 
     const handleSubmit = async (event) => {
          event.preventDefault();
          setIsLoading(true);
          validateForm();
          if (hasError === false) {
               const { data } = await axios.post(createUnitRoute, {
                    name: name.trim(),
                    description: description.trim()
               });
               if (data.success === true) {
                    setIsLoading(false);
                    toast.success(data.msg, toastOptions, toastOptions['position'] = "top-right");
                    setValues({ name: "", description: "" });
                    navigate("/units")
               } else {
                    toast.error(data.msg, toastOptions, toastOptions['position'] = "bottom-right");
                    setIsLoading(false);
               }
          } else {
               toast.error("Kindly Fill the require fields !", toastOptions, toastOptions['position'] = "top-right");
          }
     };

     return (
          <>
               <div className="d-flex align-items-center justify-content-between flex-wrap">
                    <h4 className="section-heading my-2 mb-4">Create <span> Units</span></h4>
                    <Link to="/units" className="Btn main-btn">View All Units</Link>
               </div>
               <form onSubmit={(event) => handleSubmit(event)}>
                    <Row>
                         <Col lg={8}>
                              <p>Add A New Unit From here</p>
                              <Row>
                                   <Col lg={12} className="my-2">
                                        <label className="text-bold mb-1" htmlFor="name">Unit Name<span className="text-danger">*</span></label>
                                        <input
                                             value={name}
                                             onChange={(e) => handleInpChange(e)}
                                             name='name'
                                             id='name'
                                             type="text"
                                             className="filterSelect ps-3"
                                             placeholder='Enter Unit Name' />
                                        {errors.name && <p className="text-danger fw-500 fs-13 my-2 text-capitalize">{errors.name}</p>}
                                   </Col>
                                   <Col lg={12} className="my-2">
                                        <label className="text-bold mb-1" htmlFor="description">description</label>
                                        <textarea
                                             value={description}
                                             onChange={(e) => handleInpChange(e)}
                                             name="description"
                                             id="description"
                                             type="url"
                                             className="filterSelect ps-3 w-100"
                                             placeholder='Enter Description'
                                             rows="5"></textarea>
                                   </Col>
                                   <Col lg={12} className="text-end">
                                        <button className="main-btn mt-4" disabled={isLoading} type="submit"> {isLoading ? 'creating...' : "create"} </button>
                                   </Col>
                              </Row>
                         </Col>
                    </Row>
               </form>
          </>
     );
};

export default Index; 