import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { Link, useNavigate} from 'react-router-dom';
import Title from "../../hook/Title";
import TargetVsActual from '../../Components/Charts/TargetVsActual';
import NewVsReturningCustomer from '../../Components/Charts/NewVsReturningCustomer';
import SalesVsBenchmarkVsTarget from '../../Components/Charts/SalesVsBenchmarkVsTarget';
import Top10SalePersons from '../../Components/Charts/Top10SalePersons';
import { getAllSalesPersonRoute, getSaleDashboardDataRoute } from "../../utils/APIRoutes";
import axios from "axios";
import Loader from '../../utils/Loader';
// import { FaMoneyBillTransfer } from "react-icons/fa";
import { RiCurrencyLine, RiRefund2Line } from "react-icons/ri";
import { BsCashCoin, BsPeople, BsPersonAdd, BsFillPersonLinesFill } from "react-icons/bs";
import { PiWarningCircleBold, PiListMagnifyingGlassBold } from "react-icons/pi";
import { MdPeopleAlt } from "react-icons/md";
import { AiOutlineFileSync } from "react-icons/ai";
import { BiPurchaseTag, BiTransfer } from "react-icons/bi";
import { RemoveAuthAction } from '../../actions/AuthAction';



import { useSelector, useDispatch } from "react-redux";

const SalesDashboard = () => {
    Title("Sales Dashboard");

    const [filterLoader, setFilterLoader] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [salePersons, setSalePersons] = useState([]);
    const [servError, setServError] = useState(false);
    const [selectedSaleperson, setSelectedSaleperson] = useState("{}");
    const [month, setMonth] = useState(new Date().getMonth().toString());
    const [year, setYear] = useState(new Date().getFullYear().toString());
    const [grossSales, setGrossSales] = useState([])
    const [netSales, setNetSales] = useState([])
    const [disputedSales, setDisputedSales] = useState([])
    const [refundedSales, setRefundedSales] = useState([])
    const [newCustomerCount, setNewCustomerCount] = useState(0)
    const [newCustomersSales, setNewCustomersSales] = useState([])
    const [returningCustomerCount, setReturningCustomerCount] = useState(0)
    const [returningCustomerSales, setReturningCustomerSales] = useState([])
    const [avgSalesTicket, setAvgSalesTicket] = useState("0")
    const [transactionCount, setTransactionCount] = useState(0)
    const [targetVsActual, setTargetVsActual] = useState([])
    const [targetVsActualPercent, setTargetVsActualPercent] = useState("")
    const [saleVsBenchmarkVsTargetData, setSaleVsBenchmarkVsTargetData] = useState([])
    const [newVsReturningCustomersData, setNewVsReturningCustomersData] = useState([])
    const [top10SalePersonsData, setTop10SalePersonsData] = useState([])

    const navigate = useNavigate();
    const dispatch = useDispatch()

    const finalShowModal = () => {
        // dispatch(RemoveAuthAction())
        navigate("/dashboard")
    };

    // getting value of authenticated user using redux persistor
    const AuthReducer = useSelector((state) => state.AuthReducer);
    const { empId, designation, department } = AuthReducer.data;
    // console.log(department)
    const monthNames = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

    const clearFilters = () => {
        setSelectedSaleperson("{}")
        setMonth(new Date().getMonth().toString())
        setYear(new Date().getFullYear().toString())
    }


    const cyear = new Date().getFullYear();
    const previousYears = [];

    for (let i = 0; i <= 9; i++) {
        previousYears.push((cyear - i).toString());
    }
    // console.log(previousYears)

    const getSalesDashboardData = async () => {
        try {
            setFilterLoader(true)
            const data = await axios.get(`${getSaleDashboardDataRoute}?salePersonId=${selectedSaleperson === "{}" ? "" : (JSON.parse(selectedSaleperson))._id}&monthIndex=${month}&year=${year}`)
            if (data.data.success === true) {
                // console.log(data.data)
                setGrossSales(data?.data?.data?.grossSales);
                setNetSales(data?.data?.data?.netSales);
                setDisputedSales(data?.data?.data?.disputedSales);
                setRefundedSales(data?.data?.data?.refundedSales);
                setNewCustomerCount(data?.data?.data?.newCustomerCount);
                setNewCustomersSales(data?.data?.data?.newCustomersSales);
                setReturningCustomerCount(data?.data?.data?.returningCustomerCount);
                setReturningCustomerSales(data?.data?.data?.returningCustomerSales);
                setAvgSalesTicket(data?.data?.data?.avgSalesTicket);
                setTransactionCount(data?.data?.data?.transactionCount);
                setTargetVsActual(data?.data?.data?.targetVsActual);
                setTargetVsActualPercent(data?.data?.data?.targetVsActualPercent);
                setSaleVsBenchmarkVsTargetData(data?.data?.data?.saleVsBenchmarkVsTargetGraph)
                setNewVsReturningCustomersData(data?.data?.data?.newVsReturningCustomerGraph)
                setTop10SalePersonsData(data?.data?.data?.top10SalePerson)

                // setIsLoading(false)
                setFilterLoader(false)
            }
        } catch (err) {
            console.log(err);
            setFilterLoader(false)
            // setIsLoading(false);
        }
    }

    useEffect(
        () => {
            const getAlSalesPerson = async () => {
                try {
                    const salePersons = await axios.get(getAllSalesPersonRoute).catch(function (error) {
                        if (error.response) {
                            if (error.response.status === 500) {
                                setServError(true);
                            }
                        }
                    })
                    if (salePersons?.data.success === true) {
                        setSalePersons(salePersons.data.data);
                    } else {
                        console.log("error In Getting all Sales Person", salePersons.data.msg)
                    }
                } catch (error) {
                    setServError(true);
                    console.log("error In Getting all Sales Person", error)
                }
            }
            getAlSalesPerson();
            setIsLoading(false)
        },
        []
    );
    useEffect(
        () => {
            getSalesDashboardData();
        }, [selectedSaleperson, month, year]

    )



    return (
        <>
            {(department === "sales" && designation === "head_of_department") || (department === "sales" && designation === "unit_head") || department === "finance" && designation === "president" ? (
                <>
                    <h4 className="primHeading my-3">
                        Sales Dashboard
                    </h4>
                    {
                        isLoading === true ? (
                            <Loader />
                        ) : (
                            servError === true ? (
                                <>
                                    <div className="text-center">
                                        <h5 className="my-2">Server is Not Responding !. Please Inform To Concern Person.</h5>
                                        <h5 className="my-2">Error Code: <span className="text-danger">500 - Internal server Error</span></h5>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <Row className="customFilters mb-3">
                                        <Col xl={4} lg={4} md={4} sm={4} className="my-2">
                                            <label className="text-bold" htmlFor="SalePersonSelect">Sales Person:</label>
                                            <select className='filterSelect ps-3' value={selectedSaleperson} id='SalePersonSelect' onChange={(e) => setSelectedSaleperson(e.target.value)}>
                                                <option value="{}">Select SalesPerson</option>
                                                {
                                                    salePersons?.map((data, index) => {
                                                        const { employeeName, unit } = data;
                                                        return (
                                                            <option key={index} value={JSON.stringify(data)}>{`${employeeName} (${unit.length === 0 ? "--" : unit.length === 1 ? unit[0].name : "AVP"})`}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </Col>
                                        <Col xl={2} lg={3} md={3} sm={3} className="my-2">
                                            <label className="text-bold" htmlFor="MonthSelect">Month:</label>
                                            <select className='primSelect prim-date white' value={month} id='MonthSelect' onChange={(e) => { setMonth(e.target.value) }}>

                                                <option value="0">January</option>
                                                <option value="1">February</option>
                                                <option value="2">March</option>
                                                <option value="3">April</option>
                                                <option value="4">May</option>
                                                <option value="5">June</option>
                                                <option value="6">July</option>
                                                <option value="7">August</option>
                                                <option value="8">September</option>
                                                <option value="9">October</option>
                                                <option value="10">November</option>
                                                <option value="11">December</option>
                                            </select>
                                        </Col>
                                        <Col xl={2} lg={3} md={3} sm={3} className="my-2">
                                            <label className="text-bold" htmlFor="YearSelect">By Year:</label>
                                            <select className='primSelect prim-date white' value={year} id='YearSelect' onChange={(e) => { setYear(e.target.value) }}>

                                                {previousYears.map((year, index) => {
                                                    return (<option key={index} value={year}>{year}</option>)
                                                })}
                                            </select>
                                        </Col>
                                        {
                                            filterLoader ? (<Col lg={2} md={2} sm={2} className="my-2 position-relative">
                                                <div className="preloaderWrapper">
                                                    <div className="preloaderMainWrapper">
                                                        <div className="smLoader">
                                                            <div className="smdot"></div>
                                                        </div>
                                                        <div className="smLoader">
                                                            <div className="smdot"></div>
                                                        </div>
                                                        <div className="loader">
                                                            <div className="smdot"></div>
                                                        </div>
                                                        <div className="smLoader">
                                                            <div className="smdot"></div>
                                                        </div>
                                                        <div className="smLoader">
                                                            <div className="smdot"></div>
                                                        </div>
                                                        <div className="smLoader">
                                                            <div className="smdot"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>) : (<Col xl={2} lg={2} md={2} sm={2} className="my-2">
                                                <button onClick={clearFilters} title="Clear Date Filters" className="main-btn clearFilter w-100 mt-4">Reset</button>
                                            </Col>)
                                        }
                                    </Row>
                                    <Row>
                                        <Col xxl={2} xl={2} lg={3} md={3} sm={4} className="analyticsWrap1 mb-2 mx-auto">
                                            <Link to="#">
                                                <div className="dashboard-anatlytics">
                                                    <div className="d-flex align-items-center gap-2">
                                                        <span className='d-block mx-auto'><RiCurrencyLine className="kpiIcon text-secondary" /></span>

                                                    </div>
                                                    <div className=''>
                                                        <div className="text-center">
                                                            <h4 className='fw-600 mb-0 font-xl'>$ {grossSales.length === 0 ? 0 : grossSales[0].totalAmount}</ h4>
                                                            <h3 className='fs-14 fw-400 colGray mb-0'>Gross Sales Amount</h3>
                                                            <hr className="sp-hr" />
                                                            {/* <small>Not Computed</small> */}
                                                        </div>
                                                    </div>

                                                </div>
                                            </Link>
                                        </Col>
                                        <Col xxl={2} xl={2} lg={3} md={3} sm={4} className="analyticsWrap1 mb-2 mx-auto">
                                            <Link to="#">
                                                <div className="dashboard-anatlytics">
                                                    <div className="d-flex align-items-center gap-2">
                                                        <span className='d-block mx-auto'><BsCashCoin className="kpiIcon text-warning" /></span>

                                                    </div>
                                                    <div className=''>
                                                        <div className="text-center">
                                                            <h4 className='fw-600 mb-0 font-xl'>$ {netSales.length === 0 ? 0 : netSales[0].totalAmount}</h4>
                                                            <h3 className='fs-14 fw-400 colGray mb-0'>Net Sales Amount</h3>
                                                            <hr className="sp-hr" />
                                                            {/* {totalLeadPercent === null || totalLeadPercent === undefined ? <small>Not Computed</small> : <h4 className={`mb-0 ${totalLeadPercent < 0 ? "text-danger" : ""}`}> {totalLeadPercent <= 0 ? <BsArrowDownLeft /> : <BsArrowUpRight />} {totalLeadPercent} / <small className="fs-13 text-capitalize colGray">month</small> </h4>} */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                        </Col>
                                        <Col xxl={2} xl={2} lg={3} md={3} sm={4} className="analyticsWrap1 mb-2 mx-auto">
                                            <Link to="#">
                                                <div className="dashboard-anatlytics">
                                                    <div className="d-flex align-items-center gap-2">
                                                        <span className='d-block mx-auto'><PiWarningCircleBold className="kpiIcon text-secondary" /></span>

                                                    </div>
                                                    <div className=''>
                                                        <div className="text-center">
                                                            <h4 className='fw-600 mb-0 font-xl'>$ {disputedSales.length === 0 ? 0 : disputedSales[0].totalAmount}</h4>
                                                            <h3 className='fs-14 fw-400 colGray mb-0'>Disputed Amount</h3>
                                                            <hr className="sp-hr" />
                                                            {/* {totalLeadPercent === null || totalLeadPercent === undefined ? <small>Not Computed</small> : <h4 className={`mb-0 ${totalLeadPercent < 0 ? "text-danger" : ""}`}> {totalLeadPercent <= 0 ? <BsArrowDownLeft /> : <BsArrowUpRight />} {totalLeadPercent} / <small className="fs-13 text-capitalize colGray">month</small> </h4>} */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                        </Col>
                                        <Col xxl={2} xl={2} lg={3} md={3} sm={4} className="analyticsWrap1 mb-2 mx-auto">
                                            <Link to="#">
                                                <div className="dashboard-anatlytics">
                                                    <div className="d-flex align-items-center gap-2">
                                                        <span className='d-block mx-auto'><RiRefund2Line className="kpiIcon text-danger" /></span>

                                                    </div>
                                                    <div className=''>
                                                        <div className="text-center">
                                                            <h4 className='fw-600 mb-0 font-xl'>$ {refundedSales.length === 0 ? 0 : refundedSales[0].totalAmount}</h4>
                                                            <h3 className='fs-14 fw-400 colGray mb-0'>Refunded Amount</h3>
                                                            <hr className="sp-hr" />
                                                            {/* {totalLeadPercent === null || totalLeadPercent === undefined ? <small>Not Computed</small> : <h4 className={`mb-0 ${totalLeadPercent < 0 ? "text-danger" : ""}`}> {totalLeadPercent <= 0 ? <BsArrowDownLeft /> : <BsArrowUpRight />} {totalLeadPercent} / <small className="fs-13 text-capitalize colGray">month</small> </h4>} */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                        </Col>
                                        <Col xxl={2} xl={2} lg={3} md={3} sm={4} className="analyticsWrap1 mb-2 mx-auto">
                                            <Link to="#">
                                                <div className="dashboard-anatlytics">
                                                    <div className="d-flex align-items-center gap-2">
                                                        <span className='d-block mx-auto'><PiListMagnifyingGlassBold className="kpiIcon text-secondary" /></span>
                                                    </div>
                                                    <div className=''>
                                                        <div className="text-center">
                                                            <h4 className='fw-600 mb-0 font-xl'>$ {avgSalesTicket}</h4>
                                                            <h3 className='fs-14 fw-400 colGray mb-0'>Avg. Gross Sales Ticket</h3>
                                                            <hr className="sp-hr" />
                                                            {/* {totalLeadPercent === null || totalLeadPercent === undefined ? <small>Not Computed</small> : <h4 className={`mb-0 ${totalLeadPercent < 0 ? "text-danger" : ""}`}> {totalLeadPercent <= 0 ? <BsArrowDownLeft /> : <BsArrowUpRight />} {totalLeadPercent} / <small className="fs-13 text-capitalize colGray">month</small> </h4>} */}
                                                        </div>
                                                    </div>

                                                </div>
                                            </Link>
                                        </Col>
                                        <Col xxl={2} xl={2} lg={3} md={3} sm={4} className="analyticsWrap1 mb-2 mx-auto">
                                            <Link to="#">
                                                <div className="dashboard-anatlytics">
                                                    <div className="d-flex align-items-center gap-2">
                                                        <span className='d-block mx-auto'><BiTransfer className="kpiIcon text-secondary" /></span>
                                                    </div>
                                                    <div className=''>
                                                        <div className="text-center">
                                                            <h4 className='fw-600 mb-0 font-xl'>{transactionCount}</h4>
                                                            <h3 className='fs-14 fw-400 colGray mb-0'># No. of Transactions</h3>
                                                            <hr className="sp-hr" />
                                                            {/* {totalLeadPercent === null || totalLeadPercent === undefined ? <small>Not Computed</small> : <h4 className={`mb-0 ${totalLeadPercent < 0 ? "text-danger" : ""}`}> {totalLeadPercent <= 0 ? <BsArrowDownLeft /> : <BsArrowUpRight />} {totalLeadPercent} / <small className="fs-13 text-capitalize colGray">month</small> </h4>} */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                        </Col>
                                        <Col xxl={2} xl={2} lg={3} md={3} sm={4} className="analyticsWrap1 mb-2 mx-auto">
                                            <Link to="#">
                                                <div className="dashboard-anatlytics">
                                                    <div className="d-flex align-items-center gap-2">
                                                        <span className='d-block mx-auto'><BsPeople className="kpiIcon text-info" /></span>

                                                    </div>
                                                    <div className=''>
                                                        <div className="text-center">
                                                            <h4 className='fw-600 mb-0 font-xl'>{newCustomerCount}</h4>
                                                            <h3 className='fs-14 fw-400 colGray mb-0'># New Customers</h3>
                                                            <hr className="sp-hr" />
                                                            {/* {totalLeadPercent === null || totalLeadPercent === undefined ? <small>Not Computed</small> : <h4 className={`mb-0 ${totalLeadPercent < 0 ? "text-danger" : ""}`}> {totalLeadPercent <= 0 ? <BsArrowDownLeft /> : <BsArrowUpRight />} {totalLeadPercent} / <small className="fs-13 text-capitalize colGray">month</small> </h4>} */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                        </Col>
                                        <Col xxl={2} xl={2} lg={3} md={3} sm={4} className="analyticsWrap1 mb-2 mx-auto">
                                            <Link to="#">
                                                <div className="dashboard-anatlytics">
                                                    <div className="d-flex align-items-center gap-2">
                                                        <span className='d-block mx-auto'><BsPersonAdd className="kpiIcon text-info" /></span>
                                                    </div>
                                                    <div className=''>
                                                        <div className="text-center">
                                                            <h4 className='fw-600 mb-0 font-xl'>$ {newCustomersSales.length === 0 ? 0 : newCustomersSales[0].totalAmount}</h4>
                                                            <h3 className='fs-14 fw-400 colGray mb-0'>New Customers Sales Amount</h3>
                                                            <hr className="sp-hr" />
                                                            {/* {totalLeadPercent === null || totalLeadPercent === undefined ? <small>Not Computed</small> : <h4 className={`mb-0 ${totalLeadPercent < 0 ? "text-danger" : ""}`}> {totalLeadPercent <= 0 ? <BsArrowDownLeft /> : <BsArrowUpRight />} {totalLeadPercent} / <small className="fs-13 text-capitalize colGray">month</small> </h4>} */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                        </Col>
                                        <Col xxl={2} xl={2} lg={3} md={3} sm={4} className="analyticsWrap1 mb-2 mx-auto">
                                            <Link to="#">
                                                <div className="dashboard-anatlytics">
                                                    <div className="d-flex align-items-center gap-2">
                                                        <span className='d-block mx-auto'><MdPeopleAlt className="kpiIcon text-secondary" /></span>
                                                    </div>
                                                    <div className=''>
                                                        <div className="text-center">
                                                            <h4 className='fw-600 mb-0 font-xl'>{returningCustomerCount}</h4>
                                                            <h3 className='fs-14 fw-400 colGray mb-0'># Returning Customers</h3>
                                                            <hr className="sp-hr" />
                                                            {/* {totalLeadPercent === null || totalLeadPercent === undefined ? <small>Not Computed</small> : <h4 className={`mb-0 ${totalLeadPercent < 0 ? "text-danger" : ""}`}> {totalLeadPercent <= 0 ? <BsArrowDownLeft /> : <BsArrowUpRight />} {totalLeadPercent} / <small className="fs-13 text-capitalize colGray">month</small> </h4>} */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                        </Col>
                                        <Col xxl={2} xl={2} lg={3} md={3} sm={4} className="analyticsWrap1 mb-2 mx-auto">
                                            <Link to="#">
                                                <div className="dashboard-anatlytics">
                                                    <div className="d-flex align-items-center gap-2">
                                                        <span className='d-block mx-auto'><BsFillPersonLinesFill className="kpiIcon text-secondary" /></span>
                                                    </div>
                                                    <div className=''>
                                                        <div className="text-center">
                                                            <h4 className='fw-600 mb-0 font-xl'>$ {returningCustomerSales.length === 0 ? 0 : returningCustomerSales[0].totalAmount}</h4>
                                                            <h3 className='fs-14 fw-400 colGray mb-0'>Returning Customers Sales Amount</h3>
                                                            <hr className="sp-hr" />
                                                            {/* {totalLeadPercent === null || totalLeadPercent === undefined ? <small>Not Computed</small> : <h4 className={`mb-0 ${totalLeadPercent < 0 ? "text-danger" : ""}`}> {totalLeadPercent <= 0 ? <BsArrowDownLeft /> : <BsArrowUpRight />} {totalLeadPercent} / <small className="fs-13 text-capitalize colGray">month</small> </h4>} */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                        </Col>
                                        <Col xxl={2} xl={2} lg={3} md={3} sm={4} className="analyticsWrap1 mb-2 mx-auto">
                                            <Link to="#">
                                                <div className="dashboard-anatlytics">
                                                    <div className="d-flex align-items-center gap-2">
                                                        <span className='d-block mx-auto'><AiOutlineFileSync className="kpiIcon text-secondary" /></span>
                                                    </div>
                                                    <div className=''>
                                                        <div className="text-center">
                                                            <h4 className='fw-600 mb-0 font-xl'>--</h4>
                                                            <h3 className='fs-14 fw-400 colGray mb-0'>Ordered Placed</h3>
                                                            <hr className="sp-hr" />
                                                            {/* {totalLeadPercent === null || totalLeadPercent === undefined ? <small>Not Computed</small> : <h4 className={`mb-0 ${totalLeadPercent < 0 ? "text-danger" : ""}`}> {totalLeadPercent <= 0 ? <BsArrowDownLeft /> : <BsArrowUpRight />} {totalLeadPercent} / <small className="fs-13 text-capitalize colGray">month</small> </h4>} */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                        </Col>
                                        <Col xxl={2} xl={2} lg={3} md={3} sm={4} className="analyticsWrap1 mb-2 mx-auto">
                                            <Link to="#">
                                                <div className="dashboard-anatlytics">
                                                    <div className="d-flex align-items-center gap-2">
                                                        <span className='d-block mx-auto'><BiPurchaseTag className="kpiIcon text-secondary" /></span>
                                                    </div>
                                                    <div className=''>
                                                        <div className="text-center">
                                                            <h4 className='fw-600 mb-0 font-xl'>--</h4>
                                                            <h3 className='fs-14 fw-400 colGray mb-0'>Item Purchased</h3>
                                                            <hr className="sp-hr" />
                                                            {/* {totalLeadPercent === null || totalLeadPercent === undefined ? <small>Not Computed</small> : <h4 className={`mb-0 ${totalLeadPercent < 0 ? "text-danger" : ""}`}> {totalLeadPercent <= 0 ? <BsArrowDownLeft /> : <BsArrowUpRight />} {totalLeadPercent} / <small className="fs-13 text-capitalize colGray">month</small> </h4>} */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                        </Col>



                                    </Row>
                                    <Row className='mt-2 justify-content-center '>
                                        <Col xl={4} lg={4} className="targetCol targetLegend">
                                            <TargetVsActual data={targetVsActual} />
                                            <label className="text-bold targetPercent">{targetVsActualPercent}%</label>
                                            <label className="text-bold targetLabel">Sales Actual vs Benchmark</label>
                                        </Col>

                                        <Col xl={8} lg={8} className="targetCol">
                                            <div className="salesDashboard-analytics">
                                                <h5 className="fs-14 fw-400 colGray mb-0">Sales Vs Benchmark/Target - {year}</h5>
                                                <SalesVsBenchmarkVsTarget data={saleVsBenchmarkVsTargetData} />
                                            </div>
                                        </Col>

                                    </Row>
                                    <hr className="sp-hr" />
                                    <Row className='mt-2 justify-content-center'>
                                        <Col xl={6} lg={6} className="targetCol">
                                            <div className="salesDashboard-analytics">
                                                <h5 className="fs-14 fw-400 colGray mb-0">Sales Amount - New vs Returning Customers - {year}</h5>
                                                <NewVsReturningCustomer data={newVsReturningCustomersData} />
                                            </div>
                                        </Col>
                                        <Col xl={6} lg={6} className="targetCol">
                                            <div className="salesDashboard-analytics">
                                                <h5 className="fs-14 fw-400 colGray mb-0">Top 10 - Salesperson - {monthNames[month]}/{year}</h5>
                                                <Top10SalePersons data={top10SalePersonsData} />
                                            </div>
                                        </Col>
                                    </Row>
                                </>
                            )

                        )
                    }
                </>
            ) : (
                finalShowModal()
            )}

        </>
    );
};

export default SalesDashboard;
