import React, { useEffect, useState } from "react";
import { getAssignedPPCBrandsByUserIdRoute, setPpcEnabledStatusRoute } from "../utils/APIRoutes";
import { Row, Col } from "react-bootstrap";
import Skeleton from 'react-loading-skeleton';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import axios from "axios";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Loader from "../utils/Loader";

const AssignedBrand = ({ urlOf }) => {

    let toastOptions = {
        position: "top-right",
        autoClose: 5000,
        pauseOnHover: false,
        draggable: false,
        theme: "light",
    };
    const AuthReducer = useSelector((state) => state.AuthReducer);
    const { empId } = AuthReducer.data;
    const [error, setError] = useState(false);
    const [allBrandsData, setBrandsData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const getAllBrands = async () => {
        setIsLoading(true)
        const AllbrandsData = await fetch(`${getAssignedPPCBrandsByUserIdRoute}?userId=${empId}`);
        const brandsData = await AllbrandsData.json();
        if (AllbrandsData.status === 500) {
            setError(true)
            setIsLoading(false);
        } else {
            setBrandsData(brandsData.data);
            setIsLoading(false);
        }
    }

    useEffect(() => {
        getAllBrands();
    }, []);

    // PPC Status Start
    const ppcToggle = async (_id, ppcStatus) => {
        try {
            const resultant = await axios.post(`${setPpcEnabledStatusRoute}?brandId=${_id}&status=${ppcStatus}`)
            if (resultant) {
                if (resultant.data.success === true) {
                    toast.success(resultant.data.msg, toastOptions);
                    getAllBrands();
                } else {
                    toast.error(resultant.data.msg, toastOptions);
                }
            } else {
                toast.error(resultant.data.msg, toastOptions);
            }
        } catch (error) {
            setIsLoading(false);
            console.log("ppcToggle ~ error:", error)
        }
    }

    return (
        <Row className="my-4">
            {error === true ? (
                <div className="text-center">
                    <div className='brandBox'>
                        <Skeleton count={1} height={35} />
                        <Skeleton count={1} />
                    </div>
                </div>
            ) : (
                isLoading ? <Loader /> : (
                    allBrandsData?.map((brandData) => {
                        const { brandLogoUrl, brandName, brandWebsite, ppcUserName, ppcUserAssingedOn, _id, ppcEnable } = brandData;
                        return (
                            <OverlayTrigger key={_id} placement="top"
                                overlay={
                                    <Popover id="popover-basic">
                                        <Popover.Header as="h3">Consultant Details</Popover.Header>
                                        <Popover.Body>
                                            {(ppcUserName === "" || ppcUserName === null) ? "No Consultant Assigned In This Brand" : `You have Assigned This Brand On ${ppcUserAssingedOn !== null ? (ppcUserAssingedOn.slice(0, 10).split("-").reverse().join("-") || "--") : ""}.`}
                                        </Popover.Body>
                                    </Popover>}>
                                <Col xxl={3} md={4} sm={6} className="position-relative">
                                    {ppcEnable === false ? <button title="Start PPC" onClick={() => ppcToggle(_id, true)} className="assigningBtn">Start PPC</button> : <button title="Stop PPC" onClick={() => ppcToggle(_id, false)} className="assigningBtn removeConsultant">Stop PPC</button>}
                                    <Link to={urlOf} state={{ brandWebsite, ppcUserAssingedOn, brandName }}>
                                        <div className='brandBox'>
                                            <img disabled={true} src={brandLogoUrl} alt="brandLogo" />
                                            <h4 className="brandName">{brandName}</h4>
                                        </div>
                                    </Link>
                                </Col>
                            </OverlayTrigger>
                        )
                    })
                )
            )}
        </Row>
    );
};
export default AssignedBrand; 