import { combineReducers } from 'redux';
import persistReducer from "redux-persist/es/persistReducer";
import storage from 'redux-persist/lib/storage';
import BrandBtnReducer from './BrandBtnReducer';
import EditDealModalReducer from './EditDealModalReducer';
import AuthReducer from './AuthReducer';
import ThemeReducer from './ThemeReducer';
import GraphReducer from './GraphReducer';

const persistConfig = {
    key: 'root',
    storage,
    // *** blacklist property allows to unpersist the state in localstorage while whitelist propery allws to persist the state in local storage.  *** //
    // blacklist: ['EditDealModalReducer'], 
}

const mainReducer = combineReducers({ AuthReducer, BrandBtnReducer, EditDealModalReducer, ThemeReducer, GraphReducer })

export default persistReducer(persistConfig, mainReducer);