import React, { useState } from 'react';
import GoBack from '../../../Components/GoBack';
import { Col, Row } from 'react-bootstrap';
import allMonths from "../../../helpers/months.json";
import Tittle from "../../../hook/Title";
import { createCustomerCardOnStripeRoute } from "../../../utils/APIRoutes";
import { useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import axios from "axios";
import LoadingSpinner from '../../../utils/LoadingSpinner';
import { useParams } from 'react-router-dom';

const AddCard = () => {
    const { custId, } = useParams()
    const exp_month = allMonths.months;
    Tittle("Add Card");
    const [border, setBorder] = useState("")
    let toastOptions = {
        position: "top-left",
        autoClose: 5000,
        pauseOnHover: false,
        draggable: false,
        theme: "dark",
    };
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);

    // ---- States of inputs in the form of key value pair name:value ------ // 
    const [values, setValues] = useState({
        number: "", exp_month: "", exp_year: "", cvc: ""
    });
    // ---- Getting values of inputs ------ //  
    const handleChange = (event) => {
        setValues({ ...values, [event.target.name]: event.target.value });
    };
    // ---- Validate Form ------ //
    const validateForm = () => {
        const {
            number,
            exp_month,
            exp_year,
            cvc,
        } = values;
        if (number === "" || exp_month === "" || exp_year === "" || cvc === "") {
            toast.error("Please Fill Required Fields ! ", toastOptions, toastOptions['position'] = "bottom-right");
            setBorder("redborder");
            setIsLoading(false);
            return false;
        } else if (number.length !== 16) {
            toast.error("Card Number should always be in 16 digits! ", toastOptions, toastOptions['position'] = "bottom-right");
            setIsLoading(false);
            return false;
        }
        else if (cvc.length !== 3) {
            toast.error("CVC should always be in 3 digits! ", toastOptions, toastOptions['position'] = "bottom-right");
            setIsLoading(false);
            return false;
        } else {
            return true;
        }
    }; 
    // ---- Form Submittion Function ------ // 
    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);
        if (validateForm()) {
            const {
                number,
                exp_month,
                exp_year,
                cvc,
            } = values;
            const { data } = await axios.post(`${createCustomerCardOnStripeRoute}?id=${custId}`, {
                number,
                exp_month,
                exp_year,
                cvc,
            });
            if (data.success === true) {
                setBorder("");
                toast.success(data.message, toastOptions, toastOptions['position'] = "top-right");
                setValues({
                    number: "", exp_month: "", exp_year: "", cvc: ""
                });
                setIsLoading(false);
                navigate(`/view-client/${custId}`);
            } else {
                toast.error(data.message, toastOptions, toastOptions['position'] = "bottom-right");
                setIsLoading(false);
            }
        }
    };
    // Getting current year and start loop for next 10 iterations: 
    const date = new Date();
    let year = date.getFullYear();
    let tenYears = [year]
    for (let i = 1; i <= 10; i++) {
        year = year + 1
        tenYears.push(year);
    }

    return (
        <div className="box my-3">
            <div className="d-flex align-items-center mb-2 flex-wrap my-3">
                <GoBack /><h4 className="primHeading mb-0">Add Card</h4>
            </div>
            <form onSubmit={handleSubmit}>
                <Row>
                    <Col xl={8} lg={10}>
                        <Row>
                            <Col md={6}>
                                <div className="my-2">
                                    <label className="text-bold" htmlFor="name">Card Number:</label>
                                    <input
                                        value={values.number}
                                        onChange={(e) => handleChange(e)}
                                        name='number' id='number' type="number" className={`${border} filterSelect`} placeholder='Enter Account Number' />
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="my-2">
                                    <label className="text-bold" htmlFor="country">Exp Month</label>
                                    <select
                                        className={`${border} filterSelect`}
                                        id="exp_month"
                                        name='exp_month'
                                        value={values.exp_month}
                                        onChange={(e) => handleChange(e)}>
                                        <option disabled={true}>Select Month</option>
                                        {Object.keys(exp_month).map((key, index) => {
                                            return (
                                                <option key={index} value={key}>{exp_month[key]}</option>
                                            );
                                        })}
                                    </select>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="my-2">
                                    <label className="text-bold" htmlFor="exp_year">Exp Year</label>
                                    <select
                                        className={`${border} filterSelect`}
                                        id="exp_year"
                                        name='exp_year'
                                        value={values.exp_year}
                                        onChange={(e) => handleChange(e)}>
                                        <option disabled={true}>Select Year</option>
                                        {tenYears?.map((exp_year, index) => {
                                            return (
                                                <option key={index} value={exp_year}>{exp_year}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="my-2">
                                    <label className="text-bold" htmlFor="cvc">cvc:</label>
                                    <input
                                        value={values.cvc}
                                        onChange={(e) => handleChange(e)}
                                        name='cvc' id='cvc' type="number" className={`${border} filterSelect`} placeholder='cvc' />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <button type="submit" className="main-btn my-3"> {isLoading ? <LoadingSpinner /> : "Submit"} </button>
            </form>
        </div>
    );
}
export default AddCard; 