import React, { useEffect, useRef, useState } from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { toast } from "react-toastify";
import currencyCode from "../../helpers/currencyCodes.json";
import countryCodes from "../../helpers/countryCodes.json";
import {
  createBrandRoute,
  availableTimeZonesRoute,
} from "../../utils/APIRoutes";
import axios from "axios";
import { FaEdit } from "react-icons/fa";
import { Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
const Index = () => {
  const navigate = useNavigate();
  const allbrandCurrencyCode = currencyCode.codes;
  const allbrandCountryCodes = countryCodes.codes;
  // ============= | Get Brand Time Zone | ============== //
  const [allbrandTimeZone, setAllBrandTimeZone] = useState([]);

  const getTimeZone = async () => {
    const response = await axios.get(availableTimeZonesRoute);
    if (response?.data?.success === true) {
      setAllBrandTimeZone(response?.data?.data);
    } else {
      setAllBrandTimeZone([]);
    }
  };

  useEffect(() => {
    getTimeZone();
  }, []);

  //============================================ ! Api inegration of Create Employee code !  ===============================================//
  let toastOptions = {
    position: "top-left",
    autoClose: 5000,
    pauseOnHover: false,
    draggable: false,
    theme: "dark",
  };
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const fileInput = useRef(null);
  const [imgUrl, setImgUrl] = useState("");
  const [brandLogo, setbrandLogo] = useState("");
  function handleImg(e) {
    setImgUrl(URL.createObjectURL(e.target.files[0]));
    setbrandLogo(e.target.files[0]);
  }
  // ---- States of inputs in the form of key value pair name:value ------ //
  let [values, setValues] = useState({
    brandName: "",
    brandWebsite: "",
    brandEmail: "",
    brandPhone: "",
    brandZipCode: "",
    brandAddress: "",
    brandCurrencyCode: "",
    brandCountryCode: "",
    brandTimeZone: "",
  });
  
  const {
    brandName,
    brandWebsite,
    brandEmail,
    brandPhone,
    brandCurrencyCode,
    brandCountryCode,
    brandZipCode,
    brandAddress,
    brandTimeZone,
  } = values;
  // ---- Getting values of inputs ------ //
  const handleInpChange = (event) => {
    setValues({ ...values, [event.target.name]: event.target.value });
  };
  const form = new FormData();
  let hasError = false;
  // ---- Validate Form ------ //
  const validateForm = () => {
    const newErrors = {};
    if (!brandName) {
      hasError = true;
      setIsLoading(false);
      newErrors.brandName = "Name is required !";
    }
    if (brandCurrencyCode === "") {
      hasError = true;
      setIsLoading(false);
      newErrors.brandCurrencyCode = "currency is required !";
    }
    if (brandCountryCode === "") {
      hasError = true;
      setIsLoading(false);
      newErrors.brandCountryCode = "country is required !";
    }
    if (!brandEmail) {
      hasError = true;
      setIsLoading(false);
      newErrors.brandEmail = "Email is required !";
    } else if (!/\S+@\S+\.\S+/.test(brandEmail)) {
      hasError = true;
      setIsLoading(false);
      newErrors.brandEmail = "Email is not valid !";
    }
    if (!brandWebsite) {
      hasError = true;
      setIsLoading(false);
      newErrors.brandWebsite = "Brand Website Link is required !";
    }
    if (!brandPhone) {
      hasError = true;
      setIsLoading(false);
      newErrors.brandPhone = "Phone Number is required !";
    }
    if (
      brandTimeZone === "" ||
      brandTimeZone === undefined ||
      brandTimeZone === null
    ) {
      hasError = true;
      setIsLoading(false);
      newErrors.brandTimeZone = "Brand Time Zone is required !";
    }
    // Bytes 1000000 not exceed != iimg should not be exceed to 1mb
    if (!brandLogo || brandLogo === undefined || brandLogo === "") {
      hasError = true;
      setIsLoading(false);
      newErrors.brandLogo = "Logo is required !";
    }
    if (brandLogo?.type === "image/png" || brandLogo?.type === "image/jpeg") {
      if (brandLogo.size > 1000000) {
        toast.error(
          "Logo size should not be exceed to 1mb Plz choose a smaller file",
          toastOptions,
          (toastOptions["position"] = "bottom-right")
        );
        hasError = true;
        setIsLoading(false);
        newErrors.brandLogo = "Logo size should not be exceed to 1mb !";
      }
    } else {
      newErrors.brandLogo = "only JPG, JPEG, PNG files are allowed !";
      hasError = true;
      setIsLoading(false);
    }
    setErrors(newErrors);
  };
  // ---- Form Submittion Function ------ //
  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    validateForm();
    if (hasError === false) {
      form.append("brandName", brandName.trim());
      form.append("brandWebsite", brandWebsite.trim());
      form.append("brandEmail", brandEmail.trim());
      form.append("brandPhone", brandPhone.trim());
      form.append("brandCountryCode", brandCountryCode.trim());
      form.append("brandCurrencyCode", brandCurrencyCode.trim());
      form.append("brandZipCode", brandZipCode.trim());
      form.append("brandAddress", brandAddress.trim());
      form.append("brandLogo", brandLogo);
      form.append("timeZone", brandTimeZone);
      const { data } = await axios.post(createBrandRoute, form, {
        headers: {
          "Content-Type": "application/json",
          "Content-type": "multipart/form-date",
        },
      });
      if (data.success === true) {
        setIsLoading(false);
        toast.success(
          data.msg,
          toastOptions,
          (toastOptions["position"] = "top-right")
        );
        setValues({
          brandName: "",
          brandWebsite: "",
          brandEmail: "",
          brandPhone: "",
          brandZipCode: "",
          brandAddress: "",
          brandCurrencyCode: "",
          brandCountryCode: "",
          brandTimeZone: "",
        });
        setbrandLogo("");
        setImgUrl("");
        navigate("/brands");
      } else {
        toast.error(
          data.msg,
          toastOptions,
          (toastOptions["position"] = "top-left")
        );
        setIsLoading(false);
      }
    } else {
      toast.error(
        "Kindly Fill the require fields !",
        toastOptions,
        (toastOptions["position"] = "top-right")
      );
    }
  };

  return (
    <>
      <form onSubmit={(event) => handleSubmit(event)}>
        <Row>
          <Col lg={10}>
            <p>Add New Brand From here</p>
            <Row>
              <Col lg={12} className="my-2">
                <label className="text-bold mb-1" htmlFor="brandName">
                  Name<span className="text-danger">*</span>
                </label>
                <input
                  value={brandName}
                  onChange={(e) => handleInpChange(e)}
                  name="brandName"
                  id="brandName"
                  type="text"
                  className="filterSelect ps-3"
                  placeholder="Enter Brand Name"
                />
                {errors.brandName && (
                  <p className="text-danger fw-500 fs-13 my-2 text-capitalize">
                    {errors.brandName}
                  </p>
                )}
              </Col>
              <Col lg={6} className="my-2">
                <label className="text-bold mb-1" htmlFor="brandWebsite">
                  Website<span className="text-danger">*</span>
                </label>
                <input
                  value={brandWebsite}
                  onChange={(e) => handleInpChange(e)}
                  name="brandWebsite"
                  id="brandWebsite"
                  type="url"
                  className="filterSelect ps-3"
                  placeholder="Enter Brand Website Link"
                />
                {errors.brandWebsite && (
                  <p className="text-danger fw-500 fs-13 my-2 text-capitalize">
                    {errors.brandWebsite}
                  </p>
                )}
              </Col>
              <Col lg={6} className="my-2">
                <label className="text-bold mb-1" htmlFor="brandEmail">
                  Email<span className="text-danger">*</span>
                </label>
                <input
                  value={brandEmail}
                  onChange={(e) => handleInpChange(e)}
                  name="brandEmail"
                  id="brandEmail"
                  type="text"
                  className="filterSelect ps-3"
                  placeholder="Enter Brand Email"
                />
                {errors.brandEmail && (
                  <p className="text-danger fw-500 fs-13 my-2 text-capitalize">
                    {errors.brandEmail}
                  </p>
                )}
              </Col>
              <Col lg={6} className="my-2">
                <label className="text-bold mb-1" htmlFor="brandPhone">
                  Phone<span className="text-danger">*</span>
                </label>
                <input
                  value={brandPhone}
                  onChange={(e) => handleInpChange(e)}
                  name="brandPhone"
                  id="brandPhone"
                  type="number"
                  className="filterSelect ps-3"
                  placeholder="Enter Phone Number"
                />
                {errors.brandPhone && (
                  <p className="text-danger fw-500 fs-13 my-2 text-capitalize">
                    {errors.brandPhone}
                  </p>
                )}
              </Col>
              <Col lg={6} className="my-2">
                <label className="text-bold mb-1" htmlFor="brandTimeZone">
                  Brand Time Zones
                </label>
                <select
                  className="filterSelect ps-3"
                  id="brandTimeZone"
                  name="brandTimeZone"
                  value={brandTimeZone}
                  onChange={(e) => handleInpChange(e)}
                >
                  <option value="">Select Option</option>
                  {allbrandTimeZone.length > 0
                    ? allbrandTimeZone?.map((timeZone, index) => {
                        return (
                          <option key={index} value={timeZone}>
                            {timeZone}
                          </option>
                        );
                      })
                    : ""}
                </select>
                {errors.brandTimeZone && (
                  <p className="text-danger fw-500 fs-13 my-2 text-capitalize">
                    {errors.brandTimeZone}
                  </p>
                )}
              </Col>
              <Col lg={6} className="my-2">
                <label className="text-bold mb-1" htmlFor="brandZipCode">
                  ZIP Code
                </label>
                <input
                  value={brandZipCode}
                  onChange={(e) => handleInpChange(e)}
                  name="brandZipCode"
                  id="brandZipCode"
                  type="number"
                  className="filterSelect ps-3"
                  placeholder="Enter ZIP Code"
                />
                {errors.brandZipCode && (
                  <p className="text-danger fw-500 fs-13 my-2 text-capitalize">
                    {errors.brandZipCode}
                  </p>
                )}
              </Col>
              <Col lg={6} className="my-2">
                <label className="text-bold mb-1" htmlFor="brandCurrencyCode">
                  Currency<span className="text-danger">*</span>
                </label>
                <select
                  className="filterSelect ps-3"
                  id="brandCurrencyCode"
                  name="brandCurrencyCode"
                  value={brandCurrencyCode}
                  onChange={(e) => handleInpChange(e)}
                >
                  <option value="">Select Option</option>
                  {Object.keys(allbrandCurrencyCode).map((objkey, index) => {
                    return (
                      <option key={index} value={objkey}>
                        {`${allbrandCurrencyCode[objkey]} (${objkey})`}{" "}
                      </option>
                    );
                  })}
                </select>
                {errors.brandCurrencyCode && (
                  <p className="text-danger fw-500 fs-13 my-2 text-capitalize">
                    {errors.brandCurrencyCode}
                  </p>
                )}
              </Col>
              <Col lg={6} className="my-2">
                <label className="text-bold mb-1" htmlFor="brandCountryCode">
                  Country<span className="text-danger">*</span>
                </label>
                <select
                  className="filterSelect ps-3"
                  id="brandCountryCode"
                  name="brandCountryCode"
                  value={brandCountryCode}
                  onChange={(e) => handleInpChange(e)}
                >
                  <option value="">Select Option</option>
                  {Object.keys(allbrandCountryCodes).map((objkey, index) => {
                    return (
                      <option key={index} value={objkey}>
                        {allbrandCountryCodes[objkey]}
                      </option>
                    );
                  })}
                </select>
                {errors.brandCountryCode && (
                  <p className="text-danger fw-500 fs-13 my-2 text-capitalize">
                    {errors.brandCountryCode}
                  </p>
                )}
              </Col>
              <Col lg={12} className="my-2">
                <label className="text-bold mb-1" htmlFor="brandAddress">
                  Address
                </label>
                <textarea
                  value={brandAddress}
                  onChange={(e) => handleInpChange(e)}
                  name="brandAddress"
                  id="brandAddress"
                  type="text"
                  className="filterSelect ps-3"
                  placeholder="Write you full address here"
                ></textarea>
                {errors.brandAddress && (
                  <p className="text-danger fw-500 fs-13 my-2 text-capitalize">
                    {errors.brandAddress}
                  </p>
                )}
              </Col>
              <Col lg={12} className="my-2">
                <label className="text-bold mb-1" htmlFor="brandLogoUrl">
                  Logo<span className="text-danger">*</span>
                </label>
                {imgUrl === "" ? (
                  <button
                    onClick={() => fileInput.current.click()}
                    className="uploadLogoIcon mb-2"
                    type="button"
                  >
                    Upload
                  </button>
                ) : (
                  <div className="uploadWrapper mb-4">
                    <img
                      src={imgUrl}
                      alt="service"
                      className="img-fluid w-100 servImg"
                      draggable={false}
                    />
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip>
                          <small>Change Logo!</small>
                        </Tooltip>
                      }
                    >
                      <button
                        onClick={() => fileInput.current.click()}
                        className="uploadFileBtn"
                        type="button"
                      >
                        <FaEdit />
                      </button>
                    </OverlayTrigger>
                  </div>
                )}
                <input
                  type="file"
                  ref={fileInput}
                  id="brandLogo"
                  className="d-none"
                  name="brandLogo"
                  onChange={handleImg}
                  accept="image/png , image/jpeg"
                />
                {errors.brandLogo && (
                  <p className="text-danger fw-500 fs-13 my-2 text-capitalize">
                    {errors.brandLogo}
                  </p>
                )}
              </Col>
            </Row>
            <button
              className="main-btn mt-5"
              disabled={isLoading}
              type="submit"
            >
              {" "}
              {isLoading ? "saving..." : "save"}{" "}
            </button>
          </Col>
        </Row>
      </form>
    </>
  );
};
export default Index;
