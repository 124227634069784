import React from "react";
import { Link } from "react-router-dom";
import { FaRegEnvelope, FaRegEdit } from "react-icons/fa";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";

const ProfileCard = () => {
  const AuthReducer = useSelector((state) => state.AuthReducer);
  const { email, employeeName, gender } = AuthReducer.data;
  return (
    <>
      <div className="profileCardWrap">
        <div className="position-relative userInfo">
          <img src={require(`../../assets/images/${gender === "female" ? "adminAvatarfemale.png" : "avatar.png"}`)} alt="user avatar" className="userAvatar" draggable={false} />
          <span className="activeDot"></span>
        </div>
        <h5>{employeeName || "--"}</h5>
        <h6>{email || "--"}</h6>
        <div className="d-flex align-items-center justify-content-center flex-wrap my-2">
          <Link to="#" className="smIconWrap">
            <FaRegEdit className="d-inline-block" />
          </Link>
          <Link to="#_" className="smIconWrap">
            <FaRegEnvelope className="d-inline-block" />
          </Link>
        </div>
        <Row className="border-top border-bottom  p-3">
          <Col>
            <h4 className="subHeading-sm">This Year</h4>
            <h6 className="colGreen">5</h6>
            <h4 className="subHeading-sm">Total Projects</h4>
          </Col>
          <Col className="text-center borderCenter">
            <h4 className="subHeading-sm">This Year</h4>
            <h6 className="colRed">25</h6>
            <h4 className="subHeading-sm">Total Projects</h4>
          </Col>
          <Col className="text-center">
            <h4 className="subHeading-sm">This Year</h4>
            <h6 className="colBlue">30</h6>
            <h4 className="subHeading-sm">Total Projects</h4>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ProfileCard;
