import clipboardCopy from 'clipboard-copy';
import { useState } from 'react';
const useClipboard = () => {
    const [copiedText, setCopiedText] = useState(null);
    const handleCopyClipboard = (text) => {
        clipboardCopy(text);
        setCopiedText(text);
        setTimeout(() => { setCopiedText(null) }, 2000);
    };
    return { handleCopyClipboard, copiedText };
};
export default useClipboard;