import React, { useState, useEffect } from 'react';
import Cards from 'react-credit-cards';
import currencySymbols from "../../helpers/currencySymbols.json";
import { Row, Col } from "react-bootstrap";
import { getAllCountries, getCountriesState, getCountriesCity, createAuthorizenetPaymentRoute } from "../../utils/APIRoutes";
import LoadingSpinner from "../../utils/LoadingSpinner";
import axios from "axios";
import { toast } from "react-toastify";
import 'react-credit-cards/es/styles-compiled.css';
import { useNavigate } from 'react-router-dom';

const AuthorizeMerchant = ({ subTotalAmount, currency, invObjId, trackingDetails, authorizeGateId }) => {
    const navigate = useNavigate();
    let toastOptions = {
        position: "top-left",
        autoClose: 5000,
        pauseOnHover: false,
        draggable: false,
        theme: "light",
    };
    const [number, setNumber] = useState('');
    const [name, setName] = useState('');
    const [expiry, setExpiry] = useState('');
    const [cvc, setCvc] = useState('');
    const [focused, setFocused] = useState('');
    const [loadingStateOption, setLoadingStateOption] = useState(false);
    const [loadingCityOption, setLoadingCityOption] = useState(false);
    const [cardError, setCardError] = useState("");

    const handleInputFocus = (e) => {
        setFocused(e.target.name);
    };
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === 'expiry') {
            const val = value.replace(/\D/g, '').slice(0, 4); // Remove non-numeric characters and limit to 4 characters
            if (val.length <= 2) {
                setExpiry(val);
            } else {
                const month = val.slice(0, 2);
                const year = val.slice(2, 4);
                setExpiry(`${month}/${year}`);
            }
        }
        // When Sending data to api
        // let value = "44/44";
        // const expiry = expiry.replace(/\D/g, '').slice(0, 4);
        // console.log("val", val)
        switch (name) {
            case 'number':
                if (cardType === "visa" || cardType === "mastercard" || cardType === "jcb" || cardType === "discover") {
                    if (value.length <= 16) {
                        setNumber(value);
                    }
                } else if (cardType === "amex") {
                    if (value.length <= 15) {
                        setNumber(value);
                    }
                } else if (cardType === "dinersclub") {
                    if (value.length <= 14) {
                        setNumber(value);
                    }
                } else {
                    setNumber(value);
                }
                break;
            case 'name':
                setName(value);
                break;
            case 'cvc':
                if (value.length <= 3) {
                    setCvc(value);
                }
                break;
            default:
                break;
        }
    };

    const [cardType, setCardType] = useState('');
    const [states, setStates] = useState([]);
    const [selectedState, setSelectedState] = useState("");
    const [cities, setCities] = useState([]);
    const [selectedCity, setSelectedCity] = useState("");
    const [email, setEmail] = useState("");
    const [zipCode, setZipCode] = useState("");
    const [fNname, setFName] = useState("");
    const [lNname, setLName] = useState("");
    const [streetAddress, setStreetAddress] = useState("");

    const handleCardCallback = (type) => {
        setCardType(type.issuer);
    };

    let currencyCodeSymbols = currencySymbols.codes;
    let currSymbol = ""

    try {
        Object.keys(currencyCodeSymbols).map((objkey) => {
            if (currency === objkey) {
                return (
                    currSymbol = currencyCodeSymbols[objkey]
                );
            }
        })
    } catch (error) {
        console.log(error);
        currSymbol = ""
    }

    const [countries, setCountries] = useState([]);
    const [selectedCountries, setSelectedCountries] = useState("");

    useEffect(() => {
        // Fetch the countries data from the API
        fetch(getAllCountries)
            .then((response) => response.json())
            .then((data) => {
                setCountries(data.data);
            }).catch((error) => {
                console.error("Error fetching countries data:", error);
            });
    }, []);

    const handleCountryChange = async (event) => {
        const country = event.target.value;
        setSelectedCountries(event.target.value);
        setLoadingStateOption(true)
        // Clear the states and cities data when the country changes
        setStates([]);
        setCities([]);
        // Fetch the states for the selected country
        if (country !== "") {
            const statesData = await axios.post(getCountriesState, { country })
            if (statesData.status === 200) {
                setStates(statesData.data.data.states)
            } else {
                setStates([])
            }
        }
    };

    const handleStateChange = async (event) => {
        const stateName = event.target.value;
        setSelectedState(stateName);
        setCities([]);
        setLoadingCityOption(true)
        // Fetch the cities for the selected state
        if (stateName !== "" || selectedCountries !== "") {
            const citiesData = await axios.post(getCountriesCity, {
                state: stateName, country: selectedCountries
            })
            if (citiesData.status === 200) {
                setCities(citiesData.data.data)
            } else {
                setCities([])
            }
        }
    };

    const handleCityChange = async (event) => {
        const cityName = event.target.value;
        setSelectedCity(cityName);
    };

    // Handle Authorize Submit
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState({});
    let hasError = false;
    const validateForm = () => {
        const newErrors = {};
        if (number === "" || number === undefined || number === null) {
            setIsLoading(false);
            hasError = true;
            newErrors.number = 'Account Number Is Required !';
        }
        if (name === "" || name === undefined || name === null) {
            setIsLoading(false);
            hasError = true;
            newErrors.name = 'Name Is Required !';
        }
        if (expiry === "" || expiry === undefined || expiry === null) {
            setIsLoading(false);
            hasError = true;
            newErrors.expiry = 'Expiry Date Is Required !';
        }
        if (cvc === "" || cvc === undefined || cvc === null) {
            setIsLoading(false);
            hasError = true;
            newErrors.cvc = 'CVC Is Required !';
        }
        if (cardType === "visa" || cardType === "mastercard" || cardType === "jcb" || cardType === "discover" || cardType === "amex" || cardType === "dinersclub") {
            setIsLoading(false);
            hasError = false
        } else {
            setIsLoading(false);
            hasError = true
            newErrors.cardsType = 'Card Type Must Be Visa, Mastercard, JCB, Discover, Amex, or Dinersclub';
        }
        if (selectedCountries === "" || selectedCountries === undefined || selectedCountries === null) {
            setIsLoading(false);
            hasError = true;
            newErrors.selectedCountries = 'Country Is Required !';
        }
        if (selectedState === "" || selectedState === undefined || selectedState === null) {
            setIsLoading(false);
            hasError = true;
            newErrors.selectedState = 'State Is Required !';
        }
        if (email === "" || email === undefined || email === null) {
            setIsLoading(false);
            hasError = true;
            newErrors.email = 'Email Is Required !';
        }
        if (fNname === "" || fNname === undefined || fNname === null) {
            setIsLoading(false);
            hasError = true;
            newErrors.fNname = 'First Name Is Required !';
        }
        if (lNname === "" || lNname === undefined || lNname === null) {
            setIsLoading(false);
            hasError = true;
            newErrors.lNname = 'Last Name Is Required !';
        }
        if (streetAddress === "" || streetAddress === undefined || streetAddress === null) {
            setIsLoading(false);
            hasError = true;
            newErrors.streetAdd = 'Street Address Is Required !';
        }
        if (zipCode === "" || zipCode === undefined || zipCode === null) {
            setIsLoading(false);
            hasError = true;
            newErrors.zipCode = 'ZIP Code Address Is Required !';
        }
        setErrors(newErrors);
    };

    const handleAuthorizeSubmit = async (e) => {
        e.preventDefault();
        validateForm();
        if (hasError === false) {
            setIsLoading(true);
            try {
                const { data } = await axios.post(`${createAuthorizenetPaymentRoute}?gatewayId=${authorizeGateId}`, {
                    invoiceObjectId: invObjId,
                    carison: number,
                    aspiron: expiry.replace(/\D/g, '').slice(0, 4),
                    avaca: cvc,
                    firstName: fNname,
                    lastName: lNname,
                    email,
                    streetAddress,
                    country: selectedCountries,
                    state: selectedState,
                    city: selectedCity,
                    zipCode: zipCode,
                    transactionTime: new Date,
                    trackingDetails
                });
                if (data.success === true) {
                    toast.success(data.msg, toastOptions, toastOptions['position'] = "top-right");
                    setIsLoading(false);
                    console.log("🚀 ~ file: AuthorizeMerchant.js:259 ~ handleAuthorizeSubmit ~ data:", data)
                    navigate(`/payment-completion/${invObjId}`)
                } else {
                    toast.error(data.msg, toastOptions, toastOptions['position'] = "top-right");
                    setIsLoading(false);
                    setCardError(data.msg);
                }
            } catch (error) {
                if (error.response.status === 500) {
                    toast.error("Something went wrong please try again later !", toastOptions, toastOptions['position'] = "top-right");
                    setIsLoading(false);
                }
            }
        }
    }
    return (
        <>
            <form onSubmit={handleAuthorizeSubmit}>
                <h4 className='my-2 mb-4'><b>Card Info</b></h4>
                <Cards
                    number={number}
                    name={name}
                    expiry={expiry}
                    cvc={cvc}
                    onChange={handleInputChange}
                    onFocus={handleInputFocus}
                    callback={handleCardCallback}
                    focused={focused}
                />
                <Row className='mt-4 text-start'>
                    <Col lg={6} className="my-2">
                        <label className="text-bold fs-13" htmlFor="cardNumber">Card Number:<span className="text-danger">*</span></label>
                        <input
                            type="number"
                            name="number"
                            id='cardNumber'
                            className='primary-inputs px-3'
                            placeholder="Enter Card Number"
                            value={number}
                            onChange={(e) => handleInputChange(e)}
                        />
                        {errors.number && <p className="text-danger fw-500 fs-13 my-2 text-capitalize">{errors.number}</p>}
                        {errors.cardsType && <p className="text-danger fw-500 fs-13 my-2 text-capitalize">{errors.cardsType}</p>}
                    </Col>
                    <Col lg={6} className="my-2">
                        <label className="text-bold fs-13" htmlFor="name">Enter Name:<span className="text-danger">*</span></label>
                        <input
                            type="text"
                            id='name'
                            name="name"
                            className='primary-inputs px-3'
                            placeholder="Enter Name"
                            onChange={(e) => handleInputChange(e)}
                        />
                        {errors.name && <p className="text-danger fw-500 fs-13 my-2 text-capitalize">{errors.name}</p>}
                    </Col>
                    <Col lg={6} className="my-2">
                        <label className="text-bold fs-13" htmlFor="expiry">Enter Expiration:<span className="text-danger">*</span></label>
                        <input
                            type="text"
                            name="expiry"
                            id='expiry'
                            className='primary-inputs px-3'
                            pattern="^\d{2}(\/|\s)?(\d{2}|\d{4})?$"
                            value={expiry}
                            placeholder="Card Expiry (mm/yy)"
                            maxLength={5}
                            onChange={(e) => handleInputChange(e)}
                        />
                        {errors.expiry && <p className="text-danger fw-500 fs-13 my-2 text-capitalize">{errors.expiry}</p>}
                    </Col>
                    <Col lg={6} className="my-2">
                        <label className="text-bold fs-13" htmlFor="cvc">Enter CVC:<span className="text-danger">*</span></label>
                        <input
                            type="tel"
                            name="cvc"
                            id='cvc'
                            value={cvc}
                            className='primary-inputs px-3 cvc'
                            placeholder="Card CVC"
                            onChange={(e) => handleInputChange(e)}
                        />
                        {errors.cvc && <p className="text-danger fw-500 fs-13 my-2 text-capitalize">{errors.cvc}</p>}
                    </Col>
                </Row>
                <h4 className='mt-4 mb-0'><b>Billing Info</b></h4>
                <Row className='text-start'>
                    <Col lg={6} className="my-2">
                        <label className="text-bold fs-13" htmlFor="firstName">First Name:<span className="text-danger">*</span></label>
                        <input
                            type="text"
                            name="firstName"
                            id='firstName'
                            className='primary-inputs px-3'
                            placeholder="Enter First Name"
                            onChange={(e) => setFName(e.target.value)}
                        />
                        {errors.fNname && <p className="text-danger fw-500 fs-13 my-2 text-capitalize">{errors.fNname}</p>}
                    </Col>
                    <Col lg={6} className="my-2">
                        <label className="text-bold fs-13" htmlFor="lastName">Last Name:<span className="text-danger">*</span></label>
                        <input
                            type="text"
                            name="lastName"
                            id='lastName'
                            className='primary-inputs px-3'
                            placeholder="Enter Last Name"
                            onChange={(e) => setLName(e.target.value)}
                        />
                        {errors.lNname && <p className="text-danger fw-500 fs-13 my-2 text-capitalize">{errors.lNname}</p>}
                    </Col>
                    <Col lg={6} className="my-2">
                        <label className="text-bold fs-13" htmlFor="email">Email:<span className="text-danger">*</span></label>
                        <input
                            type="email"
                            name="email"
                            id='email'
                            className='primary-inputs px-3'
                            placeholder="Enter Email"
                            onChange={(e) => setEmail(e.target.value)}
                            value={email}
                        />
                        {errors.email && <p className="text-danger fw-500 fs-13 my-2 text-capitalize">{errors.email}</p>}
                    </Col>
                    <Col lg={6} className="my-2">
                        <label className="text-bold fs-13" htmlFor="zipCode">Zip Code:<span className="text-danger">*</span></label>
                        <input
                            type="number"
                            name="zipCode"
                            id='zipCode'
                            className='primary-inputs px-3'
                            placeholder="Enter Zip Code"
                            onChange={(e) => setZipCode(e.target.value)}
                            value={zipCode}
                        />
                        {errors.zipCode && <p className="text-danger fw-500 fs-13 my-2 text-capitalize">{errors.zipCode}</p>}
                    </Col>
                    <Col lg={6} className="my-2">
                        <label className="text-bold fs-13" htmlFor="country">Select Country<span className="text-danger">*</span></label>
                        {
                            countries.length > 0 ? (
                                <select onChange={handleCountryChange} name="country" className='primary-inputs px-3' id="country">
                                    <option value="">Select Country</option>
                                    {
                                        countries.length > 0 ? countries?.map((countData, index) => {
                                            return (
                                                <option key={index} value={countData?.name}>{countData?.name}</option>
                                            )
                                        }) : ""
                                    }
                                </select>
                            ) : (
                                <div className="text-center">
                                    <LoadingSpinner />
                                </div>
                            )
                        }
                        {errors.country && <p className="text-danger fw-500 fs-13 my-2 text-capitalize">{errors.country}</p>}
                    </Col>
                    <Col lg={6} className="my-2">
                        <label className="text-bold fs-13" htmlFor="selectState">Select State<span className="text-danger">*</span></label>
                        <select onChange={handleStateChange} className='primary-inputs px-3' id="selectState">
                            <option value="">Select State</option>
                            {
                                states.length > 0 ? (
                                    states?.map((state, index) => {
                                        const { name } = state
                                        return (
                                            <option key={index} value={name}>{name}</option>
                                        )
                                    })
                                ) : (
                                    loadingStateOption === true ? <option value="">Loading....</option> : <option value="">Please Select Country First !</option>
                                )
                            }
                        </select>
                        {errors.selectedState && <p className="text-danger fw-500 fs-13 my-2 text-capitalize">{errors.selectedState}</p>}
                    </Col>
                    <Col lg={6} className="my-2">
                        <label className="text-bold fs-13" htmlFor="selectCity">Select City</label>
                        <select onChange={handleCityChange} className='primary-inputs px-3' id="selectCity">
                            <option value="">Select City</option>
                            {
                                cities.length > 0 ? (
                                    cities?.map((city, index) => {
                                        return (
                                            <option key={index} value={city}>{city}</option>
                                        )
                                    })
                                ) : (
                                    loadingCityOption === true ? <option value="">Loading....</option> : <option value="">Please Select State OR Country First !</option>
                                )
                            }
                        </select>
                        {errors.selectedCity && <p className="text-danger fw-500 fs-13 my-2 text-capitalize">{errors.selectedCity}</p>}
                    </Col>
                    <Col lg={12} className="my-2">
                        <label className="text-bold fs-13" htmlFor="streetAddress">Street Address:<span className="text-danger">*</span></label>
                        <textarea
                            type="text"
                            name="streetAddress"
                            id='streetAddress'
                            className='primary-inputs px-3'
                            placeholder="Enter Street Address"
                            rows={3}
                            onChange={(e) => setStreetAddress(e.target.value)}></textarea>
                        {errors.streetAdd && <p className="text-danger fw-500 fs-13 my-2 text-capitalize">{errors.streetAdd}</p>}
                    </Col>
                    <Col lg={12} className="text-center">
                        <button className='main-btn my-2 mt-4' disabled={isLoading} >{isLoading ? "submitting" : `Pay ${subTotalAmount} ${currSymbol}`}</button>
                        <p className="my-2 text-danger text-capitalize">{cardError}</p>
                    </Col>
                </Row>
            </form>
        </>
    )
}

export default AuthorizeMerchant 