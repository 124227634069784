import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { getHodDashboardDataRoute } from "../../utils/APIRoutes";
import axios from "axios";
import currencySymbolsList from "../../helpers/currencySymbols.json";
import Loader from "../../utils/Loader";
import BrandsIncome from "../../Components/Charts/BrandsIncome";
import PaymentActivity from "../../Components/Charts/PaymentActivityGraph";
import UnitIncome from "../../Components/Charts/UnitIncome";
import GrowthGraph from "../../Components/Charts/GrowthGraph";
import ProgressBar from "react-bootstrap/ProgressBar";
import { BsArrowUpRight, BsArrowDownLeft } from "react-icons/bs";
import { FaFileInvoiceDollar } from "react-icons/fa";
import { useSelector } from "react-redux";

export const Progresses = ({ value, label }) => {
  const progressBarStyle = {
    height: "15px", // Set the height to 20px
  };
  return (
    <div className="customProgress ">
      <ProgressBar style={progressBarStyle} label={label} now={value} />
    </div>
  );
};

const CompanyAnalytics = () => {
  const currencySymbols = currencySymbolsList.codes;
  const [filterLoader, setFilterLoader] = useState(false);

  const GraphReducer = useSelector((state) => state.GraphReducer);
  const {
    completeGrowthByMonthChart,
    paymentActivity,
    topSellBrands,
    unitWiseIncome,
    totInvReceivable,
  } = GraphReducer;

  // ! Filter By Date Code for table ! :
  // Getting by default month value !
  const currentDate = new Date(); // get current date
  const currentYear = currentDate.getFullYear(); // get current year
  const currentMonth = currentDate.getMonth() + 1; // get current month and add 1 to adjust for zero-based index
  const formattedMonth = currentMonth < 10 ? `0${currentMonth}` : currentMonth; // add leading zero if month is less than 10
  const monthStartDate = `${currentYear}-${formattedMonth}-01`; // format date as YYYY-MM-DD

  const [filterDates, setfilterDates] = useState({
    from: monthStartDate,
    to: "",
  });
  const { from, to } = filterDates;
  const handleDateChange = (e) => {
    setfilterDates({ ...filterDates, [e.target.name]: e.target.value });
  };
  const clearFilters = () => {
    setfilterDates({ from: "", to: "" });
    document.getElementById("MonthSelect").selectedIndex = 0;
    document.getElementById("YearSelect").selectedIndex = 0;
  };

  const [isLoading, setIsLoading] = useState(true);
  const [analytics, setAnalytics] = useState([]);
  const [invoicedAmount, setInvoicedAmount] = useState([]);
  const [invoicedAmountPaid, setInvoicedAmountPaid] = useState([]);
  const [topSellingBrands, setTopSellingBrands] = useState([]);
  const {
    totalLeadPercent,
    totalLead,
    barkLeads,
    ppcLeads,
    smmLeads,
    seoLeads,
    marketingLeads,
    totalInvoices,
    smmLeadsPercent,
    totalInvoicesPercent,
    marketingLeadsPercent,
    barkLeadsPercent,
    ppcLeadsPercent,
    seoLeadsPercent,
    totalInvoicesReceivable,
    totalCancelledInvoices,
    totalPaidInvoicesPercent,
    totalDraftInvoicesPercent,
    totalDraftInvoices,
    totalPaidInvoices,
  } = analytics;
  const getAnalytics = async () => {
    try {
      const analyticsdata = await axios.get(
        `${getHodDashboardDataRoute}?from=${from}&to=${to}`
      );
      if (analyticsdata.data.success === true) {
        setAnalytics(analyticsdata?.data?.data);
        setInvoicedAmount(analyticsdata?.data?.data?.invoicedAmount);
        setInvoicedAmountPaid(analyticsdata?.data?.data?.invoicedAmountPaid);
        setTopSellingBrands(analyticsdata?.data?.data?.topSellingBrands);
        setIsLoading(false);
        setFilterLoader(false);
      }
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAnalytics();
    if (from || to) {
      setFilterLoader(true);
    }
  }, [from, to]);

  const filteredInvoicedAmount = invoicedAmount.filter((item) => {
    const matchingPaidAmount = invoicedAmountPaid.find((paidItem) => {
      return paidItem._id === item._id;
    });
    return matchingPaidAmount !== undefined;
  });

  const paidTotal = filteredInvoicedAmount.map((item) => {
    const matchingPaidAmount = invoicedAmountPaid.find(
      (paidItem) => paidItem._id === item._id
    );
    const paid = matchingPaidAmount.totalAmount;
    const total = item.totalAmount;
    const percent = (paid / total) * 100;
    const currencyCode = item._id.toLowerCase();
    const currencySymbol = currencySymbols[currencyCode];
    return (
      <>
        <div className="box rounded-0 text-center mb-2 shadow-none p-10">
          <h6 className="subHeading text-uppercase my-2 text-start">
            Invoices In {currencyCode || ""}
          </h6>
          <Progresses
            label={`${parseFloat(percent.toFixed(2))}%`}
            value={parseFloat(percent.toFixed(2))}
          />
          <hr className="mb-0" />
          <div className="row my-0 mt-3">
            <div className="col-6 border-right">
              <p className="mb-0">Paid</p>
              <h6 className="colLateblueColor fw-600">
                {paid} {currencySymbol}
              </h6>
            </div>
            <div className="col-6">
              <p className="mb-0">Total</p>
              <h6 className="colLateblueColor fw-600">
                {total} {currencySymbol}
              </h6>
            </div>
          </div>
        </div>
      </>
    );
  });

  const [selectedYear, setSelectedYear] = useState("");

  const handleMonthChange = (e) => {
    const year = selectedYear !== "" ? selectedYear : new Date().getFullYear();
    const month = Number(e.target.value);
    // Getting First Date of the Month
    const firstDayOfMonth = new Date(year, month, 1);
    const formattedFirstDayOfMonth = formatDate(firstDayOfMonth);
    // Getting Last Date of the Month
    const lastDayOfMonth = new Date(year, month + 1, 0);
    const formattedLastDayOfMonth = formatDate(lastDayOfMonth);
    setfilterDates({
      from: formattedFirstDayOfMonth,
      to: formattedLastDayOfMonth,
    });
  };

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const cyear = new Date().getFullYear();
  const previousYears = [];

  for (let i = 0; i <= 9; i++) {
    previousYears.push(cyear - i);
  }

  const handleYearChange = (e) => {
    document.getElementById("MonthSelect").selectedIndex = 0;
    const selectedYear = Number(e.target.value);
    setSelectedYear(selectedYear);
    function getLastDayOfYear(selectedYear) {
      return new Date(selectedYear, 11, 31);
    }
    function getFirstDayOfYear(year) {
      return new Date(year, 0, 1);
    }
    // Get the First day of the selected year
    const firstDayOfYear = getFirstDayOfYear(selectedYear);
    const yearsFirstday = formatDate(firstDayOfYear);
    // Get the last day of the selected year
    const lastDayOfYear = getLastDayOfYear(selectedYear);
    const yearsLastday = formatDate(lastDayOfYear);
    setfilterDates({ from: yearsFirstday, to: yearsLastday });
  };
  const [yearForGraph, setYearForGraph] = useState(new Date().getFullYear());
  const [year2, setYearForGraph2] = useState(new Date().getFullYear());

  return (
    <>
      {isLoading === true ? (
        <Loader />
      ) : (
        <>
          {/*==========================|||****** Filters Start Here ********|||==========================*/}
          <Row className="customFilters mb-3">
            <Col xl={2} lg={4} md={6} sm={5} className="my-2">
              <label className="text-bold" htmlFor="From">
                From:
              </label>
              <input
                value={from}
                onChange={handleDateChange}
                className="prim-date border-0"
                type="date"
                name="from"
                id="From"
              />
            </Col>
            <Col xl={2} lg={4} md={6} sm={5} className="my-2">
              <label className="text-bold" htmlFor="To">
                To:
              </label>
              <input
                value={to}
                onChange={handleDateChange}
                className="prim-date border-0"
                type="date"
                name="to"
                id="To"
              />
            </Col>
            <Col xl={2} lg={4} md={6} sm={5} className="my-2">
              <label className="text-bold" htmlFor="MonthSelect">
                By Month:
              </label>
              <select
                className="primSelect prim-date white"
                id="MonthSelect"
                onChange={handleMonthChange}
              >
                <option value=" ">Select Month</option>
                <option value="0">January</option>
                <option value="1">February</option>
                <option value="2">March</option>
                <option value="3">April</option>
                <option value="4">May</option>
                <option value="5">June</option>
                <option value="6">July</option>
                <option value="7">August</option>
                <option value="8">September</option>
                <option value="9">October</option>
                <option value="10">November</option>
                <option value="11">December</option>
              </select>
            </Col>
            <Col xl={2} lg={4} md={6} sm={5} className="my-2">
              <label className="text-bold" htmlFor="YearSelect">
                By Year:
              </label>
              <select
                className="primSelect prim-date white"
                id="YearSelect"
                onChange={handleYearChange}
              >
                <option value=" ">Select Year</option>
                {previousYears.map((year, index) => {
                  return (
                    <option key={index} value={year}>
                      {year}
                    </option>
                  );
                })}
              </select>
            </Col>
            {filterLoader ? (
              <Col lg={2} md={6} sm={12} className="my-2 position-relative">
                <div className="preloaderWrapper">
                  <div className="preloaderMainWrapper">
                    <div className="smLoader">
                      <div className="smdot"></div>
                    </div>
                    <div className="smLoader">
                      <div className="smdot"></div>
                    </div>
                    <div className="loader">
                      <div className="smdot"></div>
                    </div>
                    <div className="smLoader">
                      <div className="smdot"></div>
                    </div>
                    <div className="smLoader">
                      <div className="smdot"></div>
                    </div>
                    <div className="smLoader">
                      <div className="smdot"></div>
                    </div>
                  </div>
                </div>
              </Col>
            ) : (
              <Col xl={2} lg={4} md={6} sm={2} className="my-2">
                <button
                  onClick={clearFilters}
                  title="Clear Date Filters"
                  className="main-btn clearFilter w-100 mt-4"
                >
                  Clear
                </button>
              </Col>
            )}
          </Row>
          <Row>
            <Col
              xxl={totInvReceivable ? 2 : 3}
              xl={6}
              sm={6}
              className="analyticsWrap1 mb-2 mx-auto"
            >
              <Link to="/leads" state={filterDates}>
                <div className="dashboard-anatlytics">
                  <div className="d-flex align-items-center gap-2">
                    <span className="smIcon">
                      <FaFileInvoiceDollar />
                    </span>
                    <h3 className="fs-14 fw-400 colGray mb-0">
                      Leads Generated
                    </h3>
                  </div>
                  <div className="min-130h">
                    <div className="text-center">
                      <h1 className="fw-600 mb-0 font-xl">{totalLead}</h1>
                      {totalLeadPercent === null ||
                      totalLeadPercent === undefined ? (
                        <small>Not Computed</small>
                      ) : (
                        <h4
                          className={`mb-0 ${
                            totalLeadPercent < 0 ? "text-danger" : ""
                          }`}
                        >
                          {" "}
                          {totalLeadPercent <= 0 ? (
                            <BsArrowDownLeft />
                          ) : (
                            <BsArrowUpRight />
                          )}{" "}
                          {totalLeadPercent} /{" "}
                          <small className="fs-13 text-capitalize colGray">
                            month
                          </small>{" "}
                        </h4>
                      )}
                    </div>
                  </div>
                </div>
              </Link>
            </Col>
            <Col
              xxl={totInvReceivable ? 2 : 3}
              xl={6}
              sm={6}
              className="analyticsWrap1 mb-2 mx-auto"
            >
              <Link to="/all-invoices" state={{ from, to, query: "" }}>
                <div className="dashboard-anatlytics">
                  <div className="d-flex align-items-center gap-2">
                    <span className="smIcon">
                      <FaFileInvoiceDollar />
                    </span>
                    <h3 className="fs-14 fw-400 colGray mb-0">
                      Invoices Generated
                    </h3>
                  </div>
                  <div className="min-130h">
                    <div className="text-center">
                      <h1 className="fw-600 mb-0 font-xl">{totalInvoices}</h1>
                      {totalInvoicesPercent === null ||
                      totalInvoicesPercent === undefined ? (
                        <small>Not Computed</small>
                      ) : (
                        <h4
                          className={`mb-0 ${
                            totalInvoicesPercent <= 0 ? "text-danger" : ""
                          }`}
                        >
                          {" "}
                          {totalInvoicesPercent <= 0 ? (
                            <BsArrowDownLeft />
                          ) : (
                            <BsArrowUpRight />
                          )}{" "}
                          {totalInvoicesPercent} /{" "}
                          <small className="fs-13 text-capitalize colGray">
                            month
                          </small>{" "}
                        </h4>
                      )}
                    </div>
                  </div>
                </div>
              </Link>
            </Col>
            <Col
              xxl={totInvReceivable ? 2 : 3}
              xl={6}
              sm={6}
              className="analyticsWrap1 mb-2 mx-auto"
            >
              <Link to="/all-invoices" state={{ from, to, query: "completed" }}>
                <div className="dashboard-anatlytics">
                  <div className="d-flex align-items-center gap-2">
                    <span className="smIcon">
                      <FaFileInvoiceDollar />
                    </span>
                    <h3 className="fs-14 fw-400 colGray mb-0">Paid Invoices</h3>
                  </div>
                  <div className="min-130h">
                    <div className="text-center">
                      <h1 className="fw-600 mb-0 font-xl">
                        {totalPaidInvoices}
                      </h1>
                      {totalPaidInvoicesPercent === null ||
                      totalPaidInvoicesPercent === undefined ? (
                        <small>Not Computed</small>
                      ) : (
                        <h4
                          className={`mb-0 ${
                            totalPaidInvoicesPercent <= 0 ? "text-danger" : ""
                          }`}
                        >
                          {" "}
                          {totalPaidInvoicesPercent <= 0 ? (
                            <BsArrowDownLeft />
                          ) : (
                            <BsArrowUpRight />
                          )}{" "}
                          {totalPaidInvoicesPercent} /{" "}
                          <small className="fs-13 text-capitalize colGray">
                            month
                          </small>{" "}
                        </h4>
                      )}
                    </div>
                  </div>
                </div>
              </Link>
            </Col>
            <Col
              xxl={totInvReceivable ? 2 : 3}
              xl={6}
              sm={6}
              className="analyticsWrap1 mb-2 mx-auto"
            >
              <Link to="/all-invoices" state={{ from, to, query: "draft" }}>
                <div className="dashboard-anatlytics">
                  <div className="d-flex align-items-center gap-2">
                    <span className="smIcon">
                      <FaFileInvoiceDollar />
                    </span>
                    <h3 className="fs-14 fw-400 colGray mb-0">
                      Pending Invoices
                    </h3>
                  </div>
                  <div className="min-130h">
                    <div className="text-center">
                      <h1 className="fw-600 mb-0 font-xl">
                        {totalDraftInvoices}
                      </h1>
                      {totalDraftInvoicesPercent === null ||
                      totalDraftInvoicesPercent === undefined ? (
                        <small>Not Computed</small>
                      ) : (
                        <h4
                          className={`mb-0 ${
                            totalDraftInvoicesPercent <= 0 ? "text-danger" : ""
                          }`}
                        >
                          {" "}
                          {totalDraftInvoicesPercent <= 0 ? (
                            <BsArrowDownLeft />
                          ) : (
                            <BsArrowUpRight />
                          )}{" "}
                          {totalDraftInvoicesPercent} /{" "}
                          <small className="fs-13 text-capitalize colGray">
                            month
                          </small>{" "}
                        </h4>
                      )}
                    </div>
                  </div>
                </div>
              </Link>
            </Col>
            {totInvReceivable && (
              <Col xxl={4} xl={6} className="analyticsWrap1 mb-2 mx-auto">
                <div className="dashboard-anatlytics h-100">
                  <h3 className="fs-14 fw-400 colGray mb-4">
                    Total Invoices Receivable
                  </h3>
                  <Progresses value={totalInvoicesReceivable} />
                  <p className="my-2">
                    Total Invoices Receivable:{" "}
                    <span className="colLateblueColor fw-600">
                      {totalInvoicesReceivable || ""}
                    </span>
                  </p>
                </div>
              </Col>
            )}
          </Row>
          <Row className="mt-3 justify-content-center">
            <Col xl={topSellBrands === true ? 6 : 12}>
              <Row>
                <Col lg={4} sm={6}>
                  <Link to="/leads" state={{ from, to, query: "marketing" }}>
                    <div className="dashboard-anatlytics mb-4">
                      <div className="min-130h">
                        <div className="text-center">
                          <h6 className="subHeading text-capitalize colGray">
                            Marketing Leads
                          </h6>
                          <h1 className="fw-400 mb-0 font-xl colLgGray">
                            {marketingLeads}
                          </h1>
                          {marketingLeadsPercent === null ||
                          marketingLeadsPercent === undefined ? (
                            <small>Not Computed</small>
                          ) : (
                            <h4
                              className={`mb-0 fs-18 ${
                                marketingLeadsPercent < 0 ? "text-danger" : ""
                              }`}
                            >
                              {" "}
                              {marketingLeadsPercent <= 0 ? (
                                <BsArrowDownLeft />
                              ) : (
                                <BsArrowUpRight />
                              )}{" "}
                              {marketingLeadsPercent} /{" "}
                              <small className="fs-13 text-capitalize colGray">
                                month
                              </small>{" "}
                            </h4>
                          )}
                        </div>
                      </div>
                    </div>
                  </Link>
                </Col>
                <Col lg={4} sm={6}>
                  <Link to="/leads" state={{ from, to, query: "bark" }}>
                    <div className="dashboard-anatlytics mb-4">
                      <div className="min-130h">
                        <div className="text-center">
                          <h6 className="subHeading text-capitalize colGray">
                            Bark Leads
                          </h6>
                          <h1 className="fw-400 mb-0 font-xl colLgGray">
                            {barkLeads}
                          </h1>
                          {barkLeadsPercent === null ||
                          barkLeadsPercent === undefined ? (
                            <small>Not Computed</small>
                          ) : (
                            <h4
                              className={`mb-0 fs-18 ${
                                barkLeadsPercent < 0 ? "text-danger" : ""
                              }`}
                            >
                              {" "}
                              {barkLeadsPercent <= 0 ? (
                                <BsArrowDownLeft />
                              ) : (
                                <BsArrowUpRight />
                              )}{" "}
                              {barkLeadsPercent} /{" "}
                              <small className="fs-13 text-capitalize colGray">
                                month
                              </small>{" "}
                            </h4>
                          )}
                        </div>
                      </div>
                    </div>
                  </Link>
                </Col>
                <Col lg={4} sm={6}>
                  <Link to="/leads" state={{ from, to, query: "ppc" }}>
                    <div className="dashboard-anatlytics mb-4">
                      <div className="min-130h">
                        <div className="text-center">
                          <h6 className="subHeading text-capitalize colGray">
                            PPC Leads
                          </h6>
                          <h1 className="fw-400 mb-0 font-xl colLgGray">
                            {ppcLeads}
                          </h1>
                          {ppcLeadsPercent === null ||
                          ppcLeadsPercent === undefined ? (
                            <small>Not Computed</small>
                          ) : (
                            <h4
                              className={`mb-0 fs-18 ${
                                ppcLeadsPercent < 0 ? "text-danger" : ""
                              }`}
                            >
                              {" "}
                              {ppcLeadsPercent <= 0 ? (
                                <BsArrowDownLeft />
                              ) : (
                                <BsArrowUpRight />
                              )}{" "}
                              {ppcLeadsPercent} /{" "}
                              <small className="fs-13 text-capitalize colGray">
                                month
                              </small>{" "}
                            </h4>
                          )}
                        </div>
                      </div>
                    </div>
                  </Link>
                </Col>
                <Col lg={4} sm={6}>
                  <Link to="/leads" state={{ from, to, query: "smm" }}>
                    <div className="dashboard-anatlytics mb-4">
                      <div className="min-130h">
                        <div className="text-center">
                          <h6 className="subHeading text-capitalize colGray">
                            SMM Leads
                          </h6>
                          <h1 className="fw-400 mb-0 font-xl colLgGray">
                            {smmLeads}
                          </h1>
                          {smmLeadsPercent === null ||
                          smmLeadsPercent === undefined ? (
                            <small>Not Computed</small>
                          ) : (
                            <h4
                              className={`mb-0 fs-18 ${
                                smmLeadsPercent < 0 ? "text-danger" : ""
                              }`}
                            >
                              {" "}
                              {smmLeadsPercent <= 0 ? (
                                <BsArrowDownLeft />
                              ) : (
                                <BsArrowUpRight />
                              )}{" "}
                              {smmLeadsPercent} /{" "}
                              <small className="fs-13 text-capitalize colGray">
                                month
                              </small>{" "}
                            </h4>
                          )}
                        </div>
                      </div>
                    </div>
                  </Link>
                </Col>
                <Col lg={4} sm={6}>
                  <Link to="/leads" state={{ from, to, query: "seo" }}>
                    <div className="dashboard-anatlytics mb-4">
                      <div className="min-130h">
                        <div className="text-center">
                          <h6 className="subHeading text-capitalize colGray">
                            SEO Leads
                          </h6>
                          <h1 className="fw-400 mb-0 font-xl colLgGray">
                            {seoLeads}
                          </h1>
                          {seoLeadsPercent === null ||
                          seoLeadsPercent === undefined ? (
                            <small>Not Computed</small>
                          ) : (
                            <h4
                              className={`mb-0 fs-18 ${
                                seoLeadsPercent < 0 ? "text-danger" : ""
                              }`}
                            >
                              {" "}
                              {seoLeadsPercent <= 0 ? (
                                <BsArrowDownLeft />
                              ) : (
                                <BsArrowUpRight />
                              )}{" "}
                              {seoLeadsPercent} /{" "}
                              <small className="fs-13 text-capitalize colGray">
                                month
                              </small>{" "}
                            </h4>
                          )}
                        </div>
                      </div>
                    </div>
                  </Link>
                </Col>
              </Row>
            </Col>
            {topSellBrands && (
              <Col xl={6}>
                <div className="box rounded-3">
                  <h6 className="subHeading text-uppercase">
                    Top Selling Brands{" "}
                  </h6>
                  <BrandsIncome topSellingBrands={topSellingBrands} />
                </div>
              </Col>
            )}
            <Row>
              {unitWiseIncome && (
                <Col lg={6}>
                  <div className="box rounded-3 my-2 h-100">
                    <h6 className="subHeading text-uppercase">
                      Unit Wise Income
                    </h6>
                    <div className="graphCenter">
                      <UnitIncome />
                    </div>
                  </div>
                </Col>
              )}
              <Col lg={unitWiseIncome === true ? 6 : 12}>
                {invoicedAmount.length === 0 ? (
                  <p className="text-center text-danger fw-600 mt-4">
                    No Record Found !
                  </p>
                ) : (
                  <Row className="my-4">
                    {paidTotal?.map((item, index) => (
                      <Col xl={unitWiseIncome === true ? 12 : 6} key={++index}>
                        {item}
                      </Col>
                    ))}
                  </Row>
                )}
              </Col>
            </Row>
            <Row className="mt-4">
              <Col
                xl={completeGrowthByMonthChart === false ? 6 : 2}
                lg={completeGrowthByMonthChart === false ? 12 : 4}
              >
                <Row>
                  <Col
                    lg={completeGrowthByMonthChart === false ? 6 : 12}
                    sm={6}
                  >
                    <Link
                      to="/all-invoices"
                      state={{ from, to, query: "draft" }}
                    >
                      <div className="dashboard-anatlytics mb-4">
                        <div className="min-130h">
                          <div className="text-center">
                            <h6 className="subHeading text-capitalize colGray">
                              Draft Invoices
                            </h6>
                            <h1 className="fw-400 mb-0 font-xl colLgGray">
                              {totalDraftInvoices}
                            </h1>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </Col>
                  <Col
                    lg={completeGrowthByMonthChart === false ? 6 : 12}
                    sm={6}
                  >
                    <Link
                      to="/all-invoices"
                      state={{ from, to, query: "cancelled" }}
                    >
                      <div className="dashboard-anatlytics mb-4">
                        <div className="min-130h">
                          <div className="text-center">
                            <h6 className="subHeading text-capitalize colGray">
                              Cancelled Invoices
                            </h6>
                            <h1 className="fw-400 mb-0 font-xl colLgGray">
                              {totalCancelledInvoices}
                            </h1>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </Col>
                </Row>
              </Col>
              {completeGrowthByMonthChart && (
                <Col xl={10} lg={8}>
                  <div className="box rounded-3">
                    <div className="d-flex align-items-center justify-content-between flex-wrap">
                      <h6 className="subHeading text-uppercase">
                        Complete Growth By Month Chart
                      </h6>
                      <div className="flex-shrink-0 ms-auto">
                        <select
                          className="primSelect prim-date white min-w-150"
                          onChange={(e) => setYearForGraph(e.target.value)}
                          id="YearSelect"
                        >
                          {previousYears.map((year, index) => {
                            return (
                              <option key={index} value={year}>
                                {year}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <GrowthGraph year={yearForGraph} />
                  </div>
                </Col>
              )}
            </Row>
            {paymentActivity && (
              <Col lg={12}>
                <div className="box rounded-3 my-3 ">
                  <div className="d-flex align-items-center justify-content-between flex-wrap">
                    <h6 className="subHeading text-uppercase">
                      Payment Activity
                    </h6>
                    <div className="flex-shrink-0 ms-auto">
                      <select
                        className="primSelect prim-date white min-w-150"
                        onChange={(e) => setYearForGraph2(e.target.value)}
                        id="YearSelect"
                      >
                        {previousYears.map((year, index) => {
                          return (
                            <option key={index} value={year}>
                              {year}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <PaymentActivity year={year2} />
                </div>
              </Col>
            )}
          </Row>
        </>
      )}
    </>
  );
};

export default CompanyAnalytics;
