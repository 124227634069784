import React, { useState, useEffect } from "react";
import Title from "../../../hook/Title";
import { TableCom } from "../../../Components/TableCom";
import { getAllLeadsByBrandWebsiteRoute } from "../../../utils/APIRoutes";
import { useLocation, useNavigate } from "react-router-dom";
import { FaRegEye, FaEllipsisV, FaTimes } from "react-icons/fa";
import CustomModal from "../../../Components/CustomModal";
import { Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import GoBack from "../../../Components/GoBack";
import Dropdown from "react-bootstrap/Dropdown";
import { toast } from "react-toastify";

const PpcLeadsOF = () => {
  Title("Brand Wise Leads Summary");
  const location = useLocation();
  const state = location.state;
  const { brandWebsite, ppcUserAssingedOn, brandName } = state;
  // ! Filter By Date Code for table ! :
  const [filterDates, setfilterDates] = useState({
    from: "",
    to: "",
  });
  const handleDateChange = (e) => {
    setfilterDates({ ...filterDates, [e.target.name]: e.target.value });
  };
  const clearFilters = () => {
    setfilterDates({ from: "", to: "" });
  };
  const { from, to } = filterDates;
  // Table Head Data
  const [thLength, setThlength] = useState(1);
  const [items, setItems] = useState([]);
  const [numPerPage, setNumPerPage] = useState(10);
  const [totalData, setTotalData] = useState(0);
  const [pageCount, setpageCount] = useState(0);
  const [currPage, setCurrPage] = useState(0);
  const [searchVal, setSearchVal] = useState("");
  const Thead = [
    "S.No",
    "name",
    "Email",
    "phone",
    "country",
    "created At",
    "Lead Status",
    "Action",
  ];
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  let toastOptions = {
    position: "top-left",
    autoClose: 5000,
    pauseOnHover: false,
    draggable: false,
    theme: "dark",
  };
  useEffect(
    (currPage) => {
      currPage = currPage ?? 0;
      const getData = async () => {
        const res = await fetch(
          `${getAllLeadsByBrandWebsiteRoute}?numPerPage=${numPerPage}&page=${currPage}&search=${searchVal}&from=${from}&to=${to}&brandWebsite=${brandWebsite}&ppcUserAssignedOn=${ppcUserAssingedOn}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (res.status === 401) {
          navigate("/");
          toast.error(
            "Session has been Expired",
            toastOptions,
            (toastOptions["position"] = "top-right")
          );
          return;
        }
        const data = await res.json();
        const total = data.pagiantion.dataCount;
        setTotalData(total);
        setpageCount(Math.ceil(total / numPerPage));
        setItems(data.result);
      };
      getData();
    },
    [numPerPage, searchVal, from, to]
  );
  useEffect(() => {
    setThlength(Thead.length);
  }, [thLength]);
  const fetchData = async (currPage) => {
    const res = await fetch(
      `${getAllLeadsByBrandWebsiteRoute}?numPerPage=${numPerPage}&page=${currPage}&search=${searchVal}&from=${from}&to=${to}&brandWebsite=${brandWebsite}&ppcUserAssignedOn=${ppcUserAssingedOn}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (res.status === 401) {
      navigate("/");
      toast.error(
        "Session has been Expired",
        toastOptions,
        (toastOptions["position"] = "top-right")
      );
      return;
    }
    const changedData = await res.json();
    const data = changedData.result;
    return data;
  };
  const handlePageClick = async (data) => {
    let currPage = data.selected;
    const myData = await fetchData(currPage);
    setItems(myData);
    setCurrPage(currPage);
  };
  const handleChange = (e) => {
    setNumPerPage(e.target.value);
  };
  // search data in api using search box
  const hangleSearchVal = (e) => {
    setSearchVal(e.target.value);
  };
  // Message Popup Show
  const [messageModal, setMessagModal] = useState(false);
  const [message, setMessag] = useState(false);
  const showMessage = (message) => {
    setMessagModal(true);
    setMessag(message);
  };

  const MessagePopUp = () => {
    return (
      <CustomModal
        show={messageModal}
        onHide={() => setMessagModal(false)}
        backdrop="static"
        keyboard="False"
        size="md"
      >
        <button onClick={() => setMessagModal(false)} className="modal-cancel">
          {" "}
          <FaTimes />{" "}
        </button>
        <div className="modalContent">
          <h3 className="fw-600 my-2">Message</h3>
          <div className="my-3">
            <p className="textOriginal">
              {message === null || message === "" || message === undefined
                ? "No Message Found..."
                : message}
            </p>
          </div>
          <button
            className="sec-btn m-2 mt-3"
            type="button"
            onClick={() => setMessagModal(false)}
          >
            {" "}
            Close{" "}
          </button>
        </div>
      </CustomModal>
    );
  };
  return (
    <>
      <div className="d-flex align-items-center my-2 mb-5">
        <GoBack />
        <h4 className="primHeading my-2">
          Leads Of{" "}
          <span>
            {brandName !== undefined || brandName !== null || brandName !== ""
              ? brandName
              : ""}
          </span>{" "}
        </h4>
      </div>
      <div className="box my-3">
        <h6 className="sectionHeading my-2 mb-4">
          {" "}
          {brandName !== undefined || brandName !== null || brandName !== ""
            ? brandName
            : ""}{" "}
          Leads Summary
        </h6>
        {/*==========================|||****** Filters Start Here ********|||==========================*/}
        <Row className="customFilters">
          <Col xl={2} lg={4} md={6} sm={5} className="my-2">
            <label className="text-bold" htmlFor="From">
              From:
            </label>
            <input
              value={from}
              onChange={handleDateChange}
              className="prim-date"
              type="date"
              name="from"
              id="From"
            />
          </Col>
          <Col xl={2} lg={4} md={6} sm={5} className="my-2">
            <label className="text-bold" htmlFor="To">
              To:
            </label>
            <input
              value={to}
              onChange={handleDateChange}
              className="prim-date"
              type="date"
              name="to"
              id="To"
            />
          </Col>
          <Col xl={2} lg={4} md={6} sm={2} className="my-2">
            <button
              onClick={clearFilters}
              title="Clear Date Filters"
              className="main-btn clearFilter w-100 mt-4"
            >
              Clear
            </button>
          </Col>
        </Row>
        <hr />
        <TableCom
          numPerPage={numPerPage}
          totalData={totalData}
          handleChange={handleChange}
          hangleSearchVal={hangleSearchVal}
          pageCount={pageCount}
          handlePageClick={handlePageClick}
          searcPlaceHolder="Search..."
        >
          <div className="customTable2 w-100 table-responsive">
            <table className="w-100">
              <thead>
                <tr>
                  {Thead?.map((th, index) => {
                    return <th key={index}>{th}</th>;
                  })}
                </tr>
              </thead>
              <tbody>
                {items == "" || items == undefined || items == null ? (
                  <tr>
                    <td colSpan={thLength}>
                      <p className="my-2 smText text-decoration-underline text-danger">
                        No Record Found
                      </p>
                    </td>
                  </tr>
                ) : (
                  items.map((item, index) => {
                    const {
                      phone,
                      country,
                      email,
                      name,
                      read,
                      _id,
                      createdAt,
                      leadStatus,
                      leadFollowUpDate,
                      message,
                    } = item;
                    return (
                      <tr
                        key={_id}
                        className={read === false ? "unreadtr" : ""}
                      >
                        <td>{++index}</td>
                        <td>
                          <span className="ellipsisContent">
                            {name || "--"}
                          </span>
                        </td>
                        <td>
                          <span className="ellipsisContent">
                            <input
                              type="password"
                              disabled
                              value={email || "--"}
                              style={{
                                border: 0,
                                textAlign: "center",
                                background: "transparent",
                              }}
                            />
                          </span>
                        </td>
                        <td>
                          <span className="ellipsisContent">
                            <input
                              type="password"
                              disabled
                              value={phone || "--"}
                              style={{
                                border: 0,
                                textAlign: "center",
                                background: "transparent",
                              }}
                            />
                          </span>
                        </td>
                        <td>{country || "--"}</td>
                        <td>{new Date(createdAt).toLocaleString()}</td>
                        <td>
                          {leadStatus ? (
                            leadStatus === "followup" ? (
                              <>
                                <span className="d-block">follow Up</span>
                                <span className="bedge colWhite rounded-5 bgSeaGreen fs-10">
                                  {leadFollowUpDate
                                    ?.slice(0, 10)
                                    .split("-")
                                    .reverse()
                                    .join("-") || "-"}
                                </span>
                              </>
                            ) : leadStatus === "won" ? (
                              <span className="bedge colWhite rounded-5 bgGreen fs-10">
                                {leadStatus}
                              </span>
                            ) : leadStatus === "lost" ? (
                              <span className="bedge colWhite rounded-5 bgRed fs-10">
                                {leadStatus}
                              </span>
                            ) : leadStatus === "archived" ? (
                              <span className="bedge colWhite rounded-5 bgRed fs-10">
                                {leadStatus}
                              </span>
                            ) : (
                              leadStatus
                            )
                          ) : (
                            "--"
                          )}
                        </td>
                        <td>
                          <Dropdown className="actionDropDown">
                            <Dropdown.Toggle className="customDropDown">
                              <FaEllipsisV className="colBlack" />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item
                                onClick={() => showMessage(message)}
                                className="drop_item"
                                type="button"
                              >
                                <FaRegEye className="me-1" />
                                <span>View Message</span>
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
        </TableCom>
      </div>
      {messageModal && <MessagePopUp />}
    </>
  );
};

export default PpcLeadsOF;
