import React, { useState, useEffect, useCallback, memo, useRef } from "react";
import Title from "../../../../hook/Title";
import { TableCom } from "../../../../Components/TableCom";
import currencycode from "../../../../helpers/currencyCodes.json";
import countrycodes from "../../../../helpers/countryCodes.json";
import Dropdown from "react-bootstrap/Dropdown";
import { Link, useNavigate } from "react-router-dom";
import {
  getAllLeadsByUnitRoute,
  updateLeadRoute,
  getLeadbyIdRoute,
  getBrandsByUnitIdRoute,
  getAllSaleEmpOfThisUnitRoute,
  exportCsvOfAllLeadsByUnitRoute,
  updateManyLeadsStatusByLeadsRoute,
  assignManyleadsToUnitSalePersonRoute,
  getAllSaleAgentsOfThisUnitRoute,
  addCommentUnitLeadsRoute,
  getCommentUnitLeadsRoute,
} from "../../../../utils/APIRoutes";
import { toast } from "react-toastify";
import axios from "axios";
import {
  FaRegEye,
  FaEllipsisV,
  FaEdit,
  FaTimes,
  FaBookmark,
} from "react-icons/fa";
import { FaRegCommentDots } from "react-icons/fa6";
import CustomModal from "../../../../Components/CustomModal";
import { Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useParams, useLocation } from "react-router-dom";
import GoBack from "../../../../Components/GoBack";
import useClipboard from "../../../../hook/useClipboard";
import { debounce } from "lodash";

const SingleUnitLeads = () => {
  Title("Unit Leads");

  const { handleCopyClipboard, copiedText } = useClipboard();

  // ! Filter By Date Code for table ! :
  const [filterDates, setfilterDates] = useState({
    from: new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      1
    ).toLocaleDateString("en-CA"), // Set from to the first day of the current month
    to: "",
  });

  const handleDateChange = (e) => {
    setfilterDates({ ...filterDates, [e.target.name]: e.target.value });
  };
  const clearFilters = () => {
    setfilterDates({ from: "", to: "" });
  };
  const { from, to } = filterDates;

  const { id } = useParams();
  const location = useLocation();
  const unitname = location.state;

  const AuthReducer = useSelector((state) => state.AuthReducer);
  const { designation, empId } = AuthReducer.data;
  // console.log(empId);
  const allcurrencyCode = currencycode.codes;
  const allCountryCodes = countrycodes.codes;

  const [servError, setServError] = useState(false);
  const [brandByUnit, setBrandByUnit] = useState([]);
  const [empByUnit, setEmpByUnit] = useState([]);
  const [empidByApi, setEmpidByApi] = useState("");
  const token = localStorage.getItem("token");
  const userId = localStorage.getItem("emp_id");
  const userName = localStorage.getItem("name");
  const navigate = useNavigate();
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedSalePerson, setSelectedSalePerson] = useState("");
  let toastOptions = {
    position: "top-left",
    autoClose: 5000,
    pauseOnHover: false,
    draggable: false,
    theme: "dark",
  };

  // fetch data by sale person
  useEffect(() => {
    const fetchDropdownOptions = async () => {
      try {
        const response = await fetch(
          `${getAllSaleAgentsOfThisUnitRoute}?unitId=${id}`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch dropdown options");
        }
        const data = await response.json();
        setDropdownOptions(data.data);
        console.log(data, "dropdownoptions");
      } catch (error) {
        console.error("Error fetching dropdown options:", error);
        toast.error(
          error?.message,
          toastOptions,
          (toastOptions["position"] = "top-left")
        );
        setServError(true);
      }
    };
    fetchDropdownOptions();
  }, []);

  //    Get Brands Name
  const getBrandsName = async () => {
    try {
      const resp = await fetch(`${getBrandsByUnitIdRoute}?unitId=${id}`);
      const resData = await resp.json();
      if (resData.success === true) {
        setBrandByUnit(resData.data);
        setServError(false);
      } else {
        throw new Error("Something went wrong.");
      }
    } catch (error) {
      setServError(true);
    }
  };

  const getEmpList = async () => {
    try {
      const resp = await fetch(`${getAllSaleEmpOfThisUnitRoute}?unitId=${id}`);
      const resData = await resp.json();
      if (resData.success === true) {
        setEmpByUnit(resData.data);
        setServError(false);
      } else {
        throw new Error("Something went wrong.");
      }
    } catch (error) {
      setServError(true);
    }
  };

  useEffect(() => {
    getBrandsName();
    getEmpList();
  }, []);

  // Table Head Data
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [organization, setOrganization] = useState("");
  const [phone, setPhone] = useState("");
  const [country, setCountry] = useState("");
  const [currencyCode, setCurrencyCode] = useState("");
  const [budget, setBudget] = useState("");
  const [industry, setIndustry] = useState("");
  const [source, setSource] = useState("");
  const [note, setNote] = useState("");
  const [message, setMessage] = useState("");
  const [city, setCity] = useState("-");
  const [state, setState] = useState("-");
  const [thLength, setThlength] = useState(1);
  const [items, setItems] = useState([]);
  const [numPerPage, setNumPerPage] = useState(10);
  const [totalData, setTotalData] = useState(0);
  const [pageCount, setpageCount] = useState(0);
  const [currPage, setCurrPage] = useState(0);
  const [searchVal, setSearchVal] = useState("");
  const [leadId, setLeadId] = useState(null);
  const [editableState, setEditableState] = useState(false);
  const [assignModalShow, setAssignModalShow] = useState(false);
  const [leadSource, setLeadSource] = useState("--");
  const [comments, setComments] = useState([]); // State to store comments
  const [newComment, setNewComment] = useState(""); // State for new comment input
  const [commentsLoader, setCommentsLoader] = useState(false);
  const [commentModalShow, setCommentModalShow] = useState(false);
  const commentsEndRef = useRef(null);
  const [commentsHeight, setCommentsHeight] = useState(0);
  const commentHeightRef = useRef(null);

  console.log(commentsHeight, "comments height check");

  const Thead = [
    "S.No",
    "Mark",
    "name",
    "Email",
    "phone",
    "Note",
    "Brand Name",
    "Lead Source",
    "country",
    "created At",
    "budget",
    "Product",
    "Assigned To",
    "Assigned On",
    "Lead Status",
    "Action",
  ];
  console.log(items);

  useEffect(
    (currPage) => {
      currPage = currPage ?? 0;
      const getData = async () => {
        const res = await fetch(
          `${getAllLeadsByUnitRoute}?unitId=${id}&numPerPage=${numPerPage}&page=${currPage}&search=${searchVal}&from=${from}&to=${to}&bySalePerson=${selectedSalePerson}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (res.status === 401) {
          navigate("/");
          toast.error(
            "Session has been Expired",
            toastOptions,
            (toastOptions["position"] = "top-right")
          );
          return;
        }
        const data = await res.json();
        const total = data.pagiantion.dataCount;
        setTotalData(total);
        setpageCount(Math.ceil(total / numPerPage));
        setItems(data.result);
      };
      getData();
    },
    [numPerPage, searchVal, from, to, selectedOption]
  );

  useEffect(() => {
    setThlength(Thead.length);
  }, [thLength]);

  console.log(thLength, "th length check");

  const fetchData = async (currPage) => {
    const res = await fetch(
      `${getAllLeadsByUnitRoute}?unitId=${id}&numPerPage=${numPerPage}&page=${currPage}&search=${searchVal}&from=${from}&to=${to}&bySalePerson=${selectedSalePerson}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (res.status === 401) {
      navigate("/");
      toast.error(
        "Session has been Expired",
        toastOptions,
        (toastOptions["position"] = "top-right")
      );
      return;
    }
    const changedData = await res.json();
    const data = changedData.result;
    return data;
  };

  const handlePageClick = async (data) => {
    let currPage = data.selected;
    const myData = await fetchData(currPage);
    setItems(myData);
    setCurrPage(currPage);
  };
  const handleChange = (e) => {
    setNumPerPage(e.target.value);
  };

  // Debounced search function
  const Search = useCallback(
    debounce((value) => {
      setSearchVal(value);
    }, 500), // 500ms debounce delay
    []
  );

  // Search input handler
  const hangleSearchVal = (e) => {
    Search(e.target.value);
  };

  const [editModalShow, setEditModalShow] = useState(false);
  //============================================ ! Api inegration of Create Employee code !  ===============================================//

  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  // ---- States of inputs in the form of key value pair name:value ------ //
  let hasError = false;
  // ---- Validate Form ------ //
  const validateForm = () => {
    const newErrors = {};
    if (name === "") {
      hasError = true;
      setIsLoading(false);
      newErrors.name = "Name is required !";
    }
    if (email === "") {
      hasError = true;
      setIsLoading(false);
      newErrors.email = "Email is required !";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      hasError = true;
      setIsLoading(false);
      newErrors.email = "Email is not valid !";
    }
    if (phone === "") {
      hasError = true;
      setIsLoading(false);
      newErrors.phone = "Phone Number is required !";
    }
    if (source === "") {
      hasError = true;
      setIsLoading(false);
      newErrors.source = "Brand Source is required !";
    }
    setErrors(newErrors);
  };
  // ---- Form Submittion Function ------ //
  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    validateForm();
    if (hasError === false) {
      const { data } = await axios.put(`${updateLeadRoute}?leadId=${leadId}`, {
        name,
        email,
        organization,
        phone,
        country,
        currencyCode,
        budget,
        industry,
        source,
        note,
        message,
        city,
        state,
        leadSource,
      });
      if (data.success === true) {
        setIsLoading(false);
        setEditModalShow(false);
        toast.success(
          data.msg,
          toastOptions,
          (toastOptions["position"] = "top-right")
        );
        // Reload Data
        const myData = await fetchData(currPage);
        setItems(myData);
      } else {
        toast.error(
          data.msg,
          toastOptions,
          (toastOptions["position"] = "top-left")
        );
        setIsLoading(false);
      }
    } else {
      toast.error(
        "Kindly Fill the require fields !",
        toastOptions,
        (toastOptions["position"] = "top-left")
      );
    }
  };

  const openEditLeadModal = async (_id) => {
    // Reload Data
    const myData = await fetchData(currPage);
    setItems(myData);
    setEditModalShow(true);
    setEditableState(false);
    setLeadId(_id);
    fetch(`${getLeadbyIdRoute}?leadId=${_id}`)
      .then((response) => response.json())
      .then(({ data: leadData }) => {
        setName(leadData.name);
        setEmail(leadData.email);
        setOrganization(leadData.organization);
        setPhone(leadData.phone);
        setCountry(leadData.country);
        setCurrencyCode(leadData.currencyCode);
        setBudget(leadData.budget);
        setIndustry(leadData.industry);
        setSource(leadData.source);
        setNote(leadData.note);
        setMessage(leadData.message);
        setLeadSource(leadData.leadSource);
      });
  };

  const openLeadModal = async (_id) => {
    // Reload Data
    const myData = await fetchData(currPage);
    setItems(myData);
    setEditModalShow(true);
    setEditableState(true);
    setLeadId(_id);
    fetch(`${getLeadbyIdRoute}?leadId=${_id}`)
      .then((response) => response.json())
      .then(({ data: leadData }) => {
        setName(leadData.name);
        setEmail(leadData.email);
        setOrganization(leadData.organization);
        setPhone(leadData.phone);
        setCountry(leadData.country);
        setCurrencyCode(leadData.currencyCode);
        setBudget(leadData.budget);
        setIndustry(leadData.industry);
        setSource(leadData.source);
        setNote(leadData.note);
        setMessage(leadData.message);
        setLeadSource(leadData.leadSource);
      });
  };

  // Function to fetch comments from API
  const fetchComments = async () => {
    // setCommentsLoader(true);
    try {
      const response = await axios.get(
        `${getCommentUnitLeadsRoute}?leadId=${leadId}`
      );
      if (response?.data?.success === true) {
        // setCommentsLoader(false);
        setComments(response?.data?.lead_comments);
      }
    } catch (error) {
      // setCommentsLoader(false);
      console.error("Error fetching comments:", error);
      if (error?.response?.data?.status === 401) {
        navigate("/");
      } else {
        toast.error(
          error?.response?.data?.msg,
          toastOptions,
          (toastOptions["position"] = "top-right")
        );
      }
    }
  };

  useEffect(() => {
    if (commentModalShow && leadId) {
      fetchComments();
    }
  }, [commentModalShow, leadId]);

  useEffect(() => {
    if (commentModalShow && comments.length > 0) {
      // Use setTimeout to ensure DOM updates before scrolling
      setTimeout(() => {
        if (commentHeightRef.current) {
          setCommentsHeight(commentHeightRef.current.clientHeight);
          if (commentsEndRef.current) {
            commentsEndRef.current.scrollIntoView({ behavior: "smooth" });
          }
        }
      }, 50);
    }
  }, [comments, commentModalShow]);

  const openCommentModal = (_id) => {
    setLeadId(_id);
    setCommentModalShow(true);
  };

  useEffect(() => {
    let intervalId;

    if (commentModalShow) {
      // Call fetchComments immediately when modal opens
      fetchComments();

      // Set up interval to fetch comments every 5 seconds
      intervalId = setInterval(() => {
        fetchComments();
      }, 10000); // 5000ms = 5 seconds
    }

    // Clear the interval when the modal is closed or component is unmounted
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [commentModalShow, leadId]); // run when the modal is shown or leadId changes

  // Function to handle comment submission
  const handleCommentSubmit = async () => {
    if (newComment.trim()) {
      try {
        const payload = {
          userId: userId,
          userName: userName,
          new_comment: newComment,
        };
        const response = await axios.post(
          `${addCommentUnitLeadsRoute}?leadId=${leadId}`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.data.success) {
          // Refresh comments
          fetchComments();
          setNewComment("");
          toast.success(response?.data?.msg);
        }
      } catch (error) {
        console.error("Error submitting comment:", error);
        if (error?.response?.data?.status === 401) {
          navigate("/");
        } else {
          toast.error(
            error?.response?.data?.msg,
            toastOptions,
            (toastOptions["position"] = "top-right")
          );
        }
      }
    }
  };

  const handleAssignForm = async (e) => {
    e.preventDefault();
    const { data } = await axios.put(
      `${assignManyleadsToUnitSalePersonRoute}?empId=${empidByApi}&assignedByEmpId=${empId}`,
      {
        leads: selectedIds,
      }
    );
    if (data.success === true) {
      toast.success(
        data.msg,
        toastOptions,
        (toastOptions["position"] = "top-right")
      );
      // Reload Data
      const myData = await fetchData(currPage);
      setItems(myData);
      setIsLoading(false);
      setAssignModalShow(false);
      setEmpidByApi("");
      setSelectedIds([]);
    } else {
      toast.error(
        data.msg,
        toastOptions,
        (toastOptions["position"] = "bottom-right")
      );
      // Reload Data
      const myData = await fetchData(currPage);
      setItems(myData);
      setIsLoading(false);
      setAssignModalShow(false);
      setEmpidByApi("");
    }
  };

  const [csvLoading, setCsvLoading] = useState(false);
  const exportAsCsv = async () => {
    setCsvLoading(true);
    try {
      const response = await axios.get(
        `${exportCsvOfAllLeadsByUnitRoute}?unitId=${id}&search=${searchVal}&from=${from}&to=${to}`,
        { responseType: "blob" }
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const a = document.createElement("a");
      a.href = url;
      setCsvLoading(false);
      a.download = "leads.csv";
      a.click();
    } catch (error) {
      setCsvLoading(false);
      console.log(error);
    }
  };

  //  Api Code for Multiple Lead Assigning
  const [selectedIds, setSelectedIds] = useState([]);
  const handleLeadAssignChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setSelectedIds((prevSelectedIds) => [...prevSelectedIds, value]);
    } else {
      setSelectedIds((prevSelectedIds) =>
        prevSelectedIds.filter((id) => id !== value)
      );
    }
  };

  //  Api Code for Lead Status Changes
  const [changeStatusModalShow, setChangeStatusModalShow] = useState(false);
  const [followUpModal, setFollowUpModal] = useState(false);
  const [followDate, setFollowDate] = useState("");

  const handleFollowUpDate = (e) => {
    e.preventDefault();
    if (followDate) {
      setIsLoading(true);
      var date = new Date(followDate);
      var isoDateString = date.toISOString();
      setFollowDate(isoDateString);
      setCurrPage((prevPage) => prevPage);
      changeLeadStatus("followup", followDate, currPage);
    } else {
      alert("Please Select Follow Up Date !");
    }
  };

  const handleLeadStatus = (e) => {
    const leadstatus = e.target.value;
    if (leadstatus) {
      if (leadstatus === "followup") {
        setFollowUpModal(true);
        setChangeStatusModalShow(false);
      } else {
        alert(`Lead status change to ${leadstatus}`);
        setCurrPage((prevPage) => prevPage);
        changeLeadStatus(leadstatus, "", currPage);
      }
    }
  };

  //  Api Code for Lead Status Changes
  const changeLeadStatus = async (leadstatus, followDate, currPage) => {
    const { data } = await axios.put(`${updateManyLeadsStatusByLeadsRoute}`, {
      leads: selectedIds,
      leadStatus: leadstatus,
      followupDate: followDate,
    });
    if (data.success === true) {
      toast.success(
        data.msg,
        toastOptions,
        (toastOptions["position"] = "top-right")
      );
      const myData = await fetchData(currPage);
      setItems(myData);
      setFollowUpModal(false);
      setFollowDate("");
      setIsLoading(false);
      setChangeStatusModalShow(false);
      setSelectedIds([]);
    } else {
      toast.error(
        data.msg,
        toastOptions,
        (toastOptions["position"] = "top-right")
      );
      setIsLoading(false);
      setChangeStatusModalShow(false);
    }
  };

  return (
    <>
      <div className="d-flex align-items-center justify-content-between flex-wrap mb-4">
        <div className="d-flex align-items-center my-2">
          <GoBack />
          <h4 className="primHeading mb-0">
            {unitname === ("" || undefined || null) ? "Unit" : unitname} Leads
          </h4>
        </div>
        {designation === "head_of_department" ? (
          ""
        ) : (
          <Link
            title="Create New Lead!"
            to={`/create-lead/${id}`}
            className="main-btn my-2"
          >
            Add Lead
          </Link>
        )}
      </div>
      <div className="box my-3">
        <div className="d-flex align-items-center justify-content-between flex-wrap">
          <h4 className="sectionHeading my-2 mb-4">
            {unitname === ("" || undefined || null) ? "Unit" : unitname} Leads
            Summary
          </h4>
          {/* <button title="Download As CSV" onClick={exportAsCsv} className="main-btn  my-2">{csvLoading ? "Downloading..." : "Download As CSV"}</button> */}
        </div>
        {/*==========================|||****** Filters Start Here ********|||==========================*/}
        <Row className="customFilters">
          <Col xl={2} lg={4} md={6} sm={5} className="my-2">
            <label className="text-bold" htmlFor="From">
              From:
            </label>
            <input
              value={from}
              onChange={handleDateChange}
              className="prim-date"
              type="date"
              name="from"
              id="From"
            />
          </Col>
          <Col xl={2} lg={4} md={6} sm={5} className="my-2">
            <label className="text-bold" htmlFor="To">
              To:
            </label>
            <input
              value={to}
              onChange={handleDateChange}
              className="prim-date"
              type="date"
              name="to"
              id="To"
            />
          </Col>
          <Col xl={2} lg={4} md={6} sm={2} className="my-2">
            <button
              onClick={clearFilters}
              title="Clear Date Filters"
              className="main-btn clearFilter w-100 mt-4"
            >
              Clear
            </button>
          </Col>
          <Col xl={2} lg={4} md={6} sm={4} className="my-2">
            <label className="text-bold" htmlFor="Dropdown">
              Options:
            </label>
            <Dropdown>
              <Dropdown.Toggle
                variant="success"
                id="dropdown-basic"
                className="sales-dropdown"
                title="Select Sale person"
              >
                {selectedOption || "Select an option"}
              </Dropdown.Toggle>

              <Dropdown.Menu className="scrollable-dropdown-menu">
                <Dropdown.Item
                  key=""
                  onClick={() => {
                    setSelectedOption("");
                    setSelectedSalePerson("");
                  }}
                >
                  All
                </Dropdown.Item>
                {dropdownOptions.map((option) => (
                  <Dropdown.Item
                    key={option.employeeName}
                    onClick={() => {
                      setSelectedOption(option.employeeName);
                      setSelectedSalePerson(option._id);
                    }}
                  >
                    {option.employeeName}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
        <div className="text-end">
          <div className="d-flex align-items-center justify-content-end gap-3 my-3">
            {selectedIds.length > 0 ? (
              <>
                <button
                  className="main-btn my-2"
                  onClick={() => setChangeStatusModalShow(true)}
                >
                  Change Lead Status
                </button>
                <button
                  className="main-btn my-2"
                  onClick={() => setAssignModalShow(true)}
                >
                  Assign Leads
                </button>
                <button
                  className="main-btn clearFilter my-2"
                  onClick={() => setSelectedIds([])}
                >
                  Remove All Select
                </button>
              </>
            ) : (
              ""
            )}
          </div>
        </div>
        <hr />
        <TableCom
          numPerPage={numPerPage}
          totalData={totalData}
          handleChange={handleChange}
          hangleSearchVal={hangleSearchVal}
          pageCount={pageCount}
          handlePageClick={handlePageClick}
          searcPlaceHolder="Search..."
        >
          <div className="customTable2 w-100 table-responsive">
            <table className="w-100">
              <thead>
                <tr>
                  {Thead?.map((th, index) => {
                    return <th key={index}>{th}</th>;
                  })}
                </tr>
              </thead>
              <tbody>
                {items == "" || items == undefined || items == null ? (
                  <tr>
                    <td colSpan={thLength}>
                      <p className="my-2 smText text-decoration-underline text-danger">
                        No Record Found
                      </p>
                    </td>
                  </tr>
                ) : (
                  items.map((item, index) => {
                    const {
                      budget,
                      phone,
                      country,
                      email,
                      industry,
                      name,
                      read,
                      _id,
                      createdAt,
                      source,
                      salesPerson,
                      leadSource,
                      leadStatus,
                      leadFollowUpDate,
                      assignedOn,
                      note,
                    } = item;
                    return (
                      <tr
                        key={_id}
                        className={read === false ? "unreadtr" : ""}
                        onDoubleClick={() => {
                          openLeadModal(_id);
                        }}
                        style={{ cursor: "pointer" }}
                        title="Double Click To Edit Details"
                      >
                        <td>{++index}</td>
                        <td>
                          <label className="checkBoxDiv">
                            <input
                              checked={selectedIds.includes(_id)}
                              type="checkbox"
                              value={_id}
                              onChange={handleLeadAssignChange}
                            />
                            <span className="primCheckmark"></span>
                          </label>
                        </td>
                        <td>
                          <span className="ellipsisContent">
                            {name || "--"}{" "}
                          </span>
                        </td>
                        <td>
                          <span className="ellipsisContent">
                            {email || "--"}
                          </span>
                        </td>
                        <td>
                          <a
                            className="sw-phone"
                            title="Call with Dialpad"
                            href={`dialpad://${phone}`}
                          >
                            {phone || "--"}
                          </a>
                        </td>
                        <td>{note || "--"}</td>
                        {/* <td onClick={() => handleCopyClipboard(phone)}><span className="cursor-pointer ellipsisContent">{copiedText === phone ? 'Copied' : phone}</span></td> */}
                        <td>
                          {brandByUnit?.map((brandByUnitData) => {
                            const { brandName, brandWebsite, _id } =
                              brandByUnitData;
                            if (brandWebsite === source) {
                              return (
                                <span key={_id} value={brandWebsite}>
                                  {brandName}
                                </span>
                              );
                            }
                          })}
                        </td>
                        <td>{leadSource || "--"}</td>
                        <td>{country || "--"}</td>

                        <td>{new Date(createdAt).toLocaleString() || "-"}</td>
                        {/* <td>{createdAt.slice(0, 10).split("-").reverse().join("-") || "-"} {createdAt.slice(11, 16) || "-"}</td> */}
                        <td>{budget || "--"}</td>
                        <td>
                          {" "}
                          <span className="ellipsisContent">
                            {industry || "--"}
                          </span>
                        </td>
                        <td>
                          {" "}
                          {salesPerson.length > 0
                            ? salesPerson[0]?.name
                            : "--"}{" "}
                        </td>
                        <td>
                          {assignedOn === null
                            ? "-"
                            : assignedOn
                                .slice(0, 10)
                                .split("-")
                                .reverse()
                                .join("-") || "-"}
                        </td>
                        <td className="text-capitalize">
                          {leadStatus === "followup" ? (
                            <span>
                              Follow Up <br />{" "}
                              {leadFollowUpDate
                                ?.slice(0, 10)
                                .split("-")
                                .reverse()
                                .join("-")}
                            </span>
                          ) : leadStatus === "" ? (
                            "--"
                          ) : (
                            leadStatus
                          )}
                        </td>
                        <td>
                          <Dropdown className="actionDropDown">
                            <Dropdown.Toggle className="customDropDown">
                              <FaEllipsisV className="colBlack" />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item
                                className="drop_item"
                                type="button"
                                onClick={() => {
                                  openLeadModal(_id);
                                }}
                              >
                                <FaRegEye className="me-1" />
                                <span>View</span>
                              </Dropdown.Item>
                              <Dropdown.Item
                                className="drop_item"
                                type="button"
                                onClick={() => {
                                  openEditLeadModal(_id);
                                }}
                              >
                                <FaEdit className="me-1" />
                                <span>Edit</span>
                              </Dropdown.Item>
                              <Dropdown.Item
                                className="drop_item"
                                type="button"
                                onClick={() => {
                                  openCommentModal(_id);
                                }}
                              >
                                <FaRegCommentDots className="me-1" />
                                <span>Comment</span>
                              </Dropdown.Item>
                              {/* <Dropdown.Item
                                                                                className="drop_item"
                                                                                type="button"
                                                                                onClick={() => handleLeadStatus("archived", _id)}>
                                                                                <FaBookmark className="me-1" />
                                                                                <span>Mark as archived</span>
                                                                           </Dropdown.Item> */}
                            </Dropdown.Menu>
                          </Dropdown>
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
        </TableCom>
      </div>
      {/*========================================== ! View --Or-- Edit Leads Modal code start here ! =======================================*/}
      <CustomModal
        show={editModalShow}
        onHide={() => setEditModalShow(false)}
        backdrop="static"
        keyboard="False"
        size="lg"
      >
        <button
          onClick={() => setEditModalShow(false)}
          className="modal-cancel"
        >
          <FaTimes />
        </button>
        <div className="modalContent">
          {/* modal text */}
          {servError === true ? (
            <p className="text-center my-2 fw-600 text-danger">
              Something went wrong Please try again later (Server Error 500)
            </p>
          ) : (
            <form onSubmit={(event) => handleSubmit(event)}>
              <div className="text-start">
                <div className="d-flex align-items-center justify-content-between flex-wrap mt-4">
                  <h3 className="fw-600 my-2">
                    {" "}
                    {editableState === false ? "Edit Lead" : `${name}`}{" "}
                  </h3>
                </div>
                <p>
                  {editableState === false
                    ? "Edit Lead From here"
                    : `View ${name} Details here`}{" "}
                </p>
                <Row>
                  <Col lg={6} className="my-2">
                    <label className="text-bold mb-1" htmlFor="name">
                      Name:<span className="text-danger">*</span>
                    </label>
                    <input
                      disabled={editableState}
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      name="name"
                      id="name"
                      type="text"
                      className="filterSelect ps-3"
                      placeholder="Enter Name"
                    />
                    {errors.name && (
                      <p className="text-danger fw-500 fs-13 my-2 text-capitalize">
                        {errors.name}
                      </p>
                    )}
                  </Col>
                  <Col lg={6} className="my-2">
                    <label className="text-bold mb-1" htmlFor="email">
                      Email:<span className="text-danger">*</span>
                    </label>
                    <input
                      disabled={editableState}
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      name="email"
                      id="email"
                      readOnly
                      type="password"
                      className="filterSelect ps-3"
                      placeholder="Enter Email"
                    />
                    {errors.email && (
                      <p className="text-danger fw-500 fs-13 my-2 text-capitalize">
                        {errors.email}
                      </p>
                    )}
                  </Col>
                  <Col lg={6} className="my-2">
                    <label className="text-bold mb-1" htmlFor="phone">
                      Phone:<span className="text-danger">*</span>
                    </label>
                    <input
                      disabled={editableState}
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      name="phone"
                      id="phone"
                      readOnly
                      type="password"
                      className="filterSelect ps-3"
                      placeholder="Enter Phone Number"
                    />
                    {errors.phone && (
                      <p className="text-danger fw-500 fs-13 my-2 text-capitalize">
                        {errors.phone}
                      </p>
                    )}
                  </Col>
                  <Col lg={6} className="my-2">
                    <label className="text-bold mb-1" htmlFor="leadSource">
                      Lead Source:
                    </label>
                    <select
                      className="filterSelect ps-3"
                      id="leadSource"
                      name="leadSource"
                      disabled={editableState}
                      value={leadSource}
                      onChange={(e) => setLeadSource(e.target.value)}
                    >
                      <option value="">Select Option</option>
                      <option value="marketting">Marketing</option>
                      <option value="ppc">PPC</option>
                      <option value="website">Website</option>
                      <option value="custom">Custom</option>
                    </select>
                  </Col>
                  <Col lg={6} className="my-2">
                    <label className="text-bold mb-1" htmlFor="organization">
                      organization:
                    </label>
                    <input
                      disabled={editableState}
                      value={organization}
                      onChange={(e) => setOrganization(e.target.value)}
                      name="organization"
                      id="organization"
                      type="text"
                      className="filterSelect ps-3"
                      placeholder="Enter Organization Name"
                    />
                  </Col>
                  <Col lg={6} className="my-2">
                    <label className="text-bold mb-1" htmlFor="currencyCode">
                      Currency:
                    </label>
                    <select
                      className="filterSelect ps-3"
                      id="currencyCode"
                      name="currencyCode"
                      disabled={editableState}
                      value={currencyCode}
                      onChange={(e) => setCurrencyCode(e.target.value)}
                    >
                      <option value="">Select Option</option>
                      {Object.keys(allcurrencyCode).map((objkey, index) => {
                        return (
                          <option key={index} value={objkey}>
                            {allcurrencyCode[objkey]}
                          </option>
                        );
                      })}
                    </select>
                  </Col>
                  <Col lg={6} className="my-2">
                    <label className="text-bold mb-1" htmlFor="country">
                      Country:
                    </label>
                    <select
                      className="filterSelect ps-3"
                      id="country"
                      name="country"
                      value={country}
                      disabled={editableState}
                      onChange={(e) => setCountry(e.target.value)}
                    >
                      <option value="">Select Option</option>
                      {Object.keys(allCountryCodes).map((objkey, index) => {
                        return (
                          <option key={index} value={allCountryCodes[objkey]}>
                            {allCountryCodes[objkey]}
                          </option>
                        );
                      })}
                    </select>
                  </Col>
                  <Col lg={6} className="my-2">
                    <label className="text-bold mb-1" htmlFor="city">
                      City:
                    </label>
                    <input
                      disabled={editableState}
                      value={city}
                      onChange={(e) => setCity(e.target.value)}
                      name="city"
                      id="city"
                      type="text"
                      className="filterSelect ps-3"
                      placeholder="Enter City"
                    />
                  </Col>
                  <Col lg={6} className="my-2">
                    <label className="text-bold mb-1" htmlFor="state">
                      State:
                    </label>
                    <input
                      disabled={editableState}
                      value={state}
                      onChange={(e) => setState(e.target.value)}
                      name="state"
                      id="state"
                      type="text"
                      className="filterSelect ps-3"
                      placeholder="Enter state"
                    />
                  </Col>
                  <Col lg={6} className="my-2">
                    <label className="text-bold mb-1" htmlFor="budget">
                      Budget:
                    </label>
                    <input
                      disabled={editableState}
                      value={budget}
                      onChange={(e) => setBudget(e.target.value)}
                      name="budget"
                      id="budget"
                      type="number"
                      className="filterSelect ps-3"
                      placeholder="Enter Budget"
                    />
                  </Col>
                  <Col lg={6} className="my-2">
                    <label className="text-bold mb-1" htmlFor="industry">
                      Product:
                    </label>
                    <input
                      disabled={editableState}
                      value={industry}
                      onChange={(e) => setIndustry(e.target.value)}
                      name="industry"
                      id="industry"
                      type="text"
                      className="filterSelect ps-3"
                      placeholder="Enter Industry Name"
                    />
                  </Col>
                  <Col lg={6} className="my-2">
                    <label className="text-bold mb-1" htmlFor="source">
                      Brand Source: <span className="text-danger">*</span>{" "}
                    </label>
                    <select
                      className="filterSelect ps-3"
                      name="source"
                      id="source"
                      value={source}
                      onChange={(e) => setSource(e.target.value)}
                      disabled={true}
                    >
                      <option value="">Select Option</option>
                      {brandByUnit?.map((brandByUnitData) => {
                        const { brandName, brandWebsite, _id } =
                          brandByUnitData;
                        return (
                          <option key={_id} value={brandWebsite}>
                            {brandName}
                          </option>
                        );
                      })}
                    </select>
                    {errors.source && (
                      <p className="text-danger fw-500 fs-13 my-2 text-capitalize">
                        {errors.source}
                      </p>
                    )}
                  </Col>
                  <Col lg={6} className="my-2">
                    <label className="text-bold mb-1" htmlFor="note">
                      note:
                    </label>
                    <input
                      disabled={editableState}
                      value={note}
                      onChange={(e) => setNote(e.target.value)}
                      name="note"
                      id="note"
                      type="text"
                      className="filterSelect ps-3"
                      placeholder="Enter Note"
                    />
                  </Col>
                  <Col lg={12} className="my-2">
                    <label className="text-bold mb-1" htmlFor="message">
                      message:
                    </label>
                    <textarea
                      rows="5"
                      disabled={editableState}
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      name="message"
                      id="message"
                      className="filterSelect ps-3"
                      placeholder="Enter Message..."
                    ></textarea>
                  </Col>
                </Row>
              </div>
              <div className="mt-3">
                {/* modal buttons */}
                {editableState === false ? (
                  <>
                    <button
                      className="main-btn m-2"
                      disabled={isLoading}
                      type="submit"
                    >
                      {" "}
                      {isLoading ? "saving..." : "save"}{" "}
                    </button>
                    <button
                      className="sec-btn m-2"
                      type="button"
                      onClick={() => setEditModalShow(false)}
                    >
                      Cancel
                    </button>
                  </>
                ) : (
                  ""
                )}
              </div>
            </form>
          )}
        </div>
      </CustomModal>
      {/*========================================== ! Assign Lead to Modal Show code start here ! =======================================*/}
      <CustomModal
        show={assignModalShow}
        onHide={() => setAssignModalShow(false)}
        backdrop="static"
        keyboard="False"
        size="md"
      >
        <button
          onClick={() => setAssignModalShow(false)}
          className="modal-cancel"
        >
          <FaTimes />
        </button>
        <div className="modalContent">
          {/* modal text */}
          <form onSubmit={(e) => handleAssignForm(e)}>
            <h3 className="modalPrimHeading my-2 mb-4">
              Assign Lead To Sales Employee{" "}
            </h3>
            <select
              className="filterSelect ps-3 mb-3"
              onChange={(e) => setEmpidByApi(e.target.value)}
            >
              <option value="">Select Option</option>
              {empByUnit?.length > 0
                ? empByUnit?.map((empList) => {
                    const { _id, employeeName, designation } = empList;
                    return (
                      <option
                        key={_id}
                        value={_id}
                      >{`${employeeName} (${designation})`}</option>
                    );
                  })
                : ""}
            </select>
            <button className="main-btn my-2" type="submit">
              Assign
            </button>
          </form>
        </div>
      </CustomModal>
      {/*========================================== follow Up Modal =======================================*/}
      <CustomModal
        show={followUpModal}
        onHide={() => {
          setFollowUpModal(false);
          setFollowDate("");
        }}
        backdrop="static"
        keyboard="False"
        size="sm"
      >
        <button
          onClick={() => {
            setFollowUpModal(false);
            setFollowDate("");
          }}
          className="modal-cancel"
        >
          <FaTimes />
        </button>
        <div className="modalContent">
          {/* modal text */}
          <form onSubmit={handleFollowUpDate}>
            <div className="text-start">
              <h5 className="fw-600 my-2 secHeading">Select Date</h5>
              <input
                onChange={(e) => setFollowDate(e.target.value)}
                name="date"
                id="date"
                type="date"
                className="filterSelect ps-3"
              />
              <div className="text-center">
                <button
                  className="main-btn mt-3"
                  disabled={isLoading}
                  type="submit"
                >
                  {isLoading ? "Setting..." : "Set"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </CustomModal>
      {/*========================================== ! Change Leads Status start here ! =======================================*/}
      <CustomModal
        show={changeStatusModalShow}
        onHide={() => setChangeStatusModalShow(false)}
        backdrop="static"
        keyboard="False"
        size="md"
      >
        <button
          onClick={() => setChangeStatusModalShow(false)}
          className="modal-cancel"
        >
          <FaTimes />
        </button>
        <div className="modalContent">
          <div className="my-4 text-start">
            <label htmlFor="leadStatusChange">Change Leads Status</label>
            <select
              id="leadStatusChange"
              onChange={handleLeadStatus}
              className="dashboard-inputs w-100"
            >
              <option value="">Change Status</option>
              <option value="dnc">Do Not Contact</option>
              <option value="not-interested">Not Interested</option>
              <option value="voicemail/unanswered">
                Voicemail / Unanswered
              </option>
              <option value="archived">Junk/Archived</option>
              <option value="duplicate">Duplicate</option>
              <option value="language-barrier">Language Barrier</option>
              <option value="followup">Follow Up</option>
            </select>
          </div>
        </div>
      </CustomModal>

      <CustomModal
        show={commentModalShow}
        onHide={() => {
          setCommentModalShow(false);
          setComments([]);
          setNewComment("");
        }}
        backdrop="static"
        keyboard="False"
        size="md"
      >
        <button
          onClick={() => {
            setCommentModalShow(false);
            setComments([]);
            setNewComment("");
          }}
          className="modal-cancel"
        >
          <FaTimes />
        </button>
        <div className="modalContent">
          <div className="my-4 text-start">
            <h4 className="fw-600 my-2">Comments</h4>
            {commentsLoader === true ? (
              <div className="d-flex justify-content-center">
                <div className="loader">
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              </div>
            ) : (
              <div
                ref={commentHeightRef}
                className="comments-list"
                style={{
                  maxHeight: "20em",
                  // overflowY: commentsHeight > 300 ? "scroll" : "",
                  overflowY: "scroll",
                  paddingRight: "3px",
                }}
              >
                {comments?.length > 0 ? (
                  comments?.map((comment, index) => (
                    <div
                      key={comment.id}
                      className="comment-item my-2 px-2 py-1 rounded-3"
                      style={{ background: "#C6D4FF" }}
                    >
                      <strong style={{ fontSize: "14px" }}>
                        {comment.userId === userId ? "You" : comment.userName}
                      </strong>
                      <p className="mb-0 " style={{ fontSize: "12px" }}>
                        {comment.comment}
                      </p>
                      <div className="d-flex justify-content-end">
                        <small
                          className="text-align-right"
                          style={{ fontSize: "11px" }}
                        >
                          {new Date(comment.created_at).toLocaleString()}
                        </small>
                      </div>
                    </div>
                  ))
                ) : (
                  <p style={{ fontSize: "14px" }}>No comments yet...</p>
                )}
                <div ref={commentsEndRef} />
              </div>
            )}
          </div>
          <div className="mt-4 text-align-left">
            <textarea
              rows="3"
              className="form-control filterSelect ps-3"
              placeholder="Add a comment..."
              value={newComment}
              onChange={(e) => setNewComment(e.target.value)}
              // onKeyDown={handleKeyPress}
              onKeyDown={(e) => e.key === "Enter" && handleCommentSubmit()}
            />
            <div className="d-flex justify-content-center">
              <button
                disabled={!newComment}
                className="main-btn mt-2 rounded-0 fs-6"
                onClick={handleCommentSubmit}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </CustomModal>
    </>
  );
};

export default SingleUnitLeads;
