import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import {
  getUnitByIdRoute,
  checkSalesPersonInUnitIdRoute,
  assignBrandToUnitRoute,
  assignUnitHeadToUnitRoute,
  assignUnitHeadToUnitFromOtherUnitRoute,
  getBrandListThatAreNotAssignedToUnitsRoute,
  getSalesEmpNotAssignedToUnitsRoute,
  addSalesEmployeeToUnitRoute,
  getUnitHeadsOfUnitRoute,
  removeUnitHeadFromUnitRoute,
  removeUnitHeadFromUnitAndUpdateDesignationRoute,
} from "../../../utils/APIRoutes";
import Title from "../../../hook/Title";
import GoBack from "../../../Components/GoBack";
import CustomModal from "../../../Components/CustomModal";
import Brandbox from "../../../Components/Brandbox";
import { toast } from "react-toastify";
import { FaTimes } from "react-icons/fa";
import axios from "axios";
import UnitEmployeeTable from "../../../Components/ScreenComponents/UnitEmployeeTable";
import LoadingSpinner from "../../../utils/LoadingSpinner";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

const ViewUnit = () => {
  const AuthReducer = useSelector((state) => state.AuthReducer);
  const { designation } = AuthReducer.data;
  const location = useLocation();
  const unitname = location.state;
  Title("Unit Details");
  let toastOptions = {
    position: "top-left",
    autoClose: 5000,
    pauseOnHover: false,
    draggable: false,
    theme: "dark",
  };
  const { unitId } = useParams();
  const [unitHeadModal, setUnitHeadModal] = useState(false);
  const [unitHeadOptModal, setUnitHeadOptModal] = useState(false);
  const [assignOtherUnitHeadModal, setAssignOtherUnitHeadModal] =
    useState(false);
  const [assignBrandModal, setAssignBrandModal] = useState(false);
  const [employeeToBrandModal, setEmployeeToBrandModal] = useState(false);
  const [unitData, setUnitData] = useState([]);
  const [unitBrands, setUnitBrands] = useState([]);
  const [uhName, setUHName] = useState([]);
  const [unassigneBrands, setUnassigneBrands] = useState([]);
  const [newEmpList, setNewEmpList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [empList, setEmpList] = useState([]);
  const [selectedHeadId, setSelectedHeadId] = useState("");
  const [selectedBrandId, setSelectedBrandId] = useState("");
  const [selectedEmpId, setSelectedEmpId] = useState("");
  const [otherUnitHeads, setOtherUnitHeads] = useState([]);
  const [uhDesignationModal, setUhDesignationModal] = useState(false);
  const [designationValue, setDesignationValue] = useState("");
  const [removeUheadModal, setRemoveUheadModal] = useState(false);
  const [rerender, setRerender] = useState(false);
  const { _id, name, createdAt, description, active, unitHead } = unitData;
  const token = localStorage.getItem("token");
  const navigate = useNavigate()

  const getUnitData = async () => {
    const response = await fetch(`${getUnitByIdRoute}?unitId=${unitId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status === 401) {
      navigate("/");
      toast.error(
        "Session has been Expired",
        toastOptions,
        (toastOptions["position"] = "top-right")
      );
      return;
    }
    const data = await response.json();
    setUnitBrands(data.brands);
    setUnitData(data.data);
    setUHName(data.data.unitHead);
  };
  useEffect(() => {
    getUnitData();
  }, []);
  // Asssign usit head to unit
  const assignUnitHead = async () => {
    setUnitHeadModal(true);
    setUnitHeadOptModal(false);
    const response = await fetch(
      `${checkSalesPersonInUnitIdRoute}?unitId=${unitId}&name=${name}`
    );
    const result = await response.json();
    setEmpList(result.data);
  };
  const assignUnitBrand = async () => {
    setAssignBrandModal(true);
    const response = await fetch(
      `${getBrandListThatAreNotAssignedToUnitsRoute}`
    );
    const result = await response.json();
    setUnassigneBrands(result.data);
  };
  const addEmpToUnit = async () => {
    setEmployeeToBrandModal(true);
    const response = await fetch(`${getSalesEmpNotAssignedToUnitsRoute}`);
    const result = await response.json();
    setNewEmpList(result.data);
  };
  const handleOtherUnitSubmit = async (event) => {
    event.preventDefault();
    if (selectedHeadId === "" || selectedHeadId === undefined) {
      toast.error(
        "Please Select Employee !",
        toastOptions,
        (toastOptions["position"] = "bottom-right")
      );
    } else {
      setIsLoading(true);
      const { data } = await axios.put(
        `${assignUnitHeadToUnitFromOtherUnitRoute}?unitId=${unitId}`,
        {
          empId: selectedHeadId,
        }
      );
      if (data.success === true) {
        setAssignOtherUnitHeadModal(false);
        setIsLoading(false);
        setUnitHeadModal(false);
        setSelectedHeadId("");
        setUnitHeadOptModal(false);
        getUnitData();
        toast.success(
          data.msg,
          toastOptions,
          (toastOptions["position"] = "top-right")
        );
      } else {
        setIsLoading(false);
        toast.error(
          data.msg,
          toastOptions,
          (toastOptions["position"] = "bottom-right")
        );
      }
    }
  };
  const handleThisUnitSubmit = async (event) => {
    event.preventDefault();
    if (selectedHeadId === "" || selectedHeadId === undefined) {
      toast.error(
        "Please Select Brand Name !",
        toastOptions,
        (toastOptions["position"] = "bottom-right")
      );
    } else {
      setIsLoading(true);
      const { data } = await axios.put(
        `${assignUnitHeadToUnitRoute}?unitId=${unitId}`,
        {
          empId: selectedHeadId,
        }
      );
      if (data.success === true) {
        setIsLoading(false);
        setUnitHeadModal(false);
        setSelectedHeadId("");
        setUnitHeadOptModal(false);
        getUnitData();
        toast.success(
          data.msg,
          toastOptions,
          (toastOptions["position"] = "top-right")
        );
      } else {
        setIsLoading(false);
        toast.error(
          data.msg,
          toastOptions,
          (toastOptions["position"] = "bottom-right")
        );
      }
    }
  };
  const handleRemoveUheadSubmit = async (event) => {
    event.preventDefault();
    const { data } = await axios.put(
      `${removeUnitHeadFromUnitRoute}?unitId=${unitId}&unitHeadId=${unitHead[0].id}`
    );
    if (data.success === true) {
      setIsLoading(false);
      setRemoveUheadModal(false);
      getUnitData();
      if (data.otherUnitExists === false) {
        setUhDesignationModal(true);
      }
      toast.success(
        data.msg,
        toastOptions,
        (toastOptions["position"] = "top-right")
      );
    } else {
      setIsLoading(false);
      toast.error(
        data.msg,
        toastOptions,
        (toastOptions["position"] = "bottom-right")
      );
    }
  };
  const handleSubmitBrand = async (event) => {
    event.preventDefault();
    if (selectedBrandId === "" || selectedBrandId === undefined) {
      toast.error(
        "Please Select Brand Name !",
        toastOptions,
        (toastOptions["position"] = "bottom-right")
      );
    } else {
      setIsLoading(true);
      const { data } = await axios.put(
        `${assignBrandToUnitRoute}?unitId=${unitId}&brandId=${selectedBrandId}`,
        {
          name,
        }
      );
      if (data.success === true) {
        setIsLoading(false);
        setAssignBrandModal(false);
        setSelectedBrandId("");
        getUnitData();
        toast.success(
          data.msg,
          toastOptions,
          (toastOptions["position"] = "top-right")
        );
      } else {
        setIsLoading(false);
        toast.error(
          data.msg,
          toastOptions,
          (toastOptions["position"] = "bottom-right")
        );
      }
    }
  };
  const handleSubmitEmployee = async (event) => {
    event.preventDefault();
    if (selectedEmpId === "" || selectedEmpId === undefined) {
      toast.error(
        "Please Select Employee Name !",
        toastOptions,
        (toastOptions["position"] = "bottom-right")
      );
    } else {
      setIsLoading(true);
      const { data } = await axios.put(
        `${addSalesEmployeeToUnitRoute}?empId=${selectedEmpId}&brandId=${selectedBrandId}`,
        {
          unitId: _id,
          unitName: name,
        }
      );
      if (data.success === true) {
        setEmployeeToBrandModal(false);
        setIsLoading(false);
        setSelectedEmpId("");
        getUnitData();
        setRerender(!rerender);
        toast.success(
          data.msg,
          toastOptions,
          (toastOptions["position"] = "top-right")
        );
      } else {
        setIsLoading(false);
        toast.error(
          data.msg,
          toastOptions,
          (toastOptions["position"] = "bottom-right")
        );
      }
    }
  };
  // Flow to change unit head !
  const changeUnitHead = () => {
    setUnitHeadOptModal(true);
  };
  const removeUnitHead = () => {
    setRemoveUheadModal(true);
  };
  const handleDesignationUheadSubmit = async (e) => {
    e.preventDefault();
    if (designationValue === "" || designationValue === undefined) {
      toast.error(
        "Please Select Designation !",
        toastOptions,
        (toastOptions["position"] = "bottom-right")
      );
    } else {
      try {
        setIsLoading(true);
        const { data } = await axios.put(
          `${removeUnitHeadFromUnitAndUpdateDesignationRoute}?unitId=${_id}&unitHeadId=${unitHead[0].id}`,
          {
            designation: designationValue,
          }
        );
        if (data.success === true) {
          toast.success(
            data.msg,
            toastOptions,
            (toastOptions["position"] = "top-right")
          );
          setUhDesignationModal(false);
          setIsLoading(false);
          getUnitData();
        } else {
          toast.error(
            data.msg,
            toastOptions,
            (toastOptions["position"] = "bottom-right")
          );
          setUhDesignationModal(false);
          setIsLoading(false);
        }
      } catch (error) {
        if (error.response.status === 500) {
          toast.error(
            "Something went wrong please try again later !",
            toastOptions,
            (toastOptions["position"] = "bottom-right")
          );
          setUhDesignationModal(false);
          setIsLoading(false);
        }
      }
    }
  };
  const getListOfAllUnitHeads = async () => {
    const response = await fetch(
      `${getUnitHeadsOfUnitRoute}?unitId=${_id}&name=${name}`
    );
    const result = await response.json();
    setOtherUnitHeads(result.data);
    setUnitHeadOptModal(false);
    setAssignOtherUnitHeadModal(true);
  };
  return (
    <>
      <div className="box rounded-1">
        <div className="d-flex align-items-center  justify-content-between flex-wrap">
          <div className="d-flex align-items-center my-2 mb-5">
            <GoBack />
            <h4 className="primHeading my-2">
              {unitname === ("" || undefined || null) ? "Unit" : unitname}{" "}
              <span>Details</span>
            </h4>
          </div>
          {designation === "head_of_department" ? (
            uhName.length > 0 ? (
              <button className="main-btn" onClick={removeUnitHead}>
                Remove Unit Head
              </button>
            ) : (
              <button className="main-btn" onClick={changeUnitHead}>
                Assign Unit Head
              </button>
            )
          ) : (
            ""
          )}
        </div>
        <Row>
          <Col xl={8} lg={10}>
            <Row>
              <Col lg={4}>
                <label htmlFor="">unit name</label>
                <p>{name || "-"}</p>
              </Col>
              <Col lg={4}>
                <label htmlFor="">unit Head</label>
                {uhName.length > 0 ? (
                  <p> {uhName[0].name}</p>
                ) : (
                  <p className="text-danger">Not Assigned</p>
                )}
              </Col>
              <Col lg={4}>
                <label htmlFor="">Status</label>
                {active === true ? (
                  <p className="colGreen">Active</p>
                ) : (
                  <p className="text-danger">Inactive</p>
                )}
              </Col>
              <Col lg={4}>
                <label htmlFor="">created At</label>
                <p>
                  {createdAt?.slice(0, 10).split("-").reverse().join("-") ||
                    "-"}
                </p>
              </Col>
              <Col lg={12}>
                <label htmlFor="">description</label>
                <p>{description || "-"}</p>
              </Col>
            </Row>
          </Col>
          <Col lg={12}>
            <div className="d-flex align-items-center  justify-content-between flex-wrap">
              <h4 className="primHeading my-2">
                {unitname === ("" || undefined || null) ? "Unit's" : unitname}{" "}
                <span>Brands</span>
              </h4>
              {designation === "head_of_department" ? (
                <button className="main-btn" onClick={assignUnitBrand}>
                  Assign Brand
                </button>
              ) : (
                ""
              )}
            </div>
          </Col>
          {unitBrands.length > 0 ? (
            unitBrands?.map((brandsData, index) => {
              const { brandLogoUrl, brandName, brandWebsite } = brandsData;
              return (
                <Col xl={2} lg={3} sm={6} key={index}>
                  <Brandbox
                    brandLogoUrl={brandLogoUrl}
                    brandName={brandName}
                    brandWebsite={brandWebsite}
                  />
                </Col>
              );
            })
          ) : (
            <p className="text-center fw-600 text-danger">Not Assigned yet</p>
          )}
          <Col lg={12} className="mt-4">
            <div className="d-flex align-items-center  justify-content-between flex-wrap">
              <h4 className="primHeading my-2">
                {unitname === ("" || undefined || null) ? "Unit" : unitname}{" "}
                <span>Employees</span>
              </h4>
              {designation === "head_of_department" ||
              designation === "unit_head" ? (
                <button className="main-btn" onClick={addEmpToUnit}>
                  Add Employee
                </button>
              ) : (
                ""
              )}
            </div>
            {name === undefined || _id === undefined ? (
              <LoadingSpinner />
            ) : (
              <UnitEmployeeTable
                rerender={rerender}
                unitName={name}
                unitId={_id}
              />
            )}
          </Col>
        </Row>
      </div>
      {/*================================ Assign Unit Head Option Modal status ========================= */}
      <CustomModal
        show={unitHeadOptModal}
        onHide={() => setUnitHeadOptModal(false)}
        backdrop="static"
        keyboard="False"
      >
        <button
          onClick={() => setUnitHeadOptModal(false)}
          className="modal-cancel"
        >
          <FaTimes />
        </button>
        <div className="modalContent">
          <h3 className="modalPrimHeading my-2">Select Unit Head From</h3>
          {/* modal buttons */}
          <button className="main-btn mx-2 my-4" onClick={assignUnitHead}>
            This Unit
          </button>
          <button
            className="main-btn mx-2 my-4"
            onClick={getListOfAllUnitHeads}
          >
            Other Units
          </button>
        </div>
      </CustomModal>
      {/*================================ Assign Unit Head Modal For Other Units status ========================= */}
      <CustomModal
        show={assignOtherUnitHeadModal}
        onHide={() => setAssignOtherUnitHeadModal(false)}
        backdrop="static"
        keyboard="False"
      >
        <button
          onClick={() => setAssignOtherUnitHeadModal(false)}
          className="modal-cancel"
        >
          <FaTimes />
        </button>
        <div className="modalContent">
          <h3 className="modalPrimHeading my-2">Select Unit Head for {name}</h3>
          {/* modal buttons */}
          <form onSubmit={(event) => handleOtherUnitSubmit(event)}>
            {otherUnitHeads.length > 0 ? (
              <>
                <select
                  className="dashboard-inputs w-100 my-2"
                  onChange={(e) => setSelectedHeadId(e.target.value)}
                >
                  <option value="">Select Option</option>
                  {otherUnitHeads?.map((uhData) => {
                    const { _id, employeeName, designation } = uhData;
                    return (
                      <option key={_id} value={_id}>
                        {`${employeeName} (Designation: ${designation} )`}
                      </option>
                    );
                  })}
                </select>
                <button
                  className="main-btn m-2"
                  type="submit"
                  disabled={isLoading}
                >
                  {isLoading === true ? "Assigning...." : "Assign"}
                </button>
              </>
            ) : (
              <p className="text-danger fw-500 my-3 text-capitalize">
                No Employee found in this unit !
              </p>
            )}
            <button
              className="sec-btn m-2"
              type="button"
              onClick={() => setAssignOtherUnitHeadModal(false)}
            >
              Cancel
            </button>
          </form>
        </div>
      </CustomModal>
      {/*================================ Remove Unit Head Modal ========================= */}
      <CustomModal
        show={removeUheadModal}
        onHide={() => setRemoveUheadModal(false)}
        backdrop="static"
        keyboard="False"
      >
        <button
          onClick={() => setRemoveUheadModal(false)}
          className="modal-cancel"
        >
          <FaTimes />
        </button>
        <div className="modalContent">
          <h3 className="modalPrimHeading my-4">
            Are you sure you want to remove this unit head <br /> from this unit
            ?
          </h3>
          {/* modal buttons */}
          <button className="main-btn mx-2" onClick={handleRemoveUheadSubmit}>
            Yes
          </button>
          <button
            className="sec-btn mx-2"
            onClick={() => setRemoveUheadModal(false)}
          >
            No
          </button>
        </div>
      </CustomModal>
      {/*================================ Change unit head designation Modal ========================= */}
      <CustomModal
        show={uhDesignationModal}
        onHide={() => setUhDesignationModal(false)}
        backdrop="static"
        keyboard="False"
      >
        <button
          onClick={() => setUhDesignationModal(false)}
          className="modal-cancel"
        >
          <FaTimes />
        </button>
        <div className="modalContent">
          <h3 className="modalPrimHeading my-4">
            Please select his designation with in this unit
          </h3>
          <form onSubmit={handleDesignationUheadSubmit}>
            <div className="registration-inp-box my-3 text-start">
              <label className="d-block" htmlFor="designation">
                {" "}
                Designation <span className="text-danger">*</span>
              </label>
              <select
                name="designation"
                id="designation"
                className="primary-inputs px-2"
                onChange={(e) => setDesignationValue(e.target.value)}
                value={designationValue}
              >
                <option value=""> Select Designation </option>
                <option value="intern">Intern</option>
                <option value="junior">Junior</option>
                <option value="mid">Mid</option>
                <option value="senior">Senior</option>
                <option value="manager">Manager</option>
              </select>
            </div>
            {/* modal buttons */}
            <button className="main-btn mx-2" type="submit">
              {isLoading === true ? "Submiting..." : "Submit"}
            </button>
          </form>
        </div>
      </CustomModal>
      {/*================================ Assign Unit Head For This units Modal status ========================= */}
      <CustomModal
        show={unitHeadModal}
        onHide={() => setUnitHeadModal(false)}
        backdrop="static"
        keyboard="False"
      >
        <button
          onClick={() => setUnitHeadModal(false)}
          className="modal-cancel"
        >
          <FaTimes />
        </button>
        <div className="modalContent">
          <h3 className="modalPrimHeading my-2">Assign Unit Head to {name} </h3>
          {/* modal buttons */}
          <form onSubmit={(event) => handleThisUnitSubmit(event)}>
            {empList.length > 0 ? (
              <>
                <select
                  className="dashboard-inputs w-100 my-2"
                  onChange={(e) => setSelectedHeadId(e.target.value)}
                >
                  <option value="">Select Option</option>
                  {empList?.map((empData) => {
                    const { _id, employeeName, designation } = empData;
                    return (
                      <option key={_id} value={_id}>
                        {`${employeeName} (Designation: ${designation} )`}
                      </option>
                    );
                  })}
                </select>
                <button
                  className="main-btn m-2"
                  type="submit"
                  disabled={isLoading}
                >
                  {isLoading === true ? "Assigning...." : "Assign"}
                </button>
              </>
            ) : (
              <p className="text-danger fw-500 my-3 text-capitalize">
                No Employee found in this unit !
              </p>
            )}
            <button
              className="sec-btn m-2"
              type="button"
              onClick={() => setUnitHeadModal(false)}
            >
              Cancel
            </button>
          </form>
        </div>
      </CustomModal>
      {/*================================ Assign Brand Modal status ========================= */}
      <CustomModal
        show={assignBrandModal}
        onHide={() => setAssignBrandModal(false)}
        backdrop="static"
        keyboard="False"
      >
        <button
          onClick={() => setAssignBrandModal(false)}
          className="modal-cancel"
        >
          <FaTimes />
        </button>
        <div className="modalContent">
          <h3 className="modalPrimHeading my-2">Assign Brands To {name} </h3>
          {/* modal buttons */}
          <form onSubmit={(event) => handleSubmitBrand(event)}>
            {unassigneBrands.length > 0 ? (
              <>
                <select
                  className="dashboard-inputs w-100 my-2"
                  value={selectedBrandId}
                  onChange={(e) => setSelectedBrandId(e.target.value)}
                >
                  <option value="">Select Option</option>
                  {unassigneBrands?.map((unAssignedBrandData) => {
                    const { _id, brandCountryCode, brandName } =
                      unAssignedBrandData;
                    return (
                      <option key={_id} value={_id}>
                        {" "}
                        {`${brandName} ( country code: ${brandCountryCode} )`}
                      </option>
                    );
                  })}
                </select>
                <button
                  className="main-btn m-2"
                  type="submit"
                  disabled={isLoading}
                >
                  {isLoading === true ? "Assigning...." : "Assign"}
                </button>
              </>
            ) : (
              <p className="text-danger fw-500 my-3 text-capitalize">
                No Brand Found !
              </p>
            )}
            <button
              className="sec-btn m-2"
              type="button"
              onClick={() => setAssignBrandModal(false)}
            >
              Cancel{" "}
            </button>
          </form>
        </div>
      </CustomModal>
      {/*================================ Add Employee Modal status ========================= */}
      <CustomModal
        show={employeeToBrandModal}
        onHide={() => setEmployeeToBrandModal(false)}
        backdrop="static"
        keyboard="False"
      >
        <button
          onClick={() => setEmployeeToBrandModal(false)}
          className="modal-cancel"
        >
          <FaTimes />
        </button>
        <div className="modalContent">
          <h3 className="modalPrimHeading my-2">Assign Employee To {name} </h3>
          {/* modal buttons */}
          <form onSubmit={(event) => handleSubmitEmployee(event)}>
            {newEmpList.length > 0 ? (
              <>
                <select
                  className="dashboard-inputs w-100 my-2"
                  onChange={(e) => setSelectedEmpId(e.target.value)}
                  value={selectedEmpId}
                >
                  <option value="">Select Option</option>
                  {newEmpList?.map((empList) => {
                    const { _id, employeeName, designation } = empList;
                    return (
                      <option key={_id} value={_id}>
                        {`${employeeName} ( Designation: ${designation} )`}
                      </option>
                    );
                  })}
                </select>

                <button
                  className="main-btn m-2"
                  type="submit"
                  disabled={isLoading}
                >
                  {isLoading === true ? "Assigning...." : "Assign"}
                </button>
              </>
            ) : (
              <p className="text-danger fw-500 my-3 text-capitalize">
                No Employee Found
              </p>
            )}
            <button
              className="sec-btn m-2"
              type="button"
              onClick={() => setEmployeeToBrandModal(false)}
            >
              Cancel{" "}
            </button>
          </form>
        </div>
      </CustomModal>
    </>
  );
};

export default ViewUnit;
