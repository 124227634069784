import React from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import Title from "../../hook/Title";
import { FaEdit } from "react-icons/fa";
import { useSelector } from 'react-redux';

const MyProfile = () => {
  Title("My Profile");
  const AuthReducer = useSelector((state) => state.AuthReducer);
  const { email, designation, department, cnic_passport, dateOfJoining, employeeName, active, gender } = AuthReducer.data;

  return (
    <>
      <div className="box">
        <div className="d-flex align-items-center justify-content-between flex-wrap mx-4 my-3">
          <h4 className="section-heading">My Profile</h4>
          <Link to="#" className="text-danger">
            <FaEdit /> Change Password
          </Link>
        </div>
        <Row>
          <Col xl={8} lg={10} className="mx-auto">
            <Row className="mt-5">
              <Col lg={12}>
                <div className="avatarWrap  mb-4">
                  <img src={require(`../../assets/images/${gender === "female" ? "adminAvatarfemale.png" : "avatar.png"}`)} alt="user avatar" className="profileAvatar" draggable={false} />
                </div>
              </Col>
              <Col md={6}>
                <div className="label-wrap mb-4">
                  <label htmlFor="employeeName" className="d-block"> First Name</label>
                  <input
                    id="employeeName"
                    className="primary-inputs disableInputs py-1 text-capitalize"
                    type="text"
                    name="employeeName"
                    placeholder="Enter Employee Name"
                    value={employeeName}
                    disabled={true}
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="label-wrap mb-4">
                  <label htmlFor="emailAddress" className="d-block">Email Address</label>
                  <input
                    id="emailAddress"
                    className="primary-inputs disableInputs py-1"
                    type="text"
                    placeholder="Enter Email Address"
                    value={email}
                    disabled={true}
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="label-wrap mb-4">
                  <label htmlFor="designation" className="d-block">Designation</label>
                  <input
                    id="designation"
                    className="primary-inputs disableInputs py-1 text-capitalize"
                    type="text"
                    placeholder="Enter Designation"
                    value={designation === "unit_head" ? "Unit Head" : designation === "head_of_department" ? "Head Of Department" : designation === "lead_generator" ? "Lead Generator" : designation || "--"}
                    disabled={true}
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="label-wrap mb-4">
                  <label htmlFor="department" className="d-block">Department</label>
                  <input
                    id="department"
                    className="primary-inputs disableInputs py-1 text-capitalize"
                    type="text"
                    placeholder="Enter Department"
                    value={department || "--"}
                    disabled={true}
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="label-wrap mb-4">
                  <label htmlFor="dateOfJoining" className="d-block">Date Of Joining :</label>
                  <p className="primary-inputs disableInputs py-1 text-capitalize"><span>{dateOfJoining?.slice(0, 10).split("-").reverse().join("-") || "--"}</span></p>
                </div>
              </Col>
              <Col md={6}>
                <div className="label-wrap mb-4">
                  <label htmlFor="cnic_passport" className="d-block">Cnic Passport</label>
                  <input
                    id="cnic_passport"
                    className="primary-inputs disableInputs py-1 text-capitalize"
                    type="number"
                    placeholder="Enter Cnic Passport"
                    value={cnic_passport || "--"}
                    disabled={true}
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="label-wrap mb-4">
                  <label htmlFor="cnic_passport" className="d-block">Status</label>
                  <p className="primary-inputs disableInputs py-1 text-capitalize">{active === true ? <span className="customBadge bgGreen">Active</span> : <span className="customBadge bgRed">InActive</span>}</p>
                </div>
              </Col>
            </Row>
            <Link to="#" className="main-btn d-inline-block">Edit</Link>
          </Col>
        </Row>
      </div>
    </>
  );
};
export default MyProfile;
