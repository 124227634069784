import React, { useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { FaCloudUploadAlt } from "react-icons/fa";
const EditorLayout = () => {
  const [editorValue, setEditorValue] = useState("");
  const [printValue, setprintValue] = useState("");
  const [projData, setprojData] = useState([]);
  const submitData = (e) => {
    e.preventDefault();
    setprintValue(editorValue);
  };
  // 👇️ initialize state to default checked radio button
  const [selected, setSelected] = useState("submit");
  // console.log(
  //   "🚀 ~ file: EditorLayout.js:13 ~ EditorLayout ~ selected",
  //   selected
  // );

  const handleChange = (e) => {
    // console.log(e.target.value);
    setSelected(e.target.value);
  };
  return (
    <>
      <div className="editor">
        {/* This div return html */}
        <Tabs
          defaultActiveKey="design"
          transition={true}
          id="noanim-tab-example"
          className="customTabs mb-3"
        >
          <Tab eventKey="design" title="Design">
            {printValue !== "" ? (
              printValue && (
                <div
                  className="viewableScreen box"
                  dangerouslySetInnerHTML={{ __html: printValue }}
                ></div>
              )
            ) : (
              <div className="text-center">
                <div className="viewableScreen box">
                  <p className="colGreen  text-decoration-underline text-capitalize text-bold mb-0">
                    No Conversation for this Project !
                  </p>
                </div>
              </div>
            )}
          </Tab>
          <Tab eventKey="test-link" title="Test Link">
            {printValue !== "" ? (
              printValue && (
                <div
                  className="viewableScreen box"
                  dangerouslySetInnerHTML={{ __html: printValue }}
                ></div>
              )
            ) : (
              <div className="text-center">
                <div className="viewableScreen box">
                  <p className="colGreen  text-decoration-underline text-capitalize text-bold mb-0">
                    No Conversation for this Project !
                  </p>
                </div>
              </div>
            )}
          </Tab>
          <Tab eventKey="customization" title="Customization">
            {printValue !== "" ? (
              printValue && (
                <div
                  className="viewableScreen box"
                  dangerouslySetInnerHTML={{ __html: printValue }}
                ></div>
              )
            ) : (
              <div className="text-center">
                <div className="viewableScreen box">
                  <p className="colGreen  text-decoration-underline text-capitalize text-bold mb-0">
                    No Conversation for this Project !
                  </p>
                </div>
              </div>
            )}
          </Tab>
          <Tab eventKey="data-entry" title="Data Entry">
            {printValue !== "" ? (
              printValue && (
                <div
                  className="viewableScreen box"
                  dangerouslySetInnerHTML={{ __html: printValue }}
                ></div>
              )
            ) : (
              <div className="text-center">
                <div className="viewableScreen box">
                  <p className="colGreen  text-decoration-underline text-capitalize text-bold mb-0">
                    No Conversation for this Project !
                  </p>
                </div>
              </div>
            )}
          </Tab>
          <Tab eventKey="go-live" title="Go Live">
            {printValue !== "" ? (
              printValue && (
                <div
                  className="viewableScreen box"
                  dangerouslySetInnerHTML={{ __html: printValue }}
                ></div>
              )
            ) : (
              <div className="text-center">
                <div className="viewableScreen box">
                  <p className="colGreen  text-decoration-underline text-capitalize text-bold mb-0">
                    No Conversation for this Project !
                  </p>
                </div>
              </div>
            )}
          </Tab>
        </Tabs>
        <Editor
          init={{
            height: 250,
            menubar: true,
            plugins: ["lists", "code", "link", "codesample"],
            toolbar:
              "undo redo | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | help",
          }}
          value={editorValue}
          onEditorChange={(newText) => setEditorValue(newText)}
        />
        <div className="text-end">
          <button className="main-btn uploadBtn">
            <input type="file" className="d-none" name="select file" id="" />
            <FaCloudUploadAlt />
          </button>
        </div>
        <label className="radioWrap my-3">
          <input
            type="radio"
            name="radio"
            value="submit"
            checked={selected === "submit"}
            onChange={handleChange}
          />
          <span className="chkmark"></span>
          Submit Task
        </label>
        <label className="radioWrap my-3">
          <input
            type="radio"
            name="radio"
            value="comment"
            checked={selected === "comment"}
            onChange={handleChange}
          />
          <span className="chkmark"></span>
          Post As Comment
        </label>
        <button className="main-btn my-3" onClick={submitData}>
          Submit Task
        </button>
      </div>
    </>
  );
};

export default EditorLayout;
