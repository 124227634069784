import React from 'react';
import Title from '../../hook/Title';
import { Col, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Congratulate from '../Congratulate';
const PaypalPayCompletion = () => {
     Title("Congratulations Payment Done 🎉");
     const { invoiceObjectId } = useParams();
     return (
          <Row>
               <Col md={8} sm={10} className="mx-auto my-5 px-0">
                    <div className='box text-center rounded-1 mx-3 py-5'>
                         <Congratulate />
                         <Link className="main-btn" to={`/invoice/${invoiceObjectId}`}>View Paid Invoice</Link>
                    </div>
               </Col>
          </Row>
     );
}

export default PaypalPayCompletion;
