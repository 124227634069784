import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend
} from "recharts";



const CommissionPayablePerSaleperson = ({ topSellingBrands }) => {
    const data = [
        {
            name: "Ghuffran Ali",
            uv: 4000,
            Commission: 2400,
            amt: 2400
          },
          {
            name: "Muhammad Faraz",
            uv: 3000,
            Commission: 1398,
            amt: 2210
          },
          {
            name: "Zohaib",
            uv: 2000,
            Commission: 9800,
            amt: 2290
          },
          {
            name: "Noah",
            uv: 2780,
            Commission: 3908,
            amt: 2000
          },
          {
            name: "Mohsin Alam",
            uv: 1890,
            Commission: 4800,
            amt: 2181
          },
          {
            name: "Naveed Ramzan",
            uv: 2390,
            Commission: 3800,
            amt: 2500
          },
          {
            name: "Eyraj Sohail",
            uv: 3490,
            Commission: 4300,
            amt: 2100
          },
          {
            name: "sohaib",
            uv: 1890,
            Commission: 4800,
            amt: 2181
          },
          {
            name: "mehar",
            uv: 2390,
            Commission: 3800,
            amt: 2500
          },
          {
            name: "aa",
            uv: 3490,
            Commission: 4300,
            amt: 2100
          }
    ];

    return (
        <BarChart
        width={670}
        height={300}
        data={data}
        margin={{
          top: 5,
          right: 5,
          left: 5,
          bottom: 5
        }}
        barSize={20}
      >
        <XAxis dataKey="name" scale="point" padding={{ left: 20, right: 20 }} />
        <YAxis />
        <Tooltip />
        <Legend />
        <CartesianGrid strokeDasharray="3 3" />
        <Bar dataKey="Commission" fill="#8884d8" background={{ fill: "#eee" }} />
      </BarChart>
    )
}
export default CommissionPayablePerSaleperson;