import { React, useEffect, useState } from "react";
import { FaBell } from "react-icons/fa";
import Title from '../../hook/Title';
import GoBack from "../../Components/GoBack";

const Notification = () => {
  const [Notifications, setNotifications] = useState()
  const [value, setValue] = useState('10');
  const handleChange = (e) => {
    setValue(e.target.value);
  };
  useEffect(() => {
    async function getData() {
      const response = await fetch(
        `https://jsonplaceholder.typicode.com/posts?_limit=${value}`
      )
      let actualData = await response.json();
      setNotifications(actualData)
    }
    getData()
  }, [value])
  Title('Notification')
  return (
    <>
      <div className="d-md-flex align-items-center justify-content-between mt-5">
        <div className="d-flex align-items-center mb-5">
          <GoBack />
          <h4 className="section-heading mb-0">Notification</h4>
        </div>
        <div>
          <label htmlFor="Showing" className="me-2">Showing:</label>
          <select id="Showing" className="dashboard-inputs mb-2" value={value} onChange={handleChange}>
            <option value='10'>10</option>
            <option value='25'>25</option>
            <option value='50'>50</option>
            <option value='75'>75</option>
            <option value='100'>100</option>
          </select>
        </div>
      </div>
      {
        Notifications?.map((item) => {
          return (
            <div key={item.id} className="d-md-flex  align-items-center mb-4">
              <span className="notification-bell d-flex justify-content-center align-items-center me-3">
                <FaBell className="rotate" />
              </span>
              <div className="notification-text">
                <p>
                  {item.body}
                </p>
                <div className="text-right">
                  <time className="date">24/04/2022</time>|<time className="date">17:10</time>
                </div>
              </div>
            </div>
          )
        })
      }
    </>
  );
}
export default Notification;