import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import Title from "../../../hook/Title";
import { Link } from "react-router-dom";

const AllTracks = () => {
    Title("All Tracks");
    const TrackList = [
        {
            trackName: "Clients Invoices Tracking"
        }
    ]
    return (
        <>
            <h4 className="primHeading my-2 mb-4">All <span>Tracks</span></h4>
            <div className="box my-3">
                <Row>
                    <Col xl={8}>
                        <Col lg={3} md={4}>
                            {
                                TrackList?.map((trackData, index) => {
                                    const { trackName } = trackData;
                                    return (
                                        <Link title="Click Here To View Trackings" key={index} to="/invoice-tracking">
                                            <div className='brandBox'>
                                                <h4 className="brandName my-0">{trackName || "--"}</h4>
                                            </div>
                                        </Link>
                                    )
                                })
                            }
                        </Col>
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default AllTracks; 