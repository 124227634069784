import React from "react";
import Title from "../../../hook/Title";
import AssignedBrand from "../../../Components/AssignedBrand";

const Index = () => {
    Title("PPC Brands");
    return (
        <>
            <h4 className="primHeading my-2 mb-4">My <span>Brands</span></h4>
            <div className="box my-3">
                <AssignedBrand urlOf={"/ppc-leadsOf"} />
            </div>
        </>
    );
};
export default Index; 