import React, { useState, useRef } from "react";
import { useNavigate } from 'react-router-dom';
import { Col, Container, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import Title from "../../hook/Title";
import GoBack from "../../Components/GoBack";
const EditTask = () => {
    const navigate = useNavigate();

    const fileInput = useRef(null);
    //  Get Values of Inputs
    const [title, setTitle] = useState("");
    const [department, setDepartment] = useState("");
    const [subDepartment, setSubDepartment] = useState("");
    const [assignUser, setAssignedUser] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [priority, setPriority] = useState("");
    const [description, setDescription] = useState("");
    const [selectedFile, setSelectedFile] = useState(null);

    // Select input file from your PC
    const changeHandler = (event) => {
        setSelectedFile(event.target.files[0]);
    };
    // Show Toast msg after user Login Sucessfull 
    const showSucessToast = () => {
        toast.success("Task Updated Successfully !", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "colored",
        });
    };


    // Post data to database
    const handleTaskCreation = () => { 
        showSucessToast()
    } 
    Title("Edit Task");
    return (
        <>
            <div className="d-flex align-items-center mb-3 flex-wrap">
                <GoBack />
                <h4 className="section-heading mb-0">Edit<span> Task</span></h4>
            </div>
            <div className="box">
                <Row className="m-md-3">
                    <Col xl={8}>
                        <Row>
                            <Col lg={6}>
                                <div className="registration-inp-box my-3">
                                    <label className="d-block" htmlFor="title">Task Title<span className="text-danger">*</span></label>
                                    <input
                                        id="title"
                                        type="text"
                                        placeholder="Enter Title"
                                        className="primary-inputs px-3"
                                        value={title}
                                        onChange={(e) => setTitle(e.target.value)}
                                    />
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className="registration-inp-box my-3">
                                    <label className="d-block" htmlFor="department">
                                        Department <span className="text-danger">*</span>
                                    </label>
                                    <select
                                        name="department"
                                        id="department"
                                        className="primary-inputs px-2"
                                        onChange={(e) => setDepartment(e.target.value)}
                                        value={department}
                                    >
                                        <option value="development">Web & App</option>
                                        <option value="admin">Admin</option>
                                        <option value="sales">Sales</option>
                                        <option value="hr" disabled>Human Resource</option>
                                        <option value="finance" disabled>Finance</option>
                                    </select>
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className="registration-inp-box my-3">
                                    <label className="d-block" htmlFor="subDepartment">
                                        Sub Department <span className="text-danger">*</span>
                                    </label>
                                    <select
                                        name="subDepartment"
                                        id="subDepartment"
                                        className="primary-inputs px-2"
                                        onChange={(e) => setSubDepartment(e.target.value)}
                                        value={subDepartment}
                                    >
                                        <option value="uiux">UI/UX Designing</option>
                                        <option value="logo">Logo Designing</option>
                                        <option value="cms">CMS Development</option>
                                        <option value="frontend">Frontend Development</option>
                                        <option value="backend">Backend Development</option>
                                        <option value="app">App Development</option>
                                    </select>
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className="registration-inp-box my-3">
                                    <label className="d-block" htmlFor="assignTo">Assign To<span className="text-danger">*</span></label>
                                    <select
                                        name="manager"
                                        id="assignTo"
                                        className="primary-inputs px-2"
                                        onChange={(e) => setAssignedUser(e.target.value)}
                                        value={assignUser}
                                    >
                                        <option value="nabiag">Nabiag</option>
                                        <option value="alex">Alex</option>
                                        <option value="sam">Sam</option>
                                    </select>
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className="registration-inp-box my-3">
                                    <label className="d-block" htmlFor="startDate">
                                        Start Date<span className="text-danger">*</span>
                                    </label>
                                    <input
                                        type="date"
                                        className="prim-date"
                                        name="dateOfJoining"
                                        id="jod"
                                        value={startDate}
                                        onChange={(e) => setStartDate(e.target.value)}
                                    />
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className="registration-inp-box my-3">
                                    <label className="d-block" htmlFor="endDate">
                                        End Date<span className="text-danger">*</span>
                                    </label>
                                    <input
                                        type="date"
                                        className="prim-date"
                                        name="dateOfJoining"
                                        id="endDate"
                                        value={endDate}
                                        onChange={(e) => setEndDate(e.target.value)}
                                    />
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className="registration-inp-box my-3">
                                    <label className="d-block" htmlFor="priority">Task Priority<span className="text-danger">*</span></label>
                                    <select
                                        name="priority"
                                        id="priority"
                                        className="primary-inputs px-2"
                                        onChange={(e) => setPriority(e.target.value)}
                                        value={priority}
                                    >
                                        <option value="high">High</option>
                                        <option value="medium">Medium</option>
                                        <option value="low">low</option>
                                    </select>
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className="registration-inp-box my-3">
                                    <label className="d-block" htmlFor="attachment">Attachment</label>
                                    <input type="file" ref={fileInput} id="attachment" className="d-none" name="file" onChange={changeHandler} />
                                    <div className="p-0 primary-inputs d-flex justify-content-between align-items-center">
                                        <p className="mb-0 mx-3 exsmText">{selectedFile == null ? "Choose File" : selectedFile.name}</p>
                                        <button className="formUploadBtn" onClick={() => fileInput.current.click()}>Choose File</button>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={12}>
                                <div className="registration-inp-box my-3">
                                    <label className="d-block" htmlFor="desc">Description<span className="text-danger">*</span> </label>
                                    <textarea
                                        name="desc"
                                        id="desc"
                                        className="primary-inputs px-3"
                                        rows="5"
                                        placeholder="Enter Task Description"
                                        onChange={(e) => setDescription(e.target.value)}
                                        value={description}
                                    ></textarea>
                                </div>
                            </Col>
                            <Col>
                                <div className="my-4">
                                    <button className="main-btn me-3" onClick={handleTaskCreation}>Submit</button>
                                    <button onClick={() => navigate(-1)} className="sec-btn me-3">Cancel</button>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default EditTask; 