import React, { useState, useEffect } from "react";
import {
  FaExclamationCircle,
  FaBoxes,
  FaFileAlt,
  FaCheck,
  FaUndo,
  FaDollarSign,
} from "react-icons/fa";
import { Row, Col } from "react-bootstrap";
import Title from "../../hook/Title";
import axios from "axios";
import { BarChartComp } from "./Charts";
import ProfileCard from "./ProfileCard";
import Info from "./Info";
import { useSelector } from "react-redux";
import PaymentActivity from "../../Components/Charts/PaymentActivityGraph";
// --------------------Subhan Code Changes---------------//////////////////////////////////////////////
import Loader from "../../utils/Loader";
import { getMyOverallSalesRoute } from "../../utils/APIRoutes";
// --------------------Subhan Code Changes---------------//////////////////////////////////////////////

const analytics = [
  {
    id: "1",
    title: "Gross Sales",
    detail: "1254",
  },
  {
    id: "2",
    title: "Net Sales",
    detail: "42",
  },
  {
    id: "3",
    title: "Disputed Amount",
    detail: "11",
  },
  {
    id: "4",
    title: "Refunds",
    detail: "38",
  },
];

const startingYear = [2022];
const cYear = new Date().getFullYear();
for (let i = 1; i <= 5; i++) {
  startingYear.push(cYear + i);
}
const UserDashboard = () => {
  Title("Dashboard");
  const [filterLoader, setFilterLoader] = useState(false);
  // ! Filter By Date Code for table ! :
  // Getting by default month value !
  const currentDate = new Date(); // get current date
  const currentYear = currentDate.getFullYear(); // get current year
  const currentMonth = currentDate.getMonth() + 1; // get current month and add 1 to adjust for zero-based index
  const formattedMonth = currentMonth < 10 ? `0${currentMonth}` : currentMonth; // add leading zero if month is less than 10
  const monthStartDate = `${currentYear}-${formattedMonth}-01`; // format date as YYYY-MM-DD

  const [filterDates, setfilterDates] = useState({
    from: monthStartDate,
    to: "",
  });
  const { from, to } = filterDates;
  const handleDateChange = (e) => {
    setfilterDates({ ...filterDates, [e.target.name]: e.target.value });
  };

  const clearFilters = () => {
    setfilterDates({ from: "", to: "" });
    document.getElementById("MonthSelect").selectedIndex = 0;
    document.getElementById("YearSelect").selectedIndex = 0;
  };

  const [selectedYear, setSelectedYear] = useState("");

  const handleMonthChange = (e) => {
    const year = selectedYear !== "" ? selectedYear : new Date().getFullYear();
    const month = Number(e.target.value);
    // Getting First Date of the Month
    const firstDayOfMonth = new Date(year, month, 1);
    const formattedFirstDayOfMonth = formatDate(firstDayOfMonth);
    // Getting Last Date of the Month
    const lastDayOfMonth = new Date(year, month + 1, 0);
    const formattedLastDayOfMonth = formatDate(lastDayOfMonth);
    setfilterDates({
      from: formattedFirstDayOfMonth,
      to: formattedLastDayOfMonth,
    });
  };

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const cyear = new Date().getFullYear();
  const previousYears = [];
  for (let i = 0; i <= 9; i++) {
    previousYears.push(cyear - i);
  }

  const handleYearChange = (e) => {
    document.getElementById("MonthSelect").selectedIndex = 0;
    const selectedYear = Number(e.target.value);
    setSelectedYear(selectedYear);
    function getLastDayOfYear(selectedYear) {
      return new Date(selectedYear, 11, 31);
    }
    function getFirstDayOfYear(year) {
      return new Date(year, 0, 1);
    }
    // Get the First day of the selected year
    const firstDayOfYear = getFirstDayOfYear(selectedYear);
    const yearsFirstday = formatDate(firstDayOfYear);
    // Get the last day of the selected year
    const lastDayOfYear = getLastDayOfYear(selectedYear);
    const yearsLastday = formatDate(lastDayOfYear);
    setfilterDates({ from: yearsFirstday, to: yearsLastday });
  };

  const [year2, setYearForGraph2] = useState(new Date().getFullYear());

  // --------------------Subhan Code Changes-------In--------//////////////////////////////////////////////
  const [isLoading, setIsLoading] = useState(true);
  // --------------------Subhan Code Changes---------Out------//////////////////////////////////////////////

  // getting value of authenticated user using redux persistor
  const AuthReducer = useSelector((state) => state.AuthReducer);
  const { empId, designation } = AuthReducer.data;

  // --------------------Subhan Code Changes---------In------//////////////////////////////////////////////
  const [myGrossSales, setMyGrossSales] = useState([]);
  const [myNetSales, setMyNetSales] = useState([]);
  const [myDisputedSales, setMyDisputedSales] = useState([]);
  const [myRefundedSales, setMyRefundedSales] = useState([]);
  // console.log(myGrossSales);
  const getAnalytics = async () => {
    try {
      const analyticsdata = await axios.get(
        `${getMyOverallSalesRoute}?empId=${empId}&from=${from}&to=${to}`
      );
      if (analyticsdata.data.success === true) {
        setMyGrossSales(analyticsdata?.data?.data?.myGrossSales);
        setMyNetSales(analyticsdata?.data?.data?.myNetSales);
        setMyDisputedSales(analyticsdata?.data?.data?.myDisputedSales);
        setMyRefundedSales(analyticsdata?.data?.data?.myRefundedSales);
        setIsLoading(false);
        setFilterLoader(false);
      }
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAnalytics();
    if (from || to) {
      setFilterLoader(true);
    }
  }, [from, to]);
  // --------------------Subhan Code Changes-----------Out----//////////////////////////////////////////////

  return (
    <>
      <Row>
        <Col xl={4}>
          <div className="box my-3 profileBg">
            <ProfileCard />
          </div>
          <div className="box my-3">
            <Info />
          </div>
        </Col>
        <Col xl={8}>
          {/* // --------------------Subhan Code Changes---------In------////////////////////////////////////////////// */}
          {isLoading === true ? (
            <Loader />
          ) : (
            <>
              {/* // --------------------Subhan Code Changes---------Out------////////////////////////////////////////////// */}

              {/* // --------------------Subhan Code Changes---------In------////////////////////////////////////////////// */}
              {/*==========================|||****** Filters Start Here ********|||==========================*/}
              <Row className="customFilters mb-3">
                <Col xl={2} lg={4} md={6} sm={5} className="my-2">
                  <label className="text-bold" htmlFor="From">
                    From:
                  </label>
                  <input
                    value={from}
                    onChange={handleDateChange}
                    className="prim-date border-0"
                    type="date"
                    name="from"
                    id="From"
                  />
                </Col>
                <Col xl={2} lg={4} md={6} sm={5} className="my-2">
                  <label className="text-bold" htmlFor="To">
                    To:
                  </label>
                  <input
                    value={to}
                    onChange={handleDateChange}
                    className="prim-date border-0"
                    type="date"
                    name="to"
                    id="To"
                  />
                </Col>
                <Col xl={2} lg={4} md={6} sm={5} className="my-2">
                  <label className="text-bold" htmlFor="MonthSelect">
                    By Month:
                  </label>
                  <select
                    className="primSelect prim-date white"
                    id="MonthSelect"
                    onChange={handleMonthChange}
                  >
                    <option value=" ">Select Month</option>
                    <option value="0">January</option>
                    <option value="1">February</option>
                    <option value="2">March</option>
                    <option value="3">April</option>
                    <option value="4">May</option>
                    <option value="5">June</option>
                    <option value="6">July</option>
                    <option value="7">August</option>
                    <option value="8">September</option>
                    <option value="9">October</option>
                    <option value="10">November</option>
                    <option value="11">December</option>
                  </select>
                </Col>
                <Col xl={2} lg={4} md={6} sm={5} className="my-2">
                  <label className="text-bold" htmlFor="YearSelect">
                    By Year:
                  </label>
                  <select
                    className="primSelect prim-date white"
                    id="YearSelect"
                    onChange={handleYearChange}
                  >
                    <option value=" ">Select Year</option>
                    {previousYears.map((year, index) => {
                      return (
                        <option key={index} value={year}>
                          {year}
                        </option>
                      );
                    })}
                  </select>
                </Col>
                {filterLoader ? (
                  <Col lg={2} md={6} sm={12} className="my-2 position-relative">
                    <div className="preloaderWrapper">
                      <div className="preloaderMainWrapper">
                        <div className="smLoader">
                          <div className="smdot"></div>
                        </div>
                        <div className="smLoader">
                          <div className="smdot"></div>
                        </div>
                        <div className="loader">
                          <div className="smdot"></div>
                        </div>
                        <div className="smLoader">
                          <div className="smdot"></div>
                        </div>
                        <div className="smLoader">
                          <div className="smdot"></div>
                        </div>
                        <div className="smLoader">
                          <div className="smdot"></div>
                        </div>
                      </div>
                    </div>
                  </Col>
                ) : (
                  <Col xl={2} lg={4} md={6} sm={2} className="my-2">
                    <button
                      onClick={clearFilters}
                      title="Clear Date Filters"
                      className="main-btn clearFilter w-100 mt-4"
                    >
                      Clear
                    </button>
                  </Col>
                )}
              </Row>
              {/* // --------------------Subhan Code Changes---------Out------////////////////////////////////////////////// */}

              <Row>
                <Col
                  key={0}
                  xxl={3}
                  xl={6}
                  sm={6}
                  className={`analyticsWrap1 mb-2`}
                >
                  <div className="dashboard-anatlytics d-flex align-items-center justify-content-between flex-wrap">
                    <div>
                      <h3>Gross Sales</h3>
                      <h4>
                        {myGrossSales.length === 0
                          ? "$ 0"
                          : `$ ${myGrossSales[0]?.totalAmount}`}
                      </h4>
                    </div>
                    <div className={`analyticsIcon1 analyticsIcon`}>
                      <FaDollarSign className="icons" />
                    </div>
                  </div>
                </Col>

                <Col
                  key={1}
                  xxl={3}
                  xl={6}
                  sm={6}
                  className={`analyticsWrap2 mb-2`}
                >
                  <div className="dashboard-anatlytics d-flex align-items-center justify-content-between flex-wrap">
                    <div>
                      <h3>Net Sales</h3>
                      <h4>
                        {myNetSales.length === 0
                          ? "$ 0"
                          : `$ ${myNetSales[0]?.totalAmount}`}
                      </h4>
                    </div>
                    <div className={`analyticsIcon2 analyticsIcon`}>
                      <FaCheck className="icons" />
                    </div>
                  </div>
                </Col>
                <Col
                  key={2}
                  xxl={3}
                  xl={6}
                  sm={6}
                  className={`analyticsWrap3 mb-2`}
                >
                  <div className="dashboard-anatlytics d-flex align-items-center justify-content-between flex-wrap">
                    <div>
                      <h3>Disputed Sales</h3>
                      <h4>
                        {myDisputedSales.length === 0
                          ? "$ 0"
                          : `$ ${myDisputedSales[0]?.totalAmount}`}
                      </h4>
                    </div>
                    <div className={`analyticsIcon3 analyticsIcon`}>
                      <FaExclamationCircle className="icons" />
                    </div>
                  </div>
                </Col>
                <Col
                  key={3}
                  xxl={3}
                  xl={6}
                  sm={6}
                  className={`analyticsWrap4 mb-2`}
                >
                  <div className="dashboard-anatlytics d-flex align-items-center justify-content-between flex-wrap">
                    <div>
                      <h3>Refunds</h3>
                      <h4>
                        {myRefundedSales.length === 0
                          ? "$ 0"
                          : `$ ${myRefundedSales[0]?.formattedTotal}`}
                      </h4>
                    </div>
                    <div className={`analyticsIcon4 analyticsIcon`}>
                      <FaUndo className="icons" />
                    </div>
                  </div>
                </Col>
              </Row>
              {designation === "unit_head" ? (
                <div className="box rounded-3 my-3 ">
                  <div className="d-flex align-items-center justify-content-between flex-wrap">
                    <h6 className="subHeading text-uppercase">
                      Payment Activity
                    </h6>
                    <div className="flex-shrink-0 ms-auto">
                      <select
                        className="primSelect prim-date white min-w-150"
                        onChange={(e) => setYearForGraph2(e.target.value)}
                        id="YearSelect"
                      >
                        {previousYears.map((year, index) => {
                          return (
                            <option key={index} value={year}>
                              {year}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <PaymentActivity year={year2} />
                </div>
              ) : (
                <div className="box my-3">
                  <div className="d-flex align-items-center flex-wrap justify-content-between">
                    <h4 className="sectionHeading mb-3">Task Report</h4>
                    <div className="d-flex align-items-center justify-content-between my-2 flex-wrap">
                      <div className="chartLabels m-2">
                        <span className="bgBlue"></span>
                        <p className="mb-0">Completed</p>
                      </div>
                      <div className="chartLabels m-2">
                        <span className="bgLgGray"></span>
                        <p className="mb-0">On Going</p>
                      </div>
                      <select className="dashboard-inputs m-2">
                        {startingYear?.map((item, index) => {
                          return (
                            <option key={index} value={item}>
                              {item}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <BarChartComp />
                </div>
              )}
              {/* // --------------------Subhan Code Changes---------In------////////////////////////////////////////////// */}
            </>
          )}
          {/* // --------------------Subhan Code Changes---------Out------////////////////////////////////////////////// */}
        </Col>
      </Row>
    </>
  );
};

export default UserDashboard;
