import React from 'react';
const UnitBox = ({ unitData }) => {
     const { name, description, active, createdAt } = unitData;
     return (
          <div className={active === false ? "rounded-1 unitBox my-2 disabledUnitBox" : "rounded-1 unitBox my-2"}>
               <h4 className="fw-600 fs-14 text-capitalize">{name || "--"}</h4>
               <h6 className='colBlue'>{description || ""}</h6>
               {active === true ? <span className="activeBadge spnBdge">Active</span> : <span className="inactiveBadge spnBdge">InActive</span>}
               <p className="fs-11 mb-0">Created At: {createdAt.slice(0, 10).split("-").reverse().join("-") || "-"}</p>
          </div>
     );
}

export default UnitBox;
