import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';

const Integrations = () => {
     const [showSmtpForm, setShowSmtpForm] = useState(false);
     return (
          <>
               <h6 className='sectionHeading my-3 mb-4'>Integration Settings</h6>
               <div className="d-flex align-items-center justify-content-between flex-wrap">
                    <h6 className='secHeading'>SMTP</h6>
                    {
                         showSmtpForm === true ? <button onClick={() => setShowSmtpForm(false)} className='main-btn'>Show Smtps</button> :
                              <button onClick={() => setShowSmtpForm(true)} className='main-btn'>Add SMTP</button>
                    }
               </div>
               <Row className={showSmtpForm === true ? "d-none" : "d-block"}>
                    <Col md={4}>
                         <div className='brandBox text-center' onClick={() => setShowSmtpForm(true)}>
                              <h4 className="brandName">Smtp for Brand 01</h4>
                         </div>
                    </Col>
               </Row>
               <AddSmtp showSmtpForm={showSmtpForm} />
          </>
     );
}
const AddSmtp = ({ showSmtpForm }) => {
     const handleSmtpSubmit = (e) => {
          e.preventDefault();
     }
     return (
          <>
               <form onSubmit={(e) => handleSmtpSubmit(e)} className={showSmtpForm === false ? "d-none" : "d-block"}>
                    <Row>
                         <Col className='my-3' lg={6}>
                              <label className="text-bold mb-1" htmlFor="smtpHost">SMTP Host<span className="text-danger">*</span></label>
                              <input
                                   // value={}
                                   // onChange={(e) => handleInpChange(e)}
                                   name='smtpHost'
                                   id='smtpHost'
                                   type="text"
                                   className="filterSelect ps-3"
                                   placeholder='Enter SMTP Host' />
                         </Col>
                         <Col className='my-3' lg={6}>
                              <label className="text-bold mb-1 d-block" htmlFor="smtpPort">SMTP Port<span className="text-danger">*</span></label>
                              <input
                                   // value={}
                                   // onChange={(e) => handleInpChange(e)}
                                   name='smtpPort'
                                   id='smtpPort'
                                   type="text"
                                   className="filterSelect ps-3 w-50"
                                   placeholder='Enter SMTP Port' />
                         </Col>
                         <Col className='my-3' lg={6}>
                              <label className="text-bold mb-1" htmlFor="username">Username<span className="text-danger">*</span></label>
                              <input
                                   // value={}
                                   // onChange={(e) => handleInpChange(e)}
                                   name='username'
                                   id='username'
                                   type="text"
                                   className="filterSelect ps-3"
                                   placeholder='Enter Username' />
                         </Col>
                         <Col className='my-3' lg={6}>
                              <label className="text-bold mb-1" htmlFor="password">Password<span className="text-danger">*</span></label>
                              <input
                                   // value={}
                                   // onChange={(e) => handleInpChange(e)}
                                   name='password'
                                   id='password'
                                   type="password"
                                   className="filterSelect ps-3"
                                   placeholder='Enter password' />
                         </Col>
                    </Row>
                    <div className="text-end">
                         <button className='main-btn'>Add</button>
                    </div>
               </form>
          </>

     )
}
export default Integrations;
