import React from "react";
import SideNav from "./SideNav";
import Header from "./Header";
import { Row, Col, Container } from "react-bootstrap";
import Footer from "./Footer";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

const Layout = ({ children }) => {
  const [myClass, setMyClass] = useState(true);
  const [mobMenuOption, setMobMenuOption] = useState(false);
  const location = useLocation();

  function toggleSideNav() {
    setMyClass(!myClass);
    if (!mobMenuOption) {
      setMyClass(false);
    }
    setMobMenuOption(true);
  }

  useEffect(() => {
    setMobMenuOption(false);
  }, [location]);

  useEffect(() => {
    const currentPath = location.pathname;
    // console.log("Current Path:", currentPath);

    const isExcludedRoute =
      currentPath.startsWith("/my-unit-invoices/") ||
      currentPath.startsWith("/sales-invoice/") ||
      currentPath.startsWith("/my-invoices") ||
      currentPath.startsWith("/disputes") ||
      currentPath.startsWith("/all-invoices");

    if (!isExcludedRoute) {
      console.log("Removing searchValue from localStorage");
      localStorage.removeItem("searchValue");
      localStorage.removeItem("searchValueTwo");
      localStorage.removeItem("searchValueThree");
      localStorage.removeItem("searchValueFour");
      localStorage.removeItem("filterDates");
      localStorage.removeItem("filterDatesTwo");
      localStorage.removeItem("filterDatesThree");
      localStorage.removeItem("filterDatesFour");
      localStorage.removeItem("apiState");
      localStorage.removeItem("merchantVal");
    } else {
      // console.log("Path is excluded, searchValue remains");
    }
    const excludedPaths = [
      "/my-unit-invoices/",
      "/sales-invoice/",
      "/my-invoices",
      "/disputes",
      "/all-invoices",
    ];

    // Check if the current path is in the excluded paths
    const shouldSetDate = !excludedPaths.some((path) =>
      currentPath.startsWith(path)
    );

    if (shouldSetDate) {
      // Set 'fromDate' to the first day of the current month in localStorage
      const firstDayOfMonth = new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        1
      ).toLocaleDateString("en-CA");

      localStorage.setItem("fromDate", firstDayOfMonth);
    }
  }, [location]);

  return (
    <>
      <div className={`siteWrapper ${myClass ? "" : "removeSideBar"}`}>
        <Container
          fluid
          className={`g-0 ${mobMenuOption ? "" : "mobileFunctionalNav"}`}
        >
          <Row>
            <Col>
              <div className="topBar">
                <Header toggleSideNav={toggleSideNav} />
              </div>
            </Col>
          </Row>
          <div className="d-flex mainContent">
            <div className="sideNav">
              <SideNav />
            </div>
            <div className="configuration">
              <Container fluid>{children}</Container>
              <Footer />
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Layout;
