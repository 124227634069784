import React, { useEffect, useState } from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { completeGowthByMonthRoute } from '../../utils/APIRoutes';
import axios from 'axios';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
);

export const options = {
    responsive: true,
    maintainAspectRatio: false, // Set this to false to allow setting a fixed height
    height: 200, // Set the height to 400 pixels
    plugins: {
        legend: {
            position: 'top',
        },
        title: {
            display: false,
            text: '',
        },
    },
};



const GrowthGraph = ({ year }) => {
    const [amount, setAmmount] = useState([])
    const [month, setMonth] = useState([])
    useEffect(() => {
        try {
            const getGraphsData = async () => {
                const response = await axios.get(`${completeGowthByMonthRoute}?year=${year}`);
                const data = response.data.data
                setAmmount(data.amount)
                setMonth(data.month)
            }
            getGraphsData()
        } catch (err) {
            console.log("🚀 ~ file: UnitIncome:", err)
        }
    }, [year])

    const data = {
        labels: month,
        datasets: [
            {
                fill: true,
                label: 'Monthly Growth',
                data: amount,
                borderColor: 'rgb(53, 162, 235)',
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
            },
        ],
    };
    return (
        <div className="h-340">
            <Line options={options} data={data} />
        </div>
    );
}
export default GrowthGraph;