import React, { useState, useEffect, useCallback } from "react";
import { TableCom } from "../../../Components/TableCom";
import { Link, json, useNavigate } from "react-router-dom";
import {
  getAllinvoicesRoute,
  getPaidInvoicesSummaryRoute,
  csvOfGetPaidInvoicesSummaryRoute,
  getAllGatewayRoute,
} from "../../../utils/APIRoutes";
import Dropdown from "react-bootstrap/Dropdown";
import Title from "../../../hook/Title";
import {
  FaRegEye,
  FaEllipsisV,
  FaEye,
  FaTimes,
  FaFileInvoice,
} from "react-icons/fa";
import { useSelector } from "react-redux";
import CustomModal from "../../../Components/CustomModal";
import { Row, Col } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import axios from "axios";
import useClipboard from "../../../hook/useClipboard";
import { toast } from "react-toastify";
import { debounce } from "lodash";

const AllInvoicesListings = () => {
  Title("All Invoices Listings");
  const { handleCopyClipboard, copiedText } = useClipboard();
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  const AuthReducer = useSelector((state) => state.AuthReducer);
  const { empId, department, designation } = AuthReducer.data;
  const location = useLocation();
  // Retrieve the 'fromDate' from localStorage, or set it to the first day of the current month
  const storedFromDate = localStorage.getItem("fromDate");

  // Set up the state with the retrieved or default value
  const [filterDates, setfilterDates] = useState({
    from: storedFromDate || "",
    to: "",
  });

  // const handleDateChange = (e) => {
  //   setfilterDates({ ...filterDates, [e.target.name]: e.target.value });
  // };

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    setfilterDates((prevDates) => {
      const newDates = { ...prevDates, [name]: value };
      localStorage.setItem("filterDatesFour", JSON.stringify(newDates)); // Save to localStorage
      return newDates;
    });
  };

  useEffect(() => {
    const storedDates = localStorage.getItem("filterDatesFour");
    if (storedDates) {
      setfilterDates(JSON.parse(storedDates));
    }
  }, []);

  // const clearFilters = () => {
  //   setfilterDates({ from: "", to: "" }); // Clear from localStorage
  // };

  const clearFilters = () => {
    setfilterDates({ from: "", to: "" });
    localStorage.removeItem("filterDatesFour"); // Clear from localStorage
    localStorage.removeItem("fromDate"); // Clear fromDate from localStorage
  };

  const { from, to } = filterDates;

  let toastOptions = {
    position: "top-left",
    autoClose: 5000,
    pauseOnHover: false,
    draggable: false,
    theme: "dark",
  };

  // Retrieve initial values from localStorage or set default values
  const storedMerchantVal =
    JSON.parse(localStorage.getItem("merchantVal")) || {}; // Parse the string as JSON
  // getting filter values for Merchant Filter
  const [merchantVal, setMerchantVal] = useState(storedMerchantVal);
  const [gatewayName, setGatewayName] = useState(
    storedMerchantVal.gatewayName || ""
  );
  const [accountName, setAccountName] = useState(
    storedMerchantVal.accountName || ""
  );
  const [bankName, setBankName] = useState(storedMerchantVal.bankName || "");

  const [selectedMerchant, setSelectedMerchant] = useState(
    localStorage.getItem("merchantVal") || ""
  );

  const handleMerchantVal = (e) => {
    const selectedValue = e.target.value;
    setSelectedMerchant(selectedValue);
    const merchantData = JSON.parse(selectedValue);

    if (merchantData) {
      const { gatewayName, accountName, bankName } = merchantData;
      setGatewayName(gatewayName);
      setAccountName(accountName);
      setBankName(bankName);
    }

    localStorage.setItem("merchantVal", selectedValue);
  };

  // Table Head Data
  const [thLength, setThlength] = useState(1);
  const [items, setItems] = useState([]);
  const [numPerPage, setNumPerPage] = useState(10);
  const [totalData, setTotalData] = useState(0);
  const [pageCount, setpageCount] = useState(0);
  const [currPage, setCurrPage] = useState(0);
  const [searchVal, setSearchVal] = useState("");
  const Thead = [
    "S.No",
    "Client",
    "invoice Id",
    "Total Amount",
    "Invoice Status",
    "created At",
    "Payment Time",
    "Sales Person",
    "brand",
    "unit",
    "Payment Status Code",
    "Invoice Payment",
    "Gateway Used",
    "Account Name",
    "Reference Id",
    "Bank Name",
    "Action",
  ];

  // const [apiState, setApiState] = useState(
  //   department === "accounts" ? "paidInvoices" : "allInvoices"
  // );
  // const handleApiChange = (e) => {
  //   setApiState(e.target.value);
  //   if (e.target.value === "allInvoices") {
  //     setGatewayName("");
  //     setAccountName("");
  //     setBankName("");
  //   }
  // };

  // Retrieve the apiState from localStorage or set a default value
  const storedApiState = localStorage.getItem("apiState");
  const initialApiState =
    storedApiState === null ? "allInvoices" : storedApiState;
  const [apiState, setApiState] = useState(initialApiState);
  console.log(initialApiState, "initialApiState");

  // Save the apiState to localStorage whenever it changes
  const handleApiChange = (e) => {
    const newApiState = e.target.value;
    setApiState(newApiState);
    localStorage.setItem("apiState", newApiState); // Save to localStorage
  };

  console.log(apiState, "api state check");

  useEffect(
    (currPage) => {
      currPage = currPage ?? 0;
      const getData = async () => {
        let getAllinvoicesApiUrl = `${getAllinvoicesRoute}?empId=${empId}&numPerPage=${numPerPage}&page=${currPage}&search=${searchVal}&from=${from}&to=${to}`;
        let getPaidInvoicesSummaryApiUrl = `${getPaidInvoicesSummaryRoute}?numPerPage=${numPerPage}&page=${currPage}&search=${searchVal}&from=${from}&to=${to}&gatewayName=${gatewayName}&accountName=${accountName}&bankName=${bankName}`;
        let apiUrl =
          department === "accounts" || apiState === "paidInvoices"
            ? getPaidInvoicesSummaryApiUrl
            : getAllinvoicesApiUrl;
        const res = await fetch(apiUrl, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (res.status === 401) {
          navigate("/");
          toast.error(
            "Session has been Expired",
            toastOptions,
            (toastOptions["position"] = "top-right")
          );
          return;
        }
        const data = await res.json();
        const total = data.pagiantion.dataCount;
        setTotalData(total);
        setpageCount(Math.ceil(total / numPerPage));
        setItems(data.result);
      };
      getData();
    },
    [
      numPerPage,
      searchVal,
      from,
      to,
      apiState,
      gatewayName,
      accountName,
      bankName,
      merchantVal,
    ]
  );

  useEffect(() => {
    setThlength(Thead.length);
  }, [thLength]);

  const fetchData = async (currPage) => {
    let getAllinvoicesApiUrl = `${getAllinvoicesRoute}?empId=${empId}&numPerPage=${numPerPage}&page=${currPage}&search=${searchVal}&from=${from}&to=${to}`;
    let getPaidInvoicesSummaryApiUrl = `${getPaidInvoicesSummaryRoute}?numPerPage=${numPerPage}&page=${currPage}&search=${searchVal}&from=${from}&to=${to}&gatewayName=${gatewayName}&accountName=${accountName}&bankName=${bankName}`;
    let apiUrl =
      department === "accounts" || apiState === "paidInvoices"
        ? getPaidInvoicesSummaryApiUrl
        : getAllinvoicesApiUrl;
    const res = await fetch(apiUrl, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (res.status === 401) {
      navigate("/");
      toast.error(
        "Session has been Expired",
        toastOptions,
        (toastOptions["position"] = "top-right")
      );
      return;
    }
    const changedData = await res.json();
    const data = changedData.result;
    return data;
  };
  const handlePageClick = async (data) => {
    let currPage = data.selected;
    const myData = await fetchData(currPage);
    setItems(myData);
    setCurrPage(currPage);
  };

  const handleChange = (e) => {
    setNumPerPage(e.target.value);
  };

  // stored search value in local storage
  useEffect(() => {
    const storedSearchValue = localStorage.getItem("searchValueFour");
    const searchValueFromState = location.state?.searchValue;

    if (searchValueFromState) {
      setSearchVal(searchValueFromState);
      localStorage.setItem("searchValueFour", searchValueFromState);
    } else if (storedSearchValue) {
      setSearchVal(storedSearchValue);
    }
  }, [location.state]);

  // Debounced search function
  const debouncedSearch = useCallback(
    debounce((value) => {
      setSearchVal(value);
    }, 500),
    []
  );

  // search data in api using search box
  const hangleSearchVal = (e) => {
    const value = e.target.value;
    debouncedSearch(value);
    localStorage.setItem("searchValueFour", value); // Store search value in localStorage
  };

  const handleRowDoubleClick = (_id) => {
    navigate(`/sales-invoice/${_id}`, { state: { searchValue: searchVal } });
  };

  //======================== Show Complete Error When Clicking on show Btn ========================//
  const [errorModal, setErrorModal] = useState(false);
  const [errorText, setErrorText] = useState(false);
  const handleShowError = (errorDetails) => {
    setErrorModal(true);
    setErrorText(errorDetails);
  };
  // ............ csv Of Paid Invoices Summary download code .............
  const [csvLoading, setCsvLoading] = useState(false);
  const exportInvAsCsv = async () => {
    setCsvLoading(true);
    try {
      const response = await axios.get(
        `${csvOfGetPaidInvoicesSummaryRoute}?search=${searchVal}&from=${from}&to=${to}&gatewayName=${gatewayName}&accountName=${accountName}&bankName=${bankName}`,
        { responseType: "blob" }
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const a = document.createElement("a");
      a.href = url;
      setCsvLoading(false);
      a.download = "paidInvoicesSummary.csv";
      a.click();
    } catch (error) {
      setCsvLoading(false);
      console.log(error);
    }
  };
  //  Reciept Image ModaL
  const [recImg, setRecImg] = useState("");
  const handleRecieptModaL = (recpImg) => {
    setRecImg(recpImg);
  };
  // Getting a list of all wire accounts
  const [wireList, setWireList] = useState([]);
  useEffect(() => {
    const getWireList = async () => {
      const { data } = await axios.get(getAllGatewayRoute);
      if (data.success === true) {
        setWireList(data.wire);
      }
    };
    getWireList();
  }, []);

  return (
    <>
      <h4 className="primHeading my-2 mb-4">All Invoices</h4>
      <div className="box my-3">
        <h4 className="sectionHeading my-2 mb-4">All Invoices Summary</h4>
        {/*==========================|||****** Filters Start Here ********|||==========================*/}
        <Row className="customFilters">
          <Col xl={2} lg={4} md={6} sm={5} className="my-2">
            <label className="text-bold" htmlFor="From">
              From:
            </label>
            <input
              value={from}
              onChange={handleDateChange}
              className="prim-date"
              type="date"
              name="from"
              id="From"
            />
          </Col>
          <Col xl={2} lg={4} md={6} sm={5} className="my-2">
            <label className="text-bold" htmlFor="To">
              To:
            </label>
            <input
              value={to}
              onChange={handleDateChange}
              className="prim-date"
              type="date"
              name="to"
              id="To"
            />
          </Col>
          <Col xl={2} lg={4} md={6} sm={2} className="my-2">
            <button
              onClick={clearFilters}
              title="Clear Date Filters"
              className="main-btn clearFilter w-100 mt-4"
            >
              Clear
            </button>
          </Col>
          {
            <Row>
              {department === "accounts" ? (
                ""
              ) : (
                <Col xl={2} lg={4} md={6} sm={5} className="my-2">
                  <label className="text-bold" htmlFor="filterBY">
                    Filter By:
                  </label>
                  <select
                    className="primSelect prim-date white"
                    id="filterBY"
                    onChange={handleApiChange}
                    value={apiState}
                  >
                    <option value="allInvoices">All Invoices Summary</option>
                    <option value="paidInvoices">Paid Invoices Summary</option>
                  </select>
                </Col>
              )}
              {apiState === "paidInvoices" ? (
                // <Col xl={2} lg={4} md={6} sm={5} className="my-2">
                //   <label className="text-bold" htmlFor="filterBYMerchant">
                //     Filter By Merchant:
                //   </label>
                //   <select
                //     onChange={(e) => handleMerchantVal(e)}
                //     className="primSelect prim-date white text-capitalize"
                //     id="filterBYMerchant"
                //     value={selectedMerchant}
                //   >
                //     <option value="" disabled>
                //       Select Merchant
                //     </option>
                //     {wireList.length > 0 &&
                //       wireList.map((wirelist) => {
                //         const { accountName, gatewayName, bankName, _id } =
                //           wirelist;
                //         return (
                //           <option key={_id} value={JSON.stringify(wirelist)}>
                //             {bankName === ""
                //               ? `${gatewayName}-${accountName}`
                //               : `${gatewayName}-${accountName} (${bankName})`}
                //           </option>
                //         );
                //       })}
                //   </select>
                // </Col>
                <Col xl={2} lg={4} md={6} sm={5} className="my-2">
                  <label className="text-bold" htmlFor="filterBYMerchant">
                    Filter By Merchant:
                  </label>
                  <select
                    onChange={handleMerchantVal}
                    className="primSelect prim-date white text-capitalize"
                    id="filterBYMerchant"
                    value={selectedMerchant}
                  >
                    <option value="">Select Merchant</option>
                    {wireList.length > 0 &&
                      wireList.map((wirelist) => {
                        const optionValue = JSON.stringify(wirelist);
                        const { accountName, gatewayName, bankName, _id } =
                          wirelist;
                        return (
                          <option key={_id} value={optionValue}>
                            {bankName === ""
                              ? `${gatewayName}-${accountName}`
                              : `${gatewayName}-${accountName} (${bankName})`}
                          </option>
                        );
                      })}
                  </select>
                </Col>
              ) : (
                ""
              )}
            </Row>
          }
        </Row>
        {(designation === "president" ||
          designation === "head_of_department" ||
          department === "accounts") &&
        apiState === "paidInvoices" ? (
          <div className="text-end">
            <button
              title="Download As CSV"
              onClick={exportInvAsCsv}
              className="main-btn mt-4"
            >
              {csvLoading ? "Downloading..." : "Download As CSV"}
            </button>
          </div>
        ) : (
          ""
        )}
        <hr />
        <TableCom
          numPerPage={numPerPage}
          totalData={totalData}
          handleChange={handleChange}
          hangleSearchVal={hangleSearchVal}
          searchVal={searchVal}
          pageCount={pageCount}
          handlePageClick={handlePageClick}
          searcPlaceHolder="Search..."
        >
          <div className="customTable2 w-100 table-responsive">
            <table className="w-100">
              <thead>
                <tr>
                  {Thead?.map((th, index) => {
                    return <th key={index}>{th}</th>;
                  })}
                </tr>
              </thead>
              <tbody>
                {items == "" || items == undefined || items == null ? (
                  <tr>
                    <td colSpan={thLength}>
                      <p className="my-2 smText text-decoration-underline text-danger">
                        No Record Found
                      </p>
                    </td>
                  </tr>
                ) : (
                  items?.map((item, index) => {
                    const {
                      _id,
                      client,
                      brand,
                      createdAt,
                      currency,
                      invoiceId,
                      paid,
                      unit,
                      totalAmount,
                      paidByGateway,
                      salePerson,
                      status,
                      paidAtDateTime,
                      paidAmountInUsd,
                    } = item;
                    return (
                      <tr
                        key={index}
                        onDoubleClick={() => handleRowDoubleClick(_id)}
                        title="Double Click To View Details"
                        style={{ cursor: "pointer" }}
                      >
                        <td>{++index}</td>
                        <td>{client.length > 0 ? client[0].name : "--"}</td>
                        <td onClick={() => handleCopyClipboard(invoiceId)}>
                          <span className="cursor-pointer">
                            {copiedText === invoiceId
                              ? "Copied"
                              : invoiceId || "--"}
                          </span>
                        </td>
                        <td className="text-lowercase">
                          {totalAmount === "" || currency === ""
                            ? "--"
                            : `${totalAmount + " " + currency}`}{" "}
                          {paid && currency !== "usd"
                            ? `= $` + paidAmountInUsd
                            : ""}{" "}
                        </td>
                        <td>
                          <span
                            className={
                              status === "completed"
                                ? "bedge colWhite colbgGreen"
                                : status === "draft"
                                ? "bedge colWhite colbgRed"
                                : status === "linkSend"
                                ? "bedge colWhite colbgYellow"
                                : status === "emailSend"
                                ? "bedge colWhite colbgBlue"
                                : status === "overDue"
                                ? "bedge colWhite colbgDRed"
                                : "bedge colWhite colbgDRed"
                            }
                          >
                            {status === "emailSend"
                              ? "email Send"
                              : status === "overDue"
                              ? "Over Due"
                              : status === "linkSend"
                              ? "Link Send"
                              : status}
                          </span>
                        </td>
                        <td>{new Date(createdAt).toLocaleString()}</td>
                        <td>
                          {paidAtDateTime
                            ? new Date(paidAtDateTime).toLocaleString()
                            : "--"}
                        </td>
                        <td>
                          {salePerson.length > 0 ? salePerson[0].name : "--"}
                        </td>
                        <td>{brand.length > 0 ? brand[0].name : "--"}</td>
                        <td>{unit.length > 0 ? unit[0].name : "--"}</td>
                        <td>
                          {paidByGateway.length > 0 ? (
                            <span
                              className={
                                paidByGateway[0]?.status === "succeeded"
                                  ? "bedge colWhite bgGreen"
                                  : paidByGateway[0]?.status === "Incomplete"
                                  ? "bedge colWhite bgLgBlack"
                                  : "bedge colWhite bgRed"
                              }
                            >
                              {paidByGateway[0]?.status.length > 13 ? (
                                <span>
                                  {`${paidByGateway[0]?.status.slice(
                                    0,
                                    12
                                  )}...`}{" "}
                                  <button
                                    onClick={() =>
                                      handleShowError(paidByGateway[0]?.status)
                                    }
                                    className="smBtn"
                                  >
                                    <FaEye />
                                  </button>
                                </span>
                              ) : (
                                paidByGateway[0]?.status
                              )}
                            </span>
                          ) : (
                            "--"
                          )}
                        </td>
                        <td>
                          {paid === "" ? (
                            "--"
                          ) : paid === true ? (
                            <span className="bedge colWhite bgGreen">Paid</span>
                          ) : (
                            <span className="bedge colWhite bgRed">UnPaid</span>
                          )}
                        </td>
                        <td>
                          {" "}
                          {paidByGateway.length > 0
                            ? paidByGateway[0]?.gatewayName
                            : "--"}{" "}
                        </td>
                        <td>
                          {" "}
                          {paidByGateway.length > 0
                            ? paidByGateway[0]?.accountName
                            : "--"}{" "}
                        </td>
                        <td>
                          {paidByGateway.length > 0
                            ? paidByGateway[0]?.orderId === undefined ||
                              paidByGateway[0]?.orderId === "" ||
                              paidByGateway[0]?.orderId === null
                              ? paidByGateway[0]?.transactionId === undefined ||
                                paidByGateway[0]?.transactionId === "" ||
                                paidByGateway[0]?.transactionId === null
                                ? paidByGateway[0]?.paymentIntent ===
                                    undefined ||
                                  paidByGateway[0]?.paymentIntent === "" ||
                                  paidByGateway[0]?.paymentIntent === null
                                  ? "--"
                                  : paidByGateway[0]?.paymentIntent
                                : paidByGateway[0]?.transactionId
                              : paidByGateway[0]?.orderId
                            : "--"}
                        </td>
                        <td>
                          {" "}
                          {paidByGateway.length > 0
                            ? paidByGateway[0]?.bankName === "" ||
                              paidByGateway[0]?.bankName === null ||
                              paidByGateway[0]?.bankName === undefined
                              ? "--"
                              : paidByGateway[0]?.bankName
                            : "--"}{" "}
                        </td>
                        <td>
                          <Dropdown className="actionDropDown">
                            <Dropdown.Toggle className="customDropDown">
                              <FaEllipsisV className="colBlack" />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item
                                className="drop_item"
                                as={Link}
                                to={`/sales-invoice/${_id}`}
                              >
                                <FaRegEye className="me-1" />
                                <span>View</span>
                              </Dropdown.Item>
                              {paid === true ? (
                                paidByGateway[0]?.gatewayName === "wire" &&
                                (paidByGateway[0].receipt !== "" ||
                                  paidByGateway[0].receipt !== null ||
                                  paidByGateway[0].receipt !== undefined) ? (
                                  <Dropdown.Item
                                    className="drop_item"
                                    onClick={() =>
                                      handleRecieptModaL(
                                        paidByGateway[0]?.receipt
                                      )
                                    }
                                  >
                                    <FaFileInvoice className="me-1" />
                                    <span>View Reciept</span>
                                  </Dropdown.Item>
                                ) : (
                                  ""
                                )
                              ) : (
                                ""
                              )}
                            </Dropdown.Menu>
                          </Dropdown>
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
        </TableCom>
      </div>
      {/*========================================== ! View --Or-- Errors here ! =======================================*/}
      <CustomModal
        show={errorModal}
        backdrop="static"
        keyboard="False"
        size="md"
      >
        <button onClick={() => setErrorModal(false)} className="modal-cancel">
          <FaTimes />
        </button>
        <div className="modalContent">
          <p className="mb-0 fw-600 my-2">{errorText || "--"}</p>
        </div>
      </CustomModal>
      {/*========================================== ! View Reciept Modal ! =======================================*/}
      <CustomModal
        show={recImg !== "" ? true : false}
        backdrop="static"
        keyboard="False"
        size="md"
      >
        <button onClick={() => setRecImg("")} className="modal-cancel">
          <FaTimes />
        </button>
        <div className="modalContent">
          {recImg === null || recImg === undefined || recImg === "" ? (
            "No Reciept Found..."
          ) : (
            <img
              draggable={false}
              src={recImg}
              className="popUpImage"
              alt="recipt"
            />
          )}
        </div>
      </CustomModal>
    </>
  );
};

export default AllInvoicesListings;
