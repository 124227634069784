import React, { useEffect, useState } from "react";
import { getAllBrandsNameAndLogoRoute, assignBrandPpcConsultantByRoute, getPpcConsultantUsersRoute, removeBrandPpcConsultantRoute, setPpcEnabledStatusRoute } from "../../utils/APIRoutes";
import { Row, Col } from "react-bootstrap";
import Brandbox from "../Brandbox";
import Skeleton from 'react-loading-skeleton';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import CustomModal from "../CustomModal";
import { FaTimes } from "react-icons/fa";
import axios from "axios";
import { toast } from "react-toastify";

const AllBrands = () => {
     let toastOptions = {
          position: "top-right",
          autoClose: 5000,
          pauseOnHover: false,
          draggable: false,
          theme: "light",
     };
     const [error, setError] = useState(false);
     const [allBrandsData, setBrandsData] = useState([]);
     const [brandID, setBrandID] = useState("");
     const [consultantUserList, SetConsultantUserList] = useState([]);
     const [consultantData, setConsultantData] = useState("");

     const getAllBrands = async () => {
          const AllbrandsData = await fetch(getAllBrandsNameAndLogoRoute);
          const brandsData = await AllbrandsData.json();
          if (AllbrandsData.status === 500) {
               setError(true)
          } else {
               setBrandsData(brandsData.data);
          }
     }

     const getPpcConsultantUsers = async () => {
          const ppcConsultantUsers = await fetch(getPpcConsultantUsersRoute);
          const consultantData = await ppcConsultantUsers.json();
          if (consultantData) {
               SetConsultantUserList(consultantData.data)
          }
     }

     useEffect(() => {
          getAllBrands();
          getPpcConsultantUsers();
     }, []);


     // Assigning PPC Consultant Code Here 
     const [assignModalState, setAssignModalState] = useState(false);
     const [removeAssignModalState, setRemoveAssignModalState] = useState(false);
     const [isLoading, setIsLoading] = useState(false);

     const handleAssignigConsultant = async (e) => {
          e.preventDefault();
          try {
               setIsLoading(true)
               if (consultantData) {
                    const consultData = JSON.parse(consultantData);
                    const resultant = await axios.post(`${assignBrandPpcConsultantByRoute}?brandId=${brandID}`, {
                         ppcUserId: consultData._id,
                         ppcUserName: consultData.employeeName
                    })
                    if (resultant) {
                         toast.success(resultant.data.msg, toastOptions);
                         setIsLoading(false)
                         setAssignModalState(false)
                         setConsultantData("")
                         getAllBrands();
                    } else {
                         toast.error(resultant.data.msg, toastOptions);
                         setIsLoading(false)
                         setAssignModalState(false)
                         setConsultantData("")
                    }
               } else {
                    setIsLoading(false)
                    setAssignModalState(false)
                    setConsultantData("")
                    toast.error("Please Select PPC Consultant", toastOptions);
               }
          } catch (error) {
               setIsLoading(false)
               console.log("handleAssignigConsultant ~ error:", error)
               setAssignModalState(false)
          }
     }

     // Remove Consultant 
     const handleRemoveConsultant = async () => {
          try {
               setIsLoading(true)
               const resultant = await axios.post(`${removeBrandPpcConsultantRoute}?brandId=${brandID}`)
               if (resultant) {
                    toast.success(resultant.data.msg, toastOptions);
                    setIsLoading(false)
                    setRemoveAssignModalState(false)
                    getAllBrands();
               } else {
                    toast.error(resultant.data.msg, toastOptions);
                    setIsLoading(false)
                    setRemoveAssignModalState(false)
               }
          } catch (error) {
               setIsLoading(false)
               setRemoveAssignModalState(false)
               console.log("handleAssignigConsultant ~ error:", error)
          }
     }

     // PPC Status Start
     const ppcToggle = async (_id, ppcStatus) => {
          try {
               const resultant = await axios.post(`${setPpcEnabledStatusRoute}?brandId=${_id}&status=${ppcStatus}`)
               if (resultant) {
                    if (resultant.data.success === true) {
                         toast.success(resultant.data.msg, toastOptions);
                         getAllBrands();
                    } else {
                         console.log("🚀 ~ file: AllBrands.js:118 ~ ppcToggle ~ resultant:", resultant)
                         toast.error(resultant.data.msg, toastOptions);
                    }
               } else {
                    toast.error(resultant.data.msg, toastOptions);
               }
          } catch (error) {
               console.log("ppcToggle ~ error:", error)
          }
     }

     return (
          <>
               <Row className="my-4">
                    {error === true ? (
                         <div className="text-center">
                              <div className='brandBox'>
                                   <Skeleton count={1} height={35} />
                                   <Skeleton count={1} />
                              </div>
                         </div>
                    ) : allBrandsData?.map((brandData) => {
                         const { brandLogoUrl, brandName, brandWebsite, ppcUserName, ppcUserAssingedOn, _id, ppcEnable } = brandData;
                         return (
                              <OverlayTrigger key={_id} placement="top"
                                   overlay={
                                        <Popover id="popover-basic">
                                             <Popover.Header as="h3">PPC Consultant Details</Popover.Header>
                                             <Popover.Body>
                                                  {(ppcUserName === "" || ppcUserName === null) ? "No PPC Consultant Assigned In This Brand" : `PPC Consultant, ${ppcUserName}, Assigned To This Brand On ${ppcUserAssingedOn !== null ? (ppcUserAssingedOn.slice(0, 10).split("-").reverse().join("-") || "--") : ""}.`}
                                             </Popover.Body>
                                        </Popover>
                                   }>
                                   <Col xxl={3} md={4} sm={6} className="position-relative">
                                        {ppcEnable === false ? <button title="Start PPC" onClick={() => ppcToggle(_id, true)} className="ppcStatus actionIcon border-none bgOrange">Start PPC</button> : <button title="Stop PPC" onClick={() => ppcToggle(_id, false)} className="ppcStatus actionIcon border-none bgRed">Stop PPC</button>}
                                        {(ppcUserName === "" || ppcUserName === null) ? <button onClick={() => { setAssignModalState(true); setBrandID(_id) }} className="assigningBtn">Assign PPC Consultant</button> : <button onClick={() => { setRemoveAssignModalState(true); setBrandID(_id) }} className="assigningBtn removeConsultant">Remove PPC Consultant</button>}
                                        <Brandbox brandLogoUrl={brandLogoUrl} brandName={brandName} brandWebsite={brandWebsite} />
                                   </Col>
                              </OverlayTrigger>
                         )
                    })}
               </Row>
               {/* ==================================================|| Assign PPC Modal State Code ||================================================== */}
               <CustomModal show={assignModalState} onHide={() => setAssignModalState(false)} backdrop='static' keyboard="False" size="md">
                    <button onClick={() => { setAssignModalState(false); setBrandID(""); setConsultantData("") }} className="modal-cancel"><FaTimes /></button>
                    <div className="modalContent">
                         <form onSubmit={(event) => handleAssignigConsultant(event)}>
                              <div className="my-4 text-start">
                                   <label htmlFor="selectUser">Select PPC Consultant</label>
                                   <select onChange={(e) => setConsultantData(e.target.value)} className="primary-inputs px-3" name="selectUser" id="selectUser">
                                        <option value="">Select PPC Consultant</option>
                                        {consultantUserList.length > 0 ? (
                                             consultantUserList?.map((consultantList) => {
                                                  const { employeeName, _id } = consultantList
                                                  return (<option key={_id} value={JSON.stringify(consultantList)}>{employeeName}</option>)
                                             })) : ""}
                                   </select>
                              </div>
                              <div className="mt-3">
                                   <button className="main-btn m-2" disabled={isLoading} type="submit"> {isLoading ? 'Assigning...' : "Assign"} </button>
                                   <button className="sec-btn m-2" type="button" onClick={() => { setAssignModalState(false); setConsultantData(""); setBrandID("") }}>Cancel</button>
                              </div>
                         </form>
                    </div>
               </CustomModal>
               {/* ==============================================|| Remove Assigned PPC Modal State Code ||============================================== */}
               <CustomModal show={removeAssignModalState} onHide={() => setRemoveAssignModalState(false)} backdrop='static' keyboard="False" size="md">
                    <button onClick={() => { setRemoveAssignModalState(false); setBrandID("") }} className="modal-cancel"><FaTimes /></button>
                    <div className="modalContent">
                         <h6 className="my-2 mt-4 text-capitalize">Are You Sure you want to remove this PPC Consultant From Brand ?</h6>
                         <div className="mt-3">
                              <button className="main-btn m-2" disabled={isLoading} onClick={handleRemoveConsultant}> {isLoading ? 'Removing...' : "Yes"} </button>
                              <button className="sec-btn m-2" type="button" onClick={() => { setRemoveAssignModalState(false); setBrandID("") }}>No</button>
                         </div>
                    </div>
               </CustomModal>
          </>
     );
};

export default AllBrands; 