import React, { useState, useEffect, useCallback } from "react";
import { TableCom } from "../../../Components/TableCom";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  getMyInvoicesBySalesPersonRoute,
  getActiveWireGatewayAccountsRoute,
  getGeoLocationRoute,
  updatePaidStatusThroughWirePaymentByInvoiceIdRoute,
} from "../../../utils/APIRoutes";
import { AiFillBank } from "react-icons/ai";
import Dropdown from "react-bootstrap/Dropdown";
import Title from "../../../hook/Title";
import {
  FaRegEye,
  FaEllipsisV,
  FaTimes,
  FaEye,
  FaFileInvoice,
} from "react-icons/fa";
import { useSelector } from "react-redux";
import currencyCodes from "../../../helpers/currencyCodes.json";
import { toast } from "react-toastify";
import axios from "axios";
import { Row, Col } from "react-bootstrap";
import CustomModal from "../../../Components/CustomModal";
import useClipboard from "../../../hook/useClipboard";
import { debounce } from "lodash";

const MyInvoices = () => {
  Title("My Invoices");
  // const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const location = useLocation();
  const { handleCopyClipboard, copiedText } = useClipboard();

  // Retrieve the 'fromDate' from localStorage, or set it to the first day of the current month
  const storedFromDate = localStorage.getItem("fromDate");

  // Set up the state with the retrieved or default value
  const [filterDates, setfilterDates] = useState({
    from: storedFromDate || "",
    to: "",
  });

  // const handleDateChange = (e) => {
  //   setfilterDates({ ...filterDates, [e.target.name]: e.target.value });
  // };

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    setfilterDates((prevDates) => {
      const newDates = { ...prevDates, [name]: value };
      localStorage.setItem("filterDatesTwo", JSON.stringify(newDates)); // Save to localStorage
      return newDates;
    });
  };

  useEffect(() => {
    const storedDates = localStorage.getItem("filterDatesTwo");
    if (storedDates) {
      setfilterDates(JSON.parse(storedDates));
    }
  }, []);

  // const clearFilters = () => {
  //   setfilterDates({ from: "", to: "" }); // Clear from localStorage
  // };

  const clearFilters = () => {
    setfilterDates({ from: "", to: "" });
    localStorage.removeItem("filterDatesTwo"); // Clear from localStorage
    localStorage.removeItem("fromDate"); // Clear fromDate from localStorage
  };

  const { from, to } = filterDates;

  let toastOptions = {
    position: "top-left",
    autoClose: 5000,
    pauseOnHover: false,
    draggable: false,
    theme: "dark",
  };

  const AuthReducer = useSelector((state) => state.AuthReducer);
  const { empId } = AuthReducer.data;
  // Table Head Data
  const [thLength, setThlength] = useState(1);
  const [items, setItems] = useState([]);
  const [numPerPage, setNumPerPage] = useState(10);
  const [totalData, setTotalData] = useState(0);
  const [pageCount, setpageCount] = useState(0);
  const [currPage, setCurrPage] = useState(0);
  const [searchVal, setSearchVal] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const Thead = [
    "S.No",
    "Client",
    "invoice Id",
    "Total Amount",
    "Invoice Status",
    "created At",
    "Payment Time",
    "brand",
    "unit",
    "Gateway Used",
    "Payment Status Code",
    "Invoice Payment",
    "Account Manager",
    "Action",
  ];

  useEffect(
    (currPage) => {
      currPage = currPage ?? 0;
      const getData = async () => {
        const res = await fetch(
          `${getMyInvoicesBySalesPersonRoute}?empId=${empId}&numPerPage=${numPerPage}&page=${currPage}&search=${searchVal}&from=${from}&to=${to}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (res.status === 401) {
          navigate("/");
          toast.error(
            "Session has been Expired",
            toastOptions,
            (toastOptions["position"] = "top-right")
          );
          return;
        }
        const data = await res.json();
        const total = data.pagiantion.dataCount;
        setTotalData(total);
        setpageCount(Math.ceil(total / numPerPage));
        setItems(data.result);
        //------------------------------------------------------
        console.log(data);
      };
      getData();
    },
    [numPerPage, searchVal, from, to]
  );
  useEffect(() => {
    setThlength(Thead.length);
  }, [thLength]);
  const fetchData = async (currPage) => {
    const res = await fetch(
      `${getMyInvoicesBySalesPersonRoute}?empId=${empId}&numPerPage=${numPerPage}&page=${currPage}&search=${searchVal}&from=${from}&to=${to}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (res.status === 401) {
      navigate("/");
      toast.error(
        "Session has been Expired",
        toastOptions,
        (toastOptions["position"] = "top-right")
      );
      return;
    }
    const changedData = await res.json();
    const data = changedData.result;
    return data;
  };

  const handlePageClick = async (data) => {
    let currPage = data.selected;
    const myData = await fetchData(currPage);
    setItems(myData);
    setCurrPage(currPage);
  };

  const handleChange = (e) => {
    setNumPerPage(e.target.value);
  };

  // stored search value in local storage
  useEffect(() => {
    const storedSearchValue = localStorage.getItem("searchValueTwo");
    const searchValueFromState = location.state?.searchValue;

    if (searchValueFromState) {
      setSearchVal(searchValueFromState);
      localStorage.setItem("searchValueTwo", searchValueFromState);
    } else if (storedSearchValue) {
      setSearchVal(storedSearchValue);
    }
  }, [location.state]);

  // Debounced search function
  const debouncedSearch = useCallback(
    debounce((value) => {
      setSearchVal(value);
    }, 500),
    []
  );

  // search data in api using search box
  const hangleSearchVal = (e) => {
    const value = e.target.value;
    debouncedSearch(value);
    localStorage.setItem("searchValueTwo", value); // Store search value in localStorage
  };

  const handleRowDoubleClick = (_id) => {
    navigate(`/sales-invoice/${_id}`, { state: { searchValue: searchVal } });
  };

  // ------------- GET All Active Wire/Bank Accounts ------------------ //
  //  payment By Bank Transfer Form completion
  const [bankFormModal, setBankFormModal] = useState(false);
  const [invoiceObjectId, setInvoiceObjectId] = useState("");
  const [invId, setInvId] = useState("");
  const [transId, setTransId] = useState("");
  const [wireData, setWireData] = useState([]);
  const [servError, setServError] = useState(false);
  const [wireAccount, setWireAccount] = useState({});
  const [currency, setCurrency] = useState("");
  const [trackedResult, setTrackedResult] = useState("");
  const [attachement, setAttachement] = useState("");
  const allCurrencyCodes = currencyCodes.codes;

  const getActiveWireGatewayAccounts = async () => {
    try {
      const wire_accounts = await axios
        .get(getActiveWireGatewayAccountsRoute)
        .catch(function (error) {
          if (error.response) {
            if (error.response.status === 500) {
              setServError(true);
            }
          }
        });
      if (wire_accounts?.data.success === true) {
        setWireData(wire_accounts.data.wire);
      } else {
        console.log(
          "error In Getting all Wire Accounts",
          wire_accounts.data.msg
        );
      }
    } catch (error) {
      console.log("error In Getting all Wire Accounts", error);
    }
  };
  const handleBankTransferForm = (_id, invoiceId) => {
    setBankFormModal(true);
    setInvoiceObjectId(_id);
    setInvId(invoiceId);
    getActiveWireGatewayAccounts();
    getGeoLocation();
  };

  const [errors, setErrors] = useState({});
  let hasError = false;
  const validateForm = () => {
    setIsLoading(true);
    const newErrors = {};
    if (
      wireAccount === null ||
      wireAccount === undefined ||
      wireAccount === "" ||
      JSON.stringify(wireAccount) === "{}"
    ) {
      hasError = true;
      setIsLoading(false);
      newErrors.bank = "Bank Details Required !";
    }
    if (currency === null || currency === undefined || currency === "") {
      hasError = true;
      setIsLoading(false);
      newErrors.currency = "Currency Is Required !";
    }
    setErrors(newErrors);
  };
  const handleBankDetailsSubmit = async (e) => {
    e.preventDefault();
    validateForm();
    if (hasError === false) {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("bankName", JSON.parse(wireAccount)?.bankName);
      formData.append("accountName", JSON.parse(wireAccount)?.accountName);
      formData.append("transactionId", transId);
      formData.append("trackingDetails", trackedResult);
      formData.append("currency", currency);
      formData.append("gatewayId", JSON.parse(wireAccount)?._id);
      formData.append("receipt", attachement);
      const { data } = await axios.put(
        `${updatePaidStatusThroughWirePaymentByInvoiceIdRoute}?invoiceObjectId=${invoiceObjectId}`,
        formData
      );
      if (data.success === true) {
        toast.success(
          data.msg,
          toastOptions,
          (toastOptions["position"] = "top-right")
        );
        setIsLoading(false);
        closeWireModal();
        window.location.reload();
      } else {
        toast.error(
          data.msg,
          toastOptions,
          (toastOptions["position"] = "top-right")
        );
        closeWireModal();
        setIsLoading(false);
      }
    } else {
      toast.error(
        "Kindly Fill the require fields !",
        toastOptions,
        (toastOptions["position"] = "top-right")
      );
      setBankFormModal(false);
      setIsLoading(false);
    }
  };
  const getGeoLocation = async () => {
    const trackedResponse = await fetch(getGeoLocationRoute);
    const trackedResult = await trackedResponse.json();
    setTrackedResult(trackedResult);
  };

  const closeWireModal = () => {
    setBankFormModal(false);
    setWireAccount("");
    setTransId("");
    setCurrency("");
    setWireAccount({});
  };
  //======================== Show Complete Error When Clicking on show Btn ========================//
  const [errorModal, setErrorModal] = useState(false);
  const [errorText, setErrorText] = useState(false);
  const handleShowError = (errorDetails) => {
    setErrorModal(true);
    setErrorText(errorDetails);
  };
  //  Reciept Image ModaL
  const [recImg, setRecImg] = useState("");
  const handleRecieptModaL = (recpImg) => {
    setRecImg(recpImg);
  };
  return (
    <>
      <div className="d-flex align-items-center justify-content-between flex-wrap  mb-3">
        <h4 className="primHeading my-2">My Invoices</h4>
        <Link className="main-btn" to="/create-invoice">
          Create Invoice
        </Link>
      </div>
      <div className="box my-3">
        <h4 className="sectionHeading my-2 mb-4">My Invoices Summary</h4>
        {/*==========================|||****** Filters Start Here ********|||==========================*/}
        <Row className="customFilters">
          <Col xl={2} lg={4} md={6} sm={5} className="my-2">
            <label className="text-bold" htmlFor="From">
              From:
            </label>
            <input
              value={from}
              onChange={handleDateChange}
              className="prim-date"
              type="date"
              name="from"
              id="From"
            />
          </Col>
          <Col xl={2} lg={4} md={6} sm={5} className="my-2">
            <label className="text-bold" htmlFor="To">
              To:
            </label>
            <input
              value={to}
              onChange={handleDateChange}
              className="prim-date"
              type="date"
              name="to"
              id="To"
            />
          </Col>
          <Col xl={2} lg={4} md={6} sm={2} className="my-2">
            <button
              onClick={clearFilters}
              title="Clear Date Filters"
              className="main-btn clearFilter w-100 mt-4"
            >
              Clear
            </button>
          </Col>
        </Row>
        <hr />
        <TableCom
          numPerPage={numPerPage}
          totalData={totalData}
          handleChange={handleChange}
          hangleSearchVal={hangleSearchVal}
          pageCount={pageCount}
          handlePageClick={handlePageClick}
          searcPlaceHolder="Search..."
          searchVal={searchVal}
        >
          <div className="customTable2 w-100 table-responsive">
            <table className="w-100">
              <thead>
                <tr>
                  {Thead?.map((th, index) => {
                    return <th key={index}>{th}</th>;
                  })}
                </tr>
              </thead>
              <tbody>
                {items == "" || items == undefined || items == null ? (
                  <tr>
                    <td colSpan={thLength}>
                      <p className="my-2 smText text-decoration-underline text-danger">
                        No Record Found
                      </p>
                    </td>
                  </tr>
                ) : (
                  items?.map((item, index) => {
                    const {
                      _id,
                      client,
                      brand,
                      createdAt,
                      currency,
                      invoiceId,
                      paid,
                      unit,
                      totalAmount,
                      paidByGateway,
                      accountManager,
                      status,
                      paidAtDateTime,
                      paidAmountInUsd,
                    } = item;
                    return (
                      <tr
                        key={index}
                        onDoubleClick={() => {
                          handleRowDoubleClick(_id);
                        }}
                        title="Double Click To View Details"
                        style={{ cursor: "pointer" }}
                      >
                        <td>{++index}</td>
                        <td>{client.length > 0 ? client[0].name : "--"}</td>
                        <td onClick={() => handleCopyClipboard(invoiceId)}>
                          <span className="cursor-pointer">
                            {copiedText === invoiceId
                              ? "Copied"
                              : invoiceId || "--"}
                          </span>
                        </td>
                        <td className="">
                          {totalAmount === "" || currency === ""
                            ? "--"
                            : `${
                                currency === "usd"
                                  ? "$"
                                  : currency === "cad"
                                  ? "C$"
                                  : currency === "gbp"
                                  ? "£"
                                  : currency === "aud"
                                  ? "A$"
                                  : currency === "eur"
                                  ? "€"
                                  : ""
                              } ${totalAmount}`}{" "}
                          {paid && currency !== "usd"
                            ? `= $` + paidAmountInUsd
                            : ""}
                        </td>
                        <td>
                          <span
                            className={
                              status === "completed"
                                ? "bedge colWhite colbgGreen"
                                : status === "draft"
                                ? "bedge colWhite colbgRed"
                                : status === "linkSend"
                                ? "bedge colWhite colbgYellow"
                                : status === "emailSend"
                                ? "bedge colWhite colbgBlue"
                                : status === "overDue"
                                ? "bedge colWhite colbgDRed"
                                : "bedge colWhite colbgDRed"
                            }
                          >
                            {status === "emailSend"
                              ? "email Send"
                              : status === "overDue"
                              ? "Over Due"
                              : status === "linkSend"
                              ? "Link Send"
                              : status}
                          </span>
                        </td>
                        <td>{new Date(createdAt).toLocaleString()}</td>
                        <td>
                          {paidAtDateTime
                            ? new Date(paidAtDateTime).toLocaleString()
                            : "--"}
                        </td>
                        <td>{brand.length > 0 ? brand[0].name : "--"}</td>
                        <td>{unit.length > 0 ? unit[0].name : "--"}</td>
                        <td>
                          {" "}
                          {paidByGateway.length > 0
                            ? paidByGateway[0]?.gatewayName
                            : "--"}{" "}
                        </td>
                        <td>
                          {paidByGateway.length > 0 ? (
                            <span
                              className={
                                paidByGateway[0]?.status === "succeeded"
                                  ? "bedge colWhite bgGreen"
                                  : paidByGateway[0]?.status === "Incomplete"
                                  ? "bedge colWhite bgLgBlack"
                                  : "bedge colWhite bgRed"
                              }
                            >
                              {paidByGateway[0]?.status.length > 13 ? (
                                <span>
                                  {`${paidByGateway[0]?.status.slice(
                                    0,
                                    12
                                  )}...`}{" "}
                                  <button
                                    onClick={() =>
                                      handleShowError(paidByGateway[0]?.status)
                                    }
                                    className="smBtn"
                                  >
                                    <FaEye />
                                  </button>
                                </span>
                              ) : (
                                paidByGateway[0]?.status
                              )}
                            </span>
                          ) : (
                            "--"
                          )}
                        </td>
                        <td>
                          {paid === "" ? (
                            "--"
                          ) : paid === true ? (
                            <span className="bedge colWhite bgGreen">Paid</span>
                          ) : (
                            <span className="bedge colWhite bgRed">UnPaid</span>
                          )}
                        </td>
                        <td>
                          {accountManager.length > 0
                            ? accountManager[0].name
                            : "--"}
                        </td>
                        <td>
                          <Dropdown className="actionDropDown">
                            <Dropdown.Toggle className="customDropDown">
                              <FaEllipsisV className="colBlack" />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item
                                className="drop_item"
                                as={Link}
                                to={`/sales-invoice/${_id}`}
                              >
                                <FaRegEye className="me-1" />
                                <span>View</span>
                              </Dropdown.Item>
                              {paid === false ? (
                                <Dropdown.Item
                                  className="drop_item"
                                  onClick={() =>
                                    handleBankTransferForm(_id, invoiceId)
                                  }
                                >
                                  <AiFillBank className="me-1" />
                                  <span>Wire/Bank Transfer</span>
                                </Dropdown.Item>
                              ) : (
                                ""
                              )}
                              {paid === true ? (
                                paidByGateway[0].gatewayName === "wire" &&
                                (paidByGateway[0].receipt !== "" ||
                                  paidByGateway[0].receipt !== null ||
                                  paidByGateway[0].receipt !== undefined) ? (
                                  <Dropdown.Item
                                    className="drop_item"
                                    onClick={() =>
                                      handleRecieptModaL(
                                        paidByGateway[0].receipt
                                      )
                                    }
                                  >
                                    <FaFileInvoice className="me-1" />
                                    <span>View Reciept</span>
                                  </Dropdown.Item>
                                ) : (
                                  ""
                                )
                              ) : (
                                ""
                              )}
                            </Dropdown.Menu>
                          </Dropdown>
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
        </TableCom>
      </div>
      {/*========================================== ! View --Or-- Edit Deals Modal code start here ! =======================================*/}
      <CustomModal
        show={bankFormModal}
        backdrop="static"
        keyboard="False"
        size="md"
      >
        <button onClick={closeWireModal} className="modal-cancel">
          <FaTimes />
        </button>
        <div className="modalContent">
          {/* modal text */}
          <form onSubmit={(e) => handleBankDetailsSubmit(e)}>
            <h5 className="text-center my-2">Confirm Wire/Bank Transfer</h5>
            <small className="text-center my-2">
              <b>{invId || ""}</b>
            </small>
            {servError === true ? (
              <h5 className="text-center my-2">
                Something Went Wrong Please Try Again Later !
              </h5>
            ) : (
              <Row className="text-start">
                {wireData.length > 0 ? (
                  <Col lg={12} className="my-2">
                    <label className="text-bold mb-1" htmlFor="bankDetails">
                      Select Bank<span className="text-danger">*</span>
                    </label>
                    <select
                      className="filterSelect ps-3"
                      id="bankDetails"
                      name="bankDetails"
                      onChange={(e) => setWireAccount(e.target.value)}
                    >
                      <option value="">Select Bank</option>
                      {wireData?.map((wireData, index) => {
                        const { bankName, accountName } = wireData;
                        return (
                          <option
                            key={index}
                            value={JSON.stringify(wireData)}
                          >{`${bankName} (${accountName})`}</option>
                        );
                      })}
                    </select>
                    {errors.bank && (
                      <p className="text-danger fw-500 fs-13 my-2 text-capitalize">
                        {errors.bank}
                      </p>
                    )}
                  </Col>
                ) : (
                  ""
                )}
                {JSON.stringify(wireAccount) === "{}" ||
                wireAccount === null ||
                wireAccount === undefined ||
                wireAccount === "" ? (
                  ""
                ) : (
                  <>
                    <div className="my-2">
                      <label htmlFor="accTitle">Bank Name</label>
                      <p className="mb-0">
                        {JSON.parse(wireAccount)?.bankName}
                      </p>
                    </div>
                    <div className="my-2">
                      <label htmlFor="accTitle">Accont Title</label>
                      <p className="mb-0">
                        {JSON.parse(wireAccount)?.accountName}
                      </p>
                    </div>
                  </>
                )}
                <Col lg={12} className="my-2">
                  <label className="text-bold mb-1" htmlFor="transactionId">
                    Transaction Id:
                  </label>
                  <input
                    value={transId}
                    onChange={(e) => setTransId(e.target.value)}
                    name="transactionId"
                    id="transactionId"
                    type="text"
                    className="filterSelect ps-3"
                    placeholder="Enter transaction Id"
                  />
                </Col>
                <Col lg={12} className="my-2">
                  <label className="text-bold mb-1" htmlFor="currency">
                    Currency<span className="text-danger">*</span>
                  </label>
                  <select
                    className="filterSelect ps-3"
                    id="currency"
                    name="currency"
                    value={currency}
                    onChange={(e) => setCurrency(e.target.value)}
                  >
                    <option value="">Select Option</option>
                    {Object.keys(allCurrencyCodes).map((objkey, index) => {
                      return (
                        <option
                          key={index}
                          value={objkey}
                        >{`${allCurrencyCodes[objkey]} (${objkey})`}</option>
                      );
                    })}
                  </select>
                  {errors.currency && (
                    <p className="text-danger fw-500 fs-13 my-2 text-capitalize">
                      {errors.currency}
                    </p>
                  )}
                </Col>
                <Col lg={12} className="my-2">
                  <label className="text-bold mb-1" htmlFor="attachment">
                    Attachment:
                  </label>
                  <input
                    onChange={(e) => setAttachement(e.target.files[0])}
                    type="file"
                    className="d-block"
                    id="attachment"
                    accept="image/png, image/jpeg"
                  />
                </Col>
                <Col lg={12} className="my-2 text-center">
                  <button type="submit" className="main-btn">
                    {isLoading ? "Submiting..." : "Submit"}
                  </button>
                </Col>
              </Row>
            )}
          </form>
        </div>
      </CustomModal>
      {/*========================================== ! View --Or-- Errors here ! =======================================*/}
      <CustomModal
        show={errorModal}
        backdrop="static"
        keyboard="False"
        size="md"
      >
        <button onClick={() => setErrorModal(false)} className="modal-cancel">
          <FaTimes />
        </button>
        <div className="modalContent">
          <p className="mb-0 fw-600 my-2">{errorText || "--"}</p>
        </div>
      </CustomModal>
      {/*========================================== ! View Reciept Modal ! =======================================*/}
      <CustomModal
        show={recImg !== "" ? true : false}
        backdrop="static"
        keyboard="False"
        size="md"
      >
        <button onClick={() => setRecImg("")} className="modal-cancel">
          <FaTimes />
        </button>
        <div className="modalContent">
          {recImg === null || recImg === undefined || recImg === "" ? (
            "No Reciept Found..."
          ) : (
            <img
              draggable={false}
              src={recImg}
              className="popUpImage"
              alt="recipt"
            />
          )}
        </div>
      </CustomModal>
    </>
  );
};

export default MyInvoices;
