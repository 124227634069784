import React, { useState, useEffect, useRef } from 'react';
import { Col, Row } from 'react-bootstrap';
import Title from '../../../hook/Title';
import ReactToPrint from "react-to-print";
import { getInvoiceByIdRoute } from '../../../utils/APIRoutes';
import { useParams } from 'react-router-dom';
import currencySymbols from "../../../helpers/currencySymbols.json";
import { FaPrint, FaDownload, FaRegCopy, FaTimes } from "react-icons/fa";
import CustomModal from "../../../Components/CustomModal";
import { Link } from 'react-router-dom';
import "../../../assets/css/invoice.css";
import { useSelector } from 'react-redux';

const SalesInvoice = () => {
     Title("Client Ivoice");
     let currencyCodeSymbols = currencySymbols.codes;
     const AuthReducer = useSelector((state) => state.AuthReducer);
     const { designation, role } = AuthReducer.data;
     
     const { _id } = useParams();
     let componentRef = useRef();
     const [servError, setServError] = useState(false);
     const [cancelShow, setCancelShow] = useState(false);
     ////////////////////////////// Storing Data of Brand //////////////////////////
     const [brand, setBrand] = useState([]);
     const [client, setClient] = useState([]);
     const [invoice, setInvoice] = useState([]);
     const [salePerson, setSalePerson] = useState([]);
     const [lineItems, setLineItems] = useState([]);
     const [invStatus, setInvStatus] = useState("");
     // const [paymentGatewayAvailable, setPaymentGatewayAvailable] = useState([]);
     const [paidByGateway, setPaidByGateway] = useState([]);
     const { name, email, organization, phone } = client;
     const { brandEmail, brandName, brandPhone, brandWebsite, brandLogoUrl } = brand;
     const { invoiceId, dueDate, createdAt, currency, paid, status, paidAtDateTime, disputeDateTime } = invoice;
     let InvDueDate = new Date(parseInt(dueDate));
     InvDueDate = InvDueDate.toString();
     
     /////////////////////////////  Fetch Invoice Api  /////////////////////////////  
     const getInvoiceDetails = async () => {
          try {
               const result = await fetch(`${getInvoiceByIdRoute}?invoiceId=${_id}`);
               const response = await result.json();
               if (response.success === true) {
                    setBrand(response.brand);
                    setClient(response.client);
                    setInvoice(response.invoice);
                    setSalePerson(response.salePerson);
                    setLineItems(response.invoice.lineItems);
                    setPaidByGateway(response.invoice.paidByGateway);
                    setInvStatus(response.invoice.status)
                    setServError(false);
               } else {
                    throw new Error('Something went wrong.');
               }
          } catch (error) {
               setServError(true);
          }
     }
     useEffect(() => {
          getInvoiceDetails();
     }, []);
     // variable to store the data to be copied. 
     const [isCopied, setIsCopied] = useState(false);
     // const clientUrl = `http://localhost:3000/pms/invoice/${_id}`
     // const clientUrl = `https://dedicatetestserver.com/pms/invoice/${_id}`
     const clientUrl = `https://payurinvoice.com/invoice/${_id}`
     // onClick handler function for the copy button
     const handleCopyClick = () => {
          // Asynchronously call copyTextToClipboard
          copyTextToClipboard(clientUrl)
               .then(() => {
                    // If successful, update the isCopied state value
                    setIsCopied(true);
                    setTimeout(() => {
                         setIsCopied(false);
                    }, 1500);
               })
               .catch((err) => {
                    console.log(err);
               });
     }
     const copyTextToClipboard = async (clientUrl) => {
          if ('clipboard' in navigator) {
               return await navigator.clipboard.writeText(clientUrl);
          }
     }
     return (
          <>
               {servError === true ? (<p className='text-danger fw-400 text-center my-3'>Some Thing went wrong Please try again later (500).</p>) : (
                    <>
                         {
                              designation === "lead_generator" || designation === "consultant" ? "" : (
                                   <Row>
                                        <Col xxl={6} xl={8} className="mx-auto my-3 mt-5">
                                             <div className="d-flex align-items-center justify-content-between flex-wrap">
                                                  <div>
                                                       <ReactToPrint
                                                            trigger={() => <button className='sm-btn main-btn rounded-1 me-3'><FaDownload /> Download</button>}
                                                            content={() => componentRef}
                                                       />
                                                       <ReactToPrint
                                                            trigger={() => <button className='sm-btn main-btn rounded-1 me-3'><FaPrint /> Print</button>}
                                                            content={() => componentRef}
                                                       />
                                                  </div>
                                                  {paid === false && (invStatus === "refunded" || invStatus === "partially_refunded") ? "" : paid === false && invStatus !== "cancelled" && designation !== "head_of_department" &&  role !== "accounts"? <button onClick={() => setCancelShow(true)} className='sec-btn bg-danger rounded-1'>Cancel Invoice</button> : ""}
                                                  {
                                                       invStatus === "cancelled" || paid === true || invStatus === "refunded" || invStatus === "partially_refunded" ? "" : (
                                                            <div className="d-flex align-items-center">
                                                                 <label className='me-2' htmlFor="copyLink">Payment Link</label>
                                                                 <button id='copyLink' className={isCopied === true ? "sm-btn main-btn rounded-1 bgGreen" : "sm-btn main-btn rounded-1"} onClick={handleCopyClick}> <span>{isCopied ? 'Link Copied!' : <FaRegCopy />}</span></button>
                                                            </div>
                                                       )
                                                  }
                                             </div>
                                        </Col>
                                   </Row>
                              )
                         }
                         <div className="overflow-x-scroll">
                              <div className="ember-view a4page" ref={(el) => (componentRef = el)}>
                                   <div className="pcs-template">
                                        <div className="pcs-template-header pcs-header-content">
                                             <div className="pcs-template-fill-emptydiv" />
                                        </div>
                                        <div className="pcs-template-body">
                                             <table style={{ width: '100%', tableLayout: 'fixed' }}>
                                                  <tbody>
                                                       <tr>
                                                            <td>
                                                                 <div className="uImg mb-2">
                                                                      {
                                                                           brandLogoUrl === ("" || null || undefined) ? "" : <img draggable={false} src={brandLogoUrl} alt="brand logo" className="img-fluid" />
                                                                      }
                                                                 </div>
                                                                 <p className="fw-bold my-0">From,</p>
                                                                 <p className="fw-bold my-0">{brandName || ""}</p>
                                                                 <p className="my-0">{brandEmail || ""}</p>
                                                                 {brandWebsite !== "" ? <a title='View Website' href={brandWebsite} className="my-0">{brandWebsite || ""}</a> : ""}
                                                                 <p className="my-0">{brandPhone || ""}</p>
                                                            </td>
                                                            <td className="text-align-right v-top">
                                                                 <span className="pcs-entity-title fw-600">INVOICE</span><br />
                                                                 <span style={{ fontSize: '10pt' }} className="pcs-label"><b>{invoiceId || ""}</b></span>
                                                            </td>
                                                       </tr>
                                                  </tbody>
                                             </table>
                                             <table style={{ clear: 'both', width: '100%', marginTop: '30px', tableLayout: 'fixed' }}>
                                                  <tbody>
                                                       <tr>
                                                            <td>
                                                                 <div className="billto-section">
                                                                      <label style={{ fontSize: '10pt' }} className="pcs-label">Bill To,</label>
                                                                      <br />
                                                                      <span className='d-block'><strong><span className="pcs-customer-name">{name || ""}</span></strong></span>
                                                                      <span className='d-block'><strong><span className="pcs-customer-name">{email || ""}</span></strong></span>
                                                                      <span className='d-block'><strong><span className="pcs-customer-name">{phone || ""}</span></strong></span>
                                                                      <span className='d-block'><strong><span className="pcs-customer-name">{organization || ""}</span></strong></span>
                                                                 </div>
                                                            </td>
                                                            <td style={{ verticalAlign: 'bottom', width: '40%' }}>
                                                                 <table style={{ float: 'right', width: '100%', tableLayout: 'fixed', wordWrap: 'break-word' }} cellSpacing={0} cellPadding={0}>
                                                                      <tbody>
                                                                           {
                                                                                salePerson?.pseudoName !== "" || salePerson?.pseudoName !== undefined || salePerson?.pseudoName !== null ? (
                                                                                     <tr>
                                                                                          <td style={{ padding: '5px 10px 5px 0px', fontSize: '10pt' }} className="text-align-right">
                                                                                               <span className="pcs-label"><b>Sales Agent :</b></span>
                                                                                          </td>
                                                                                          <td className="text-align-right">
                                                                                               <span>{salePerson?.pseudoName || ""}</span>
                                                                                          </td>
                                                                                     </tr>
                                                                                ) : ""
                                                                           }
                                                                           <tr>
                                                                                <td style={{ padding: '5px 10px 5px 0px', fontSize: '10pt' }} className="text-align-right">
                                                                                     <span className="pcs-label"><b>Invoice Date :</b></span>
                                                                                </td>
                                                                                <td className="text-align-right">
                                                                                     <span>{new Date(createdAt).toLocaleDateString()}</span>
                                                                                </td>
                                                                           </tr>
                                                                           <tr>
                                                                                <td style={{ padding: '5px 10px 5px 0px', fontSize: '10pt' }} className="text-align-right">
                                                                                     <span className="pcs-label"><b>Due Date :</b></span>
                                                                                </td>
                                                                                <td className="text-align-right">
                                                                                     <span>{new Date(InvDueDate).toLocaleDateString() || ""}</span>
                                                                                </td>
                                                                           </tr>
                                                                      </tbody>
                                                                 </table>
                                                            </td>
                                                       </tr>
                                                  </tbody>
                                             </table>
                                             <div style={{ clear: 'both', marginTop: '10px', width: '100%' }}>
                                                  <label style={{ fontSize: '10pt' }} className="pcs-label">Memo</label>
                                                  <p style={{ marginTop: '7px', whiteSpace: 'pre-wrap', wordWrap: 'break-word' }} className="pcs-notes">
                                                       {invoice?.memo === "" || invoice?.memo === undefined ? "Let us know if you have any questions or concerns regarding this invoice, we are happy to assist you." : JSON.stringify(invoice) !== '{}' ? (invoice?.memo) : "Please let us know if you have any questions or concerns regarding this invoice, we are happy to assist you."}
                                                  </p>
                                             </div>
                                             <div className="main-table-body">
                                                  <table style={{ width: '100%', marginTop: '20px', tableLayout: 'fixed' }} className="pcs-itemtable" cellSpacing={0} cellPadding={0}>
                                                       <thead>
                                                            <tr style={{ height: '32px' }}>
                                                                 <td style={{ padding: '5px 0px 5px 5px', width: '5%', textAlign: 'center' }} className="pcs-itemtable-header pcs-itemtable-breakword">#</td>
                                                                 <td style={{ padding: '5px 10px 5px 20px', textAlign: 'left' }} className="pcs-itemtable-header pcs-itemtable-breakword">
                                                                      Item &amp; Description
                                                                 </td>
                                                                 <td style={{ padding: '5px 10px 5px 5px', width: '11%', textAlign: 'right' }} className="pcs-itemtable-header pcs-itemtable-breakword">
                                                                      Qty
                                                                 </td>
                                                                 <td style={{ padding: '5px 10px 5px 5px', width: '11%', textAlign: 'right' }} className="pcs-itemtable-header pcs-itemtable-breakword">
                                                                      Rate
                                                                 </td>
                                                                 <td style={{ padding: '5px 10px 5px 5px', width: '15%', textAlign: 'right' }} className="pcs-itemtable-header pcs-itemtable-breakword">
                                                                      Amount
                                                                 </td>
                                                            </tr>
                                                       </thead>
                                                       <tbody className="itemBody">
                                                            {
                                                                 lineItems.length > 0 ? (
                                                                      lineItems?.map((item, index) => {
                                                                           const { description, quantity, rate, total } = item;
                                                                           return (
                                                                                <tr key={index} className="breakrow-inside breakrow-after">
                                                                                     <td rowSpan={1} valign="top" style={{ padding: '10px 0 10px 5px', textAlign: 'center', wordWrap: 'break-word' }} className="pcs-item-row">{++index}</td>
                                                                                     <td rowSpan={1} valign="top" style={{ padding: '10px 0px 10px 20px' }} className="pcs-item-row">
                                                                                          <p style={{ wordWrap: 'break-word' }}>{description || ""}</p>
                                                                                     </td>
                                                                                     <td rowSpan={1} className="pcs-item-row lineitem-column text-align-right">
                                                                                          <span>{quantity || ""}</span>
                                                                                     </td>
                                                                                     <td rowSpan={1} className="pcs-item-row lineitem-column text-align-right">
                                                                                          <span>{rate || ""}</span>
                                                                                     </td>
                                                                                     <td rowSpan={1} className="pcs-item-row lineitem-column lineitem-content-right text-align-right">
                                                                                          <span>{total || ""}</span>
                                                                                     </td>
                                                                                </tr>
                                                                           );
                                                                      })
                                                                 ) : <tr className="my-1"><td colSpan={4}>No Item Found !</td></tr>
                                                            }
                                                       </tbody>
                                                  </table>
                                                  <div style={{ width: '100%', marginTop: '1px' }}>
                                                       <div className="v-top total-number-section">
                                                            <div style={{ whiteSpace: 'pre-wrap' }} />
                                                       </div>
                                                       <div className="v-top total-section">
                                                            <table className="pcs-totals" cellSpacing={0} width="100%">
                                                                 <tbody>
                                                                      <tr>
                                                                           <td className="total-section-label text-align-right">
                                                                                Sub Total
                                                                           </td>
                                                                           <td className="total-section-value text-align-right">
                                                                                {JSON.stringify(invoice) !== '{}' ?
                                                                                     (
                                                                                          Object.keys(currencyCodeSymbols).map((objkey, index) => {
                                                                                               if (currency === objkey) {
                                                                                                    return (
                                                                                                         <p className='mb-0' key={index}>{currencyCodeSymbols[objkey]} {invoice?.subTotalAmount || ""}</p>
                                                                                                    );
                                                                                               }
                                                                                          })
                                                                                     ) : ""
                                                                                }
                                                                           </td>
                                                                      </tr>
                                                                      <tr style={{ height: '40px' }} className="pcs-balance">
                                                                           <td className="total-section-label text-align-right">
                                                                                <b>Total</b>
                                                                           </td>
                                                                           <td className="total-section-value text-align-right">
                                                                                <b>
                                                                                     {JSON.stringify(invoice) !== '{}' ?
                                                                                          (
                                                                                               Object.keys(currencyCodeSymbols).map((objkey, index) => {
                                                                                                    if (currency === objkey) {
                                                                                                         return (
                                                                                                              <p className='mb-0' key={index}>{currencyCodeSymbols[objkey]} {invoice?.subTotalAmount || ""}</p>
                                                                                                         );
                                                                                                    }
                                                                                               })
                                                                                          ) : ""
                                                                                     }
                                                                                </b>
                                                                           </td>
                                                                      </tr>
                                                                 </tbody>
                                                            </table>
                                                            {paid === true && status !== "disputed" ? (
                                                                 <div className='text-end my-2'>
                                                                      <img src={require("../../../assets/images/paidstamp.png")} draggable={false} alt="invoice paid" className="paidStamp" />
                                                                 </div>
                                                            ) : ""}
                                                            {status === "disputed" ? (
                                                                 <div className='text-end my-2'>
                                                                      <img src={require("../../../assets/images/disputed.png")} draggable={false} alt="invoice paid" className="paidStamp" />
                                                                 </div>
                                                            ) : ""}
                                                            {invStatus === "cancelled" ? (
                                                                 <div className='text-end my-2'>
                                                                      <img src={require("../../../assets/images/cancelledStamp.png")} draggable={false} alt="invoice paid" className="paidStamp" />
                                                                 </div>
                                                            ) : invStatus === "refunded" || invStatus === "partially_refunded" ? (
                                                                 <div className='text-end my-2'>
                                                                      <img src={require("../../../assets/images/refunded.png")} draggable={false} alt="invoice paid" className="paidStamp" />
                                                                 </div>
                                                            ) : ""}
                                                       </div>
                                                       <div style={{ clear: 'both' }} />
                                                  </div>
                                                  <div style={{ clear: 'both', marginTop: '10px', width: '100%' }}>
                                                       <label style={{ fontSize: '10pt' }} className="pcs-label">Note</label><br />
                                                       <p style={{ marginTop: '7px', whiteSpace: 'pre-wrap', wordWrap: 'break-word' }} className="pcs-notes">Thanks for your business.</p>
                                                  </div>
                                                  {paid === true ? (
                                                       paidByGateway[0]?.statusDescriptor === "" || paidByGateway[0]?.statusDescriptor === null || paidByGateway[0]?.statusDescriptor === undefined ? "" : (
                                                            <div style={{ clear: 'both', marginTop: '10px', width: '100%' }}>
                                                                 <label style={{ fontSize: '10pt' }} className="pcs-label">Descriptor</label><br />
                                                                 <p style={{ marginTop: '7px', whiteSpace: 'pre-wrap', wordWrap: 'break-word' }} className="pcs-notes">{paidByGateway[0]?.statusDescriptor || ""}</p>
                                                            </div>
                                                       )) : ""}
                                             </div>
                                             <p className="footerText fs-13 lh">{invoice?.footer === "" || invoice?.footer === undefined ? "Thank you for choosing our services, we appreciate your business." : JSON.stringify(invoice) !== '{}' ? (invoice?.footer) : "Thank you for choosing our services, we appreciate your business."}</p>
                                        </div>
                                   </div>
                                   
                              </div>
                              {
                                   paidAtDateTime ? 
                              <div className='d-flex align-items-center justify-content-start'>
                               <h6 className='mb-0 me-3'>payment date/time: </h6>
                              <span className='me-4'>{paidAtDateTime ? new Date(paidAtDateTime).toLocaleString(): ""}</span>
                              </div> : ""
                              }
                              {
                              disputeDateTime ? 
                              <div className='d-flex align-items-center justify-content-start'>
                               <h6 className='mb-0 me-3'>dispute date/time: </h6>
                              {/* <span className='me-4'>{paidAtDateTime ? new Date(paidAtDateTime).toLocaleString(): ""}</span> */}
                              <span className='me-4'>{disputeDateTime ? new Date(disputeDateTime).toLocaleString() : ""}</span>
                              </div> : ""
                              }
                              
                         </div>
                    </>
               )}
               {/*==================== Cancellation Modal =================*/}
               <CustomModal show={cancelShow} onHide={() => setCancelShow(false)}>
                    <button onClick={() => setCancelShow(false)} className="modal-cancel"><FaTimes /></button>
                    <div className="modalContent">
                         <img src={require("../../../assets/images/sure.png")} draggable={false} alt="modal" />
                         {/* modal text */}
                         <h3 className="modalPrimHeading my-2">Are You Sure You Want To Cancell This Invoice & Create A Revised Invoice ? </h3>
                         {/* modal buttons */}
                         <Link onClick={() => setCancelShow(false)} to={`/edit-invoice/${_id}`} className="main-btn m-2" type="button">Yes</Link>
                         <button className="sec-btn m-2" type="button" onClick={() => setCancelShow(false)}> No </button>
                    </div>
               </CustomModal>
          </>
     );
}
export default SalesInvoice; 