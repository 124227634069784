import React, { useEffect, useState } from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { paymentActivityDashboardRoute } from '../../utils/APIRoutes';
import axios from 'axios';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
    plugins: {
        title: {
            display: false,
            text: '',
        },
    },
    responsive: true,
    maintainAspectRatio: false, // Set this to false to allow setting a fixed height
    height: 200, // Set the height to 400 pixels
    scales: {
        x: {
            stacked: true,
        },
        y: {
            stacked: true,
        },
    },
};

const PaymentActivityGraph = ({ year }) => {
    const [datasets, setDatasets] = useState([])
    const [labels, setlabels] = useState([])
    const data = {
        labels,
        datasets,
    };
    useEffect(() => {
        try {
            const getGraphsData = async () => {
                const response = await axios.get(`${paymentActivityDashboardRoute}?year=${year}`);
                const data = response.data.data
                setlabels(data.labels)
                setDatasets(data.datasets)
            }
            getGraphsData()
        } catch (err) {
            console.log("🚀 ~ file: UnitIncome:", err)
        }
    }, [year])
    return (
        <div className='h-350'>
            <Bar options={options} data={data} />
        </div>
    );
}
export default PaymentActivityGraph