import React, { useEffect } from "react";
import Routes from "./Routes/MainRoutes";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-loading-skeleton/dist/skeleton.css";
import "../src/assets/css/style.css";
import { ToastContainer } from "react-toastify";
import { BrowserRouter as Router, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

function App() {
  const ThemeReducer = useSelector((state) => state.ThemeReducer);
  const {
    themeColor,
    primColor,
    secColor,
    boxBg,
    boxColor,
    textColor,
    btnMainColor,
    secHovColor,
  } = ThemeReducer.myTheme;

  useEffect(() => {
    document.documentElement.style.setProperty("--themeColor", themeColor);
    document.documentElement.style.setProperty("--primColor", primColor);
    document.documentElement.style.setProperty("--secColor", secColor);
    document.documentElement.style.setProperty("--boxBg", boxBg);
    document.documentElement.style.setProperty("--textColor", textColor);
    document.documentElement.style.setProperty("--boxColor", boxColor);
    document.documentElement.style.setProperty("--btnMainColor", btnMainColor);
    document.documentElement.style.setProperty("--secHovColor", secHovColor);
  }, [ThemeReducer]);

  return (
    <>
      <Router basename="/">
        <Routes />
        <ToastContainer />
      </Router>
    </>
  );
}

export default App;
