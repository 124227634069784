import React from "react";
import { NavLink } from "react-router-dom";
import { FaHockeyPuck, FaHome, FaUniregistry, FaDollarSign, FaIdeal, FaFileInvoiceDollar, FaCog, FaMedal, FaUsers, FaUserCheck, FaFileInvoice, FaMapMarked, FaRibbon, FaBraille, FaStreetView, FaFrown } from "react-icons/fa";
import { FcSalesPerformance } from "react-icons/fc";
import { MdOutlineCurrencyExchange, MdOutlineManageAccounts } from "react-icons/md";

import { MdNetworkPing } from "react-icons/md";
import SidebarMenu from "react-bootstrap-sidebar-menu";
import { useSelector } from 'react-redux';

const SideNav = () => {
  const AuthReducer = useSelector((state) => state.AuthReducer);
  const { employeeName, role, designation, gender, unitAccess } = AuthReducer.data;
  let path = window.location.pathname;

  return (
    <>
      <SidebarMenu>
        <SidebarMenu.Header className="userInfo">
          <SidebarMenu.Brand className="position-relative">
            <img src={require(`../assets/images/${gender === "female" ? "adminAvatarfemale.png" : "avatar.png"}`)} alt="Brand" className="userAvatar" draggable={false} />
            <span className="activeDot"></span>
          </SidebarMenu.Brand>
          <h5>{employeeName || ""}</h5>
          <span>{role === "sales" ? "Sales" : role === "development" ? "Developer" : ""}</span>
        </SidebarMenu.Header>
        <SidebarMenu.Body className="px-2">
          <SidebarMenu.Nav>
            <NavLink to="/dashboard" className="navLink">
              <SidebarMenu.Nav.Icon>
                <FaHome className="me-2 navIco" />
                <SidebarMenu.Nav.Title className="sidebarNavTitle">Dashboard</SidebarMenu.Nav.Title>
              </SidebarMenu.Nav.Icon>
            </NavLink>

            {/* <NavLink to="/tasks" className={path.includes("view-tasks") || path.includes("edit-task") || path.includes("create-task") ? "navLink active" : "navLink"}>
              <SidebarMenu.Nav.Icon>
                <FaClock className="me-2 navIco" />
                <SidebarMenu.Nav.Title className="sidebarNavTitle">Task List</SidebarMenu.Nav.Title>
              </SidebarMenu.Nav.Icon>
            </NavLink> */}

            {
              role === "sales" && designation === "unit_head" ? (
                <>

                  <SidebarMenu.Sub className="px-2 " >
                    <SidebarMenu.Sub.Toggle className="px-2 navlink">
                      <SidebarMenu.Nav.Icon>
                        <FcSalesPerformance className="me-2 navIco" />
                      </SidebarMenu.Nav.Icon >
                      <SidebarMenu.Nav.Title className="sidebarNavTitle">
                        New Dashboard
                      </SidebarMenu.Nav.Title>
                    </SidebarMenu.Sub.Toggle>
                    <SidebarMenu.Sub.Collapse>
                      <NavLink to="/salesDashboard" className="navLink">
                        <SidebarMenu.Nav.Icon>
                          <FaHockeyPuck className="me-2 navIco" />
                          <SidebarMenu.Nav.Title className="sidebarNavTitle">Sales Dashboard</SidebarMenu.Nav.Title>
                        </SidebarMenu.Nav.Icon>
                      </NavLink>
                    </SidebarMenu.Sub.Collapse>
                  </SidebarMenu.Sub>
                  <NavLink to="/my-units" className={path.includes("view-unit") ? "navLink active" : "navLink"}>
                    <SidebarMenu.Nav.Icon>
                      <FaUniregistry className="me-2 navIco" />
                      <SidebarMenu.Nav.Title className="sidebarNavTitle">My All Units</SidebarMenu.Nav.Title>
                    </SidebarMenu.Nav.Icon>
                  </NavLink>
                  <NavLink to="/my-unit-clients" className={path.includes("unit-clients") ? "navLink active" : "navLink"}>
                    <SidebarMenu.Nav.Icon>
                      <FaUsers className="me-2 navIco" />
                      <SidebarMenu.Nav.Title className="sidebarNavTitle">Unit Clients</SidebarMenu.Nav.Title>
                    </SidebarMenu.Nav.Icon>
                  </NavLink>
                  <NavLink to="/unit-leads" className={path.includes("create-lead") ? "navLink active" : "navLink"}>
                    <SidebarMenu.Nav.Icon>
                      <FaDollarSign className="me-2 navIco" />
                      <SidebarMenu.Nav.Title className="sidebarNavTitle">Unit Leads</SidebarMenu.Nav.Title>
                    </SidebarMenu.Nav.Icon>
                  </NavLink>
                  {/* <NavLink to="/my-units-deals" className={path.includes("my-units-deals") || path.includes("unit-deals") ? "navLink active" : "navLink"}>
                    <SidebarMenu.Nav.Icon>
                      <FaIdeal className="me-2 navIco" />
                      <SidebarMenu.Nav.Title className="sidebarNavTitle">Unit Deals</SidebarMenu.Nav.Title>
                    </SidebarMenu.Nav.Icon>
                  </NavLink> */}
                  <NavLink to="/unit-invoices" className={path.includes("unit-invoice") || path.includes("my-unit-invoices") ? "navLink active" : "navLink"}>
                    <SidebarMenu.Nav.Icon>
                      <FaFileInvoice className="me-2 navIco" />
                      <SidebarMenu.Nav.Title className="sidebarNavTitle">Unit Invoices</SidebarMenu.Nav.Title>
                    </SidebarMenu.Nav.Icon>
                  </NavLink>
                  <NavLink to="/brands" className="navLink">
                    <SidebarMenu.Nav.Icon>
                      <FaRibbon className="me-2 navIco" />
                      <SidebarMenu.Nav.Title className="sidebarNavTitle">All Brands</SidebarMenu.Nav.Title>
                    </SidebarMenu.Nav.Icon>
                  </NavLink>
                  <NavLink to="/my-accounts" className="navLink">
                    <SidebarMenu.Nav.Icon>
                      <MdOutlineManageAccounts className="me-2 navIco" />
                      <SidebarMenu.Nav.Title className="sidebarNavTitle">My Accounts</SidebarMenu.Nav.Title>
                    </SidebarMenu.Nav.Icon>
                  </NavLink>
                  <NavLink to="/my-leads" className="navLink">
                    <SidebarMenu.Nav.Icon>
                      <FaDollarSign className="me-2 navIco" />
                      <SidebarMenu.Nav.Title className="sidebarNavTitle">My Leads</SidebarMenu.Nav.Title>
                    </SidebarMenu.Nav.Icon>
                  </NavLink>
                  {/* <NavLink to="/my-deals" className={path.includes("view-deal") ? "navLink active" : "navLink"}>
                    <SidebarMenu.Nav.Icon>
                      <FaDollarSign className="me-2 navIco" />
                      <SidebarMenu.Nav.Title className="sidebarNavTitle">My Deals</SidebarMenu.Nav.Title>
                    </SidebarMenu.Nav.Icon>
                  </NavLink> */}
                  <NavLink to="/my-clients" className="navLink">
                    <SidebarMenu.Nav.Icon>
                      <FaUserCheck className="me-2 navIco" />
                      <SidebarMenu.Nav.Title className="sidebarNavTitle">My Clients</SidebarMenu.Nav.Title>
                    </SidebarMenu.Nav.Icon>
                  </NavLink>
                  <NavLink to="/my-invoices" className={path.includes("create-invoice") ? "navLink active" : "navLink"}>
                    <SidebarMenu.Nav.Icon>
                      <FaFileInvoice className="me-2 navIco" />
                      <SidebarMenu.Nav.Title className="sidebarNavTitle">My Invoices</SidebarMenu.Nav.Title>
                    </SidebarMenu.Nav.Icon>
                  </NavLink>
                  <NavLink to="/disputes" className="navLink">
                    <SidebarMenu.Nav.Icon>
                      <FaFrown className="me-2 navIco" />
                      <SidebarMenu.Nav.Title className="sidebarNavTitle">Disputes</SidebarMenu.Nav.Title>
                    </SidebarMenu.Nav.Icon>
                  </NavLink>
                  <NavLink to="/settings" className="navLink">
                    <SidebarMenu.Nav.Icon>
                      <FaCog className="me-2 navIco" />
                      <SidebarMenu.Nav.Title className="sidebarNavTitle">Settings</SidebarMenu.Nav.Title>
                    </SidebarMenu.Nav.Icon>
                  </NavLink>
                </>
              ) : designation === "head_of_department" || designation === "president" ? (
                <>

                  <SidebarMenu.Sub className="px-2 " >
                    <SidebarMenu.Sub.Toggle className="px-2 navlink">
                      <SidebarMenu.Nav.Icon>
                        <FcSalesPerformance className="me-2 navIco" />
                      </SidebarMenu.Nav.Icon >
                      <SidebarMenu.Nav.Title className="sidebarNavTitle">
                        New Dashboard
                      </SidebarMenu.Nav.Title>
                    </SidebarMenu.Sub.Toggle>
                    <SidebarMenu.Sub.Collapse>
                      <NavLink to="/salesDashboard" className="navLink">
                        <SidebarMenu.Nav.Icon>
                          <FaHockeyPuck className="me-2 navIco" />
                          <SidebarMenu.Nav.Title className="sidebarNavTitle">Sales Dashboard</SidebarMenu.Nav.Title>
                        </SidebarMenu.Nav.Icon>
                      </NavLink>
                      <NavLink to="/salesKpiDashboard" className="navLink">
                        <SidebarMenu.Nav.Icon>
                          <MdNetworkPing className="me-2 navIco" />
                          <SidebarMenu.Nav.Title className="sidebarNavTitle">Sales KPI Dashboard</SidebarMenu.Nav.Title>
                        </SidebarMenu.Nav.Icon>
                      </NavLink>
                      <NavLink to="/commissionDashboard" className="navLink">
                        <SidebarMenu.Nav.Icon>
                          <MdOutlineCurrencyExchange className="me-2 navIco" />
                          <SidebarMenu.Nav.Title className="sidebarNavTitle">Commission Dashboard</SidebarMenu.Nav.Title>
                        </SidebarMenu.Nav.Icon>
                      </NavLink>
                    </SidebarMenu.Sub.Collapse>
                  </SidebarMenu.Sub>
                  <NavLink to="/leads" className="navLink">
                    <SidebarMenu.Nav.Icon>
                      <FaStreetView className="me-2 navIco" />
                      <SidebarMenu.Nav.Title className="sidebarNavTitle">All Leads</SidebarMenu.Nav.Title>
                    </SidebarMenu.Nav.Icon>
                  </NavLink>
                  <NavLink to="/units" className={path.includes("view-unit") ? "navLink active" : "navLink"}>
                    <SidebarMenu.Nav.Icon>
                      <FaBraille className="me-2 navIco" />
                      <SidebarMenu.Nav.Title className="sidebarNavTitle">All units</SidebarMenu.Nav.Title>
                    </SidebarMenu.Nav.Icon>
                  </NavLink>
                  {/* <NavLink to="/deals-pipeline" className="navLink">
                    <SidebarMenu.Nav.Icon>
                      <FaMedal className="me-2 navIco" />
                      <SidebarMenu.Nav.Title className="sidebarNavTitle">All Deals</SidebarMenu.Nav.Title>
                    </SidebarMenu.Nav.Icon>
                  </NavLink> */}
                  <NavLink to="/brands" className="navLink">
                    <SidebarMenu.Nav.Icon>
                      <FaRibbon className="me-2 navIco" />
                      <SidebarMenu.Nav.Title className="sidebarNavTitle">All Brands</SidebarMenu.Nav.Title>
                    </SidebarMenu.Nav.Icon>
                  </NavLink>
                  <NavLink to="/all-clients" className="navLink">
                    <SidebarMenu.Nav.Icon>
                      <FaUserCheck className="me-2 navIco" />
                      <SidebarMenu.Nav.Title className="sidebarNavTitle">All Clients</SidebarMenu.Nav.Title>
                    </SidebarMenu.Nav.Icon>
                  </NavLink>
                  <NavLink to="/all-invoices" className="navLink">
                    <SidebarMenu.Nav.Icon>
                      <FaFileInvoice className="me-2 navIco" />
                      <SidebarMenu.Nav.Title className="sidebarNavTitle">All Invoices</SidebarMenu.Nav.Title>
                    </SidebarMenu.Nav.Icon>
                  </NavLink>
                  <NavLink to="/all-tracks" className={path.includes("invoice-tracking") ? "navLink active" : "navLink"}>
                    <SidebarMenu.Nav.Icon>
                      <FaMapMarked className="me-2 navIco" />
                      <SidebarMenu.Nav.Title className="sidebarNavTitle">Tracking</SidebarMenu.Nav.Title>
                    </SidebarMenu.Nav.Icon>
                  </NavLink>
                  <NavLink to="/disputes" className="navLink">
                    <SidebarMenu.Nav.Icon>
                      <FaFrown className="me-2 navIco" />
                      <SidebarMenu.Nav.Title className="sidebarNavTitle">Disputes</SidebarMenu.Nav.Title>
                    </SidebarMenu.Nav.Icon>
                  </NavLink>
                  <NavLink to="/settings" className="navLink">
                    <SidebarMenu.Nav.Icon>
                      <FaCog className="me-2 navIco" />
                      <SidebarMenu.Nav.Title className="sidebarNavTitle">Settings</SidebarMenu.Nav.Title>
                    </SidebarMenu.Nav.Icon>
                  </NavLink>
                </>
              ) : role === "sales" && (designation === "manager" || designation === "associate_manager") ? (
                <>
                  {unitAccess &&
                    <>
                      <NavLink to="/my-units" className={path.includes("view-unit") ? "navLink active" : "navLink"}>
                        <SidebarMenu.Nav.Icon>
                          <FaUniregistry className="me-2 navIco" />
                          <SidebarMenu.Nav.Title className="sidebarNavTitle">My All Units</SidebarMenu.Nav.Title>
                        </SidebarMenu.Nav.Icon>
                      </NavLink>
                      <NavLink to="/unit-leads" className="navLink">
                        <SidebarMenu.Nav.Icon>
                          <FaDollarSign className="me-2 navIco" />
                          <SidebarMenu.Nav.Title className="sidebarNavTitle">Unit Leads</SidebarMenu.Nav.Title>
                        </SidebarMenu.Nav.Icon>
                      </NavLink>
                      <NavLink to="/my-unit-clients" className={path.includes("unit-clients") ? "navLink active" : "navLink"}>
                        <SidebarMenu.Nav.Icon>
                          <FaUsers className="me-2 navIco" />
                          <SidebarMenu.Nav.Title className="sidebarNavTitle">Unit Clients</SidebarMenu.Nav.Title>
                        </SidebarMenu.Nav.Icon>
                      </NavLink>
                      <NavLink to="/unit-invoices" className={path.includes("unit-invoice") || path.includes("my-unit-invoices") ? "navLink active" : "navLink"}>
                        <SidebarMenu.Nav.Icon>
                          <FaFileInvoice className="me-2 navIco" />
                          <SidebarMenu.Nav.Title className="sidebarNavTitle">Unit Invoices</SidebarMenu.Nav.Title>
                        </SidebarMenu.Nav.Icon>
                      </NavLink>
                    </>
                  }
                  <NavLink to="/unit-brands" className="navLink">
                    <SidebarMenu.Nav.Icon>
                      <FaMedal className="me-2 navIco" />
                      <SidebarMenu.Nav.Title className="sidebarNavTitle">Unit Brands</SidebarMenu.Nav.Title>
                    </SidebarMenu.Nav.Icon>
                  </NavLink>
                  <NavLink to="/unit-deals" className={path.includes("my-units-deals") || path.includes("unit-deals") ? "navLink active" : "navLink"}>
                    <SidebarMenu.Nav.Icon>
                      <FaDollarSign className="me-2 navIco" />
                      <SidebarMenu.Nav.Title className="sidebarNavTitle">Unit Deals</SidebarMenu.Nav.Title>
                    </SidebarMenu.Nav.Icon>
                  </NavLink>
                </>
              ) : role === "sales" && (designation === "intern" || designation === "junior" || designation === "mid" || designation === "senior") ? (
                <>
                  {unitAccess && (
                    <>
                      <NavLink to="/my-units" className={path.includes("view-unit") ? "navLink active" : "navLink"}>
                        <SidebarMenu.Nav.Icon>
                          <FaUniregistry className="me-2 navIco" />
                          <SidebarMenu.Nav.Title className="sidebarNavTitle">My All Units</SidebarMenu.Nav.Title>
                        </SidebarMenu.Nav.Icon>
                      </NavLink>
                      <NavLink to="/unit-leads" className="navLink">
                        <SidebarMenu.Nav.Icon>
                          <FaDollarSign className="me-2 navIco" />
                          <SidebarMenu.Nav.Title className="sidebarNavTitle">Unit Leads</SidebarMenu.Nav.Title>
                        </SidebarMenu.Nav.Icon>
                      </NavLink>
                      <NavLink to="/my-unit-clients" className={path.includes("my-unit-clients") ? "navLink active" : "navLink"}>
                        <SidebarMenu.Nav.Icon>
                          <FaUsers className="me-2 navIco" />
                          <SidebarMenu.Nav.Title className="sidebarNavTitle">Unit Clients</SidebarMenu.Nav.Title>
                        </SidebarMenu.Nav.Icon>
                      </NavLink>
                      <NavLink to="/unit-invoices" className={path.includes("unit-invoice") || path.includes("my-unit-invoices") ? "navLink active" : "navLink"}>
                        <SidebarMenu.Nav.Icon>
                          <FaFileInvoice className="me-2 navIco" />
                          <SidebarMenu.Nav.Title className="sidebarNavTitle">Unit Invoices</SidebarMenu.Nav.Title>
                        </SidebarMenu.Nav.Icon>
                      </NavLink>
                    </>
                  )}
                  <NavLink to="/my-accounts" className="navLink">
                    <SidebarMenu.Nav.Icon>
                      <MdOutlineManageAccounts className="me-2 navIco" />
                      <SidebarMenu.Nav.Title className="sidebarNavTitle">My Accounts</SidebarMenu.Nav.Title>
                    </SidebarMenu.Nav.Icon>
                  </NavLink>
                  <NavLink to="/my-leads" className="navLink">
                    <SidebarMenu.Nav.Icon>
                      <FaStreetView className="me-2 navIco" />
                      <SidebarMenu.Nav.Title className="sidebarNavTitle">My Leads</SidebarMenu.Nav.Title>
                    </SidebarMenu.Nav.Icon>
                  </NavLink>
                  {/* <NavLink to="/my-deals" className="navLink">
                    <SidebarMenu.Nav.Icon>
                      <FaDollarSign className="me-2 navIco" />
                      <SidebarMenu.Nav.Title className="sidebarNavTitle">My Deals</SidebarMenu.Nav.Title>
                    </SidebarMenu.Nav.Icon>
                  </NavLink> */}
                  <NavLink to="/my-clients" className={path.includes("create-client") || path.includes("view-client") || path.includes("view-stripe-card") || path.includes("add-card") ? "navLink active" : "navLink"}>
                    <SidebarMenu.Nav.Icon>
                      <FaUsers className="me-2 navIco" />
                      <SidebarMenu.Nav.Title className="sidebarNavTitle">My Clients</SidebarMenu.Nav.Title>
                    </SidebarMenu.Nav.Icon>
                  </NavLink>
                  <NavLink to="/my-invoices" className={path.includes("create-invoice") || path.includes("view-client") || path.includes("view-stripe-card") || path.includes("add-card") ? "navLink active" : "navLink"}>
                    <SidebarMenu.Nav.Icon>
                      <FaFileInvoiceDollar className="me-2 navIco" />
                      <SidebarMenu.Nav.Title className="sidebarNavTitle">My Invoices</SidebarMenu.Nav.Title>
                    </SidebarMenu.Nav.Icon>
                  </NavLink>
                </>
              ) : role === "sales" && designation === "lead_generator" ? (
                <>
                  <NavLink to="/my-generated-leads" className={path.includes("generate-leads") ? "navLink active" : "navLink"}>
                    <SidebarMenu.Nav.Icon>
                      <FaStreetView className="me-2 navIco" />
                      <SidebarMenu.Nav.Title className="sidebarNavTitle">My Generated Leads</SidebarMenu.Nav.Title>
                    </SidebarMenu.Nav.Icon>
                  </NavLink>
                  <NavLink to="/my-converted-leads" className={path.includes("generate-leads") ? "navLink active" : "navLink"}>
                    <SidebarMenu.Nav.Icon>
                      <FaFileInvoiceDollar className="me-2 navIco" />
                      <SidebarMenu.Nav.Title className="sidebarNavTitle">My Converted Leads</SidebarMenu.Nav.Title>
                    </SidebarMenu.Nav.Icon>
                  </NavLink>
                </>
              ) : role === "ppc" && designation === "consultant" ? (
                <>
                  <NavLink to="/ppc-leads" className={path.includes("ppc-leads") ? "navLink active" : "navLink"}>
                    <SidebarMenu.Nav.Icon>
                      <FaStreetView className="me-2 navIco" />
                      <SidebarMenu.Nav.Title className="sidebarNavTitle">PPC Leads</SidebarMenu.Nav.Title>
                    </SidebarMenu.Nav.Icon>
                  </NavLink>
                  <NavLink to="/ppc-invBrands" className={path.includes("ppc-converted-leads") || path.includes("sales-invoice") ? "navLink active" : "navLink"}>
                    <SidebarMenu.Nav.Icon>
                      <FaFileInvoiceDollar className="me-2 navIco" />
                      <SidebarMenu.Nav.Title className="sidebarNavTitle">My Converted Leads</SidebarMenu.Nav.Title>
                    </SidebarMenu.Nav.Icon>
                  </NavLink>
                </>
              ) : role === "accounts" ? (
                <NavLink to="/all-invoices" className="navLink">
                  <SidebarMenu.Nav.Icon>
                    <FaFileInvoice className="me-2 navIco" />
                    <SidebarMenu.Nav.Title className="sidebarNavTitle">Paid Invoice Summary</SidebarMenu.Nav.Title>
                  </SidebarMenu.Nav.Icon>
                </NavLink>
              ) : ""
            }
          </SidebarMenu.Nav>
        </SidebarMenu.Body>
      </SidebarMenu>
    </>
  );
};

export default SideNav;


