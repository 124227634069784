// initial state
const initialState = {
    topSellBrands: true,
    unitWiseIncome: true,
    completeGrowthByMonthChart: true,
    paymentActivity: true,
    totInvReceivable: true,
};

const GraphReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'TOGGLE_TOP_SELLING_BRANDS':
            return {
                ...state,
                topSellBrands: !state.topSellBrands,
            };
        case 'TOGGLE_UNIT_WISE_INCOME':
            return {
                ...state,
                unitWiseIncome: !state.unitWiseIncome,
            };
        case 'TOGGLE_COMPLETE_GROWTH_BY_MONTH_CHART':
            return {
                ...state,
                completeGrowthByMonthChart: !state.completeGrowthByMonthChart,
            };
        case 'TOGGLE_PAYMENT_ACTIVITY':
            return {
                ...state,
                paymentActivity: !state.paymentActivity,
            };
        case 'Tot_Inv_Receivable':
            return {
                ...state,
                totInvReceivable: !state.totInvReceivable,
            };
        default:
            return state;
    }
};
export default GraphReducer;