import React, { useState, useRef, memo } from "react";
import { Col, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import Title from "../../hook/Title";
import { useNavigate } from 'react-router-dom';
import GoBack from "../../Components/GoBack";
import makeAnimated from "react-select/animated";
import Select from 'react-select'



const AssignTask = () => {

    const animatedComponents = makeAnimated();
    const options = [
        { value: "Hassan Majeed", label: "Hassan Majeed" },
        { value: "Subhan", label: "Subhan" },
        { value: "Hasan", label: "Hasan" },
        { value: "Rameez", label: "Rameez" },
    ];
    const [selectedOptions, setSelectedOptions] = useState([]);
    // console.log("🚀 ~ file: AssignTask.js:26 ~ AssignTask ~ selectedOptions", selectedOptions);
    const navigate = useNavigate();
    const fileInput = useRef(null);
    //  Get Values of Inputs
    const [title, setTitle] = useState("Faith App");
    const [department, setDepartment] = useState("development");
    const [subDepartment, setSubDepartment] = useState("");
    const [assignUser, setAssignedUser] = useState("Sam");
    const [startDate, setStartDate] = useState("2022-12-22");
    const [endDate, setEndDate] = useState("");
    const [priority, setPriority] = useState("high");
    const [description, setDescription] = useState("");
    const [selectedFile, setSelectedFile] = useState(null);

    // Select input file from your PC
    const changeHandler = (event) => {
        setSelectedFile(event.target.files[0]);
    };
    // Show Toast msg after user Login Sucessfull
    const showSucessToast = () => {
        toast.success("Task Assigned Successfully !", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "colored",
        });
    };

    // Post data to database
    const handleTaskCreation = () => {
        // console.log("🚀 ~ file: AssignTask.js:14 ~ AssignTask ~ title", title);
        // console.log("🚀 ~ file: AssignTask.js:16 ~ AssignTask ~ department", department);
        // console.log("🚀 ~ file: AssignTask.js:18 ~ AssignTask ~ subDepartment", subDepartment);
        // console.log("🚀 ~ file: AssignTask.js:20 ~ AssignTask ~ assignUser", assignUser);
        // console.log("🚀 ~ file: AssignTask.js:22 ~ AssignTask ~ startDate", startDate);
        // console.log("🚀 ~ file: AssignTask.js:24 ~ AssignTask ~ endDate", endDate);
        // console.log("🚀 ~ file: AssignTask.js:26 ~ AssignTask ~ priority", priority);
        // console.log("🚀 ~ file: AssignTask.js:28 ~ AssignTask ~ description", description);
        // console.log("🚀 ~ file: AssignTask.js:30 ~ AssignTask ~ selectedFile", selectedFile);
        showSucessToast();
    }

    Title("Create Task");
    return (
        <>
            <div className="d-flex align-items-center mb-4 flex-wrap">
                <GoBack />
                <h4 className="section-heading mb-0">Assign<span> Task</span></h4>
            </div>
            <div className="box">
                <Row className="m-md-3">
                    <Col xl={9}>
                        <Row>
                            <Col lg={6}>
                                <div className="registration-inp-box my-3">
                                    <label className="d-block" htmlFor="title">Task Title<span className="text-danger">*</span></label>
                                    <input
                                        id="title"
                                        type="text"
                                        placeholder="Enter Title"
                                        className="primary-inputs px-3"
                                        value={title}
                                        onChange={(e) => setTitle(e.target.value)}
                                        disabled
                                    />
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className="registration-inp-box my-3">
                                    <label className="d-block" htmlFor="department">
                                        Department <span className="text-danger">*</span>
                                    </label>
                                    <select
                                        name="department"
                                        id="department"
                                        className="primary-inputs px-2"
                                        onChange={(e) => setDepartment(e.target.value)}
                                        defaultValue={department}
                                        disabled
                                    >
                                        <option value="admin">Admin</option>
                                        <option value="development">Web & App</option>
                                        <option value="sales">Sales</option>
                                        <option value="hr" disabled>Human Resource</option>
                                        <option value="finance" disabled>Finance</option>
                                    </select>
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className="registration-inp-box my-3">
                                    <label className="d-block" htmlFor="subDepartment">
                                        Sub Department <span className="text-danger">*</span>
                                    </label>
                                    <select
                                        name="subDepartment"
                                        id="subDepartment"
                                        className="primary-inputs px-2"
                                        onChange={(e) => setSubDepartment(e.target.value)}
                                        value={subDepartment}
                                    >
                                        <option value="uiux">UI/UX Designing</option>
                                        <option value="logo">Logo Designing</option>
                                        <option value="cms">CMS Development</option>
                                        <option value="frontend">Frontend Development</option>
                                        <option value="backend">Backend Development</option>
                                        <option value="app">App Development</option>
                                    </select>
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className="registration-inp-box my-3">
                                    <label className="d-block" htmlFor="assignTo">Assigned By<span className="text-danger">*</span></label>
                                    <select
                                        name="manager"
                                        id="assignTo"
                                        className="primary-inputs px-2"
                                        onChange={(e) => setAssignedUser(e.target.value)}
                                        value={assignUser}
                                        disabled
                                    >
                                        <option value="sam">Sam</option>
                                        <option value="nabiag" disabled>Nabiag</option>
                                        <option value="alex" disabled>Alex</option>
                                    </select>
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className="registration-inp-box my-3">
                                    <label className="d-block" htmlFor="startDate">
                                        Start Date<span className="text-danger">*</span>
                                    </label>
                                    <input
                                        disabled
                                        type="date"
                                        className="prim-date"
                                        name="dateOfJoining"
                                        id="jod"
                                        value={startDate}
                                        onChange={(e) => setStartDate(e.target.value)}
                                    />
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className="registration-inp-box my-3">
                                    <label className="d-block" htmlFor="endDate">
                                        End Date<span className="text-danger">*</span>
                                    </label>
                                    <input
                                        type="date"
                                        className="prim-date"
                                        name="dateOfJoining"
                                        id="endDate"
                                        value={endDate}
                                        onChange={(e) => setEndDate(e.target.value)}
                                    />
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className="registration-inp-box my-3">
                                    <label className="d-block" htmlFor="priority">Task Priority<span className="text-danger">*</span></label>
                                    <select
                                        name="priority"
                                        id="priority"
                                        className="primary-inputs px-2"
                                        onChange={(e) => setPriority(e.target.value)}
                                        value={priority}
                                        disabled
                                    >
                                        <option value="high">High</option>
                                        <option value="medium">Medium</option>
                                        <option value="low">low</option>
                                    </select>
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className="registration-inp-box my-3">
                                    <label className="d-block" htmlFor="attachment">Attachment</label>
                                    <input type="file" ref={fileInput} id="attachment" className="d-none" name="file" onChange={changeHandler} />
                                    <div className="p-0 primary-inputs d-flex justify-content-between align-items-center">
                                        <p className="mb-0 mx-3 exsmText">{selectedFile == null ? "Choose File" : selectedFile.name}</p>
                                        <button className="formUploadBtn" onClick={() => fileInput.current.click()}>Choose File</button>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className="registration-inp-box my-3">
                                    <label className="d-block" htmlFor="selectMembers">Assigned Team:<span className="text-danger">*</span></label>
                                    <Select
                                        id="selectMembers"
                                        // defaultValue={[options[0], options[2]]}
                                        components={animatedComponents}
                                        isMulti
                                        options={options}
                                        onChange={(item) => setSelectedOptions(item)}
                                        className="select"
                                        isClearable={true}
                                        isSearchable={true}
                                        isDisabled={false}
                                        isLoading={false}
                                        isRtl={false}
                                        closeMenuOnSelect={false}
                                    />
                                </div>
                            </Col>
                            <Col lg={12}>
                                <div className="registration-inp-box my-3">
                                    <label className="d-block" htmlFor="desc">Description<span className="text-danger">*</span> </label>
                                    <div className="summernote">
                                        Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.
                                    </div>
                                </div>
                            </Col>
                            <Col>
                                <div className="my-4">
                                    <button className="main-btn me-3" onClick={handleTaskCreation}>Submit</button>
                                    <button onClick={() => navigate(-1)} className="sec-btn me-3">Cancel</button>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default memo(AssignTask);; 