import React from 'react';
import {
    ComposedChart,
    Line,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    Area
} from "recharts";



const SalesVsBenchmarkVsTarget = ({ data }) => {
    // const data = [
    //     {
    //         name: "Jan",
    //         Benchmark: 590,
    //         Sale: 800,
    //         Target: 1400
    //     },
    //     {
    //         name: "Feb",
    //         Benchmark: 868,
    //         Sale: 967,
    //         Target: 1506
    //     },
    //     {
    //         name: "Mar",
    //         Benchmark: 1397,
    //         Sale: 1098,
    //         Target: 989
    //     },
    //     {
    //         name: "Apr",
    //         Benchmark: 1480,
    //         Sale: 1200,
    //         Target: 1228
    //     },
    //     {
    //         name: "May",
    //         Benchmark: 1520,
    //         Sale: 1108,
    //         Target: 1100
    //     },
    //     {
    //         name: "Jun",
    //         Benchmark: 1400,
    //         Sale: 680,
    //         Target: 1700
    //     },
    //     {
    //         name: "Jul",
    //         Benchmark: 590,
    //         Sale: 800,
    //         Target: 1400
    //     },
    //     {
    //         name: "Aug",
    //         Benchmark: 868,
    //         Sale: 967,
    //         Target: 1506
    //     },
    //     {
    //         name: "Sep",
    //         Benchmark: 1397,
    //         Sale: 1098,
    //         Target: 989
    //     },
    //     {
    //         name: "Oct",
    //         Benchmark: 500,
    //         Sale: 1200,
    //         Target: 1228
    //     },
    //     {
    //         name: "Nov",
    //         Benchmark: 0,
    //         Sale: 0,
    //         Target: 0
    //     },
    //     {
    //         name: "Dec",
    //         Benchmark: 400,
    //         Sale: 0,
    //         Target: 0
    //     }
    // ];

    return (
        <ComposedChart
            width={1000}
            height={300}
            data={data}
            margin={{
                top: 10,
                right: 5,
                bottom: 5,
                left: 5
            }}
        >
            <CartesianGrid stroke="#f5f5f5" />
            <XAxis
                dataKey="name"
                // scale="band"
            />
            <YAxis label={{ angle: -90, position: "insideLeft" }} />
            <Tooltip />
            <Legend />
            <Area type="monotone" dataKey="Target" fill="#8884d8" stroke="#8884d8" />
            <Bar dataKey="NetSales" barSize={30} stackId="a" fill="#413ea0" />
            <Bar dataKey="Refunds" barSize={30} stackId="a" fill="#f94449" />
            <Line type="monotone" dataKey="Benchmark" stroke="#ff7300" />
        </ComposedChart>
    )
}
export default SalesVsBenchmarkVsTarget;