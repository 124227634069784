import React, { useRef, useState, useEffect, useCallback } from "react";
import Title from "../../../hook/Title";
import Dropdown from "react-bootstrap/Dropdown";
import { Link, useNavigate } from "react-router-dom";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { TableCom } from "../../../Components/TableCom";
import { toast } from "react-toastify";
import currencyCode from "../../../helpers/currencyCodes.json";
import countryCodes from "../../../helpers/countryCodes.json";
import {
  getAllbrandsRoute,
  updateBrandRoute,
  getBrandByIdRoute,
  availableTimeZonesRoute,
} from "../../../utils/APIRoutes";
import axios from "axios";
import { FaRegEye, FaEllipsisV, FaEdit, FaTimes } from "react-icons/fa";
import CustomModal from "../../../Components/CustomModal";
import { Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import { debounce } from "lodash";

const Index = () => {
  Title("All Brands");
  const navigate = useNavigate();
  const AuthReducer = useSelector((state) => state.AuthReducer);
  const { designation } = AuthReducer.data;

  const allbrandCurrencyCode = currencyCode.codes;
  const allbrandCountryCodes = countryCodes.codes;
  // Table Head Data
  const [brandName, setBrandName] = useState("");
  const [brandWebsite, setBrandWebsite] = useState("");
  const [brandEmail, setBrandEmail] = useState("");
  const [brandPhone, setBrandPhone] = useState("");
  const [brandCurrencyCode, setBrandCurrencyCode] = useState("");
  const [brandCountryCode, setBrandCountryCode] = useState("");
  const [brandZipCode, setBrandZipCode] = useState("");
  const [brandAddress, setBrandAddress] = useState("");
  const [brandLogo, setBrandLogoUrl] = useState("");
  const [createdAt, setCreatedAt] = useState("");

  const [thLength, setThlength] = useState(1);
  const [items, setItems] = useState([]);
  const [numPerPage, setNumPerPage] = useState(10);
  const [totalData, setTotalData] = useState(0);
  const [pageCount, setpageCount] = useState(0);
  const [currPage, setCurrPage] = useState(0);
  const [searchVal, setSearchVal] = useState("");
  const [brandId, setBrandId] = useState("");
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const fileInput = useRef(null);
  const [imgUrl, setImgUrl] = useState("");
  const [timeZone, setTimeZone] = useState("");
  const [disabledState, setSisabledState] = useState(false);
  const Thead = [
    "S.No",
    "Name",
    "Email",
    "Assigned To",
    "Website url",
    "created At",
    "Time Zone",
    "Status",
    "Action",
  ];
  useEffect(
    (currPage) => {
      currPage = currPage ?? 0;
      const getData = async () => {
        const res = await fetch(
          `${getAllbrandsRoute}?numPerPage=${numPerPage}&page=${currPage}&search=${searchVal}`
        );
        const data = await res.json();
        const total = data.pagiantion.dataCount;
        setTotalData(total);
        setpageCount(Math.ceil(total / numPerPage));
        setItems(data.result);
      };
      getData();
    },
    [numPerPage, searchVal]
  );
  useEffect(() => {
    setThlength(Thead.length);
  }, [thLength]);
  const fetchData = async (currPage) => {
    const res = await fetch(
      `${getAllbrandsRoute}?numPerPage=${numPerPage}&page=${currPage}&search=${searchVal}`
    );
    const changedData = await res.json();
    const data = changedData.result;
    return data;
  };
  const handlePageClick = async (data) => {
    let currPage = data.selected;
    const myData = await fetchData(currPage);
    setItems(myData);
    setCurrPage(currPage);
  };
  const handleChange = (e) => {
    setNumPerPage(e.target.value);
  };

  // Debounced search function
  const debouncedSearch = useCallback(
    debounce((value) => {
      setSearchVal(value);
    }, 500),
    []
  );

  // search data in api using search box
  const hangleSearchVal = (e) => {
    debouncedSearch(e.target.value);
  };

  const [editModalShow, setEditModalShow] = useState(false);
  const [editableState, setEditableState] = useState(false);
  //============================================ ! Api inegration of Create Employee code !  ===============================================//
  let toastOptions = {
    position: "top-left",
    autoClose: 5000,
    pauseOnHover: false,
    draggable: false,
    theme: "dark",
  };
  function handleImg(e) {
    setImgUrl(URL.createObjectURL(e.target.files[0]));
    setBrandLogoUrl(e.target.files[0]);
  }
  // ---- States of inputs in the form of key value pair name:value ------ //
  const form = new FormData();
  let hasError = false;
  // ---- Validate Form ------ //
  const validateForm = () => {
    const newErrors = {};
    if (!brandName) {
      hasError = true;
      setIsLoading(false);
      newErrors.brandName = "Name is required !";
    }
    if (brandCurrencyCode === "") {
      hasError = true;
      setIsLoading(false);
      newErrors.brandCurrencyCode = "currency is required !";
    }
    if (brandCountryCode === "") {
      hasError = true;
      setIsLoading(false);
      newErrors.brandCountryCode = "country is required !";
    }
    if (timeZone === "") {
      hasError = true;
      setIsLoading(false);
      newErrors.timeZone = "Time Zone is required !";
    }
    if (!brandEmail) {
      hasError = true;
      setIsLoading(false);
      newErrors.brandEmail = "Email is required !";
    } else if (!/\S+@\S+\.\S+/.test(brandEmail)) {
      hasError = true;
      setIsLoading(false);
      newErrors.brandEmail = "Email is not valid !";
    }
    if (!brandWebsite) {
      hasError = true;
      setIsLoading(false);
      newErrors.brandWebsite = "Brand Website Link is required !";
    }
    if (!brandPhone) {
      hasError = true;
      setIsLoading(false);
      newErrors.brandPhone = "Phone Number is required !";
    }
    if (brandLogo) {
      let serverImg = typeof brandLogo;
      // console.log("🚀 ~ file: Index.js:152 ~ validateForm ~ serverImg", serverImg)
      if (serverImg !== "object") {
        serverImg = brandLogo.includes("https://dedicatedtestserver.com:8091");
      }
      // Bytes 1000000 not exceed != img should not be exceed to 1mb !
      else if (
        brandLogo?.type === "image/png" ||
        brandLogo?.type === "image/jpeg"
      ) {
        if (brandLogo.size > 1024000) {
          hasError = true;
          setIsLoading(false);
          newErrors.brandLogo = "Logo size should not be exceed to 1mb !";
        } else {
          hasError = false;
        }
      } else {
        newErrors.brandLogo = "only JPG, JPEG, PNG files are allowed !";
        setIsLoading(false);
        hasError = true;
      }
    }
    setErrors(newErrors);
  };
  // ---- Form Submittion Function ------ //
  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    validateForm();
    if (hasError === false) {
      form.append("brandName", brandName);
      form.append("brandWebsite", brandWebsite);
      form.append("brandEmail", brandEmail);
      form.append("brandPhone", brandPhone);
      form.append("brandCountryCode", brandCountryCode);
      form.append("brandCurrencyCode", brandCurrencyCode);
      form.append("brandZipCode", brandZipCode);
      form.append("brandAddress", brandAddress);
      form.append("brandLogo", brandLogo);
      form.append("timeZone", timeZone);
      const { data } = await axios.put(
        `${updateBrandRoute}?brandId=${brandId}`,
        form,
        {
          headers: {
            "Content-Type": "application/json",
            "Content-type": "multipart/form-date",
          },
        }
      );
      if (data.success === true) {
        setIsLoading(false);
        setEditModalShow(false);
        toast.success(
          data.msg,
          toastOptions,
          (toastOptions["position"] = "top-right")
        );
        const myData = await fetchData(currPage);
        setItems(myData);
        navigate("/brands");
      } else {
        toast.error(
          data.msg,
          toastOptions,
          (toastOptions["position"] = "top-left")
        );
        setIsLoading(false);
      }
    } else {
      toast.error(
        "Kindly Fill the require fields !",
        toastOptions,
        (toastOptions["position"] = "top-left")
      );
    }
  };
  const openEditBrandModal = (_id) => {
    setEditModalShow(true);
    setEditableState(false);
    setBrandId(_id);
    fetch(`${getBrandByIdRoute}?brandId=${_id}`)
      .then((response) => response.json())
      .then(({ data: brandData }) => {
        setBrandName(brandData.brandName);
        setBrandWebsite(brandData.brandWebsite);
        setBrandEmail(brandData.brandEmail);
        setBrandPhone(brandData.brandPhone);
        setBrandCurrencyCode(brandData.brandCurrencyCode);
        setBrandCountryCode(brandData.brandCountryCode);
        setBrandZipCode(brandData.brandZipCode);
        setBrandAddress(brandData.brandAddress);
        setBrandLogoUrl(brandData.brandLogoUrl);
        setTimeZone(brandData.timeZone);
      });
  };
  const openBrandModal = (_id) => {
    setEditModalShow(true);
    setEditableState(true);
    setBrandId(_id);
    fetch(`${getBrandByIdRoute}?brandId=${_id}`)
      .then((response) => response.json())
      .then(({ data: brandData }) => {
        setBrandName(brandData.brandName);
        setBrandWebsite(brandData.brandWebsite);
        setBrandEmail(brandData.brandEmail);
        setBrandPhone(brandData.brandPhone);
        setBrandCurrencyCode(brandData.brandCurrencyCode);
        setBrandCountryCode(brandData.brandCountryCode);
        setBrandZipCode(brandData.brandZipCode);
        setBrandAddress(brandData.brandAddress);
        setBrandLogoUrl(brandData.brandLogoUrl);
        setCreatedAt(brandData.createdAt);
        setTimeZone(brandData.timeZone);
      });
  };
  useEffect(() => {
    if (designation === "head_of_department") {
      setSisabledState(false);
    } else {
      setSisabledState(true);
    }
  }, []);

  // ============= | Get Brand Time Zone | ============== //
  const [allbrandTimeZone, setAllBrandTimeZone] = useState([]);
  const getTimeZone = async () => {
    const response = await axios.get(availableTimeZonesRoute);
    if (response?.data?.success === true) {
      setAllBrandTimeZone(response?.data?.data);
    } else {
      setAllBrandTimeZone([]);
    }
  };

  useEffect(() => {
    getTimeZone();
  }, []);

  return (
    <>
      <h4 className="primHeading my-2 mb-4">
        Company <span>Brands</span>
      </h4>
      <div className="box my-3">
        <h4 className="sectionHeading my-2 mb-4">Brands Summary</h4>
        <TableCom
          numPerPage={numPerPage}
          totalData={totalData}
          handleChange={handleChange}
          hangleSearchVal={hangleSearchVal}
          pageCount={pageCount}
          handlePageClick={handlePageClick}
          searcPlaceHolder="Search By Brand Name..."
        >
          <div className="customTable2 w-100 table-responsive">
            <table className="w-100">
              <thead>
                <tr>
                  {Thead?.map((th, index) => {
                    return <th key={index}>{th}</th>;
                  })}
                </tr>
              </thead>
              <tbody>
                {items == "" || items == undefined || items == null ? (
                  <tr>
                    <td colSpan={thLength}>
                      <p className="my-2 smText text-decoration-underline text-danger">
                        No Record Found
                      </p>
                    </td>
                  </tr>
                ) : (
                  items.map((item, index) => {
                    const {
                      brandEmail,
                      brandName,
                      brandWebsite,
                      createdAt,
                      active,
                      _id,
                      Unit,
                      timeZone,
                    } = item;
                    return (
                      <tr
                        key={index}
                        onClick={() => {
                          openBrandModal(_id);
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <td>{++index}</td>
                        <td>{brandName || "--"}</td>
                        <td>{brandEmail || "--"}</td>
                        <td>{Unit.length > 0 ? Unit[0].name : "--"}</td>
                        <td>
                          <a href={brandWebsite}>{brandWebsite}</a>{" "}
                        </td>
                        <td>
                          {createdAt
                            .slice(0, 10)
                            .split("-")
                            .reverse()
                            .join("-") || "-"}
                        </td>
                        <td>{timeZone || "--"}</td>
                        {/* if role is normal sales person then only show status ! <td>{active === true ? "Active" : "InActive"}</td> */}
                        <td>
                          {active === true ? (
                            <Dropdown>
                              <Dropdown.Toggle
                                disabled={disabledState}
                                className="select_table_box bgGreen"
                                id="dropdown-basic"
                              >
                                Active
                              </Dropdown.Toggle>
                              <Dropdown.Menu className="statusMenu">
                                <Dropdown.Item
                                  type="button"
                                  className="statusBtn"
                                >
                                  InActive
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          ) : (
                            <Dropdown>
                              <Dropdown.Toggle
                                disabled={disabledState}
                                className="select_table_box bgRed"
                                id="dropdown-basic"
                              >
                                InActive
                              </Dropdown.Toggle>
                              <Dropdown.Menu className="statusMenu">
                                <Dropdown.Item
                                  type="button"
                                  className="statusBtn"
                                >
                                  Active
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          )}
                        </td>
                        <td>
                          <Dropdown className="actionDropDown">
                            <Dropdown.Toggle className="customDropDown">
                              <FaEllipsisV className="colBlack" />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item
                                className="drop_item"
                                type="button"
                                onClick={() => {
                                  openBrandModal(_id);
                                }}
                              >
                                <FaRegEye className="me-1" />
                                <span>View</span>
                              </Dropdown.Item>
                              {designation === "head_of_department" ||
                              designation === "unit_head" ? (
                                <Dropdown.Item
                                  className="drop_item"
                                  type="button"
                                  onClick={() => {
                                    openEditBrandModal(_id);
                                  }}
                                >
                                  <FaEdit className="me-1" />
                                  <span>Edit</span>
                                </Dropdown.Item>
                              ) : (
                                ""
                              )}
                            </Dropdown.Menu>
                          </Dropdown>
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
        </TableCom>
      </div>
      {/*========================================== ! Edit Brands Modal code start here ! =======================================*/}
      <CustomModal
        show={editModalShow}
        onHide={() => setEditModalShow(false)}
        backdrop="static"
        keyboard="False"
        size="lg"
      >
        <button
          onClick={() => setEditModalShow(false)}
          className="modal-cancel"
        >
          <FaTimes />
        </button>
        <div className="modalContent">
          {/* modal text */}
          <form onSubmit={(event) => handleSubmit(event)}>
            <div className="text-start">
              <h3 className="fw-600 my-2">
                {" "}
                {editableState === false ? "Edit Brand" : `${brandName}`}{" "}
              </h3>
              <p>
                {editableState === false
                  ? "Edit Brand From here"
                  : `View ${brandName} Brand Details here`}{" "}
              </p>
              <Row>
                <Col lg={editableState === true ? 6 : 12} className="my-2">
                  <label className="text-bold mb-1" htmlFor="brandName">
                    Name
                  </label>
                  <input
                    disabled={true}
                    value={brandName}
                    onChange={(e) => setBrandName(e.target.value)}
                    name="brandName"
                    id="brandName"
                    type="text"
                    className="filterSelect ps-3"
                    placeholder="Enter Brand Name"
                  />
                  {errors.brandName && (
                    <p className="text-danger fw-500 fs-13 my-2 text-capitalize">
                      {errors.brandName}
                    </p>
                  )}
                </Col>
                <Col lg={6} className="my-2">
                  <label className="text-bold mb-1" htmlFor="brandWebsite">
                    Website
                    {editableState === false ? (
                      <span className="text-danger">*</span>
                    ) : (
                      ""
                    )}
                  </label>
                  <input
                    disabled={editableState}
                    value={brandWebsite}
                    onChange={(e) => setBrandWebsite(e.target.value)}
                    name="brandWebsite"
                    id="brandWebsite"
                    type="url"
                    className="filterSelect ps-3"
                    placeholder="Enter Brand Website Link"
                  />
                  {errors.brandWebsite && (
                    <p className="text-danger fw-500 fs-13 my-2 text-capitalize">
                      {errors.brandWebsite}
                    </p>
                  )}
                </Col>
                <Col lg={6} className="my-2">
                  <label className="text-bold mb-1" htmlFor="brandEmail">
                    Email
                    {editableState === false ? (
                      <span className="text-danger">*</span>
                    ) : (
                      ""
                    )}
                  </label>
                  <input
                    disabled={editableState}
                    value={brandEmail}
                    onChange={(e) => setBrandEmail(e.target.value)}
                    name="brandEmail"
                    id="brandEmail"
                    type="text"
                    className="filterSelect ps-3"
                    placeholder="Enter Brand Email"
                  />
                  {errors.brandEmail && (
                    <p className="text-danger fw-500 fs-13 my-2 text-capitalize">
                      {errors.brandEmail}
                    </p>
                  )}
                </Col>
                <Col lg={6} className="my-2">
                  <label className="text-bold mb-1" htmlFor="brandPhone">
                    Phone
                    {editableState === false ? (
                      <span className="text-danger">*</span>
                    ) : (
                      ""
                    )}
                  </label>
                  <input
                    disabled={editableState}
                    value={brandPhone}
                    onChange={(e) => setBrandPhone(e.target.value)}
                    name="brandPhone"
                    id="brandPhone"
                    type="number"
                    className="filterSelect ps-3"
                    placeholder="Enter Phone Number"
                  />
                  {errors.brandPhone && (
                    <p className="text-danger fw-500 fs-13 my-2 text-capitalize">
                      {errors.brandPhone}
                    </p>
                  )}
                </Col>
                <Col lg={6} className="my-2">
                  <label className="text-bold mb-1" htmlFor="brandZipCode">
                    ZIP Code
                  </label>
                  <input
                    disabled={editableState}
                    value={brandZipCode}
                    onChange={(e) => setBrandZipCode(e.target.value)}
                    name="brandZipCode"
                    id="brandZipCode"
                    type="number"
                    className="filterSelect ps-3"
                    placeholder="Enter ZIP Code"
                  />
                  {errors.brandZipCode && (
                    <p className="text-danger fw-500 fs-13 my-2 text-capitalize">
                      {errors.brandZipCode}
                    </p>
                  )}
                </Col>
                <Col lg={6} className="my-2">
                  <label className="text-bold mb-1" htmlFor="brandCurrencyCode">
                    Currency
                    {editableState === false ? (
                      <span className="text-danger">*</span>
                    ) : (
                      ""
                    )}
                  </label>
                  <select
                    className="filterSelect ps-3"
                    id="brandCurrencyCode"
                    name="brandCurrencyCode"
                    disabled={editableState}
                    value={brandCurrencyCode}
                    onChange={(e) => setBrandCurrencyCode(e.target.value)}
                  >
                    {Object.keys(allbrandCurrencyCode).map((objkey, index) => {
                      return (
                        <option key={index} value={objkey}>
                          {allbrandCurrencyCode[objkey]}
                        </option>
                      );
                    })}
                  </select>
                  {errors.brandCurrencyCode && (
                    <p className="text-danger fw-500 fs-13 my-2 text-capitalize">
                      {errors.brandCurrencyCode}
                    </p>
                  )}
                </Col>
                <Col lg={6} className="my-2">
                  <label className="text-bold mb-1" htmlFor="brandCountryCode">
                    Country
                    {editableState === false ? (
                      <span className="text-danger">*</span>
                    ) : (
                      ""
                    )}
                  </label>
                  <select
                    className="filterSelect ps-3"
                    id="brandCountryCode"
                    name="brandCountryCode"
                    value={brandCountryCode}
                    disabled={editableState}
                    onChange={(e) => setBrandCountryCode(e.target.value)}
                  >
                    {Object.keys(allbrandCountryCodes).map((objkey, index) => {
                      return (
                        <option key={index} value={objkey}>
                          {allbrandCountryCodes[objkey]}
                        </option>
                      );
                    })}
                  </select>
                  {errors.brandCountryCode && (
                    <p className="text-danger fw-500 fs-13 my-2 text-capitalize">
                      {errors.brandCountryCode}
                    </p>
                  )}
                </Col>
                <Col lg={6} className="my-2">
                  <label className="text-bold mb-1" htmlFor="timeZone">
                    Time Zone<span className="text-danger">*</span>
                  </label>
                  <select
                    className="filterSelect ps-3"
                    id="timeZone"
                    name="timeZone"
                    value={timeZone}
                    disabled={editableState}
                    onChange={(e) => setTimeZone(e.target.value)}
                  >
                    <option value="">Select Option</option>
                    {allbrandTimeZone.length > 0
                      ? allbrandTimeZone?.map((timeZone, index) => {
                          return (
                            <option key={index} value={timeZone}>
                              {timeZone}
                            </option>
                          );
                        })
                      : ""}
                  </select>
                  {errors.timeZone && (
                    <p className="text-danger fw-500 fs-13 my-2 text-capitalize">
                      {errors.timeZone}
                    </p>
                  )}
                </Col>
                {editableState === true ? (
                  <Col lg={6} className="my-2">
                    <label className="text-bold mb-1" htmlFor="createdAt">
                      created At
                    </label>
                    <input
                      disabled={true}
                      value={createdAt
                        .slice(0, 10)
                        .split("-")
                        .reverse()
                        .join("-")}
                      name="createdAt"
                      id="createdAt"
                      type="text"
                      className="filterSelect ps-3"
                      placeholder="Created At"
                    />
                  </Col>
                ) : (
                  ""
                )}
                <Col lg={12} className="my-2">
                  <label className="text-bold mb-1" htmlFor="brandAddress">
                    Address
                  </label>
                  <input
                    disabled={editableState}
                    value={brandAddress}
                    onChange={(e) => setBrandAddress(e.target.value)}
                    name="brandAddress"
                    id="brandAddress"
                    type="text"
                    className="filterSelect ps-3"
                    placeholder="Write you full address here"
                  />
                  {errors.brandAddress && (
                    <p className="text-danger fw-500 fs-13 my-2 text-capitalize">
                      {errors.brandAddress}
                    </p>
                  )}
                </Col>
                <Col lg={12} className="my-2">
                  <label className="text-bold mb-1" htmlFor="brandLogo">
                    Logo<span className="text-danger">*</span>
                  </label>
                  {imgUrl === "" ? (
                    <div className="uploadWrapper mb-4">
                      <img
                        src={brandLogo}
                        alt="service"
                        className="img-fluid w-100 servImg"
                        draggable={false}
                      />
                      {editableState === false ? (
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip>
                              <small>Change Logo!</small>
                            </Tooltip>
                          }
                        >
                          <button
                            onClick={() => fileInput.current.click()}
                            className="uploadFileBtn"
                            type="button"
                          >
                            <FaEdit />
                          </button>
                        </OverlayTrigger>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    <div className="uploadWrapper mb-4">
                      <img
                        src={imgUrl}
                        alt="service"
                        className="img-fluid w-100 servImg"
                        draggable={false}
                      />
                      {editableState === false ? (
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip>
                              <small>Change Logo!</small>
                            </Tooltip>
                          }
                        >
                          <button
                            onClick={() => fileInput.current.click()}
                            className="uploadFileBtn"
                            type="button"
                          >
                            <FaEdit />
                          </button>
                        </OverlayTrigger>
                      ) : (
                        ""
                      )}
                    </div>
                  )}
                  <input
                    type="file"
                    ref={fileInput}
                    id="brandLogo"
                    className="d-none"
                    name="brandLogo"
                    onChange={handleImg}
                    accept="image/png , image/jpeg"
                  />
                  {errors.brandLogo && (
                    <p className="text-danger fw-500 fs-13 my-2 text-capitalize">
                      {errors.brandLogo}
                    </p>
                  )}
                </Col>
              </Row>
            </div>
            <div className="mt-3">
              {/* modal buttons */}
              {editableState === false ? (
                <>
                  <button
                    className="main-btn m-2"
                    disabled={isLoading}
                    type="submit"
                  >
                    {" "}
                    {isLoading ? "saving..." : "save"}{" "}
                  </button>
                  <button
                    className="sec-btn m-2"
                    type="button"
                    onClick={() => setEditModalShow(false)}
                  >
                    Cancel
                  </button>
                </>
              ) : (
                ""
              )}
            </div>
          </form>
        </div>
      </CustomModal>
    </>
  );
};

export default Index;
