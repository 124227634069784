import React, { useState, useEffect } from "react";

import Title from "../../hook/Title";
import CompanyAnalytics from "./CompanyAnalytics";
import UserDashboard from "./UserDashboard";
import { useSelector } from "react-redux";

const Index = () => {
  Title("Dashboard");

  // getting value of authenticated user using redux persistor
  const AuthReducer = useSelector((state) => state.AuthReducer);
  const { empId, designation } = AuthReducer.data;

  // --------------------Subhan Code Changes---------In------//////////////////////////////////////////////

  useEffect(() => {}, []);
  // --------------------Subhan Code Changes-----------Out----//////////////////////////////////////////////

  return (
    <>
      <h4 className="primHeading my-3">
        {designation === "head_of_department" || designation === "president"
          ? "analytics dashboard"
          : "User Dashboard"}
      </h4>
      {designation === "head_of_department" || designation === "president" ? (
        <CompanyAnalytics />
      ) : (
        <UserDashboard />
      )}
    </>
  );
};

export default Index;
