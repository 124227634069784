import React, { useState, useEffect } from 'react';
import GoBack from '../../../Components/GoBack';
import { Col, Row } from 'react-bootstrap';
import Title from '../../../hook/Title';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { FaPlus, FaTrashAlt } from "react-icons/fa";
import { toast } from "react-toastify";
import LoadingSpinner from '../../../utils/LoadingSpinner';
import {
    getInvoiceByIdForEditRoute,
    getUnitBrandClientByIdRoute,
    getBrandsClientsByUnitIdAndSalesPersonIdRoute,
    getActivePaypalGatewayAccountsRoute,
    getActiveStripeGatewayAccountsRoute,
    cancelAndReviseInvoiceByIdRoute,
    getActiveWireGatewayAccountsRoute,
    getActiveAuthorizenetGatewayAccountsRoute
} from "../../../utils/APIRoutes";
import Accordion from 'react-bootstrap/Accordion';
import axios from 'axios';
import { json, useNavigate, useParams } from 'react-router-dom';
import { FaCheck } from 'react-icons/fa';
import { useSelector } from "react-redux";
import currencyCodes from "../../../helpers/currencyCodes.json";
import currencySymbols from "../../../helpers/currencySymbols.json";
import Select from 'react-select';

const EditInvoice = () => {
    Title("Edit Invoice");
    const { _id } = useParams();
    const navigate = useNavigate();
    let toastOptions = {
        position: "top-left",
        autoClose: 5000,
        pauseOnHover: true,
        draggable: false,
        theme: "dark",
    };
    const AuthReducer = useSelector((state) => state.AuthReducer);
    const { empId } = AuthReducer.data;
    const allcurrencyCodes = currencyCodes.codes;
    let currencyCodeSymbols = currencySymbols.codes;
    //------------------------ Get Brands Name --------------------- //
    const [servError, setServError] = useState(false);
    const [unitDetails, setUnitDetails] = useState([]);
    const [brandDetails, setBrandDetails] = useState([]);
    const [clientsDetails, setClientsDetails] = useState([]);
    const [currentBrand, setCurrentBrand] = useState("");
    const [currentClient, setCurrentClient] = useState("");
    const [currUnit, setCurrUnit] = useState("");
    const [selectedstripeMerchant, setSelectedStripeMerchant] = useState("");
    const [selectedPaypalMerchant, setSelectedPaypalMerchant] = useState("");
    const [selectedBankMerchant, setSelectedBankMerchant] = useState("");
    const [selectedAuthorizeMerchant, setSelectedAuthorizeMerchant] = useState("");
    const [stripeMerchant, setStripeMerchant] = useState([]);
    const [paypalMerchant, setPaypalMerchant] = useState([]);
    const [bankMerchant, setBankMerchant] = useState([]);
    const [authorizeMerchant, setAuthorizeMerchant] = useState([]);
    const [selectedCurrency, setSelectedCurrency] = useState("");
    const [errors, setErrors] = useState({});
    // ---- States of inputs in the form of key value pair name:value ------ // 
    const [values, setValues] = useState({ memo: "", dueDate: "", footer: "" });
    const { memo, dueDate, footer } = values;
    // Get All Data Of Invoice !
    let editableDate;
    const getInvoiceDetails = async () => {
        try {
            const result = await fetch(`${getInvoiceByIdForEditRoute}?invoiceId=${_id}`);
            const response = await result.json();
            if (response.success === true) {
                setCurrUnit(response.unit._id);
                setCurrentBrand(JSON.stringify(response.brand))
                setCurrentClient(JSON.stringify(response.client))
                setlineItems(response.invoice.lineItems)
                editableDate = new Date(parseInt(response.invoice.dueDate))
                editableDate = String(editableDate.getFullYear()) + "-" + String(editableDate.getMonth() + 1).padStart(2, '0') + "-" + String(editableDate.getDate()).padStart(2, '0')
                setValues({ memo: response.invoice.memo, dueDate: editableDate, footer: response.invoice.footer })
                setServError(false);
            } else {
                throw new Error('Something went wrong.');
            }
        } catch (error) {
            setServError(true);
        }
    }
    const getEmpUnit = async () => {
        try {
            const resp = await fetch(`${getUnitBrandClientByIdRoute}?empId=${empId}`);
            const resData = await resp.json();
            if (resData.success === true) {
                setUnitDetails(resData.unit);
                setBrandDetails(resData.brand);
                setClientsDetails(resData.client);
                setCurrUnit(resData.unit[0].id);
                setServError(false);
            } else {
                throw new Error('Something went wrong.');
            }
        } catch (error) {
            setServError(true);
        }
    }
    const getAllDetailsBrandClient = async () => {
        try {
            const resp = await fetch(`${getBrandsClientsByUnitIdAndSalesPersonIdRoute}?empId=${empId}&unitId=${currUnit}`);
            const resData = await resp.json();
            if (resData.success === true) {
                setBrandDetails(resData.brand);
                setClientsDetails(resData.client);
            } else {
                setBrandDetails([]);
                setClientsDetails([]);
                setCurrentClient("");
                setCurrentBrand("");
                setServError(false);
            }
        } catch (error) {
            setServError(true);
        }
    }
    // Get Merchants Details
    const getAllStripeMerchantDetails = async () => {
        try {
            const resp = await fetch(getActiveStripeGatewayAccountsRoute);
            const resData = await resp.json();
            if (resData.success === true) {
                setStripeMerchant(resData.stripe);
                setServError(false);
            } else {
                throw new Error('Something went wrong.');
            }
        } catch (error) {
            setServError(true);
        }
    }
    const getAllPaypalMerchantDetails = async () => {
        try {
            const resp = await fetch(getActivePaypalGatewayAccountsRoute);
            const resData = await resp.json();
            if (resData.success === true) {
                setPaypalMerchant(resData.paypal);
                setServError(false);
            } else {
                throw new Error('Something went wrong.');
            }
        } catch (error) {
            setServError(true);
        }
    }
    const getWireGatewayAccountDetails = async () => {
        try {
            const resp = await fetch(getActiveWireGatewayAccountsRoute);
            const resData = await resp.json();
            if (resData.success === true) {
                setBankMerchant(resData.wire);
                setServError(false);
            } else {
                throw new Error('Something went wrong.');
            }
        } catch (error) {
            setServError(true);
        }
    }
    const getAllAuthorizeMerchantDetails = async () => {
        try {
            const resp = await fetch(getActiveAuthorizenetGatewayAccountsRoute);
            const resData = await resp.json();
            if (resData.success === true) {
                setAuthorizeMerchant(resData.authorizenet);
                setServError(false);
            } else {
                throw new Error('Something went wrong.');
            }
        } catch (error) {
            setServError(true);
        }
    }
    useEffect(() => {
        getAllStripeMerchantDetails();
        getAllPaypalMerchantDetails();
        getWireGatewayAccountDetails();
        getAllAuthorizeMerchantDetails();
        getInvoiceDetails();
    }, [])
    useEffect(() => {
        if (unitDetails.length > 1) {
            getAllDetailsBrandClient();
        } else {
            getEmpUnit()
        }
    }, [currUnit])
    let itemValidationError;
    // Getting current date and disabled the previous dates !
    const dateValue = new Date();
    let day = String(dateValue.getDate()).padStart(2, '0');
    let month = String(dateValue.getMonth() + 1).padStart(2, '0');
    let year = dateValue.getFullYear();
    let currentDate = `${year}-${month}-${day}`;
    const [lineItems, setlineItems] = useState([{ description: "", quantity: 0, rate: 0, total: 0 }]);
    let totalAmount;
    let subTot = 0;
    lineItems?.map((item) => {
        totalAmount = parseInt(item.quantity * item.rate);
        subTot = subTot + totalAmount;
    })
    const [itemError, setItemError] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    // ---- Getting values of inputs ------ //  
    const handleChange = (event) => {
        setValues({ ...values, [event.target.name]: event.target.value });
    };
    // handle input change for time slots
    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...lineItems];
        list[index][name] = value;
        list[index]["total"] = lineItems[index].quantity * lineItems[index].rate
        setlineItems(list);
    };
    // handle click event of the Remove button
    const handleRemoveClick = index => {
        const list = [...lineItems];
        list.splice(index, 1);
        setlineItems(list);
    };
    // handle click event of the Add button
    const handleAddClick = () => {
        setlineItems([...lineItems, { description: "", quantity: "", rate: "", total: "" }]);
    };
    // select payment merchant value ! 
    const [selectedMerch1, setSelectedMerch1] = useState({});
    const [selectedMerch2, setSelectedMerch2] = useState({});
    const [selectedMerch3, setSelectedMerch3] = useState({});
    const [selectedMerch4, setSelectedMerch4] = useState({});

    const handleSlectedStripeMerchant = (e) => {
        const merchantData = JSON.parse(e.target.value);
        setSelectedMerch2(merchantData);
        if (JSON.stringify(merchantData) === '{}') {
            setSelectedStripeMerchant({});
        } else {
            setSelectedStripeMerchant(merchantData._id);
        }
    };
    const handleSlectedPaypalMerchant = (e) => {
        const merchantData = JSON.parse(e.target.value)
        setSelectedMerch1(merchantData);
        if (JSON.stringify(merchantData) === '{}') {
            setSelectedPaypalMerchant({});
        } else {
            setSelectedPaypalMerchant(merchantData._id);
        }
    };
    const handleSlectedBankMerchant = (e) => {
        const merchantData = JSON.parse(e.target.value);
        setSelectedMerch3(merchantData);
        if (JSON.stringify(merchantData) === '{}') {
            setSelectedBankMerchant({});
        } else {
            setSelectedBankMerchant(merchantData._id);
        }
    };
    const handleSlectedAuthorizeMerchant = (e) => {
        const merchantData = JSON.parse(e.target.value);
        setSelectedMerch4(merchantData);
        if (JSON.stringify(merchantData) === '{}') {
            setSelectedAuthorizeMerchant({});
        } else {
            setSelectedAuthorizeMerchant(merchantData._id);
        }
    };
    let paymentGatewayAvailable = [];
    let hasError = false;

    // For client select options
    const options = clientsDetails.map(client => ({
        label: client?.name || "",
        value: client?._id || "",
        email: client?.email || "",
        phone: client?.phone || "",
    }));

    const myClient = currentClient ? JSON.parse(currentClient) : "";
    var currentOption = {
        label: myClient?.name,
        value: myClient?._id,
        email: myClient?.email,
        phone: myClient?.phone,
    };
    const validateForm = () => {
        const newErrors = {};
        // To check the Items list is not empty or not ! 
        lineItems?.map((items) => {
            const { description, quantity, rate } = items;
            if (description === "" || quantity === "" || rate === "") {
                setItemError("border-red")
                setIsLoading(false);
                hasError = true;
                itemValidationError = true;
            }
        })
        if (dueDate === "" || dueDate === undefined || dueDate === null) {
            setIsLoading(false);
            hasError = true;
            newErrors.dueDate = 'Due Date is required !';
        }
        if (currentBrand === "" || currentBrand === undefined || currentBrand === null) {
            setIsLoading(false);
            hasError = true;
            newErrors.currentBrand = 'Brand is required !';
        }
        if (currentClient === "" || currentClient === undefined || currentClient === null) {
            setIsLoading(false);
            hasError = true;
            newErrors.currentClient = 'Client is required !';
        }
        if (currUnit === "" || currUnit === undefined || currUnit === null) {
            setIsLoading(false);
            hasError = true;
            newErrors.currUnit = 'Unit is required !';
        }
        if (selectedCurrency === "" || selectedCurrency === undefined || selectedCurrency === null) {
            setIsLoading(false);
            hasError = true;
            newErrors.selectedCurrency = 'Currency is required !';
        }
        if (JSON.stringify(selectedMerch1) === '{}' && JSON.stringify(selectedMerch2) === '{}' && JSON.stringify(selectedMerch3) === '{}' && JSON.stringify(selectedMerch4) === '{}') {
            toast.error("Please Select Payment Merchant", toastOptions, toastOptions['position'] = "top-right");
            setIsLoading(false);
            hasError = true;
        }
        setErrors(newErrors);
    };
    // ---- Form Submittion Function ------ //  
    const handleInvSubmit = async (event) => {
        event.preventDefault();
        validateForm();
        if (hasError === false) {
            setIsLoading(true);
            if (itemValidationError === true) {
                toast.error("List Items can not be empty !", toastOptions, toastOptions['position'] = "top-right");
                setIsLoading(false);
                return false;
            } else {
                let expDate = new Date(dueDate);
                let date = expDate.getTime();
                let myCliendId = JSON.parse(currentClient).value === undefined || JSON.parse(currentClient).value === null || JSON.parse(currentClient).value === "" ? JSON.parse(currentClient)._id : JSON.parse(currentClient).value
                if (myCliendId === undefined || myCliendId === null || myCliendId === "") {
                    toast.error("Please select client !", toastOptions, toastOptions['position'] = "top-right");
                } else {
                    try {
                        setIsLoading(true);
                        if (JSON.stringify(selectedMerch1) !== '{}') {
                            paymentGatewayAvailable.push(selectedMerch1);
                        }
                        if (JSON.stringify(selectedMerch2) !== '{}') {
                            paymentGatewayAvailable.push(selectedMerch2);
                        }
                        if (JSON.stringify(selectedMerch3) !== '{}') {
                            paymentGatewayAvailable.push(selectedMerch3);
                        }
                        if (JSON.stringify(selectedMerch4) !== '{}') {
                            paymentGatewayAvailable.push(selectedMerch4);
                        }
                        const { data } = await axios.post(`${cancelAndReviseInvoiceByIdRoute}?invoiceObjectId=${_id}`, {
                            paymentGatewayAvailable,
                            clientId: myCliendId,
                            brandId: JSON.parse(currentBrand)._id,
                            salespersonId: empId,
                            currency: selectedCurrency,
                            unitId: currUnit,
                            subTotalAmount: subTot,
                            totalAmount: subTot,
                            dueDate: date,
                            lineItems,
                            memo,
                            footer,
                        });
                        if (data.success === true) {
                            toast.success(data.msg, toastOptions, toastOptions['position'] = "top-right");
                            setIsLoading(false);
                            navigate("/my-invoices")
                        } else {
                            toast.error(data.msg, toastOptions, toastOptions['position'] = "top-right");
                            setIsLoading(false);
                        }
                    } catch (error) {
                        if (error.response.status === 500) {
                            toast.error("Something went wrong please try again later !", toastOptions, toastOptions['position'] = "top-right");
                            setIsLoading(false);
                        }
                    }
                }
            }
        } else {
            toast.error("Please Fill Out The Required Fields.", toastOptions, toastOptions['position'] = "top-right");
        }
    };
    return (
        <>
            <Row>
                <Col xxl={11} className="mx-auto">
                    <div className="d-flex align-items-center mb-2 flex-wrap">
                        <GoBack /><h4 className="primHeading mb-0">Edit Invoice</h4>
                    </div>
                    <Row>
                        <Col xl={8} className="order-xl-1 order-2">
                            <div className="box my-1 rounded-3">
                                <div className="text-center">
                                    <h4 className="secHeading colBlue fw-600 my-2">Invoice</h4>
                                </div>
                                <form onSubmit={(event) => handleInvSubmit(event)} id='printablediv' className='position-relative'>
                                    <div className="text-center">
                                        {currentBrand === "" ||
                                            currentBrand === undefined ||
                                            currentBrand === null ? "" :
                                            <img src={JSON.parse(currentBrand).brandLogoUrl} draggable="false" alt="brand logo" className="invBrandImg mb-2 mainBrandLogo" />}
                                    </div>
                                    <Row>
                                        <Col lg={4} md={6} className="ms-auto">
                                            <div className="d-md-flex align-items-center justify-content-end gap-1 invDateWrap">
                                                {
                                                    unitDetails.length > 1 ? (
                                                        <>
                                                            <label className="text-bold flex-grow-1 flex-shrink-0" htmlFor="unit">Select Unit: </label>
                                                            <select
                                                                className="filterSelect flex-shrink-0 fs-13"
                                                                id="unit"
                                                                name='unit'
                                                                value={currUnit}
                                                                disabled={true}
                                                                onChange={(e) => setCurrUnit(e.target.value)}>
                                                                <option value="">Select Unit</option>
                                                                {
                                                                    unitDetails.map((detail) => {
                                                                        const { id, name } = detail;
                                                                        return (
                                                                            <option key={id} value={id}>{name}</option>
                                                                        )
                                                                    })
                                                                }
                                                            </select>
                                                        </>
                                                    ) : ""
                                                }
                                            </div>
                                            {errors.currUnit && <p className="text-danger fw-500 fs-13 my-2 text-capitalize">{errors.currUnit}</p>}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={4} md={6} className="ms-auto">
                                            <div className="my-2 d-md-flex align-items-center justify-content-end gap-1 invDateWrap">
                                                <label className="text-bold flex-grow-1 flex-shrink-0" htmlFor="dueDate">Due Date: </label>
                                                <input
                                                    id='dueDate'
                                                    type="date"
                                                    name="dueDate"
                                                    min={currentDate}
                                                    value={values.dueDate}
                                                    onChange={(e) => handleChange(e)}
                                                    className="filterSelect flex-shrink-0 fs-13" />
                                            </div>
                                            {errors.dueDate && <p className="text-danger fw-500 fs-13 my-2 text-capitalize">{errors.dueDate}</p>}
                                        </Col>
                                    </Row>
                                    <Row className='my-4 bordCenter'>
                                        <Col md={6}>
                                            <div className="d-flex align-items-center justify-content-between my-2 mx-lg-3 gap-2">
                                                <h6 className='w-75 my-2 fs-13'>Sender</h6>
                                                <select
                                                    className="primary-inputs px-3 invSelect flex-shrink-0"
                                                    id="currentBrand"
                                                    name='currentBrand'
                                                    value={currentBrand}
                                                    onChange={(e) => setCurrentBrand(e.target.value)} >
                                                    <option value="">Select Brand</option>
                                                    {
                                                        brandDetails.length > 0 ? (
                                                            brandDetails?.map((brandDetails) => {
                                                                const { _id, brandName } = brandDetails
                                                                return (<option key={_id} value={JSON.stringify(brandDetails)}>{brandName}</option>)
                                                            })
                                                        ) : ""
                                                    }
                                                </select>
                                            </div>
                                            {errors.currentBrand && <p className="text-danger fw-500 fs-13 my-2 text-capitalize text-end">{errors.currentBrand}</p>}
                                            {
                                                currentBrand === "" || currentBrand === undefined || currentBrand === null ? (
                                                    <div className="generalBox  mx-lg-3 text-center">
                                                        <p>No Brand Selected</p>
                                                    </div>
                                                ) : (
                                                    <div className="generalBox mx-lg-3">
                                                        <h4 className="brandName">{JSON.parse(currentBrand).brandName}</h4>
                                                        <p>{JSON.parse(currentBrand).brandEmail}</p>
                                                        <p>{JSON.parse(currentBrand).brandPhone}</p>
                                                    </div>
                                                )
                                            }
                                        </Col>
                                        <Col md={6}>
                                            <div className="d-flex align-items-center justify-content-between my-2 mx-lg-3 gap-2">
                                                <h6 className='w-75 my-2 fs-13'>Receiver</h6>
                                                <Select
                                                    defaultValue={currentOption.label === undefined ? "" : currentOption}
                                                    className="px-3 invSelect flex-shrink-0 basic-single pkgSelect"
                                                    classNamePrefix="select"
                                                    isSearchable={true}
                                                    placeholder="Change a client ?"
                                                    options={options}
                                                    onChange={(e) => setCurrentClient(JSON.stringify(e))}
                                                />
                                            </div>
                                            {errors.currentClient && <p className="text-danger fw-500 fs-13 my-2 text-capitalize text-end">{errors.currentClient}</p>}
                                            {
                                                currentClient === "" || currentClient === undefined || currentClient === null ? (
                                                    <div className="generalBox  mx-lg-3 text-center">
                                                        <p>No Client Selected</p>
                                                    </div>
                                                ) : (
                                                    <div className="generalBox  mx-lg-3">
                                                        <h4 className="brandName">{JSON.parse(currentClient).label === undefined ? JSON.parse(currentClient).name : JSON.parse(currentClient).label}</h4>
                                                        <p>{JSON.parse(currentClient).email}</p>
                                                        <p>{JSON.parse(currentClient).phone}</p>
                                                        <p>{JSON.parse(currentClient).organization}</p>
                                                    </div>
                                                )
                                            }
                                        </Col>
                                    </Row>
                                    {/* Invoice Table starts here */}
                                    <table className="table invtable customInvTable">
                                        <thead>
                                            <tr className='fs-13 fw-600 lineSmNone'>
                                                <td>Description</td>
                                                <td>Quantity</td>
                                                <td>Rate</td>
                                                <td>Total</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {lineItems.map((x, i) => {
                                                let number = i;
                                                return (
                                                    <tr key={i} className="my-2">
                                                        <td>
                                                            <textarea
                                                                id={`description${i}`}
                                                                className={`${itemError} primary-inputs px-3 my-2 fs-13`}
                                                                type="text"
                                                                name="description"
                                                                value={x.description}
                                                                onChange={e => handleInputChange(e, i)}
                                                                placeholder={`Description ${++number}`} rows="5" cols="50" ></textarea>
                                                        </td>
                                                        <td>
                                                            <input
                                                                id={`quantity${i}`}
                                                                className={`${itemError} primary-inputs px-3 my-2 fs-13`}
                                                                type="number"
                                                                name="quantity"
                                                                placeholder="Enter Quantity"
                                                                value={x.quantity}
                                                                onChange={e => handleInputChange(e, i)}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                id={`rate${i}`}
                                                                className={`${itemError} primary-inputs px-3 my-2 fs-13`}
                                                                type="number"
                                                                name="rate"
                                                                value={x.rate}
                                                                placeholder="Enter Amount"
                                                                onChange={e => handleInputChange(e, i)}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                className='primary-inputs px-3 my-2 disableAmount'
                                                                id={`total${i}`}
                                                                type="number"
                                                                name="total"
                                                                onChange={e => handleInputChange(e, i)}
                                                                value={x.total}
                                                                disabled={true}
                                                            />
                                                        </td>
                                                        <td>
                                                            {
                                                                i === 0 ? "" :
                                                                    (<OverlayTrigger placement="right" overlay={<Tooltip><small>Delete Item !</small></Tooltip>}>
                                                                        <button className="delBtn my-2 d-block ms-auto" type="button" onClick={() => handleRemoveClick(i)}><FaTrashAlt /></button>
                                                                    </OverlayTrigger>)
                                                            }
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                    <div className="text-end">
                                        <OverlayTrigger placement="right" overlay={<Tooltip><small>Add Item !</small></Tooltip>}>
                                            <button className="main-btn ms-auto mt-3 d-block btnSm" onClick={handleAddClick} type="button"><FaPlus /></button>
                                        </OverlayTrigger>
                                    </div>
                                    <Row>
                                        <Col md={6} className="ms-auto my-2">
                                            <table className='ms-auto smTable'>
                                                <tbody>
                                                    <tr className='fs-13 fw-600'>
                                                        <td>Subtotal </td>
                                                        <td>
                                                            {
                                                                selectedCurrency === "" ? subTot :
                                                                    Object.keys(currencyCodeSymbols).map((objkey, index) => {
                                                                        if (selectedCurrency === objkey) {
                                                                            return (
                                                                                <p className='mb-0' key={index}>{`${currencyCodeSymbols[objkey]} ${subTot}`}</p>
                                                                            );
                                                                        }
                                                                    })
                                                            }
                                                        </td>
                                                    </tr>
                                                    <tr className='fw-600 fs-20'>
                                                        <td>Total </td>
                                                        <td>
                                                            {
                                                                selectedCurrency === "" ? subTot :
                                                                    Object.keys(currencyCodeSymbols).map((objkey, index) => {
                                                                        if (selectedCurrency === objkey) {
                                                                            return (
                                                                                <p className='mb-0' key={index}>{`${currencyCodeSymbols[objkey]} ${subTot}`}</p>
                                                                            );
                                                                        }
                                                                    })
                                                            }
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </Col>
                                        <Col md={12}>
                                            <div className="my-2">
                                                <label className="text-bold" htmlFor="memo">Memo :</label>
                                                <textarea
                                                    name="memo"
                                                    id="memo"
                                                    className="filterSelect"
                                                    rows="2"
                                                    value={values.memo}
                                                    onChange={(e) => handleChange(e)}
                                                    placeholder="Enter Memo"></textarea>
                                            </div>
                                            <div className="my-2">
                                                <label className="text-bold" htmlFor="footer">Footer :</label>
                                                <textarea
                                                    name="footer"
                                                    id="footer"
                                                    className="filterSelect"
                                                    rows="2"
                                                    value={values.footer}
                                                    onChange={(e) => handleChange(e)}
                                                    placeholder="Enter footer"></textarea>
                                            </div>
                                            <div className="text-end">
                                                <button type="submit" disabled={isLoading} className="main-btn my-3">{isLoading ? <LoadingSpinner /> : "Submit"}</button>
                                            </div>
                                        </Col>
                                    </Row>
                                </form>
                            </div>
                        </Col>
                        <Col xl={4} className="order-xl-2 order-1">
                            <div className="box my-1 rounded-3 customAccordian">
                                <div className="text-center">
                                    <h4 className="subHeading mb-4 fw-800">Invoice <span className='colBlue'>Setting's</span></h4>
                                </div>
                                <Accordion defaultActiveKey="0" className='paymentSettingsAccordian'>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>Payment Method<span className="text-danger">*</span> </Accordion.Header>
                                        <Accordion.Body>
                                            <Accordion defaultActiveKey="04">
                                                <Accordion.Item eventKey="00">
                                                    <Accordion.Header>Stripe</Accordion.Header>
                                                    <Accordion.Body>
                                                        {
                                                            stripeMerchant.length > 0 ? (
                                                                stripeMerchant?.map((currMerchant) => {
                                                                    const { _id, accountName, gatewayName } = currMerchant
                                                                    return (
                                                                        <label key={_id} htmlFor={_id} className='chkboxType w-100'>
                                                                            <input
                                                                                value={JSON.stringify(currMerchant)}
                                                                                checked={selectedstripeMerchant === _id}
                                                                                onChange={(e) => handleSlectedStripeMerchant(e)}
                                                                                type="radio"
                                                                                className='visHidden'
                                                                                id={_id}
                                                                                name={gatewayName} /> {accountName}
                                                                            {selectedstripeMerchant === _id ? <FaCheck /> : ""}
                                                                        </label>
                                                                    )
                                                                })
                                                            ) : ""
                                                        }
                                                        <label htmlFor="stripeMerchNone" className='chkboxType w-100'>
                                                            <input
                                                                value={JSON.stringify({})}
                                                                checked={JSON.stringify(selectedstripeMerchant) === '{}'}
                                                                onChange={(e) => handleSlectedStripeMerchant(e)}
                                                                type="radio" className='visHidden' id="stripeMerchNone" name="stripe" />None
                                                            {JSON.stringify(selectedstripeMerchant) === '{}' ? <FaCheck /> : ""}
                                                        </label>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="01">
                                                    <Accordion.Header>Paypal</Accordion.Header>
                                                    <Accordion.Body>
                                                        {paypalMerchant.length > 0 ? (
                                                            paypalMerchant?.map((currMerchant) => {
                                                                const { _id, accountName, gatewayName } = currMerchant
                                                                return (
                                                                    <label key={_id} htmlFor={_id} className='chkboxType w-100'>
                                                                        <input
                                                                            value={JSON.stringify(currMerchant)}
                                                                            checked={selectedPaypalMerchant === _id}
                                                                            onChange={(e) => handleSlectedPaypalMerchant(e)}
                                                                            type="radio"
                                                                            className='visHidden'
                                                                            id={_id}
                                                                            name={gatewayName} /> {accountName}
                                                                        {selectedPaypalMerchant === _id ? <FaCheck /> : ""}
                                                                    </label>
                                                                )
                                                            })
                                                        ) : ""}
                                                        <label htmlFor="paypalMerchNone" className='chkboxType w-100'>
                                                            <input
                                                                value={JSON.stringify({})}
                                                                checked={JSON.stringify(selectedPaypalMerchant) === '{}'}
                                                                onChange={(e) => handleSlectedPaypalMerchant(e)}
                                                                type="radio" className='visHidden' id="paypalMerchNone" name="paypal" />None
                                                            {JSON.stringify(selectedPaypalMerchant) === '{}' ? <FaCheck /> : ""}
                                                        </label>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="02">
                                                    <Accordion.Header>Authorize.Net</Accordion.Header>
                                                    <Accordion.Body>
                                                        {
                                                            authorizeMerchant.length > 0 ? (
                                                                authorizeMerchant?.map((currMerchant) => {
                                                                    const { _id, accountName, gatewayName, currencyCode } = currMerchant
                                                                    return (
                                                                        <label key={_id} htmlFor={_id} className='chkboxType w-100'>
                                                                            <input
                                                                                value={JSON.stringify(currMerchant)}
                                                                                checked={selectedAuthorizeMerchant === _id}
                                                                                onChange={(e) => handleSlectedAuthorizeMerchant(e)}
                                                                                type="radio"
                                                                                className='visHidden'
                                                                                id={_id}
                                                                                name={gatewayName} /> {`${accountName} (${currencyCode})`}
                                                                            {selectedAuthorizeMerchant === _id ? <FaCheck /> : ""}
                                                                        </label>
                                                                    )
                                                                })
                                                            ) : ""
                                                        }
                                                        <label htmlFor="authorizeMerchNone" className='chkboxType w-100'>
                                                            <input
                                                                value={JSON.stringify({})}
                                                                checked={JSON.stringify(selectedAuthorizeMerchant) === '{}'}
                                                                onChange={(e) => handleSlectedAuthorizeMerchant(e)}
                                                                type="radio" className='visHidden' id="authorizeMerchNone" name="authorizenet" />None
                                                            {JSON.stringify(selectedAuthorizeMerchant) === '{}' ? <FaCheck /> : ""}
                                                        </label>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="03">
                                                    <Accordion.Header>Wire/Bank Transfer</Accordion.Header>
                                                    <Accordion.Body>
                                                        {
                                                            bankMerchant.length > 0 ? (
                                                                bankMerchant?.map((currBank) => {
                                                                    const { _id, accountName, gatewayName, bankName, currencyCode } = currBank
                                                                    return (
                                                                        <label key={_id} htmlFor={_id} className='chkboxType w-100'>
                                                                            <div>
                                                                                <input
                                                                                    value={JSON.stringify(currBank)}
                                                                                    checked={selectedBankMerchant === _id}
                                                                                    onChange={(e) => handleSlectedBankMerchant(e)}
                                                                                    type="radio"
                                                                                    className='visHidden'
                                                                                    id={_id}
                                                                                    name={gatewayName} />
                                                                                <span className='fs-12'>{`${accountName} (${currencyCode})`}</span>
                                                                                <small className='d-block'>{bankName || ""}</small>
                                                                            </div>
                                                                            {selectedBankMerchant === _id ? <FaCheck /> : ""}
                                                                        </label>
                                                                    )
                                                                })
                                                            ) : ""
                                                        }
                                                        <label htmlFor="bankMerchNone" className='chkboxType w-100'>
                                                            <input
                                                                value={JSON.stringify({})}
                                                                checked={JSON.stringify(selectedBankMerchant) === '{}'}
                                                                onChange={(e) => handleSlectedBankMerchant(e)}
                                                                type="radio" className='visHidden' id="bankMerchNone" name="wire" />None
                                                            {JSON.stringify(selectedBankMerchant) === '{}' ? <FaCheck /> : ""}
                                                        </label>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="04">
                                                    <Accordion.Header>Currency<span className="text-danger">*</span></Accordion.Header>
                                                    <Accordion.Body>
                                                        <div className='bgwhite p-2 rounded-2'>
                                                            <label className="d-block mb-2" htmlFor="currency">Currency</label>
                                                            <select
                                                                className="primary-inputs px-3"
                                                                id="currency"
                                                                name='currency'
                                                                value={selectedCurrency}
                                                                onChange={(e) => setSelectedCurrency(e.target.value)}>
                                                                <option value="">Select Option</option>
                                                                {Object.keys(allcurrencyCodes).map((key, index) => {
                                                                    return (<option key={index} value={key}>{`${allcurrencyCodes[key]} (${key})`}</option>)
                                                                })}
                                                            </select>
                                                            {errors.selectedCurrency && <p className="text-danger fw-500 fs-13 my-2 text-capitalize">{errors.selectedCurrency}</p>}
                                                        </div>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    );
}

export default EditInvoice; 