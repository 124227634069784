import React from "react";
import { Col, Row } from "react-bootstrap";
import Title from "../../hook/Title";
import EditorLayout from "./EditorLayout";
import GoBack from "../../Components/GoBack";

const ViewTasks = () => {
  Title("View Task");
  return (
    <>
      <div className="d-flex align-items-center mb-4 flex-wrap">
        <GoBack />
        <h4 className="section-heading mb-0">View Task<span> Details</span></h4>
      </div>
      <div className="box rounded p-0">
        <div className="taskTopBar">
          <h4 className="subHeading colWhite mb-0">
            Lorem, ipsum dolor sit amet consectetur adipisicing elit.
          </h4>
        </div>
        <div className="taskBody">
          <Row>
            <Col lg={4} md={6}>
              <div className="textBox">
                <label className="text-bold" htmlFor="#">
                  Project Name:
                </label>
                <p className="mb-0">Lorem Ipsum.</p>
              </div>
            </Col>
            <Col lg={4} md={6}>
              <div className="textBox">
                <label className="text-bold" htmlFor="#">
                  Created By:
                </label>
                <p className="mb-0">Sales Person</p>
              </div>
            </Col>
            <Col lg={4} md={6}>
              <div className="textBox">
                <label className="text-bold" htmlFor="#">
                  Assigned To:
                </label>
                <p className="mb-0">harry</p>
              </div>
            </Col>
            <Col lg={4} md={6}>
              <div className="textBox">
                <label className="text-bold" htmlFor="#">
                  Assigned By:
                </label>
                <p className="mb-0">thomas</p>
              </div>
            </Col>
            <Col lg={4} md={6}>
              <div className="textBox">
                <label className="text-bold" htmlFor="#">
                  Start Date:
                </label>
                <p className="mb-0">11/29/2022</p>
              </div>
            </Col>
            <Col lg={4} md={6}>
              <div className="textBox">
                <label className="text-bold" htmlFor="#">
                  Due Date:
                </label>
                <p className="mb-0">12/29/2022</p>
              </div>
            </Col>
            <Col lg={4} md={6}>
              <div className="textBox">
                <label className="text-bold" htmlFor="#">
                  Work Status:
                </label>
                <p className="mb-0">Inprogress</p>
              </div>
            </Col>
            <Col lg={4} md={6}>
              <div className="textBox">
                <label className="text-bold" htmlFor="#">
                  Brand Name:
                </label>
                <p className="mb-0">abcdef</p>
              </div>
            </Col>
            <Col lg={4} md={6}>
              <div className="textBox">
                <label className="text-bold" htmlFor="#">
                  Priority:
                </label>
                <p className="mb-0">High</p>
              </div>
            </Col>
          </Row>
        </div>
        {/* Task Editor */}
        <EditorLayout />
      </div>
    </>
  );
};

export default ViewTasks;
