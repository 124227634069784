export const allThemesData = [
    {
        themeColor: '#17263a',
        primColor: '#ffffff',
        secColor: '#3366ff',
        boxBg: '#f1f4fb',
        boxColor: '#fff',
        textColor: '#000',
        btnMainColor: "#36f",
        secHovColor: "#3366ffb2"
    },
    {
        themeColor: '#121a3f',
        primColor: '#021035',
        secColor: '#121a3f',
        boxBg: '#101d40',
        boxColor: '#021035',
        textColor: '#fff',
        btnMainColor: "#36f",
        secHovColor: "#3366ffb2"
    },
    {
        themeColor: '#113d3e',
        primColor: '#001c1d',
        secColor: 'orange',
        boxBg: '#0f292a',
        boxColor: '#001c1d',
        textColor: '#fff',
        btnMainColor: "#34d2ff82",
        secHovColor: "#34d2ff"
    },
    {
        themeColor: "#1f1f1f",
        primColor: "#212529",
        secColor: "#121a3f",
        boxBg: "#2a2f34",
        boxColor: "#212529",
        textColor: "#fff",
        btnMainColor: "#0ab39c9c",
        secHovColor: "#0ab39c",
    },
    {
        themeColor: "#3f132e",
        primColor: "#390029",
        secColor: "orange",
        boxBg: "#450e35",
        boxColor: "#390029",
        textColor: "#fff",
        btnMainColor: "#542245",
        secHovColor: "#ff328d",
    },
    {
        themeColor: "#3f2413",
        primColor: "#2c1201",
        secColor: "#121a3f",
        boxBg: "#372010",
        boxColor: "#2c1201",
        textColor: "#fff",
        btnMainColor: "#ffaa336b",
        secHovColor: "#ffaa33",
    },
    {
        themeColor: "#655be6",
        primColor: "#ffff",
        secColor: "#655be6",
        boxBg: "#fff",
        boxColor: "#eeeeeeba",
        textColor: "#000",
        btnMainColor: "#655be69c",
        secHovColor: "#3bc3e9",
    },
]