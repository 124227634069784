import React, { useState, useEffect } from "react";
import Title from "../../../../hook/Title";
import { TableCom } from "../../../../Components/TableCom";
import currencycode from "../../../../helpers/currencyCodes.json";
import countrycodes from "../../../../helpers/countryCodes.json";
import Dropdown from "react-bootstrap/Dropdown";
import { useNavigate } from "react-router-dom";
import {
  getLeadbyIdRoute,
  getAllLeadsByAddedByRoute,
} from "../../../../utils/APIRoutes";
import { FaRegEye, FaEllipsisV, FaTimes } from "react-icons/fa";
import CustomModal from "../../../../Components/CustomModal";
import { Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

const MyGeneratedLeads = () => {
  Title("My Generated Leads");
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  let toastOptions = {
    position: "top-left",
    autoClose: 5000,
    pauseOnHover: false,
    draggable: false,
    theme: "dark",
  };
  // const navigate = useNavigate();
  // ! Filter By Date Code for table ! :
  const [filterDates, setfilterDates] = useState({
    from: "",
    to: "",
  });
  const handleDateChange = (e) => {
    setfilterDates({ ...filterDates, [e.target.name]: e.target.value });
  };
  const clearFilters = () => {
    setfilterDates({ from: "", to: "" });
  };
  const { from, to } = filterDates;

  const allcurrencyCode = currencycode.codes;
  const allCountryCodes = countrycodes.codes;
  const AuthReducer = useSelector((state) => state.AuthReducer);
  const { empId } = AuthReducer.data;
  // Table Head Data
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [organization, setOrganization] = useState("");
  const [phone, setPhone] = useState("");
  const [country, setCountry] = useState("");
  const [currencyCode, setCurrencyCode] = useState("");
  const [budget, setBudget] = useState("");
  const [industry, setIndustry] = useState("");
  const [source, setSource] = useState("");
  const [note, setNote] = useState("");
  const [message, setMessage] = useState("");
  const [city, setCity] = useState("--");
  const [state, setState] = useState("--");
  const [leadSource, setLeadSource] = useState("--");
  // ...........! Table Code !......... //
  const [thLength, setThlength] = useState(1);
  const [items, setItems] = useState([]);
  const [numPerPage, setNumPerPage] = useState(10);
  const [totalData, setTotalData] = useState(0);
  const [pageCount, setpageCount] = useState(0);
  const [currPage, setCurrPage] = useState(0);
  const [searchVal, setSearchVal] = useState("");
  const [rerendertable, setRerendertableData] = useState(false);
  const Thead = [
    "S.No",
    "name",
    "Email",
    "phone",
    "Website",
    "Lead Source",
    "country",
    "created At",
    "budget",
    "Product",
    "Unit",
    "lead Status",
    "Action",
  ];
  useEffect(
    (currPage) => {
      currPage = currPage ?? 0;
      const getData = async () => {
        const res = await fetch(
          `${getAllLeadsByAddedByRoute}?empId=${empId}&numPerPage=${numPerPage}&page=${currPage}&search=${searchVal}&from=${from}&to=${to}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (res.status === 401) {
          navigate("/");
          toast.error(
            "Session has been Expired",
            toastOptions,
            (toastOptions["position"] = "top-right")
          );
          return;
        }
        const data = await res.json();
        const total = data.pagiantion.dataCount;
        setTotalData(total);
        setpageCount(Math.ceil(total / numPerPage));
        setItems(data.result);
      };
      getData();
    },
    [numPerPage, searchVal, rerendertable, from, to]
  );
  useEffect(() => {
    setThlength(Thead.length);
  }, [thLength]);
  const fetchData = async (currPage) => {
    const res = await fetch(
      `${getAllLeadsByAddedByRoute}?empId=${empId}&numPerPage=${numPerPage}&page=${currPage}&search=${searchVal}&from=${from}&to=${to}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (res.status === 401) {
      navigate("/");
      toast.error(
        "Session has been Expired",
        toastOptions,
        (toastOptions["position"] = "top-right")
      );
      return;
    }
    const changedData = await res.json();
    const data = changedData.result;
    return data;
  };
  const handlePageClick = async (data) => {
    let currPage = data.selected;
    const myData = await fetchData(currPage);
    setItems(myData);
    setCurrPage(currPage);
  };
  const handleChange = (e) => {
    setNumPerPage(e.target.value);
  };
  // search data in api using search box
  const hangleSearchVal = (e) => {
    setSearchVal(e.target.value);
  };
  const [editModalShow, setEditModalShow] = useState(false);
  const openLeadModal = (_id) => {
    setRerendertableData(!rerendertable);
    setEditModalShow(true);
    fetch(`${getLeadbyIdRoute}?leadId=${_id}`)
      .then((response) => response.json())
      .then(({ data: leadData }) => {
        setName(leadData.name);
        setEmail(leadData.email);
        setOrganization(leadData.organization);
        setPhone(leadData.phone);
        setCountry(leadData.country);
        setCurrencyCode(leadData.currencyCode);
        setBudget(leadData.budget);
        setIndustry(leadData.industry);
        setSource(leadData.source);
        setNote(leadData.note);
        setMessage(leadData.message);
        setLeadSource(leadData.leadSource);
      });
  };
  return (
    <>
      <div className="d-flex align-items-center justify-content-between flex-wrap my-2 mb-4">
        <h4 className="primHeading my-1">My Generated Leads</h4>
        <Link className="main-btn my-1" to="/generate-leads">
          Add Leads
        </Link>
      </div>
      <div className="box my-3">
        <h4 className="sectionHeading my-2 mb-4">My Leads Summary</h4>
        {/*==========================|||****** Filters Start Here ********|||==========================*/}
        <Row className="customFilters">
          <Col xl={2} lg={4} md={6} sm={5} className="my-2">
            <label className="text-bold" htmlFor="From">
              From:
            </label>
            <input
              value={from}
              onChange={handleDateChange}
              className="prim-date"
              type="date"
              name="from"
              id="From"
            />
          </Col>
          <Col xl={2} lg={4} md={6} sm={5} className="my-2">
            <label className="text-bold" htmlFor="To">
              To:
            </label>
            <input
              value={to}
              onChange={handleDateChange}
              className="prim-date"
              type="date"
              name="to"
              id="To"
            />
          </Col>
          <Col xl={2} lg={4} md={6} sm={2} className="my-2">
            <button
              onClick={clearFilters}
              title="Clear Date Filters"
              className="main-btn clearFilter w-100 mt-4"
            >
              Clear
            </button>
          </Col>
        </Row>
        <hr />
        <TableCom
          numPerPage={numPerPage}
          totalData={totalData}
          handleChange={handleChange}
          hangleSearchVal={hangleSearchVal}
          pageCount={pageCount}
          handlePageClick={handlePageClick}
          searcPlaceHolder="Search..."
        >
          <div className="customTable2 w-100 table-responsive">
            <table className="w-100">
              <thead>
                <tr>
                  {Thead?.map((th, index) => {
                    return <th key={index}>{th}</th>;
                  })}
                </tr>
              </thead>
              <tbody>
                {items == "" || items == undefined || items == null ? (
                  <tr>
                    <td colSpan={thLength}>
                      <p className="my-2 smText text-decoration-underline text-danger">
                        No Record Found
                      </p>
                    </td>
                  </tr>
                ) : (
                  items.map((item, index) => {
                    const {
                      budget,
                      phone,
                      country,
                      email,
                      industry,
                      name,
                      read,
                      _id,
                      createdAt,
                      source,
                      Unit,
                      leadSource,
                      leadStatus,
                      leadFollowUpDate,
                    } = item;
                    return (
                      <tr
                        key={_id}
                        className={read === false ? "unreadtr" : ""}
                        onDoubleClick={() => {
                          openLeadModal(_id);
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <td>{++index}</td>
                        <td>
                          <span className="ellipsisContent">
                            {name || "--"}
                          </span>
                        </td>
                        <td>
                          <span className="ellipsisContent">
                            {email || "--"}
                          </span>
                        </td>
                        <td>
                          <span className="ellipsisContent">
                            {phone || "--"}
                          </span>
                        </td>
                        <td>
                          <span className="ellipsisContent">
                            {source === "" ? (
                              "--"
                            ) : (
                              <a href={source} target="_blank">
                                {source}
                              </a>
                            )}
                          </span>
                        </td>
                        <td>{leadSource || "--"}</td>
                        <td>{country || "--"}</td>
                        <td>{new Date(createdAt).toLocaleString()}</td>
                        <td>{budget || "--"}</td>
                        <td>
                          <span className="ellipsisContent">
                            {industry || "--"}
                          </span>
                        </td>
                        <td>{Unit.length > 0 ? Unit[0].name : "--"}</td>
                        <td>
                          {leadStatus ? (
                            leadStatus === "followup" ? (
                              <>
                                <span className="d-block">follow Up</span>
                                <span className="bedge colWhite rounded-5 bgSeaGreen fs-10">
                                  {leadFollowUpDate
                                    ?.slice(0, 10)
                                    .split("-")
                                    .reverse()
                                    .join("-") || "-"}
                                </span>
                              </>
                            ) : leadStatus === "won" ? (
                              <span className="bedge colWhite rounded-5 bgGreen fs-10">
                                {leadStatus}
                              </span>
                            ) : leadStatus === "lost" ? (
                              <span className="bedge colWhite rounded-5 bgRed fs-10">
                                {leadStatus}
                              </span>
                            ) : leadStatus === "archived" ? (
                              <span className="bedge colWhite rounded-5 bgRed fs-10">
                                {leadStatus}
                              </span>
                            ) : (
                              leadStatus
                            )
                          ) : (
                            "--"
                          )}
                        </td>
                        <td>
                          <Dropdown className="actionDropDown">
                            <Dropdown.Toggle className="customDropDown">
                              <FaEllipsisV className="colBlack" />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item
                                className="drop_item"
                                type="button"
                                onClick={() => {
                                  openLeadModal(_id);
                                }}
                              >
                                <FaRegEye className="me-1" />
                                <span>View</span>
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
        </TableCom>
      </div>
      {/*========================================== ! View --Or-- Edit Leads Modal code start here ! =======================================*/}
      <CustomModal
        show={editModalShow}
        onHide={() => setEditModalShow(false)}
        backdrop="static"
        keyboard="False"
        size="lg"
      >
        <button
          onClick={() => setEditModalShow(false)}
          className="modal-cancel"
        >
          <FaTimes />
        </button>
        <div className="modalContent">
          {/* modal text */}
          <div className="text-start">
            <div className="d-flex align-items-center justify-content-between flex-wrap mt-4">
              <h3 className="fw-600 my-2">{name || "Lead"}</h3>
            </div>
            <p>View {name || "Lead"} Details here</p>
            <Row>
              <Col lg={6} className="my-2">
                <label className="text-bold mb-1" htmlFor="name">
                  Name:<span className="text-danger">*</span>
                </label>
                <input
                  disabled={true}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  name="name"
                  id="name"
                  type="text"
                  className="filterSelect ps-3"
                  placeholder="Enter Name"
                />
              </Col>
              <Col lg={6} className="my-2">
                <label className="text-bold mb-1" htmlFor="email">
                  Email:<span className="text-danger">*</span>
                </label>
                <input
                  disabled={true}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  name="email"
                  id="email"
                  type="text"
                  className="filterSelect ps-3"
                  placeholder="Enter Email"
                />
              </Col>
              <Col lg={6} className="my-2">
                <label className="text-bold mb-1" htmlFor="phone">
                  Phone:<span className="text-danger">*</span>
                </label>
                <input
                  disabled={true}
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  name="phone"
                  id="phone"
                  type="number"
                  className="filterSelect ps-3"
                  placeholder="Enter Phone Number"
                />
              </Col>
              <Col lg={6} className="my-2">
                <label className="text-bold mb-1" htmlFor="leadSource">
                  Lead Source:
                </label>
                <select
                  className="filterSelect ps-3"
                  id="leadSource"
                  name="leadSource"
                  disabled={true}
                  value={leadSource}
                  onChange={(e) => setLeadSource(e.target.value)}
                >
                  <option value="">Select Option</option>
                  <option value="marketting">Marketing</option>
                  <option value="ppc">PPC</option>
                  <option value="website">Website</option>
                  <option value="smm">SMM</option>
                  <option value="bark">BARK</option>
                  <option value="custom">Custom</option>
                </select>
              </Col>
              <Col lg={6} className="my-2">
                <label className="text-bold mb-1" htmlFor="organization">
                  organization:
                </label>
                <input
                  disabled={true}
                  value={organization}
                  onChange={(e) => setOrganization(e.target.value)}
                  name="organization"
                  id="organization"
                  type="text"
                  className="filterSelect ps-3"
                  placeholder="Enter Organization Name"
                />
              </Col>
              <Col lg={6} className="my-2">
                <label className="text-bold mb-1" htmlFor="currencyCode">
                  Currency:
                </label>
                <select
                  className="filterSelect ps-3"
                  id="currencyCode"
                  name="currencyCode"
                  disabled={true}
                  value={currencyCode}
                  onChange={(e) => setCurrencyCode(e.target.value)}
                >
                  <option value="">Select Option</option>
                  {Object.keys(allcurrencyCode).map((objkey, index) => {
                    return (
                      <option key={index} value={objkey}>
                        {allcurrencyCode[objkey]}
                      </option>
                    );
                  })}
                </select>
              </Col>
              <Col lg={6} className="my-2">
                <label className="text-bold mb-1" htmlFor="country">
                  Country:
                </label>
                <select
                  className="filterSelect ps-3"
                  id="country"
                  name="country"
                  value={country}
                  disabled={true}
                  onChange={(e) => setCountry(e.target.value)}
                >
                  <option value="">Select Option</option>
                  {Object.keys(allCountryCodes).map((objkey, index) => {
                    return (
                      <option key={index} value={allCountryCodes[objkey]}>
                        {allCountryCodes[objkey]}
                      </option>
                    );
                  })}
                </select>
              </Col>
              <Col lg={6} className="my-2">
                <label className="text-bold mb-1" htmlFor="city">
                  City:
                </label>
                <input
                  disabled={true}
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                  name="city"
                  id="city"
                  type="text"
                  className="filterSelect ps-3"
                  placeholder="Enter City"
                />
              </Col>
              <Col lg={6} className="my-2">
                <label className="text-bold mb-1" htmlFor="state">
                  State:
                </label>
                <input
                  disabled={true}
                  value={state}
                  onChange={(e) => setState(e.target.value)}
                  name="state"
                  id="state"
                  type="text"
                  className="filterSelect ps-3"
                  placeholder="Enter state"
                />
              </Col>
              <Col lg={6} className="my-2">
                <label className="text-bold mb-1" htmlFor="budget">
                  Budget:
                </label>
                <input
                  disabled={true}
                  value={budget}
                  onChange={(e) => setBudget(e.target.value)}
                  name="budget"
                  id="budget"
                  type="number"
                  className="filterSelect ps-3"
                  placeholder="Enter Budget"
                />
              </Col>
              <Col lg={6} className="my-2">
                <label className="text-bold mb-1" htmlFor="industry">
                  Product:
                </label>
                <input
                  disabled={true}
                  value={industry}
                  onChange={(e) => setIndustry(e.target.value)}
                  name="industry"
                  id="industry"
                  type="text"
                  className="filterSelect ps-3"
                  placeholder="Enter Industry Name"
                />
              </Col>
              <Col lg={6} className="my-2">
                <label className="text-bold mb-1" htmlFor="source">
                  source:
                </label>
                <input
                  disabled={true}
                  value={source}
                  onChange={(e) => setSource(e.target.value)}
                  name="source"
                  id="source"
                  type="text"
                  className="filterSelect ps-3"
                  placeholder="Enter Source"
                />
              </Col>
              <Col lg={6} className="my-2">
                <label className="text-bold mb-1" htmlFor="note">
                  note:
                </label>
                <input
                  disabled={true}
                  value={note}
                  onChange={(e) => setNote(e.target.value)}
                  name="note"
                  id="note"
                  type="text"
                  className="filterSelect ps-3"
                  placeholder="Enter Note"
                />
              </Col>
              <Col lg={12} className="my-2">
                <label className="text-bold mb-1" htmlFor="message">
                  message:
                </label>
                <textarea
                  rows="5"
                  disabled={true}
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  name="message"
                  id="message"
                  className="filterSelect ps-3"
                  placeholder="Enter Message..."
                ></textarea>
              </Col>
            </Row>
          </div>
        </div>
      </CustomModal>
    </>
  );
};

export default MyGeneratedLeads;
