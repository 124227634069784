import React, { useEffect, useState } from "react";
import Title from "../../../../hook/Title";
import { toast } from "react-toastify";
import { createLeadByLeadGeneratorRoute, getAllUnitsNameAndDescRoute } from "../../../../utils/APIRoutes";
import { Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import GoBack from "../../../../Components/GoBack";
import axios from "axios";
import { useSelector } from "react-redux";

const GenerateLeads = () => {
    Title("Generate Leads");
    const AuthReducer = useSelector((state) => state.AuthReducer);
    const { empId } = AuthReducer.data;
    const navigate = useNavigate()
    const [servError, setServError] = useState(false);
    const [unit, setUnit] = useState([]);
    //    Get Unit Details
    const getUnitDetails = async () => {
        try {
            const resp = await fetch(getAllUnitsNameAndDescRoute);
            const resData = await resp.json();
            if (resData.success === true) {
                setUnit(resData.units);
                setServError(false);
            } else {
                throw new Error('Something went wrong.');
            }
        } catch (error) {
            setServError(true);
        }
    }
    useEffect(() => {
        getUnitDetails()
    }, [])
    //============================================ | Api inegration of Create Leads code |  ===============================================//
    let toastOptions = {
        position: "top-right",
        autoClose: 5000,
        pauseOnHover: false,
        draggable: false,
        theme: "dark",
    };
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    // ---- States of inputs in the form of key value pair name:value ------ // 
    let [values, setValues] = useState({ name: "", email: "", phone: "", industry: "", unitId: "", note: "", message: "", leadSource: "" });
    const { name, email, phone, industry, unitId, note, message, leadSource } = values;
    // ---- Getting values of inputs ------ //  
    const handleInpChange = (event) => {
        setValues({ ...values, [event.target.name]: event.target.value });
    };
    let hasError = false;
    // ---- Validate Form ------ //
    const validateForm = () => {
        const newErrors = {};
        if (name === "") {
            hasError = true;
            setIsLoading(false);
            newErrors.name = 'Name is required !';
        }
        if (leadSource === "") {
            hasError = true;
            setIsLoading(false);
            newErrors.leadSource = 'Lead Source is required !';
        }
        if (unitId === "") {
            hasError = true;
            setIsLoading(false);
            newErrors.unitId = 'Unit is required !';
        }
        if (industry === "") {
            hasError = true;
            setIsLoading(false);
            newErrors.industry = 'Product is required !';
        }
        setErrors(newErrors);
    };
    // ---- Form Submittion Function ------ // 
    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);
        validateForm();
        try {
            if (hasError === false) {
                const { data } = await axios.post(`${createLeadByLeadGeneratorRoute}?empId=${empId}`, { name, email, phone, leadSource, industry, unitId, note, message });
                if (data.success === true) {
                    setIsLoading(false);
                    toast.success(data.msg, toastOptions);
                    setValues({ name: "", email: "", phone: "", leadSource: "", industry: "", unitId: "", note: "", message: "" });
                    navigate("/my-generated-leads")
                } else {
                    toast.error(data.msg, toastOptions, toastOptions['position'] = "top-left");
                    setIsLoading(false);
                }
            } else {
                toast.error("Kindly Fill the require fields !", toastOptions);
                setIsLoading(false);
            }
        } catch (err) {
            console.log("Error In Form Submittion", err)
            setIsLoading(false);
            toast.error("lead Not Created, something went wrong in server. Please Try Again Later", toastOptions);
        }
    };

    return (
        <>
            <div className="d-flex align-items-center mb-4 flex-wrap">
                <GoBack />
                <h4 className="primHeading mb-0">Generate <span>Leads</span></h4>
            </div>
            <div className="box">
                <Row>
                    <Col lg={8}>
                        <p className="fw-600 my-2 mb-4">Add New Lead From here</p>
                        {
                            servError === true ? <p className="text-center my-2 fw-600 text-danger">Something went wrong Please try again later (Server Error 500)</p> : (
                                <form onSubmit={handleSubmit}>
                                    <Row>
                                        <Col lg={6} className="my-2">
                                            <label className="text-bold mb-1" htmlFor="name">Name:<span className="text-danger">*</span></label>
                                            <input
                                                value={name}
                                                onChange={(e) => handleInpChange(e)}
                                                name='name'
                                                id='name'
                                                type="text"
                                                className="filterSelect ps-3"
                                                placeholder='Enter Name' />
                                            {errors.name && <p className="text-danger fw-500 fs-13 my-2 text-capitalize">{errors.name}</p>}
                                        </Col>
                                        <Col lg={6} className="my-2">
                                            <label className="text-bold mb-1" htmlFor="email">Email:</label>
                                            <input
                                                value={email}
                                                onChange={(e) => handleInpChange(e)}
                                                name="email"
                                                id="email"
                                                type="text"
                                                className="filterSelect ps-3"
                                                placeholder='Enter Email' />
                                        </Col>
                                        <Col lg={6} className="my-2">
                                            <label className="text-bold mb-1" htmlFor="phone">Phone:</label>
                                            <input
                                                value={phone}
                                                onChange={(e) => handleInpChange(e)}
                                                name="phone"
                                                id="phone"
                                                type="number"
                                                className="filterSelect ps-3"
                                                placeholder='Enter Phone Number' />
                                        </Col>
                                        <Col lg={6} className="my-2">
                                            <label className="text-bold mb-1" htmlFor="unitId">Unit: <span className="text-danger">*</span> </label>
                                            <select
                                                className="filterSelect ps-3"
                                                id="unitId"
                                                name='unitId'
                                                onChange={(e) => handleInpChange(e)} >
                                                <option value="">Select Option</option>
                                                {
                                                    unit.length > 0 ?
                                                        unit?.map((unitData) => {
                                                            const { description, name, _id } = unitData
                                                            return (
                                                                <option key={_id} value={_id}>{`${name} (${description})`} </option>
                                                            )
                                                        }) : "Loading..."
                                                }
                                            </select>
                                            {errors.unitId && <p className="text-danger fw-500 fs-13 my-2 text-capitalize">{errors.unitId}</p>}
                                        </Col>
                                        <Col lg={6} className="my-2">
                                            <label className="text-bold mb-1" htmlFor="leadSource">Lead Source:<span className="text-danger">*</span></label>
                                            <select
                                                className="filterSelect ps-3"
                                                id="leadSource"
                                                name='leadSource'
                                                value={leadSource}
                                                onChange={(e) => handleInpChange(e)}>
                                                <option value="">Select Option</option>
                                                <option value="marketting">Marketing</option>
                                                <option value="ppc">PPC</option>
                                                <option value="website">Website</option>
                                                <option value="smm">SMM</option>
                                                <option value="bark">BARK</option>
                                                <option value="custom">Custom</option>
                                            </select>
                                            {errors.leadSource && <p className="text-danger fw-500 fs-13 my-2 text-capitalize">{errors.leadSource}</p>}
                                        </Col>
                                        <Col lg={6} className="my-2">
                                            <label className="text-bold mb-1" htmlFor="industry">Product:<span className="text-danger">*</span></label>
                                            <select
                                                className="filterSelect ps-3"
                                                id="industry"
                                                name='industry'
                                                value={industry}
                                                onChange={(e) => handleInpChange(e)}>
                                                <option value="">Select Option</option>
                                                <option value="website">Website</option>
                                                <option value="mobile application">Mobile Application</option>
                                                <option value="social media marketing">Social Media Marketing</option>
                                                <option value="search engine optimization">Search Engine Optimization (SEO)</option>
                                                <option value="logo design">Logo Design</option>
                                                <option value="content writting">Content Writting</option>
                                                <option value="graphic designing">Graphic Designing</option>
                                                <option value="animation">Animation</option>
                                            </select>
                                            {errors.industry && <p className="text-danger fw-500 fs-13 my-2 text-capitalize">{errors.industry}</p>}
                                        </Col>
                                        <Col lg={6} className="my-2">
                                            <label className="text-bold mb-1" htmlFor="note">Note:</label>
                                            <input
                                                value={note}
                                                onChange={(e) => handleInpChange(e)}
                                                name="note"
                                                id="note"
                                                type="text"
                                                className="filterSelect ps-3"
                                                placeholder='Enter Note' />
                                        </Col>
                                        <Col lg={12} className="my-2">
                                            <label className="text-bold mb-1" htmlFor="message">message:</label>
                                            <textarea
                                                rows="5"
                                                value={message}
                                                onChange={(e) => handleInpChange(e)}
                                                name="message"
                                                id="message"
                                                className="filterSelect ps-3"
                                                placeholder='Enter Message...'></textarea>
                                        </Col>
                                    </Row>
                                    <button className="main-btn mt-3" disabled={isLoading} type="submit"> {isLoading ? 'submiting...' : "submit"} </button>
                                </form>
                            )
                        }
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default GenerateLeads; 