import React, { useState, useEffect } from "react";
import { getMyUnitsOfUnitHeadRoute } from "../../../utils/APIRoutes";
import { Col, Row } from "react-bootstrap";
import { useSelector } from 'react-redux';
import UnitBox from "../../../Components/ScreenComponents/UnitBox";
import Title from "../../../hook/Title";
import { Link } from "react-router-dom";

const MyUnits = () => {
     Title("My Units");
     const AuthReducer = useSelector((state) => state.AuthReducer);
     const { empId } = AuthReducer.data;
     const [error, setError] = useState(false);
     const [allUnits, setAllUnits] = useState([]);
     const getListOfMyUnits = async () => {
          try {
               const result = await fetch(`${getMyUnitsOfUnitHeadRoute}?empId=${empId}`);
               const response = await result.json();
               if (response.success === true) {
                    setAllUnits(response.data);
                    setError(false);
               } else {
                    throw new Error('Something went wrong.');
               }
          } catch (error) {
               setError(true);
          }
     }
     useEffect(() => {
          getListOfMyUnits()
     }, [])

     return (
          <>
               <h4 className="primHeading my-2 mb-4">My <span>Units</span></h4>
               <div className="box my-3">
                    <h4 className="secHeading my-2 mb-4">My All <span>Units</span></h4>
                    {
                         error === true ? (
                              <p className="text-danger fw-600 text-danger">Some Thing Went Wrong Please Try Again ! (Internal server Error 500)</p>
                         ) : (
                              <Row>
                                   <Col xl={8}>
                                        <Row>
                                             {
                                                  allUnits.length > 0 ? (
                                                       allUnits?.map((unitData) => {
                                                            const { _id, active, name } = unitData;
                                                            return (
                                                                 <Col lg={4} md={6} key={_id}>
                                                                      <Link title="Click Here To View Details" to={active === false ? "#" : `/view-unit/${_id}`} state={name}>
                                                                           <UnitBox unitData={unitData} />
                                                                      </Link>
                                                                 </Col>
                                                            )
                                                       })
                                                  ) : ""
                                             }
                                        </Row>
                                   </Col>
                              </Row>
                         )
                    }
               </div>
          </>
     );
};

export default MyUnits; 