import React, { useEffect, useState } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { unitWiseGrowthRoute } from '../../utils/APIRoutes';
import axios from 'axios';

ChartJS.register(ArcElement, Tooltip, Legend);
export const options = {
    responsive: true,
    maintainAspectRatio: false, // Set this to false to allow setting a fixed height
    height: 200, // Set the height to 400 pixels 
};

const UnitIncome = () => {
    const [unit, setUnit] = useState([]);
    const [amount, setAmount] = useState([]);
    const [backgroundColor, setBackgroundColor] = useState([]);
    const data = {
        labels: unit,
        datasets: [
            {
                label: 'Unit Income',
                data: amount,
                backgroundColor,
                borderColor: "#fff",
                borderWidth: 2,
            },
        ],
    };
    useEffect(() => {
        try {
            const getGraphsData = async () => {
                const response = await axios.get(unitWiseGrowthRoute);
                const data = response.data.data
                setUnit(data.unit)
                setAmount(data.amount)
                setBackgroundColor(data.backgroundColor)
            }
            getGraphsData()
        } catch (err) {
            console.log("🚀 ~ file: UnitIncome:", err)
        }
    }, [])
    return (
        <div className='h-340'>
            <Pie data={data} options={options} />
        </div>
    );
}

export default UnitIncome;