import React, { useState, useEffect } from "react";
import currencycode from "../../helpers/currencyCodes.json";
import countrycodes from "../../helpers/countryCodes.json";
import { updateDealByIdRoute, getDealbyIdRoute } from "../../utils/APIRoutes";
import { toast } from "react-toastify";
import axios from "axios";
import {
     FaTimes,
} from "react-icons/fa";
import CustomModal from "../../Components/CustomModal";
import { Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import { closeDealModal } from "../../actions/EditDealModalButton"
import { useNavigate } from "react-router-dom";

const EditDealModal = ({ id }) => {
     const navigate = useNavigate();
     const modalBtnState = useSelector((state) => state.EditDealModalReducer)
     const disPatch = useDispatch();
     const allcurrencyCode = currencycode.codes;
     const allCountryCodes = countrycodes.codes;
     // Table Head Data
     const [name, setName] = useState("");
     const [email, setEmail] = useState("");
     const [organization, setOrganization] = useState("");
     const [phone, setPhone] = useState("");
     const [country, setCountry] = useState("");
     const [currencyCode, setCurrencyCode] = useState("");
     const [budget, setBudget] = useState("");
     const [industry, setIndustry] = useState("");
     const [source, setSource] = useState("");
     const [note, setNote] = useState("");
     const [message, setMessage] = useState("");
     const [city, setCity] = useState("-");
     const [state, setState] = useState("-");
     const [dealId, setDealId] = useState("");
     const [rerendertable, setRerendertableData] = useState(false);
     const [editableState, setEditableState] = useState(false);
     const [title, setTitle] = useState("");
     const [stage, setStage] = useState("");
     useEffect(() => {
          setDealId(id)
     }, [id])
     //============================================ ! Api inegration of Create Employee code !  ===============================================//
     let toastOptions = {
          position: "top-left",
          autoClose: 5000,
          pauseOnHover: false,
          draggable: false,
          theme: "dark",
     };
     const [errors, setErrors] = useState({});
     const [isLoading, setIsLoading] = useState(false);
     // ---- States of inputs in the form of key value pair name:value ------ //  
     let hasError = false;
     // ---- Validate Form ------ // 
     const validateForm = () => {
          const newErrors = {};
          if (name === "") {
               hasError = true;
               setIsLoading(false);
               newErrors.name = 'Name is required !';
          }
          if (email === "") {
               hasError = true;
               setIsLoading(false);
               newErrors.email = 'Email is required !';
          } else if (!/\S+@\S+\.\S+/.test(email)) {
               hasError = true;
               setIsLoading(false);
               newErrors.email = 'Email is not valid !';
          }
          if (phone === "") {
               hasError = true;
               setIsLoading(false);
               newErrors.phone = 'Phone Number is required !';
          }
          setErrors(newErrors);
     };
     // ---- Form Submittion Function ------ // 
     const handleSubmit = async (event) => {
          event.preventDefault();
          setIsLoading(true);
          validateForm();
          if (hasError === false) {
               const { data } = await axios.put(`${updateDealByIdRoute}?deadId=${dealId}`, {
                    name,
                    email,
                    organization,
                    phone,
                    country,
                    currencyCode,
                    budget,
                    industry,
                    source,
                    note,
                    message,
                    city,
                    state,
                    title
               });
               if (data.success === true) {
                    disPatch(closeDealModal("closeDealModal"))
                    setIsLoading(false);
                    toast.success(data.msg, toastOptions, toastOptions['position'] = "top-right");
                    setRerendertableData(!rerendertable);
                    navigate('/deals-pipeline');
               } else {
                    toast.error(data.msg, toastOptions, toastOptions['position'] = "top-left");
                    setIsLoading(false);
               }
          } else {
               toast.error("Kindly Fill the require fields !", toastOptions, toastOptions['position'] = "top-left");
          }
     };
     const openEditDealModal = () => {
          fetch(`${getDealbyIdRoute}?dealId=${id}`)
               .then(response => response.json())
               .then(({ data: dealData }) => {
                    setName(dealData.name)
                    setEmail(dealData.email)
                    setOrganization(dealData.organization)
                    setPhone(dealData.phone)
                    setCountry(dealData.country)
                    setCurrencyCode(dealData.currencyCode)
                    setBudget(dealData.budget)
                    setIndustry(dealData.industry)
                    setSource(dealData.source)
                    setNote(dealData.note)
                    setMessage(dealData.message)
                    setTitle(dealData.title)
                    setStage(dealData.stage)
               });
     };
     useEffect(() => {
          if (modalBtnState === "openDealModal") {
               openEditDealModal()
          }
     }, [modalBtnState]);
     
     return (
          <>
               {/*========================================== ! View --Or-- Edit Deals Modal code start here ! =======================================*/}
               <CustomModal show={modalBtnState === "openDealModal" ? "show" : ""} backdrop='static' keyboard="False" size="lg">
                    <button onClick={() => disPatch(closeDealModal("closeDealModal"))} className="modal-cancel"><FaTimes /></button>
                    <div className="modalContent">
                         {/* modal text */}
                         <form onSubmit={(event) => handleSubmit(event)}>
                              <div className="text-start">
                                   <h3 className="fw-600 my-2"> {editableState === false ? "Edit Deal" : `${name}`} </h3>
                                   <p>{editableState === false ? "Edit Deal From here" : `View ${name} Details here`} </p>
                                   <Row>
                                        <Col lg={12} className="my-2">
                                             <label className="text-bold mb-1" htmlFor="title">Deal title:</label>
                                             <input
                                                  value={title}
                                                  onChange={(e) => setTitle(e.target.value)}
                                                  name="title"
                                                  id="title"
                                                  type="text"
                                                  className="filterSelect ps-3"
                                                  placeholder='Enter Title' />
                                        </Col>
                                        <Col lg={6} className="my-2">
                                             <label className="text-bold mb-1" htmlFor="name">Name:<span className="text-danger">*</span></label>
                                             <input
                                                  value={name}
                                                  onChange={(e) => setName(e.target.value)}
                                                  name='name'
                                                  id='name'
                                                  type="text"
                                                  className="filterSelect ps-3"
                                                  placeholder='Enter Name' />
                                             {errors.name && <p className="text-danger fw-500 fs-13 my-2 text-capitalize">{errors.name}</p>}
                                        </Col>
                                        <Col lg={6} className="my-2">
                                             <label className="text-bold mb-1" htmlFor="email">Email:<span className="text-danger">*</span></label>
                                             <input
                                                  value={email}
                                                  onChange={(e) => setEmail(e.target.value)}
                                                  name="email"
                                                  id="email"
                                                  type="text"
                                                  className="filterSelect ps-3"
                                                  placeholder='Enter Email' />
                                             {errors.email && <p className="text-danger fw-500 fs-13 my-2 text-capitalize">{errors.email}</p>}
                                        </Col>
                                        <Col lg={6} className="my-2">
                                             <label className="text-bold mb-1" htmlFor="phone">Phone:<span className="text-danger">*</span></label>
                                             <input
                                                  value={phone}
                                                  onChange={(e) => setPhone(e.target.value)}
                                                  name="phone"
                                                  id="phone"
                                                  type="number"
                                                  className="filterSelect ps-3"
                                                  placeholder='Enter Phone Number' />
                                             {errors.phone && <p className="text-danger fw-500 fs-13 my-2 text-capitalize">{errors.phone}</p>}
                                        </Col>
                                        <Col lg={6} className="my-2">
                                             <label className="text-bold mb-1" htmlFor="organization">organization:</label>
                                             <input
                                                  value={organization}
                                                  onChange={(e) => setOrganization(e.target.value)}
                                                  name="organization"
                                                  id="organization"
                                                  type="text"
                                                  className="filterSelect ps-3"
                                                  placeholder='Enter Organization Name' />
                                        </Col>
                                        <Col lg={6} className="my-2">
                                             <label className="text-bold mb-1" htmlFor="currencyCode">Currency:</label>
                                             <select
                                                  className="filterSelect ps-3"
                                                  id="currencyCode"
                                                  name='currencyCode'
                                                  value={currencyCode}
                                                  onChange={(e) => setCurrencyCode(e.target.value)}>
                                                  <option value="">Select Option</option>
                                                  {Object.keys(allcurrencyCode).map((objkey, index) => {
                                                       return (
                                                            <option key={index} value={objkey}>{allcurrencyCode[objkey]}</option>
                                                       );
                                                  })}
                                             </select>
                                        </Col>
                                        <Col lg={6} className="my-2">
                                             <label className="text-bold mb-1" htmlFor="country">Country:</label>
                                             <select
                                                  className="filterSelect ps-3"
                                                  id="country"
                                                  name='country'
                                                  value={country}
                                                  onChange={(e) => setCountry(e.target.value)}>
                                                  <option value="">Select Option</option>
                                                  {Object.keys(allCountryCodes).map((objkey, index) => {
                                                       return (
                                                            <option key={index} value={allCountryCodes[objkey]}>{allCountryCodes[objkey]}</option>
                                                       );
                                                  })}
                                             </select>
                                        </Col>
                                        <Col lg={6} className="my-2">
                                             <label className="text-bold mb-1" htmlFor="city">City:</label>
                                             <input
                                                  value={city}
                                                  onChange={(e) => setCity(e.target.value)}
                                                  name="city"
                                                  id="city"
                                                  type="text"
                                                  className="filterSelect ps-3"
                                                  placeholder='Enter City' />
                                        </Col>
                                        <Col lg={6} className="my-2">
                                             <label className="text-bold mb-1" htmlFor="state">State:</label>
                                             <input
                                                  value={state}
                                                  onChange={(e) => setState(e.target.value)}
                                                  name="state"
                                                  id="state"
                                                  type="text"
                                                  className="filterSelect ps-3"
                                                  placeholder='Enter state' />
                                        </Col>
                                        <Col lg={6} className="my-2">
                                             <label className="text-bold mb-1" htmlFor="budget">Budget:</label>
                                             <input
                                                  value={budget}
                                                  onChange={(e) => setBudget(e.target.value)}
                                                  name="budget"
                                                  id="budget"
                                                  type="number"
                                                  className="filterSelect ps-3"
                                                  placeholder='Enter Budget' />
                                        </Col>
                                        <Col lg={6} className="my-2">
                                             <label className="text-bold mb-1" htmlFor="industry">industry:</label>
                                             <input
                                                  value={industry}
                                                  onChange={(e) => setIndustry(e.target.value)}
                                                  name="industry"
                                                  id="industry"
                                                  type="text"
                                                  className="filterSelect ps-3"
                                                  placeholder='Enter Industry Name' />
                                        </Col>
                                        <Col lg={6} className="my-2">
                                             <label className="text-bold mb-1" htmlFor="source">source:</label>
                                             <input
                                                  value={source}
                                                  onChange={(e) => setSource(e.target.value)}
                                                  name="source"
                                                  id="source"
                                                  type="text"
                                                  className="filterSelect ps-3"
                                                  placeholder='Enter Source' />
                                        </Col>
                                        <Col lg={6} className="my-2">
                                             <label className="text-bold mb-1" htmlFor="note">note:</label>
                                             <input
                                                  value={note}
                                                  onChange={(e) => setNote(e.target.value)}
                                                  name="note"
                                                  id="note"
                                                  type="text"
                                                  className="filterSelect ps-3"
                                                  placeholder='Enter Note' />
                                        </Col>
                                        <Col lg={6} className="my-2">
                                             <label className="text-bold mb-1" htmlFor="stage">stage:</label>
                                             <input
                                                  disabled={true}
                                                  value={stage}
                                                  name="stage"
                                                  id="stage"
                                                  type="text"
                                                  className="filterSelect ps-3"
                                                  placeholder='Enter Note' />
                                        </Col>
                                        <Col lg={12} className="my-2">
                                             <label className="text-bold mb-1" htmlFor="message">message:</label>
                                             <textarea
                                                  rows="5"
                                                  disabled={true}
                                                  value={message}
                                                  onChange={(e) => setMessage(e.target.value)}
                                                  name="message"
                                                  id="message"
                                                  className="filterSelect ps-3"
                                                  placeholder='Enter Message...'></textarea>
                                        </Col>
                                   </Row>
                              </div>
                              <div className="mt-3">
                                   <button className="main-btn m-2" disabled={isLoading} type="submit"> {isLoading ? 'Updating...' : "Update"} </button>
                                   <button
                                        className="sec-btn m-2"
                                        type="button"
                                        onClick={() => disPatch(closeDealModal("closeDealModal"))}>
                                        Cancel
                                   </button>
                              </div>
                         </form>
                    </div>
               </CustomModal>
          </>
     );
};

export default EditDealModal; 