// Define your initial state
const initialState = {
    myTheme: {
        themeColor: "#17263a",
        primColor: "#ffffff",
        secColor: "#3366ff",
        boxBg: "#f1f4fb",
        boxColor: "#fff",
        textColor: "#000",
        btnMainColor: "#36f",
        secHovColor: "#3366ffb2"
    },
};

const ThemeReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_THEME_COLOR':
            return {
                ...state,
                myTheme: action.theme,
            };
        default:
            return state;
    }
};

export default ThemeReducer;