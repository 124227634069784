import React, { useState, useEffect } from "react";
import { TableCom } from "../../../../Components/TableCom";
import { Link } from "react-router-dom";
import { getAllInvoicesByPPCBrandWebsiteRoute } from "../../../../utils/APIRoutes";
import Dropdown from "react-bootstrap/Dropdown";
import Title from "../../../../hook/Title";
import { FaRegEye, FaEllipsisV } from "react-icons/fa";
import { useSelector } from "react-redux";
import { Row, Col } from "react-bootstrap";
import { useLocation } from "react-router-dom";

const PPcConvertedLeads = () => {
    Title("My Converted Leads Summary");
    const location = useLocation();
    const state = location.state;
    const { brandWebsite, ppcUserAssingedOn, brandName } = state
    // ! Filter By Date Code for table ! :
    const [filterDates, setfilterDates] = useState({
        from: "",
        to: ""
    })
    const handleDateChange = (e) => {
        setfilterDates({ ...filterDates, [e.target.name]: e.target.value });
    };
    const clearFilters = () => { setfilterDates({ from: "", to: "" }) }
    const { from, to } = filterDates;
    const AuthReducer = useSelector((state) => state.AuthReducer);
    const { empId } = AuthReducer.data;
    // Table Head Data  
    const [thLength, setThlength] = useState(1);
    const [items, setItems] = useState([]);
    const [numPerPage, setNumPerPage] = useState(10);
    const [totalData, setTotalData] = useState(0);
    const [pageCount, setpageCount] = useState(0);
    const [currPage, setCurrPage] = useState(0);
    const [searchVal, setSearchVal] = useState("");

    const Thead = [
        "S.No",
        "Client",
        "invoice Id",
        "Total Amount",
        "unit",
        "Invoice Status",
        "Created At",
        "Paid At",
        "Invoice Payment",
        "Action",
    ];
    useEffect(
        (currPage) => {
            currPage = currPage ?? 0
            const getData = async () => {
                const res = await fetch(`${getAllInvoicesByPPCBrandWebsiteRoute}?numPerPage=${numPerPage}&page=${currPage}&search=${searchVal}&from=${from}&to=${to}&brandWebsite=${brandWebsite}&ppcUserAssignedOn=${ppcUserAssingedOn}`);
                const data = await res.json();
                const total = data.pagiantion.dataCount;
                setTotalData(total);
                setpageCount(Math.ceil(total / numPerPage));
                setItems(data.result);
            };
            getData();
        },
        [numPerPage, searchVal, from, to]
    );
    useEffect(() => {
        setThlength(Thead.length);
    }, [thLength]);
    const fetchData = async (currPage) => {
        const res = await fetch(`${getAllInvoicesByPPCBrandWebsiteRoute}?numPerPage=${numPerPage}&page=${currPage}&search=${searchVal}&from=${from}&to=${to}`);
        const changedData = await res.json();
        const data = changedData.result;
        return data;
    };
    const handlePageClick = async (data) => {
        let currPage = data.selected;
        const myData = await fetchData(currPage);
        setItems(myData);
        setCurrPage(currPage);
    };
    const handleChange = (e) => {
        setNumPerPage(e.target.value);
    };
    // search data in api using search box
    const hangleSearchVal = (e) => {
        setSearchVal(e.target.value);
    };


    return (
        <>
            <h4 className="primHeading my-2">My Converted Leads</h4>
            <div className="box my-3">
                <h4 className="sectionHeading my-2 mb-4">My Converted Leads Summary</h4>
                {/*==========================|||****** Filters Start Here ********|||==========================*/}
                <Row className="customFilters">
                    <Col xl={2} lg={4} md={6} sm={5} className="my-2">
                        <label className="text-bold" htmlFor="From">From:</label>
                        <input value={from} onChange={handleDateChange} className="prim-date" type="date" name="from" id="From" />
                    </Col>
                    <Col xl={2} lg={4} md={6} sm={5} className="my-2">
                        <label className="text-bold" htmlFor="To">To:</label>
                        <input value={to} onChange={handleDateChange} className="prim-date" type="date" name="to" id="To" />
                    </Col>
                    <Col xl={2} lg={4} md={6} sm={2} className="my-2">
                        <button onClick={clearFilters} title="Clear Date Filters" className="main-btn clearFilter w-100 mt-4">Clear</button>
                    </Col>
                </Row>
                <hr />
                <TableCom
                    numPerPage={numPerPage}
                    totalData={totalData}
                    handleChange={handleChange}
                    hangleSearchVal={hangleSearchVal}
                    pageCount={pageCount}
                    handlePageClick={handlePageClick}
                    searcPlaceHolder="Search...">
                    <div className="customTable2 w-100 table-responsive">
                        <table className="w-100">
                            <thead>
                                <tr>
                                    {Thead?.map((th, index) => {
                                        return <th key={index}>{th}</th>
                                    })}
                                </tr>
                            </thead>
                            <tbody>
                                {items == "" || items == undefined || items == null ? (
                                    <tr>
                                        <td colSpan={thLength}>
                                            <p className="my-2 smText text-decoration-underline text-danger">No Record Found</p>
                                        </td>
                                    </tr>
                                ) : (
                                    items?.map((item, index) => {
                                        const { invoiceId, currency, createdAt, client, paid, status, totalAmount, unit, _id, paidAtDateTime } = item
                                        return (
                                            <tr key={_id}>
                                                <td>{++index}</td>
                                                <td>{client.length > 0 ? client[0].name : "--"}</td>
                                                <td>{invoiceId || "-"}</td>
                                                <td className="text-lowercase">{(totalAmount === "" || currency === "" ? "--" : `${totalAmount + " " + currency}`)} </td>
                                                <td>{unit.length > 0 ? unit[0].name : "--"}</td>
                                                <td><span className={status === "completed" ? "bedge colWhite colbgGreen" : status === "draft" ? "bedge colWhite colbgRed" : status === "linkSend" ? "bedge colWhite colbgYellow" : status === "emailSend" ? "bedge colWhite colbgBlue" : status === "overDue" ? "bedge colWhite colbgDRed" : "bedge colWhite colbgDRed"}>{status === "emailSend" ? "email Send" : status === "overDue" ? "Over Due" : status === "linkSend" ? "Link Send" : status}</span></td>
                                                <td>{createdAt.slice(0, 10).split("-").reverse().join("-") || "-"}</td>
                                                <td>{paidAtDateTime === null ? "--" : paidAtDateTime.slice(0, 10).split("-").reverse().join("-")}</td>
                                                <td>{paid === "" ? "--" : (paid === true ? <span className="bedge colWhite bgGreen">Paid</span> : <span className="bedge colWhite bgRed">UnPaid</span>)}</td>
                                                <td>
                                                    <Dropdown className="actionDropDown">
                                                        <Dropdown.Toggle className="customDropDown">
                                                            <FaEllipsisV className="colBlack" />
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            <Dropdown.Item
                                                                className="drop_item"
                                                                as={Link}
                                                                to={`/sales-invoice/${_id}`}>
                                                                <FaRegEye className="me-1" />
                                                                <span>View</span>
                                                            </Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </td>
                                            </tr>
                                        )
                                    })
                                )}
                            </tbody>
                        </table>
                    </div>
                </TableCom>
            </div>

        </>
    );
};

export default PPcConvertedLeads; 