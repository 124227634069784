import React from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend
} from "recharts";



const NewVsReturningCustomer = ({ data }) => {
  // const data = [
  //   {
  //     name: "Jan",
  //     Returning: 4000,
  //     New: 2400,
  //     amt: 2400
  //   },
  //   {
  //     name: "Feb",
  //     Returning: 3000,
  //     New: 1398,
  //     amt: 2210
  //   },
  //   {
  //     name: "Mar",
  //     Returning: 2000,
  //     New: 9800,
  //     amt: 2290
  //   },
  //   {
  //     name: "Apr",
  //     Returning: 2780,
  //     New: 3908,
  //     amt: 2000
  //   },
  //   {
  //     name: "May",
  //     Returning: 1890,
  //     New: 4800,
  //     amt: 2181
  //   },
  //   {
  //     name: "Jun",
  //     Returning: 2390,
  //     New: 3800,
  //     amt: 2500
  //   },
  //   {
  //     name: "Jul",
  //     Returning: 3490,
  //     New: 4300,
  //     amt: 2100
  //   },
  //   {
  //     name: "Aug",
  //     Returning: 2000,
  //     New: 9800,
  //     amt: 2290
  //   },
  //   {
  //     name: "Sep",
  //     Returning: 2780,
  //     New: 3908,
  //     amt: 2000
  //   },
  //   {
  //     name: "Oct",
  //     Returning: 1890,
  //     New: 4800,
  //     amt: 2181
  //   },
  //   {
  //     name: "Nov",
  //     Returning: 2390,
  //     New: 3800,
  //     amt: 2500
  //   },
  //   {
  //     name: "Dec",
  //     Returning: 3490,
  //     New: 4300,
  //     amt: 2100
  //   }
  // ];

  return (
    <LineChart
      width={670}
      height={300}
      data={data}
      margin={{
        top: 5,
        right: 5,
        left: 5,
        bottom: 5
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" />
      <YAxis />
      <Tooltip />
      <Legend />
      <Line
        type="monotone"
        dataKey="New"
        stroke="#8884d8"
        activeDot={{ r: 8 }}
      />
      <Line type="monotone" dataKey="Returning" stroke="#82ca9d" />
    </LineChart>
  )
}
export default NewVsReturningCustomer;