import React, { useState, useEffect } from "react";
import { getMyUnitsOfUnitHeadRoute } from "../../../utils/APIRoutes";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import UnitBox from "../../../Components/ScreenComponents/UnitBox";
import Title from "../../../hook/Title";
import { Link, useLocation } from "react-router-dom";

const UnitInvoices = () => {
  Title("Select Unit");
  const AuthReducer = useSelector((state) => state.AuthReducer);
  const { empId } = AuthReducer.data;
  const [error, setError] = useState(false);
  const [allUnits, setAllUnits] = useState([]);
  const location = useLocation();

  const getListOfMyUnits = async () => {
    try {
      const result = await fetch(`${getMyUnitsOfUnitHeadRoute}?empId=${empId}`);
      const response = await result.json();
      if (response.success === true) {
        setAllUnits(response.data);
        setError(false);
      } else {
        throw new Error("Something went wrong.");
      }
    } catch (error) {
      setError(true);
    }
  };
  useEffect(() => {
    getListOfMyUnits();
  }, []);

  return (
    <>
      <h4 className="primHeading my-2 mb-4">
        My <span>Units</span>
      </h4>
      <div className="box my-3">
        <h4 className="secHeading my-2 mb-4">
          My Unit <span>Invoices</span>
        </h4>
        {error === true ? (
          <p className="text-danger fw-600 text-danger">
            Some Thing Went Wrong Please Try Again ! (Internal Server Error 500)
          </p>
        ) : (
          <Row>
            <Col xl={8}>
              <Row>
                {allUnits.length > 0
                  ? allUnits?.map((unitData) => {
                      const { _id, active, name } = unitData;
                      return (
                        <Col lg={4} md={6} key={_id}>
                          <Link
                            title="Click Here To View Unit Invoices"
                            to={`/my-unit-invoices/${_id}`}
                            state={name}
                          >
                            <UnitBox unitData={unitData} />
                          </Link>
                        </Col>
                      );
                    })
                  : ""}
              </Row>
            </Col>
          </Row>
        )}
      </div>
    </>
  );
};

export default UnitInvoices;
