import React, { useState, useEffect, useCallback } from "react";
import Title from "../../../hook/Title";
import Dropdown from "react-bootstrap/Dropdown";
import { Link, useNavigate } from "react-router-dom";
import { TableCom } from "../../../Components/TableCom";
import { toast } from "react-toastify";
import {
  getAllUnitsRoute,
  updateActiveStatusByIdRoute,
} from "../../../utils/APIRoutes";
import axios from "axios";
import { FaRegEye, FaEllipsisV, FaTimes } from "react-icons/fa";
import CustomModal from "../../../Components/CustomModal";
import { debounce } from "lodash";

const Index = () => {
  Title("Units");
  const [unitStatus, setUnitStatus] = useState("");
  const [appModalShow, setUnitModalShow] = useState(false);
  const [uid, setUid] = useState("");
  let toastOptions = {
    position: "top-left",
    autoClose: 5000,
    pauseOnHover: false,
    draggable: false,
    theme: "dark",
  };
  // Table Head Data
  const [thLength, setThlength] = useState(1);
  const [items, setItems] = useState([]);
  const [numPerPage, setNumPerPage] = useState(10);
  const [totalData, setTotalData] = useState(0);
  const [pageCount, setpageCount] = useState(0);
  const [currPage, setCurrPage] = useState(0);
  const [searchVal, setSearchVal] = useState("");
  const [rerendertable, setRerendertableData] = useState(false);
  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  const Thead = [
    "S.No",
    "Name",
    "description",
    "created At",
    "Status",
    "Action",
  ];
  useEffect(
    (currPage) => {
      currPage = currPage ?? 0;
      const getData = async () => {
        const res = await fetch(
          `${getAllUnitsRoute}?numPerPage=${numPerPage}&page=${currPage}&search=${searchVal}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (res.status === 401) {
          navigate("/");
          toast.error(
            "Session has been Expired",
            toastOptions,
            (toastOptions["position"] = "top-right")
          );
          return;
        }
        const data = await res.json();
        const total = data.pagiantion.dataCount;
        setTotalData(total);
        setpageCount(Math.ceil(total / numPerPage));
        setItems(data.result);
      };
      getData();
    },
    [numPerPage, searchVal, rerendertable]
  );
  useEffect(() => {
    setThlength(Thead.length);
  }, [thLength]);

  const fetchData = async (currPage) => {
    const res = await fetch(
      `${getAllUnitsRoute}?numPerPage=${numPerPage}&page=${currPage}&search=${searchVal}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (res.status === 401) {
      navigate("/");
      toast.error(
        "Session has been Expired",
        toastOptions,
        (toastOptions["position"] = "top-right")
      );
      return;
    }
    const changedData = await res.json();
    const data = changedData.result;
    return data;
  };
  const handlePageClick = async (data) => {
    let currPage = data.selected;
    const myData = await fetchData(currPage);
    setItems(myData);
    setCurrPage(currPage);
  };
  const handleChange = (e) => {
    setNumPerPage(e.target.value);
  };

  // Debounced search function
  const debouncedSearch = useCallback(
    debounce((value) => {
      setSearchVal(value);
    }, 500),
    []
  );

  // search data in api using search box
  const hangleSearchVal = (e) => {
    debouncedSearch(e.target.value);
  };

  // Change status of unit active inactive !
  const changeStatusModal = (_id, active) => {
    setUid(_id);
    if (active) {
      setUnitStatus(false);
    } else {
      setUnitStatus(true);
    }
    setUnitModalShow(true);
  };
  const changeStatus = async () => {
    const { data } = await axios.put(
      `${updateActiveStatusByIdRoute}?unitId=${uid}&active=${unitStatus}`
    );
    if (data.success === true) {
      toast.success(
        data.msg,
        toastOptions,
        (toastOptions["position"] = "top-right")
      );
      setUnitModalShow(false);
      setRerendertableData(true);
    } else {
      toast.success(
        data.msg,
        toastOptions,
        (toastOptions["position"] = "bottom-right")
      );
      setUnitModalShow(false);
    }
  };

  return (
    <>
      <h4 className="primHeading my-2 mb-4">
        Company <span>Units</span>
      </h4>
      <div className="box my-3">
        <h4 className="sectionHeading my-2 mb-4">Units Summary</h4>
        <TableCom
          numPerPage={numPerPage}
          totalData={totalData}
          handleChange={handleChange}
          hangleSearchVal={hangleSearchVal}
          pageCount={pageCount}
          handlePageClick={handlePageClick}
          searcPlaceHolder="Search By Unit Name..."
        >
          <div className="customTable2 w-100 table-responsive">
            <table className="w-100">
              <thead>
                <tr>
                  {Thead?.map((th, index) => {
                    return <th key={index}>{th}</th>;
                  })}
                </tr>
              </thead>
              <tbody>
                {items == "" || items == undefined || items == null ? (
                  <tr>
                    <td colSpan={thLength}>
                      {" "}
                      <p className="my-2 smText text-decoration-underline text-danger">
                        No Record Found
                      </p>{" "}
                    </td>
                  </tr>
                ) : (
                  items.map((item, index) => {
                    const { description, name, createdAt, active, _id } = item;
                    return (
                      <tr key={index}>
                        <td>{++index}</td>
                        <td>{name || "-"}</td>
                        <td>{description || "-"}</td>
                        <td>
                          {createdAt
                            .slice(0, 10)
                            .split("-")
                            .reverse()
                            .join("-") || "-"}
                        </td>
                        <td>
                          {active === true ? (
                            <Dropdown>
                              <Dropdown.Toggle
                                className="select_table_box bgGreen"
                                id="dropdown-basic"
                              >
                                {" "}
                                Active{" "}
                              </Dropdown.Toggle>
                              <Dropdown.Menu className="statusMenu">
                                <Dropdown.Item
                                  type="button"
                                  className="statusBtn"
                                  onClick={() => {
                                    changeStatusModal(_id, active);
                                  }}
                                >
                                  InActive
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          ) : (
                            <Dropdown>
                              <Dropdown.Toggle
                                className="select_table_box bgRed"
                                id="dropdown-basic"
                              >
                                {" "}
                                InActive{" "}
                              </Dropdown.Toggle>
                              <Dropdown.Menu className="statusMenu">
                                <Dropdown.Item
                                  type="button"
                                  className="statusBtn"
                                  onClick={() => {
                                    changeStatusModal(_id, active);
                                  }}
                                >
                                  Active
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          )}
                        </td>
                        <td>
                          <Dropdown className="actionDropDown">
                            <Dropdown.Toggle className="customDropDown">
                              <FaEllipsisV className="colBlack" />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item
                                className="drop_item"
                                type="button"
                                as={Link}
                                to={`/view-unit/${_id}`}
                              >
                                <FaRegEye className="me-1" />
                                <span>View</span>
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
        </TableCom>
      </div>
      {/*================================ Change Untit activation status ========================= */}
      <CustomModal
        show={appModalShow}
        onHide={() => setUnitModalShow(false)}
        backdrop="static"
        keyboard="False"
      >
        <button
          onClick={() => setUnitModalShow(false)}
          className="modal-cancel"
        >
          <FaTimes />
        </button>
        <div className="modalContent">
          <img
            src={require("../../../assets/images/sure.png")}
            draggable="false"
            alt="modal_Img"
          />
          {/* modal text */}
          <h3 className="modalPrimHeading my-2">
            Are You Sure You Want <br /> to change this Unit status !
          </h3>
          {/* modal buttons */}
          <button
            className="main-btn m-2"
            type="button"
            onClick={() => {
              changeStatus();
            }}
          >
            Yes
          </button>
          <button
            className="sec-btn m-2"
            type="button"
            onClick={() => setUnitModalShow(false)}
          >
            No
          </button>
        </div>
      </CustomModal>{" "}
    </>
  );
};

export default Index;
