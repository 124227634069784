import React from 'react';
import Title from '../../hook/Title';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import CreateBrands from "../../Components/ScreenComponents/CreateBrands";
import CreateUnit from "../../Components/ScreenComponents/CreateUnit";
import SalesTargetListing from "../../Components/ScreenComponents/SaleTargetList";

import AllBrands from "../../Components/ScreenComponents/AllBrands";
import PaymentSettings from "../../Components/ScreenComponents/PaymentSettings";
import Integrations from '../../Components/ScreenComponents/Integrations';
import { changeBrandPage, removeAllBrands } from '../../actions/BrandsButton';
import { useSelector, useDispatch } from 'react-redux';

const Index = () => {
     Title("Settings");
     const disPatch = useDispatch();
     const brandpageState = useSelector((state) => state.BrandBtnReducer)
     return (
          <>
               <Row>
                    <Col xxl={9} className="mx-auto">
                         <h4 className="primHeading my-3 mb-4">Project Management System<span> settings</span></h4>
                         <div className="box p-0 settingTabs rounded-2">
                              <Tab.Container id="left-tabs-example" defaultActiveKey="brands" >
                                   <Row>
                                        <Col md={3} className="bgTheme px-0">
                                             <Nav variant="pills" className="flex-column align-items-center">
                                                  <Nav.Item className='w-100'>
                                                       <Nav.Link eventKey="brands" className='w-100 ps-5 rounded-0'>Brand</Nav.Link>
                                                  </Nav.Item>
                                                  <Nav.Item className='w-100'>
                                                       <Nav.Link eventKey="units" className='w-100 ps-5 rounded-0'>Units</Nav.Link>
                                                  </Nav.Item>
                                                  <Nav.Item className='w-100'>
                                                       <Nav.Link eventKey="payment" className='w-100 ps-5 rounded-0'>Payment</Nav.Link>
                                                  </Nav.Item>
                                                  <Nav.Item className='w-100'>
                                                       <Nav.Link eventKey="integrations" className='w-100 ps-5 rounded-0'>Integration Settings</Nav.Link>
                                                  </Nav.Item>
                                                  <Nav.Item className='w-100'>
                                                       <Nav.Link eventKey="salesTarget" className='w-100 ps-5 rounded-0'>Sales Target Management</Nav.Link>
                                                  </Nav.Item>
                                             </Nav>
                                        </Col>
                                        <Col md={9}>
                                             <Tab.Content>
                                                  <Tab.Pane className='my-4 mx-4' eventKey="brands">
                                                       <div className="d-flex align-items-center justify-content-between flex-wrap">
                                                            {
                                                                 brandpageState === "removeForm" ?
                                                                      (
                                                                           <>
                                                                                <h4 className="section-heading my-2 mb-4">All <span> Brands</span></h4>
                                                                                <button className="Btn main-btn" onClick={() => disPatch(removeAllBrands("removeBrands"))}>Create Brands</button>
                                                                           </>
                                                                      )
                                                                      :
                                                                      (
                                                                           <>
                                                                                <h4 className="section-heading my-2 mb-4">Create<span> Brands</span></h4>
                                                                                <button className="Btn main-btn" onClick={() => disPatch(changeBrandPage("removeForm"))} >View All Brands</button>
                                                                           </>
                                                                      )
                                                            }
                                                       </div>
                                                       {brandpageState === "removeBrands" ? <CreateBrands /> : <AllBrands />}
                                                  </Tab.Pane>
                                                  <Tab.Pane className='my-4 mx-4' eventKey="units">
                                                       <CreateUnit />
                                                  </Tab.Pane>
                                                  <Tab.Pane className='my-4 mx-4' eventKey="payment">
                                                       <PaymentSettings />
                                                  </Tab.Pane>
                                                  <Tab.Pane className='my-4 mx-4' eventKey="integrations">
                                                       <Integrations />
                                                  </Tab.Pane>
                                                  <Tab.Pane className='my-4 mx-4' eventKey="salesTarget">
                                                       <SalesTargetListing />
                                                  </Tab.Pane>
                                             </Tab.Content>
                                        </Col>
                                   </Row>
                              </Tab.Container>
                         </div>
                    </Col>
               </Row>
          </>
     );
}

export default Index;
