import React, { useState, useEffect, useCallback } from "react";
import { TableCom } from "../../../Components/TableCom";
import { Link, useNavigate } from "react-router-dom";
import {
  getMyInvoicesBySalesPersonRoute,
  getMyAccountsBySalesPersonRoute,
  getActiveWireGatewayAccountsRoute,
  getGeoLocationRoute,
  updatePaidStatusThroughWirePaymentByInvoiceIdRoute,
} from "../../../utils/APIRoutes";
import { AiFillBank } from "react-icons/ai";
import Dropdown from "react-bootstrap/Dropdown";
import Title from "../../../hook/Title";
import {
  FaRegEye,
  FaEllipsisV,
  FaTimes,
  FaEye,
  FaFileInvoice,
} from "react-icons/fa";
import { useSelector } from "react-redux";
import currencyCodes from "../../../helpers/currencyCodes.json";
import { toast } from "react-toastify";
import axios from "axios";
import { Row, Col } from "react-bootstrap";
import CustomModal from "../../../Components/CustomModal";
import useClipboard from "../../../hook/useClipboard";
import { debounce } from "lodash";

const MyAccounts = () => {
  Title("My Accounts");
  const navigate = useNavigate();
  const { handleCopyClipboard, copiedText } = useClipboard();

  // ! Filter By Date Code for table ! :
  const [filterDates, setfilterDates] = useState({
    from: new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      1
    ).toLocaleDateString("en-CA"), // Set from to the first day of the current month
    to: "",
  });
  const handleDateChange = (e) => {
    setfilterDates({ ...filterDates, [e.target.name]: e.target.value });
  };
  const clearFilters = () => {
    setfilterDates({ from: "", to: "" });
  };
  const { from, to } = filterDates;

  const AuthReducer = useSelector((state) => state.AuthReducer);
  const { empId } = AuthReducer.data;
  // Table Head Data
  const [thLength, setThlength] = useState(1);
  const [items, setItems] = useState([]);
  const [numPerPage, setNumPerPage] = useState(10);
  const [totalData, setTotalData] = useState(0);
  const [pageCount, setpageCount] = useState(0);
  const [currPage, setCurrPage] = useState(0);
  const [searchVal, setSearchVal] = useState("");

  const Thead = [
    "S.No",
    "Client",
    "Invoice Id",
    "Total Amount",
    "Sales Person",
    "Invoice Status",
    "Created At",
    "Payment Time",
    "brand",
    "Unit",
    "Gateway",
    "Assigned By",
    "Assigned On",
    "Action",
  ];

  useEffect(
    (currPage) => {
      currPage = currPage ?? 0;
      const getData = async () => {
        const res = await fetch(
          `${getMyAccountsBySalesPersonRoute}?empId=${empId}&numPerPage=${numPerPage}&page=${currPage}&search=${searchVal}&from=${from}&to=${to}`
        );
        const data = await res.json();
        const total = data.pagiantion.dataCount;
        setTotalData(total);
        setpageCount(Math.ceil(total / numPerPage));
        setItems(data.result);
        //------------------------------------------------------
        console.log(data);
      };
      getData();
    },
    [numPerPage, searchVal, from, to]
  );
  useEffect(() => {
    setThlength(Thead.length);
  }, [thLength]);
  const fetchData = async (currPage) => {
    const res = await fetch(
      `${getMyAccountsBySalesPersonRoute}?empId=${empId}&numPerPage=${numPerPage}&page=${currPage}&search=${searchVal}&from=${from}&to=${to}`
    );
    const changedData = await res.json();
    const data = changedData.result;
    return data;
  };
  const handlePageClick = async (data) => {
    let currPage = data.selected;
    const myData = await fetchData(currPage);
    setItems(myData);
    setCurrPage(currPage);
  };
  const handleChange = (e) => {
    setNumPerPage(e.target.value);
  };

  // Debounced search function
  const debouncedSearch = useCallback(
    debounce((value) => {
      setSearchVal(value);
    }, 500),
    []
  );

  // search data in api using search box
  const hangleSearchVal = (e) => {
    debouncedSearch(e.target.value);
  };

  //  Reciept Image ModaL
  const [recImg, setRecImg] = useState("");
  const handleRecieptModaL = (recpImg) => {
    setRecImg(recpImg);
  };
  return (
    <>
      <div className="d-flex align-items-center justify-content-between flex-wrap  mb-3">
        <h4 className="primHeading my-2">My Accounts</h4>
      </div>
      <div className="box my-3">
        {/*==========================|||****** Filters Start Here ********|||==========================*/}
        <Row className="customFilters">
          <Col xl={2} lg={4} md={6} sm={5} className="my-2">
            <label className="text-bold" htmlFor="From">
              From:
            </label>
            <input
              value={from}
              onChange={handleDateChange}
              className="prim-date"
              type="date"
              name="from"
              id="From"
            />
          </Col>
          <Col xl={2} lg={4} md={6} sm={5} className="my-2">
            <label className="text-bold" htmlFor="To">
              To:
            </label>
            <input
              value={to}
              onChange={handleDateChange}
              className="prim-date"
              type="date"
              name="to"
              id="To"
            />
          </Col>
          <Col xl={2} lg={4} md={6} sm={2} className="my-2">
            <button
              onClick={clearFilters}
              title="Clear Date Filters"
              className="main-btn clearFilter w-100 mt-4"
            >
              Clear
            </button>
          </Col>
        </Row>
        <hr />
        <TableCom
          numPerPage={numPerPage}
          totalData={totalData}
          handleChange={handleChange}
          hangleSearchVal={hangleSearchVal}
          pageCount={pageCount}
          handlePageClick={handlePageClick}
          searcPlaceHolder="Search..."
        >
          <div className="customTable2 w-100 table-responsive">
            <table className="w-100">
              <thead>
                <tr>
                  {Thead?.map((th, index) => {
                    return <th key={index}>{th}</th>;
                  })}
                </tr>
              </thead>
              <tbody>
                {items == "" || items == undefined || items == null ? (
                  <tr>
                    <td colSpan={thLength}>
                      <p className="my-2 smText text-decoration-underline text-danger">
                        No Record Found
                      </p>
                    </td>
                  </tr>
                ) : (
                  items?.map((item, index) => {
                    const {
                      _id,
                      client,
                      brand,
                      createdAt,
                      currency,
                      invoiceId,
                      paid,
                      unit,
                      totalAmount,
                      paidByGateway,
                      status,
                      paidAtDateTime,
                      paidAmountInUsd,
                      salePerson,
                      accManagerAssignedBy,
                      setAccountManagerDateTime,
                    } = item;
                    return (
                      <tr key={index}>
                        <td>{++index}</td>
                        <td>{client.length > 0 ? client[0].name : "--"}</td>
                        <td onClick={() => handleCopyClipboard(invoiceId)}>
                          <span className="cursor-pointer">
                            {copiedText === invoiceId
                              ? "Copied"
                              : invoiceId || "--"}
                          </span>
                        </td>
                        <td className="">
                          {totalAmount === "" || currency === ""
                            ? "--"
                            : `${
                                currency === "usd"
                                  ? "$"
                                  : currency === "cad"
                                  ? "C$"
                                  : currency === "gbp"
                                  ? "£"
                                  : currency === "aud"
                                  ? "A$"
                                  : currency === "eur"
                                  ? "€"
                                  : ""
                              } ${totalAmount}`}{" "}
                          {paid && currency !== "usd"
                            ? `= $` + paidAmountInUsd
                            : ""}
                        </td>
                        <td>
                          {salePerson.length > 0 ? salePerson[0].name : "--"}
                        </td>
                        <td>
                          <span
                            className={
                              status === "completed"
                                ? "bedge colWhite colbgGreen"
                                : status === "draft"
                                ? "bedge colWhite colbgRed"
                                : status === "linkSend"
                                ? "bedge colWhite colbgYellow"
                                : status === "emailSend"
                                ? "bedge colWhite colbgBlue"
                                : status === "overDue"
                                ? "bedge colWhite colbgDRed"
                                : "bedge colWhite colbgDRed"
                            }
                          >
                            {status === "emailSend"
                              ? "email Send"
                              : status === "overDue"
                              ? "Over Due"
                              : status === "linkSend"
                              ? "Link Send"
                              : status}
                          </span>
                        </td>
                        <td>
                          {createdAt
                            .slice(0, 10)
                            .split("-")
                            .reverse()
                            .join("-") || "-"}
                        </td>
                        <td>
                          {paidAtDateTime === null
                            ? "--"
                            : paidAtDateTime
                                .slice(0, 10)
                                .split("-")
                                .reverse()
                                .join("-") || "-"}{" "}
                          -{" "}
                          {paidAtDateTime === null
                            ? "--"
                            : paidAtDateTime.slice(11, 16) || "-"}
                        </td>
                        <td>{brand.length > 0 ? brand[0].name : "--"}</td>
                        <td>{unit.length > 0 ? unit[0].name : "--"}</td>
                        <td>
                          {" "}
                          {paidByGateway.length > 0
                            ? paidByGateway[0]?.gatewayName
                            : "--"}{" "}
                        </td>
                        <td>
                          {accManagerAssignedBy.length > 0
                            ? accManagerAssignedBy[0].name
                            : "--"}
                        </td>
                        <td>
                          {setAccountManagerDateTime === null
                            ? "--"
                            : setAccountManagerDateTime
                                .slice(0, 10)
                                .split("-")
                                .reverse()
                                .join("-") || "-"}{" "}
                          -{" "}
                          {setAccountManagerDateTime === null
                            ? "--"
                            : setAccountManagerDateTime.slice(11, 16) || "-"}
                        </td>
                        <td>
                          <Dropdown className="actionDropDown">
                            <Dropdown.Toggle className="customDropDown">
                              <FaEllipsisV className="colBlack" />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item
                                className="drop_item"
                                as={Link}
                                to={`/sales-invoice/${_id}`}
                              >
                                <FaRegEye className="me-1" />
                                <span>View</span>
                              </Dropdown.Item>
                              {paid === true ? (
                                paidByGateway[0].gatewayName === "wire" &&
                                (paidByGateway[0].receipt !== "" ||
                                  paidByGateway[0].receipt !== null ||
                                  paidByGateway[0].receipt !== undefined) ? (
                                  <Dropdown.Item
                                    className="drop_item"
                                    onClick={() =>
                                      handleRecieptModaL(
                                        paidByGateway[0].receipt
                                      )
                                    }
                                  >
                                    <FaFileInvoice className="me-1" />
                                    <span>View Reciept</span>
                                  </Dropdown.Item>
                                ) : (
                                  ""
                                )
                              ) : (
                                ""
                              )}
                            </Dropdown.Menu>
                          </Dropdown>
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
        </TableCom>
      </div>

      {/*========================================== ! View Reciept Modal ! =======================================*/}
      <CustomModal
        show={recImg !== "" ? true : false}
        backdrop="static"
        keyboard="False"
        size="md"
      >
        <button onClick={() => setRecImg("")} className="modal-cancel">
          <FaTimes />
        </button>
        <div className="modalContent">
          {recImg === null || recImg === undefined || recImg === "" ? (
            "No Reciept Found..."
          ) : (
            <img
              draggable={false}
              src={recImg}
              className="popUpImage"
              alt="recipt"
            />
          )}
        </div>
      </CustomModal>
    </>
  );
};

export default MyAccounts;
