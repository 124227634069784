import React from 'react';
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend
} from "recharts";



const CommissionPayable = ({ topSellingBrands }) => {
    const data = [
        {
            name: "Jan",
            LastYear: 4000,
            CurrentYear: 2400,
            amt: 2400
        },
        {
            name: "Feb",
            LastYear: 3000,
            CurrentYear: 1398,
            amt: 2210
        },
        {
            name: "Mar",
            LastYear: 2000,
            CurrentYear: 9800,
            amt: 2290
        },
        {
            name: "Apr",
            LastYear: 2780,
            CurrentYear: 3908,
            amt: 2000
        },
        {
            name: "May",
            LastYear: 1890,
            CurrentYear: 4800,
            amt: 2181
        },
        {
            name: "Jun",
            LastYear: 2390,
            CurrentYear: 3800,
            amt: 2500
        },
        {
            name: "Jul",
            LastYear: 3490,
            CurrentYear: 4300,
            amt: 2100
        },
        {
            name: "Aug",
            LastYear: 2000,
            CurrentYear: 9800,
            amt: 2290
        },
        {
            name: "Sep",
            LastYear: 2780,
            CurrentYear: 3908,
            amt: 2000
        },
        {
            name: "Oct",
            LastYear: 1890,
            CurrentYear: 4800,
            amt: 2181
        },
        {
            name: "Nov",
            LastYear: 2390,
            CurrentYear: 3800,
            amt: 2500
        },
        {
            name: "Dec",
            LastYear: 0,
            CurrentYear: 4300,
            amt: 2100
        }
    ];

    return (
        <BarChart
            width={670}
            height={300}
            data={data}
            margin={{
                top: 5,
                right: 5,
                left: 5,
                bottom: 5
            }}
        >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name"/>
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="CurrentYear" fill="#f4b41a" background={{ fill: "#eee" }} />
            <Bar dataKey="LastYear" fill="#143d59" />
        </BarChart>
    )
}
export default CommissionPayable;