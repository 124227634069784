import React from "react";
import { Routes, Route } from "react-router-dom";
import LoginScreen from "../Views/Auth/Login";
import ForgotPassword from "../Views/Auth/Forget";
import SetPassword from "../Views/Auth/SetPassword";
import Verification from "../Views/Auth/Verify";
import Dashboard from "../Views/Dashboard/Index";
import Notification from "../Views/Notification/Index";
import Layout from "../Layout/Layout";
import ErrorPage from "../Views/ErrorPage/Index";
import MyProfile from "../Views/Profile/MyProfile";
import EditProfile from "../Views/Profile/EditProfile";
import ChangePassword from "../Views/Profile/ChangePassword";
import Tasks from "../Views/Tasks/Index";
import ViewTasks from "../Views/Tasks/ViewTasks";
import CreateTask from "../Views/Tasks/CreateTask";
import EditTask from "../Views/Tasks/EditTask";
import AssignTask from "../Views/Tasks/AssignTask";
import MyInvoices from "../Views/Company/Client/MyInvoices";
import UnitInvoices from "../Views/Company/Client/UnitInvoices";
import MyUnitInvoices from "../Views/Company/Client/MyUnitInvoices";
import ViewClient from "../Views/Company/Client/ViewClient";
import CreateClient from "../Views/Company/Client/CreateClient";
import CreateInvoice from "../Views/Company/Client/CreateInvoice";
import EditInvoice from "../Views/Company/Client/EditInvoice";
import Invoice from "../Views/Company/Client/Invoice";
import AddCard from "../Views/Company/Client/AddCard";
import Brands from "../Views/Company/Brands/Index";
import CreateBrand from "../Components/ScreenComponents/CreateBrands";
import Leads from "../Views/Company/Client/Leads/Index";
import MyLeads from "../Views/Company/Client/Leads/MyLeads";
import MyGeneratedLeads from "../Views/Company/Client/Leads/MyGeneratedLeads";
import MyConvertedLeads from "../Views/Company/Client/Leads/MyConvertedLeads";
import PPcConvertedLeads from "../Views/Company/Client/Leads/PPcConvertedLeads";
import GenerateLeads from "../Views/Company/Client/Leads/GenerateLeads";
import CreateLead from "../Views/Company/Client/Leads/CreateLead";
import DealsPipeline from "../Views/Company/Client/DealsPipeline/Index";
import MyDeals from "../Views/Company/Client/DealsPipeline/MyDeals";
import ViewDeal from "../Views/Company/Client/DealsPipeline/ViewDeal";
import Settings from "../Views/Settings/Index";
import Units from "../Views/Company/Units/Index";
import MyUnits from "../Views/Company/Units/MyUnits";
import ViewMyUnits from "../Views/Company/Client/DealsPipeline/MyUnitDeals";
import UnitLeads from "../Views/Company/Client/Leads/UnitLeads";
import SingleUnitLeads from "../Views/Company/Client/Leads/SingleUnitLeads";
import UnitDeals from "../Views/Company/Client/DealsPipeline/UnitDeals";
import ViewUnit from "../Views/Company/Units/ViewUnit";
import MyUnitClients from "../Views/Company/Client/MyUnitClients";
import UnitClients from "../Views/Company/Client/UnitClients";
import SalesInvoice from "../Views/Company/Client/SalesInvoice";
import { useSelector } from "react-redux";
import StripePayCompletion from "../Components/ScreenComponents/StripePayCompletion";
import PaypalPayCompletion from "../Components/ScreenComponents/PaypalPayCompletion";
import AllInvoicesListings from "../Views/Company/Client/AllInvoicesListings";
import MyClients from "../Views/Company/Client/Index";
import AllClients from "../Views/Company/Client/AllClients";
import AllTracks from "../Views/Company/Tracking/AllTracks";
import InvoiceTracking from "../Views/Company/Tracking/InvoiceTracking";
import Disputes from "../Views/Company/Client/Disputes/Index";
import PpcLeads from "../Views/Company/ppc";
import PpcLeadsOF from "../Views/Company/ppc/PpcLeadsOF";
import PpcInvBrands from "../Views/Company/ppc/PpcInvBrands";
import SalesDashboard from "../Views/Dashboard/SalesDashboard";
import SalesKPIDashboard from "../Views/Dashboard/SalesKPIDashboard";
import CommissionDashboard from "../Views/Dashboard/CommissionDashboard";
import MyAccounts from "../Views/Company/Client/MyAccounts";
import ClientInfo from "../Views/Company/Client/ClientInfo";

const MainRoutes = () => {
  const AuthReducer = useSelector((state) => state.AuthReducer);
  const authUserrole = AuthReducer.data.role;

  const publicRoute = [
    {
      path: "/",
      element: <LoginScreen />,
    },
    {
      path: "/forgot-Password",
      element: <ForgotPassword />,
    },
    {
      path: "/verification/:email",
      element: <Verification />,
    },
    {
      path: "/set-password/:email",
      element: <SetPassword />,
    },
    {
      path: "/invoice/:_id",
      element: <Invoice />,
    },
    {
      path: "/card-payment-completion/:invoiceObjectId",
      element: <StripePayCompletion />,
    },
    {
      path: "/payment-completion/:invoiceObjectId",
      element: <PaypalPayCompletion />,
    },
    {
      path: "*",
      element: <ErrorPage />,
    },
  ];

  const privateRoutes = [
    {
      path: "/notification",
      element: <Notification />,
    },
    {
      path: "/profile",
      element: <MyProfile />,
    },
    {
      path: "/EditProfile",
      element: <EditProfile />,
    },
    {
      path: "/changepassword",
      element: <ChangePassword />,
    },
    {
      path: "/dashboard",
      element: <Dashboard />,
    },
    {
      path: "/tasks",
      element: <Tasks />,
    },
    {
      path: "/view-tasks",
      element: <ViewTasks />,
    },
  ];

  const privateRoutesSales = [
    {
      path: "/my-clients",
      element: <MyClients />,
    },
    {
      path: "/all-clients",
      element: <AllClients />,
    },
    {
      path: "/view-Company/client/:custId",
      element: <ViewClient />,
    },
    {
      path: "/create-client",
      element: <CreateClient />,
    },
    {
      path: "/create-invoice",
      element: <CreateInvoice />,
    },
    {
      path: "/edit-invoice/:_id",
      element: <EditInvoice />,
    },
    {
      path: "/add-card/:custId",
      element: <AddCard />,
    },
    {
      path: "/notification",
      element: <Notification />,
    },
    {
      path: "/profile",
      element: <MyProfile />,
    },
    {
      path: "/EditProfile",
      element: <EditProfile />,
    },
    {
      path: "/changepassword",
      element: <ChangePassword />,
    },
    {
      path: "/dashboard",
      element: <Dashboard />,
    },
    {
      path: "/my-invoices",
      element: <MyInvoices />,
    },
    {
      path: "/unit-invoices",
      element: <UnitInvoices />,
    },
    {
      path: "/my-unit-invoices/:unitId",
      element: <MyUnitInvoices />,
    },
    {
      path: "/brands",
      element: <Brands />,
    },
    {
      path: "/add-brand",
      element: <CreateBrand />,
    },
    {
      path: "/leads",
      element: <Leads />,
    },
    {
      path: "/my-leads",
      element: <MyLeads />,
    },
    {
      path: "/my-generated-leads",
      element: <MyGeneratedLeads />,
    },
    {
      path: "/my-converted-leads",
      element: <MyConvertedLeads />,
    },
    {
      path: "/ppc-converted-leads",
      element: <PPcConvertedLeads />,
    },
    {
      path: "/generate-leads",
      element: <GenerateLeads />,
    },
    {
      path: "/unit-leads",
      element: <UnitLeads />,
    },
    {
      path: "/unit-leads/:id",
      element: <SingleUnitLeads />,
    },
    {
      path: "/create-lead/:id",
      element: <CreateLead />,
    },
    {
      path: "/settings",
      element: <Settings />,
    },
    {
      path: "/deals-pipeline",
      element: <DealsPipeline />,
    },
    {
      path: "/my-deals",
      element: <MyDeals />,
    },
    {
      path: "/unit-deals/:id",
      element: <UnitDeals />,
    },
    {
      path: "/view-deal/:dealId",
      element: <ViewDeal />,
    },
    {
      path: "/units",
      element: <Units />,
    },
    {
      path: "/my-units",
      element: <MyUnits />,
    },
    {
      path: "/my-units-deals",
      element: <ViewMyUnits />,
    },
    {
      path: "/unit-deals",
      element: <DealsPipeline />,
    },
    {
      path: "/view-unit/:unitId",
      element: <ViewUnit />,
    },
    {
      path: "/my-unit-clients",
      element: <MyUnitClients />,
    },
    {
      path: "/unit-clients/:_id",
      element: <UnitClients />,
    },
    {
      path: "/sales-invoice/:_id",
      element: <SalesInvoice />,
    },
    {
      path: "/all-invoices",
      element: <AllInvoicesListings />,
    },
    {
      path: "/all-tracks",
      element: <AllTracks />,
    },
    {
      path: "/invoice-tracking",
      element: <InvoiceTracking />,
    },
    {
      path: "/tasks",
      element: <Tasks />,
    },
    {
      path: "/create-task",
      element: <CreateTask />,
    },
    {
      path: "/assign-task",
      element: <AssignTask />,
    },
    {
      path: "/edit-task",
      element: <EditTask />,
    },
    {
      path: "/view-tasks",
      element: <ViewTasks />,
    },
    {
      path: "/disputes",
      element: <Disputes />,
    },
    {
      path: "/ppc-leads",
      element: <PpcLeads />,
    },
    {
      path: "/ppc-leadsof",
      element: <PpcLeadsOF />,
    },
    {
      path: "/ppc-invBrands",
      element: <PpcInvBrands />,
    },
    {
      path: "/salesDashboard",
      element: <SalesDashboard />,
    },
    {
      path: "/salesKpiDashboard",
      element: <SalesKPIDashboard />,
    },
    {
      path: "/commissionDashboard",
      element: <CommissionDashboard />,
    },
    {
      path: "/my-accounts",
      element: <MyAccounts />,
    },
    {
      path: "/client-info",
      element: <ClientInfo />,
    },
  ];

  return (
    <>
      <Routes>
        {/* ************************************|| PUBLIC ROUTES ||*************************************** */}
        {publicRoute?.map((publicRoute, index) => {
          const { path, element } = publicRoute;
          return <Route key={index} exact path={path} element={element} />;
        })}
        {/*  ============================ Dashboard / Private Routes Pages =============================== */}
        {authUserrole === "development"
          ? privateRoutes?.map((privateRoutes, index) => {
              const { path, element } = privateRoutes;
              return (
                <Route
                  key={index}
                  exact
                  path={path}
                  element={<Layout>{element}</Layout>}
                />
              );
            })
          : authUserrole === "sales" ||
            authUserrole === "accounts" ||
            authUserrole === "finance" ||
            authUserrole === "ppc"
          ? privateRoutesSales?.map((privateRoutesSales, index) => {
              const { path, element } = privateRoutesSales;
              return (
                <Route
                  key={index}
                  exact
                  path={path}
                  element={<Layout>{element}</Layout>}
                />
              );
            })
          : ""}
      </Routes>
    </>
  );
};

export default MainRoutes;
