import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Title from "../../hook/Title";
import { useDispatch } from "react-redux";
import { RemoveAuthAction } from "../../actions/AuthAction";
const Index = () => {
  const disPatch = useDispatch();
  Title("Page Not Found");
  const navigate = useNavigate();

  useEffect(() => {
    disPatch(RemoveAuthAction());
    localStorage.removeItem("token");
    localStorage.removeItem("name");
    localStorage.removeItem("role");
    // navigate("/")
  }, []);
  return (
    <div className="errorBox box m-md-5 m-2 p-md-5 p-2 text-center">
      <h4>Ooops...</h4>
      <h3>Error 404</h3>
      <p>We are sorry, but the page you are looking for does not exist.</p>
      <button className="main-btn my-2" onClick={() => navigate("/")}>
        Please Login
      </button>
    </div>
  );
};

export default Index;
