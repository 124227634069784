import React, { useState, useEffect } from "react";
import { TableCom } from "../../Components/TableCom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { FaEllipsisV, FaTimes, FaBookmark } from "react-icons/fa";
import {
  getAllSalesPersonRoute,
  createNewSalePersonTargetRoute,
  getAllSalePersonsByMonthRoute,
  updateTargetByIdRoute,
} from "../../utils/APIRoutes";
import axios from "axios";
import {useNavigate} from 'react-router-dom';
import { Row, Col } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";

import CustomModal from "../../Components/CustomModal";
const Index = () => {
  const [openModal, setOpenModal] = useState(false);
  const [servError, setServError] = useState(false);
  const [viewModalDate, setViewModalDate] = useState("");
  const [modalDate, setModalDate] = useState("");
  const [benchmarkNumber, setBenchmarkNumber] = useState(0);
  const [target, setTarget] = useState(0);
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const [saleEmployeeData, setSaleEmployeeData] = useState({});
  const [selectedEmployee, setSelectedEmployee] = useState({});
  const [commissionRate, setCommissionRate] = useState("0");

  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [numPerPage, setNumPerPage] = useState(10);
  const [totalData, setTotalData] = useState(0);
  const [searchVal, setSearchVal] = useState("");
  const [pageCount, setpageCount] = useState(0);
  const [items, setItems] = useState([]);
  const [currPage, setCurrPage] = useState(0);
  const [thLength, setThlength] = useState(1);
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [benchmarkNumberForUpdate, setBenchmarkNumberForUpdate] = useState(0);
  const [targetForUpdate, setTargetForUpdate] = useState(0);
  const [commissionRateForUpdate, setCommissionRateForUpdate] = useState("0");
  const [viewUpdateModalDate, setViewUpdateModalDate] = useState("");
  const [viewUpdateModalSalePerson, setViewUpdateModalSalePerson] =
    useState("");
  const [updateErrors, setUpdateErrors] = useState({});
  const [updateTargetId, setUpdateTargetId] = useState("");

  const Thead = [
    "S.No",
    "Sale Person",
    "Benchmark",
    "Target",
    "Commission Rate",
    "Action",
  ];

  const monthsAbbreviated = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  let toastOptions = {
    position: "top-left",
    autoClose: 5000,
    pauseOnHover: false,
    draggable: false,
    theme: "dark",
  };

  const clearFilters = () => {
    setStartDate(new Date());
  };

  const getAlSalesPerson = async () => {
    try {
      const salePersons = await axios
        .get(getAllSalesPersonRoute)
        .catch(function (error) {
          if (error.response) {
            if (error.response.status === 500) {
              setServError(true);
            }
          }
        });
      if (salePersons?.data.success === true) {
        setSaleEmployeeData(salePersons.data.data);
      } else {
        console.log("error In Getting all Sales Person", salePersons.data.msg);
      }
    } catch (error) {
      console.log("error In Getting all Sales Person", error);
    }
  };

  const handleCreateModal = async () => {
    setButtonLoading(true);
    const date = new Date();
    await getAlSalesPerson();
    setViewModalDate(
      monthsAbbreviated[date.getMonth()] + " - " + date.getFullYear()
    );
    setModalDate(date.toISOString());
    setButtonLoading(false);
    setOpenModal(true);
  };
  const handleCreateModalClose = () => {
    setOpenModal(false);
    setSaleEmployeeData({});
    setSelectedEmployee("");
    setViewModalDate("");
    setModalDate("");
    setBenchmarkNumber(0);
    setTarget(0);
    setCommissionRate("0");
    setErrors({});
  };

  let hasError = false;
  const validateForm = () => {
    setIsLoading(true);
    const newErrors = {};
    if (
      selectedEmployee === null ||
      selectedEmployee === undefined ||
      selectedEmployee === "" ||
      JSON.stringify(selectedEmployee) === "{}"
    ) {
      hasError = true;
      setIsLoading(false);
      newErrors.salePersons = "Sale Person Required !";
    }
    if (
      benchmarkNumber === null ||
      benchmarkNumber === undefined ||
      benchmarkNumber === "" ||
      benchmarkNumber === 0
    ) {
      hasError = true;
      setIsLoading(false);
      newErrors.benchmarkNumber = "Benchmark is Required !";
    }
    if (
      target === null ||
      target === undefined ||
      target === "" ||
      target === 0
    ) {
      hasError = true;
      setIsLoading(false);
      newErrors.target = "Target is Required !";
    }
    if (
      commissionRate === null ||
      commissionRate === undefined ||
      commissionRate === "" ||
      commissionRate === 0 ||
      commissionRate === "0"
    ) {
      hasError = true;
      setIsLoading(false);
      newErrors.commissionRate = "Commission Rate is Required !";
    }
    setErrors(newErrors);
  };

  const handleFormDetailsSubmit = async (e) => {
    e.preventDefault();
    validateForm();
    if (hasError === false) {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("monthYear", modalDate);
      formData.append("benchmark", benchmarkNumber);
      formData.append("target", target);
      formData.append("commissionRate", commissionRate);
      const { data } = await axios.post(
        `${createNewSalePersonTargetRoute}?employeeId=${
          JSON.parse(selectedEmployee)?._id
        }`,
        formData
      );
      if (data.success === true) {
        toast.success(
          data.msg,
          toastOptions,
          (toastOptions["position"] = "top-right")
        );
        setIsLoading(false);
        handleCreateModalClose();
        setErrors({});
        window.location.reload();
      } else {
        toast.error(
          data.msg,
          toastOptions,
          (toastOptions["position"] = "top-right")
        );
        // handleCreateModalClose();
        setIsLoading(false);
      }
    } else {
      toast.error(
        "Kindly Fill the require fields !",
        toastOptions,
        (toastOptions["position"] = "top-right")
      );

      setIsLoading(false);
    }
  };

  const handleSaleTargetUpdateForm = (
    id,
    employeeName,
    benchmark,
    target,
    monthYear,
    commissionRate
  ) => {
    setUpdateTargetId(id);
    setViewUpdateModalSalePerson(employeeName);
    const date = new Date(monthYear);
    setViewUpdateModalDate(
      monthsAbbreviated[date.getMonth()] + " - " + date.getFullYear()
    );
    setBenchmarkNumberForUpdate(benchmark);
    setTargetForUpdate(target);
    setCommissionRateForUpdate(commissionRate);
    setOpenUpdateModal(true);
  };
  const handleUpdateModalClose = () => {
    setOpenUpdateModal(false);
    setUpdateTargetId("");
    setViewUpdateModalSalePerson("");
    setViewUpdateModalDate("");
    setBenchmarkNumberForUpdate(0);
    setTargetForUpdate(0);
    setUpdateErrors({});
  };

  let hasUpdateError = false;
  const validateUpdateForm = () => {
    setIsLoading(true);
    const newErrors = {};
    if (
      benchmarkNumberForUpdate === null ||
      benchmarkNumberForUpdate === undefined ||
      benchmarkNumberForUpdate === "" ||
      benchmarkNumberForUpdate === 0
    ) {
      hasUpdateError = true;
      setIsLoading(false);
      newErrors.benchmarkNumberForUpdate = "Benchmark is Required !";
    }
    if (
      targetForUpdate === null ||
      targetForUpdate === undefined ||
      targetForUpdate === "" ||
      targetForUpdate === 0
    ) {
      hasUpdateError = true;
      setIsLoading(false);
      newErrors.targetForUpdate = "Target is Required !";
    }
    if (
      commissionRateForUpdate === null ||
      commissionRateForUpdate === undefined ||
      commissionRateForUpdate === "" ||
      commissionRateForUpdate === 0 ||
      commissionRateForUpdate === "0"
    ) {
      hasUpdateError = true;
      setIsLoading(false);
      newErrors.commissionRateForUpdate = "Commission Rate is Required !";
    }
    setUpdateErrors(newErrors);
  };

  const handleFormDetailsUpdate = async (e) => {
    e.preventDefault();
    validateUpdateForm();
    if (hasUpdateError === false) {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("benchmark", benchmarkNumberForUpdate);
      formData.append("target", targetForUpdate);
      formData.append("commissionRate", commissionRateForUpdate);
      const { data } = await axios.put(
        `${updateTargetByIdRoute}?targetId=${updateTargetId}`,
        formData
      );
      if (data.success === true) {
        toast.success(
          data.msg,
          toastOptions,
          (toastOptions["position"] = "top-right")
        );
        setIsLoading(false);
        handleUpdateModalClose();
        setUpdateErrors({});
        window.location.reload();
      } else {
        toast.error(
          data.msg,
          toastOptions,
          (toastOptions["position"] = "top-right")
        );
        // handleCreateModalClose();
        setIsLoading(false);
      }
    } else {
      toast.error(
        "Kindly Fill the require fields !",
        toastOptions,
        (toastOptions["position"] = "top-right")
      );

      setIsLoading(false);
    }
  };

  useEffect(
    (currPage) => {
      currPage = currPage ?? 0;
      const getData = async () => {
        const res = await fetch(
          `${getAllSalePersonsByMonthRoute}?requestDate=${startDate
            .toISOString()
            .substring(
              0,
              10
            )}&numPerPage=${numPerPage}&page=${currPage}&search=${searchVal}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (res.status === 401) {
          navigate("/");
          toast.error(
            "Session has been Expired",
            toastOptions,
            (toastOptions["position"] = "top-right")
          );
          return;
        }
        const data = await res.json();
        const total = data.pagiantion.dataCount;
        setTotalData(total);
        setpageCount(Math.ceil(total / numPerPage));
        setItems(data.result);
        //------------------------------------------------------
        // console.log(data)
      };
      getData();
    },
    [numPerPage, searchVal, startDate]
  );
  useEffect(() => {
    setThlength(Thead.length);
  }, [thLength]);

  const fetchData = async (currPage) => {
    const res = await fetch(
      `${getAllSalePersonsByMonthRoute}?requestDate=${startDate
        .toISOString()
        .substring(
          0,
          10
        )}&numPerPage=${numPerPage}&page=${currPage}&search=${searchVal}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (res.status === 401) {
      navigate("/");
      toast.error(
        "Session has been Expired",
        toastOptions,
        (toastOptions["position"] = "top-right")
      );
      return;
    }
    const changedData = await res.json();
    const data = changedData.result;
    return data;
  };

  const handlePageClick = async (data) => {
    let currPage = data.selected;
    const myData = await fetchData(currPage);
    setItems(myData);
    setCurrPage(currPage);
  };

  const handleChange = (e) => {
    setNumPerPage(e.target.value);
  };

  const hangleSearchVal = (e) => {
    setSearchVal(e.target.value);
  };

  return (
    <>
      <div className="d-flex align-items-center justify-content-between flex-wrap  mb-3">
        <h4 className="primHeading my-2">Sale Targets</h4>
        <Link
          title="Click to Set Target For new Sale Person"
          className="main-btn"
          onClick={() => handleCreateModal()}
        >
          {buttonLoading ? "Opening..." : "Set New Sale Person Target"}
        </Link>
      </div>
      <h5 className="sectionHeading23">
        Benchmark/Target updates automatically on the day first of every month*
      </h5>
      <div className="box my-3">
        <Row className="customFilters">
          <Col xl={3} lg={4} md={6} sm={5} className="my-2">
            <label className="text-bold" htmlFor="monthYear">
              Month:
            </label>
            <DatePicker
              className="prim-date"
              title="Click to Change Month"
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              dateFormat="MM/yyyy"
              showMonthYearPicker
            />
            {/* <input value={from} onChange={handleDateChange} className="prim-date" type="date" name="from" id="From" /> */}
          </Col>
          <Col xl={2} lg={4} md={6} sm={2} className="my-2">
            <button
              onClick={clearFilters}
              title="Reset to Current Month"
              className="main-btn clearFilter w-100 mt-4"
            >
              Reset
            </button>
          </Col>
        </Row>
        <hr />
        <TableCom
          numPerPage={numPerPage}
          totalData={totalData}
          handleChange={handleChange}
          hangleSearchVal={hangleSearchVal}
          pageCount={pageCount}
          handlePageClick={handlePageClick}
          searcPlaceHolder="Search..."
        >
          <div className="customTable2 w-100 table-responsive">
            <table className="w-100">
              <thead>
                <tr>
                  {Thead?.map((th, index) => {
                    return <th key={index}>{th}</th>;
                  })}
                </tr>
              </thead>
              <tbody>
                {items == "" || items == undefined || items == null ? (
                  <tr>
                    <td colSpan={thLength}>
                      <p className="my-2 smText text-decoration-underline text-danger">
                        No Record Found
                      </p>
                    </td>
                  </tr>
                ) : (
                  items?.map((item, index) => {
                    const {
                      _id,
                      employeeName,
                      benchmark,
                      target,
                      monthYear,
                      commissionRate,
                    } = item;
                    return (
                      <tr key={index}>
                        <td>{++index}</td>
                        <td>{employeeName}</td>
                        <td>{benchmark}</td>
                        <td>{target}</td>
                        <td>{commissionRate} %</td>
                        <td>
                          <Dropdown className="actionDropDown">
                            <Dropdown.Toggle className="customDropDown">
                              <FaEllipsisV className="colBlack" />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item
                                className="drop_item"
                                onClick={() =>
                                  handleSaleTargetUpdateForm(
                                    _id,
                                    employeeName,
                                    benchmark,
                                    target,
                                    monthYear,
                                    commissionRate
                                  )
                                }
                              >
                                <FaBookmark className="me-1" />
                                <span>Update Benchmark/Target</span>
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
        </TableCom>
      </div>
      {/* -------------------SET New Sale Person Target Model-----------------------Start------------------------ */}
      <CustomModal
        show={openModal}
        backdrop="static"
        keyboard="False"
        size="md"
      >
        <button
          title="Close Form"
          onClick={() => handleCreateModalClose()}
          className="modal-cancel"
        >
          <FaTimes />
        </button>
        <div className="modalContent">
          <form onSubmit={(e) => handleFormDetailsSubmit(e)}>
            <h5 className="text-center my-2">
              Set Target For New Sales Person
            </h5>
            {/* <small className="text-center my-2"><b>{invId || ""}</b></small> */}
            {servError === true ? (
              <h5 className="text-center my-2">
                Something Went Wrong Please Try Again Later !
              </h5>
            ) : (
              <Row className="text-start">
                <div className="my-2">
                  <label htmlFor="currentMonth">Current Month</label>
                  <p className="mb-0">{viewModalDate}</p>
                </div>
                {saleEmployeeData.length > 0 ? (
                  <Col lg={12} className="my-2">
                    <label
                      className="text-bold mb-1"
                      htmlFor="salepersonDetails"
                    >
                      Select Sale Person <span className="text-danger">*</span>
                    </label>
                    <select
                      className="filterSelect ps-3"
                      id="salepersonDetails"
                      name="salepersonDetails"
                      onChange={(e) => setSelectedEmployee(e.target.value)}
                    >
                      <option value="">Select Sale Person</option>
                      {saleEmployeeData?.map((data, index) => {
                        const { employeeName, unit } = data;
                        return (
                          <option
                            key={index}
                            value={JSON.stringify(data)}
                          >{`${employeeName} (${
                            unit.length === 0
                              ? "--"
                              : unit.length === 1
                              ? unit[0].name
                              : "AVP"
                          })`}</option>
                        );
                      })}
                    </select>
                    {errors.salePersons && (
                      <p className="text-danger fw-500 fs-13 my-2 text-capitalize">
                        {errors.salePersons}
                      </p>
                    )}
                  </Col>
                ) : (
                  ""
                )}

                <Col lg={12} className="my-2">
                  <label className="text-bold mb-1" htmlFor="benchmarkNumber">
                    Benchmark <span className="text-danger">*</span>
                  </label>
                  <input
                    value={benchmarkNumber}
                    onChange={(e) => setBenchmarkNumber(e.target.value)}
                    name="benchmarkNumber"
                    id="benchmarkNumber"
                    type="number"
                    className="filterSelect ps-3"
                    placeholder="Enter Benchmark"
                  />
                  {errors.benchmarkNumber && (
                    <p className="text-danger fw-500 fs-13 my-2 text-capitalize">
                      {errors.benchmarkNumber}
                    </p>
                  )}
                </Col>
                <Col lg={12} className="my-2">
                  <label className="text-bold mb-1" htmlFor="target">
                    Target <span className="text-danger">*</span>
                  </label>
                  <input
                    value={target}
                    onChange={(e) => setTarget(e.target.value)}
                    name="target"
                    id="target"
                    type="number"
                    className="filterSelect ps-3"
                    placeholder="Enter Target"
                  />
                  {errors.target && (
                    <p className="text-danger fw-500 fs-13 my-2 text-capitalize">
                      {errors.target}
                    </p>
                  )}
                </Col>
                <Col lg={12} className="my-2">
                  <label className="text-bold mb-1" htmlFor="commissionRate">
                    Commission Rate <span className="text-danger">*</span>
                  </label>
                  <select
                    className="filterSelect ps-3"
                    id="commissionRate"
                    name="commissionRate"
                    onChange={(e) => setCommissionRate(e.target.value)}
                  >
                    <option value="0">Select Commission Rate</option>
                    <option value="1">1 %</option>
                    <option value="2">2 %</option>
                    <option value="3">3 %</option>
                    <option value="4">4 %</option>
                    <option value="5">5 %</option>
                    <option value="6">6 %</option>
                    <option value="7">7 %</option>
                    <option value="8">8 %</option>
                    <option value="9">9 %</option>
                    <option value="10">10 %</option>
                  </select>
                  {errors.commissionRate && (
                    <p className="text-danger fw-500 fs-13 my-2 text-capitalize">
                      {errors.commissionRate}
                    </p>
                  )}
                </Col>

                <Col lg={12} className="my-2 text-center">
                  <button
                    title="Click to Submit"
                    type="submit"
                    className="main-btn"
                  >
                    {isLoading ? "Submiting..." : "Submit"}
                  </button>
                </Col>
              </Row>
            )}
          </form>
        </div>
      </CustomModal>
      {/* -------------------SET New Sale Person Target Model-----------------------End------------------------ */}
      {/* -------------------SET Update Sale Person Target Model-----------------------Start------------------------ */}
      <CustomModal
        show={openUpdateModal}
        backdrop="static"
        keyboard="False"
        size="md"
      >
        <button
          title="Close Form"
          onClick={() => handleUpdateModalClose()}
          className="modal-cancel"
        >
          <FaTimes />
        </button>
        <div className="modalContent">
          <form onSubmit={(e) => handleFormDetailsUpdate(e)}>
            <h5 className="text-center my-2">Update Target</h5>
            {/* <small className="text-center my-2"><b>{invId || ""}</b></small> */}
            {servError === true ? (
              <h5 className="text-center my-2">
                Something Went Wrong Please Try Again Later !
              </h5>
            ) : (
              <Row className="text-start">
                <div className="my-2">
                  <label htmlFor="currentMonth">Sales Person</label>
                  <p className="mb-0">{viewUpdateModalSalePerson}</p>
                </div>
                <div className="my-2">
                  <label htmlFor="currentMonth">Month/Year</label>
                  <p className="mb-0">{viewUpdateModalDate}</p>
                </div>
                <Col lg={12} className="my-2">
                  <label className="text-bold mb-1" htmlFor="benchmarkNumber">
                    Benchmark <span className="text-danger">*</span>
                  </label>
                  <input
                    value={benchmarkNumberForUpdate}
                    onChange={(e) =>
                      setBenchmarkNumberForUpdate(e.target.value)
                    }
                    name="benchmarkNumber"
                    id="benchmarkNumber"
                    type="number"
                    className="filterSelect ps-3"
                    placeholder="Enter Benchmark"
                  />
                  {updateErrors.benchmarkNumberForUpdate && (
                    <p className="text-danger fw-500 fs-13 my-2 text-capitalize">
                      {updateErrors.benchmarkNumberForUpdate}
                    </p>
                  )}
                </Col>
                <Col lg={12} className="my-2">
                  <label className="text-bold mb-1" htmlFor="target">
                    Target <span className="text-danger">*</span>
                  </label>
                  <input
                    value={targetForUpdate}
                    onChange={(e) => setTargetForUpdate(e.target.value)}
                    name="target"
                    id="target"
                    type="number"
                    className="filterSelect ps-3"
                    placeholder="Enter Target"
                  />
                  {updateErrors.targetForUpdate && (
                    <p className="text-danger fw-500 fs-13 my-2 text-capitalize">
                      {updateErrors.targetForUpdate}
                    </p>
                  )}
                </Col>
                <Col lg={12} className="my-2">
                  <label className="text-bold mb-1" htmlFor="commissionRate">
                    Commission Rate <span className="text-danger">*</span>
                  </label>
                  <select
                    value={commissionRateForUpdate}
                    className="filterSelect ps-3"
                    id="commissionRate"
                    name="commissionRate"
                    onChange={(e) => setCommissionRateForUpdate(e.target.value)}
                  >
                    <option value="0">Select Commission Rate</option>
                    <option value="1">1 %</option>
                    <option value="2">2 %</option>
                    <option value="3">3 %</option>
                    <option value="4">4 %</option>
                    <option value="5">5 %</option>
                    <option value="6">6 %</option>
                    <option value="7">7 %</option>
                    <option value="8">8 %</option>
                    <option value="9">9 %</option>
                    <option value="10">10 %</option>
                  </select>
                  {updateErrors.commissionRateForUpdate && (
                    <p className="text-danger fw-500 fs-13 my-2 text-capitalize">
                      {updateErrors.commissionRateForUpdate}
                    </p>
                  )}
                </Col>

                <Col lg={12} className="my-2 text-center">
                  <button
                    title="Click to Update"
                    type="submit"
                    className="main-btn"
                  >
                    {isLoading ? "Updating..." : "Update"}
                  </button>
                </Col>
              </Row>
            )}
          </form>
        </div>
      </CustomModal>
      {/* -------------------SET Update Sale Person Target Model-----------------------End------------------------ */}
    </>
  );
};

export default Index;
